import { Button, Input, Table } from "antd";
import _ from "lodash";
import { useState } from "react";
import parseJson from "./parseJson";

const { TextArea } = Input;

const parseErrorMessages = (errorMessages) =>
  _.merge(
    ...errorMessages.slice(0, 10).map((msg) => {
      // error messages look like "data.0.email is not valid", we're looking
      // for the 0.email part
      const [, idx, field] = msg.match(/^[^.]+[.](\d+)[.](\S+)/) ?? [];
      if (idx && field) {
        return { [idx]: { [field]: true } };
      } else {
        return {};
      }
    })
  );

const errorProps = { style: { background: "pink" } };

const tryParseJson = (param, value) => {
  try {
    return parseJson(param, value);
  } catch (e) {
    return undefined;
  }
};

const JsonTable = ({ param, value, errors }) => {
  const fieldNames = Object.keys(param.element.fields);
  const errorMap = parseErrorMessages(errors);
  const data = tryParseJson(param, value)?.map((x, _idx) => ({ ...x, _idx }));
  return (
    <Table
      dataSource={data}
      columns={fieldNames.map((field) => ({
        title: field,
        dataIndex: field,
        onCell: (_, idx) => (errorMap?.[idx]?.[field] ? errorProps : undefined),
      }))}
      pagination={false}
      scroll={{ x: "min-content", y: "40vh" }}
      rowKey="_idx"
    />
  );
};

const displayNoneStyle = { display: "none" };

/**
 * An input that parses JSON, CSV, and TSV, and allows previewing values in an
 * Ant Table.
 */
const JsonArrayInput = ({ form, name, param, value, onChange, ...props }) => {
  const [showTable, setShowTable] = useState(false);
  return (
    <div>
      {/*
        hiding the text input instead of removing it from the DOM since I
        assume that wouldn't play well with ant form
      */}
      <div style={showTable ? displayNoneStyle : undefined}>
        <TextArea
          placeholder="Enter JSON, or paste CSV or TSV (e.g. from a google sheet) with headers"
          value={value}
          onChange={onChange}
          {...props}
        />
        <Button onClick={() => setShowTable(true)} size="small" type="primary">
          Preview as table
        </Button>
      </div>
      {showTable && (
        <div>
          <JsonTable
            param={param}
            value={value}
            errors={form.getFieldError(name)}
          />
          <Button
            onClick={() => setShowTable(false)}
            size="small"
            type="primary"
          >
            Edit as text
          </Button>
        </div>
      )}
    </div>
  );
};

export default JsonArrayInput;
