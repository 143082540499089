import { SettingOutlined } from "@ant-design/icons";
import { Button, Col as Column, Dropdown, Row } from "antd";

import React from "react";

const ActionMenu = ({ actions }) => {
  /**
   * @type {{ label: string, key: string, icon: JSX.Element }}
   */
  const menuItems = actions.reduce(
    (menuItems, { key, icon, label }) => [...menuItems, { key, icon, label }],
    []
  );

  /**
   * @type {Record<string, () => void>}
   */
  const onMenuItemClickHandlers = actions.reduce(
    (clickHandlers, action) => ({
      ...clickHandlers,
      [action.key]: action.onClickHandler,
    }),
    {}
  );

  /**
   * @param   {{ key: string }} event
   * @returns {void}
   */
  const onMenuItemClickHandler = (event) =>
    onMenuItemClickHandlers[event.key]?.();

  const menuProps = {
    items: menuItems,
    onClick: onMenuItemClickHandler,
  };

  return (
    <>
      <Row justify="space-between">
        <Column
          span={24}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Dropdown
            menu={menuProps}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button>
              <SettingOutlined></SettingOutlined>
            </Button>
          </Dropdown>
        </Column>
      </Row>
    </>
  );
};

export { ActionMenu };
