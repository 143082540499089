import { createContext, useContext, useState } from "react";

const UIContext = createContext();
const useUIContext = () => useContext(UIContext);

function UIProvider({ children }) {
  const [showAdmin, shouldShowAdmin] = useState(
    window.location?.pathname.includes("/admin/")
  );

  return (
    <UIContext.Provider
      value={{
        showAdmin,
        updateView: (shouldShow, history) => {
          shouldShowAdmin(shouldShow);
          if (shouldShow) {
            history.push("/organizations");
          } else {
            history.push("/order");
          }
        },
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

export { UIContext, UIProvider, useUIContext };

// Consider using this style, with useReducer instead of useState in the Provider, if things get more complex

// function uiContextReducer(state, action) {
//   switch (action.type) {
//     case "something": {
//       return { state, ...action.something };
//     }
//     default: {
//       throw new Error(`Unhandled action type: ${action.type}`);
//     }
//   }
// }

// function UIProvider({ children }) {
//   const [state, dispatch] = useReducer(uiContextReducer, { myProperty: "somethingNew" });
//   // NOTE: you *might* need to memoize this value
//   // Learn more in http://kcd.im/optimize-context
//   const value = { state, dispatch };
//   return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
// }
