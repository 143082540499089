import produce from "immer";
import moment from "moment";

const getDefaultState = () => {
  return {};
};

const reports = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "REPORTS_GET":
      const sites = {};
      if (action.payload.data) {
        action.payload.data.report.forEach((item) => {
          if (sites[item.sample_location_id]) {
            sites[item.sample_location_id].push({
              attributes: item.attributes,
              sample_date: item.kit_meta_data.sample_date,
            });
          } else {
            sites[item.sample_location_id] = [
              {
                attributes: item.attributes,
                sample_date: item.kit_meta_data.sample_date,
              },
            ];
          }
        });
      }
      Object.keys(sites).forEach((key) => {
        sites[key] = {
          dateRange: {
            startDate: "",
            endDate: "",
          },
          data: sites[key].sort(
            (a, b) =>
              moment(a.sample_date).format("x") -
              moment(b.sample_date).format("x")
          ),
        };
      });

      Object.keys(sites).forEach((key) => {
        sites[key].dateRange.startDate = sites[key].data[0].sample_date;
        sites[key].dateRange.endDate =
          sites[key].data[sites[key].data.length - 1].sample_date;
      });

      nextState = produce(state, (draftState) => {
        draftState.info = action.payload.data.info;
        draftState.report = action.payload.data.report;
        draftState.bySite = sites;
      });
      return nextState;
    case "CASES_GET":
      nextState = produce(state, (draftState) => {
        const array = [];
        action.payload.data.forEach((item) => {
          array.push({
            date: moment(item.case_date).format("YYYY-MM-DD"),
            cases: item.cases,
            average: item.rolling_avg_7_days,
          });
        });
        array.sort(
          (a, b) => moment(a.date).format("x") - moment(b.date).format("x")
        );
        draftState.cases = array;
      });
      return nextState;
    default:
      return state;
  }
};

export default reports;
