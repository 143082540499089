import axios from "axios";
import qs from "qs";
import _ from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { authorizationHeader, mkQuery, orderServiceRoot } from "./util";

export const orderServiceKey = ({ model, ...args }) => [
  { service: "biobot-api", model, ...args },
];

const joinsParam = (joins) => {
  if (joins === false || (Array.isArray(joins) && joins.length === 0)) {
    return ""; // Empty string means no joins
  }
  if (Array.isArray(joins)) {
    return joins;
  }
  // qs omits undefined, so this results in the default strapi behavior, which
  // is to include one level of relations.
  return undefined;
};

// -- Raw requests --

export const orderServiceFetchOne = async ({ model, id, _joins, _fields }) => {
  const { data } = await axios({
    method: "get",
    url: `${orderServiceRoot}/${model}/${encodeURIComponent(id)}`,
    params: {
      _joins: joinsParam(_joins),
      _fields,
    },
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  });
  return data;
};

export const orderServiceFetchList = async ({
  model,
  _where,
  _start,
  _limit,
  _sort,
  _joins,
  _fields,
}) => {
  const { data } = await axios({
    method: "get",
    url: `${orderServiceRoot}/${model}`,
    params: {
      _where,
      _start,
      _limit,
      _sort,
      _joins: joinsParam(_joins),
      _fields,
    },
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  });
  return data;
};

export const orderServiceFetchCsv = async ({
  model,
  path = "",
  _where,
  _sort,
  as_csv = true,
}) => {
  const { data } = await axios({
    method: "get",
    url: `${orderServiceRoot}/${model}/${path}`,
    params: {
      _where,
      _sort,
      as_csv,
    },
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  });
  return data;
};

export const orderServiceFetchCount = async ({ model, _where }) => {
  const { data } = await axios({
    method: "get",
    url: `${orderServiceRoot}/${model}/count`,
    params: {
      _where,
    },
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  });
  return data;
};

export const orderServiceCreate = async ({ model, data }) => {
  const res = await axios({
    method: "post",
    url: `${orderServiceRoot}/${model}`,
    data,
    headers: authorizationHeader(),
  });
  return res.data;
};

export const orderServiceExport = async ({
  model,
  _where,
  _sort,
  _limit,
  as_csv,
}) => {
  const { data } = await axios({
    method: "post",
    url: `${orderServiceRoot}/${model}/export`,
    data: {
      _where,
      _sort,
      _limit,
      as_csv,
    },
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  });
  return data;
};

// todo: Maybe combine this with `orderServiceExport`?
// For now it fixes a bug so its worth the bad abstraction.
export const orderServiceTable = async ({
  model,
  _where,
  _sort,
  _limit,
  as_csv,
}) => {
  const { data } = await axios({
    method: "get",
    url: `${orderServiceRoot}/${model}/table`,
    params: {
      _where,
      _sort,
      _limit,
      as_csv,
    },
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  });
  return data;
};

export const orderServiceUpdate = async ({ model, data, params }) => {
  const res = await axios({
    method: "put",
    url: `${orderServiceRoot}/${model}/${encodeURIComponent(data.id)}`,
    params,
    data,
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  });
  return res.data;
};

export const orderServiceCustomRoute = async ({ model, path, ...args }) => {
  const res = await axios({
    url: `${orderServiceRoot}/${model}/${path}`,
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
    ...args,
  });
  return res.data;
};

// -- React query hooks --

export const orderServiceFindOneQuery = ({ model, defaults = {}, ...opts }) =>
  mkQuery({
    key: ({ id, _joins = defaults._joins, _fields = defaults._fields }) =>
      orderServiceKey({ model, endpoint: "findOne", id, _joins, _fields }),
    query: orderServiceFetchOne,
    ...opts,
  });
export const orderServiceListQuery = ({ model, defaults = {}, ...opts }) =>
  mkQuery({
    key: ({
      _where = defaults._where,
      _start = defaults._start,
      _limit = defaults._limit,
      _sort = defaults._sort,
      _joins = defaults._joins,
      _fields = defaults._fields,
    } = {}) =>
      orderServiceKey({
        model,
        endpoint: "list",
        _where,
        _start,
        _limit,
        _sort,
        _joins,
        _fields,
      }),
    query: orderServiceFetchList,
    ...opts,
  });

export const orderServiceCountQuery = ({ model, defaults = {}, ...opts }) =>
  mkQuery({
    key: ({ _where = defaults._where } = {}) =>
      orderServiceKey({ model, endpoint: "count", _where }),
    query: orderServiceFetchCount,
    ...opts,
  });

export const orderServiceCustomQuery = ({ model, path, ...opts }) => {
  const pathReplacements = [...path.matchAll(/:([^/]+)/g)].map(([, p]) => p);
  return mkQuery({
    key: ({ ...rest } = {}) =>
      orderServiceKey({
        model,
        endpoint: "custom",
        path,
        pathParams: _.pick(rest, pathReplacements),
        params: _.omit(rest, pathReplacements),
      }),
    async query({ model, path, pathParams, params }) {
      const pathWithParams = path.replaceAll(/:([^/]+)/g, (_, param) =>
        encodeURIComponent(pathParams[param])
      );
      const res = await axios({
        method: "get",
        url: `${orderServiceRoot}/${model}/${pathWithParams}`,
        params: params,
        headers: authorizationHeader(),
        paramsSerializer: qs.stringify,
      });
      return res.data;
    },
    ...opts,
  });
};

// When we create or update a record, invalidate all queries having to do with
// this model. This invalidation is intentionally broad since a lot of things
// can change after an update (what shows up in a list, sorting, counts), and
// better to be safe than to miss something.
const useInvalidateModelQueries = ({ model }) => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(orderServiceKey({ model }));
};

export const orderServiceCreateMutation = ({ model }) => () => {
  const invalidate = useInvalidateModelQueries({ model });
  return useMutation((data) => orderServiceCreate({ model, data }), {
    onSuccess: invalidate,
  });
};

export const orderServiceUpdateMutation = ({ model }) => (params) => {
  const invalidate = useInvalidateModelQueries({ model });
  return useMutation((data) => orderServiceUpdate({ model, data, params }), {
    onSuccess: invalidate,
  });
};

export const orderServiceCustomMutation = ({ model, path, method }) => (
  params
) => {
  const invalidate = useInvalidateModelQueries({ model });
  return useMutation(
    (data) => orderServiceCustomRoute({ model, path, method, data, params }),
    {
      onSuccess: invalidate,
    }
  );
};

// TODO: if we just got rid of mkQuery composition would be a lot easier
export const useOrderServiceList = mkQuery({
  key: ({ model, _where, _start, _limit, _sort, _joins, _fields }) =>
    orderServiceKey({
      model,
      endpoint: "list",
      _where,
      _start,
      _limit,
      _sort,
      _joins,
      _fields,
    }),
  query: orderServiceFetchList,
});

export const useOrderServiceCount = mkQuery({
  key: ({ model, _where }) =>
    orderServiceKey({ model, endpoint: "count", _where }),
  query: orderServiceFetchCount,
});
