import { Row, Col, Typography, Form, Checkbox } from "antd";
import { checkedValidator } from "../../helpers";
import { fixtures } from "../../fixtures";
import { useSignupContext } from "context/SignUpContext";

const { Title, Text } = Typography;

export default function ProgramAgreements({ agreementMessage }) {
  const { variant } = useSignupContext();
  const formFixtures = fixtures[variant];

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>{formFixtures.programName} Agreements</Title>
        </Col>
      </Row>

      {formFixtures.programAgreements.map((field, index) => (
        <Form.Item
          key={index}
          name={`participant_${index + 1}`}
          valuePropName="checked"
          noStyle
          rules={[{ required: true, validator: checkedValidator }]}
        >
          <Row className="mb-2">
            <Col>
              <Checkbox>
                <Text>{field}</Text>
              </Checkbox>
            </Col>
          </Row>
        </Form.Item>
      ))}
    </>
  );
}
