import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

import {
  LCMS_UPLOAD_ASSAY_OPTIONS,
  useBulkCreateLcmsUpload,
  useLcmsUploadSchemaVersionList,
} from "api/fileUpload";
import { HRS_ASSAY } from "configs/constants";
import { VersionSelector, UploadResultTable } from "utils/components";
import SingleUploadFormFields from "./SingleUploadFormFields";

const LcmsUploadFormPage = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResults, setUploadResults] = useState();
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
  const { mutateAsync: bulkCreateLcmsUploads } = useBulkCreateLcmsUpload();
  const { data: uploadSchemaVersions } = useLcmsUploadSchemaVersionList();

  const handleFormSubmit = async (formValue) => {
    setIsLoading(true);
    message.loading("Uploading LCMS...", 0);
    const results = await bulkCreateLcmsUploads(formValue);
    setUploadResults(results);
    message.destroy();
    setIsResultsModalOpen(true);
    setIsLoading(false);
  };

  const handleFileUpload = async (file, fieldPath) => {
    const fieldVal = form.getFieldValue(fieldPath) || {};
    fieldVal["uploaded_file"] = file;
    form.setFieldValue(fieldPath, fieldVal);
  };

  const handleModalAction = () => {
    setIsResultsModalOpen(false);
    setUploadResults(false);
  };

  return (
    <>
      <PageHeader title="LCMS Upload Submission" />
      <Form
        name="lcms-upload"
        layout="vertical"
        autoComplete="off"
        className="px-3 py-4"
        form={form}
        onFinish={handleFormSubmit}
      >
        {/* Submitted By */}
        <Row>
          <Col span={10}>
            <Form.Item name="form_submitted_by" label="Submitted By" required>
              <Input placeholder="Initials" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          {/* LCMS Ran By */}
          <Col span={5}>
            <Form.Item name="lcms_ran_by" label="Person who ran LCMS">
              <Input placeholder="Initials" />
            </Form.Item>
          </Col>

          {/* Date of LCMS review completion */}
          <Col span={5}>
            <Form.Item
              name="lcms_reviewed_at"
              label="Date of LCMS Review Completion"
            >
              <DatePicker
                className="w-100"
                format={(date) => date.utc().format()}
              />
            </Form.Item>
          </Col>

          {/* Date of LCMS */}
          <Col span={5}>
            <Form.Item name="date_of_lcms" label="Date of LCMS">
              <DatePicker
                className="w-100"
                format={(date) => date.utc().format()}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Upload Schema Version */}
        <Row>
          <Col span={5}>
            <VersionSelector
              name="upload_schema_version"
              label="What format is the tracefinder CSV output in?"
              versionNumberPrefix="v"
              versions={uploadSchemaVersions}
              required={true}
            />
          </Col>
          <Col span={5}>
            <Form.Item
              name="assay_name"
              label="Assay Name"
              required
              initialValue={HRS_ASSAY}
            >
              <Select options={LCMS_UPLOAD_ASSAY_OPTIONS} />
            </Form.Item>
          </Col>
        </Row>

        {/* Upload List */}
        <Form.List name={"uploads"} initialValue={[{}]}>
          {(fields, { add: addUpload, remove: removeUpload }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restUploadField }) => (
                <SingleUploadFormFields
                  key={key}
                  uploadFieldIdx={name}
                  onUpload={handleFileUpload}
                  onRemove={() => removeUpload(name)}
                  {...restUploadField}
                />
              ))}
              <Form.ErrorList errors={errors} />
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => addUpload()}
              >
                Add Upload
              </Button>
            </>
          )}
        </Form.List>

        <Divider className="mt-5" />
        <Space align="center" className="flex justify-center">
          <Popconfirm
            title="Reset Form?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => form.resetFields()}
          >
            <Button disabled={isLoading}>Reset</Button>
          </Popconfirm>
          <Form.Item className="mb-0">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <Modal
        title="LCMS Upload Results"
        open={isResultsModalOpen}
        onOk={handleModalAction}
        onCancel={handleModalAction}
      >
        <UploadResultTable uploadResults={uploadResults} />
      </Modal>
    </>
  );
};

export default LcmsUploadFormPage;
