import _ from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Radio, Form, Col, Row, Typography } from "antd";
import { actionProductsGet } from "actions";
import "./styles.css";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return {
      accountType: state.organization.account_type,
      products: state.products,
    };
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionProductsGet,
};

const { Title } = Typography;

const ProductList = ({ accountType, actionProductsGet, products }) => {
  useEffect(() => {
    if (accountType) {
      actionProductsGet(accountType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  const [productsPerGroup, setProductsPerGroup] = useState({});

  useEffect(() => {
    if (products) {
      setProductsPerGroup(
        _.groupBy(products, "product_group.product_group_name")
      );
    }
  }, [products]);

  const { Text } = Typography;

  return (
    <Row className="mb-3">
      <Col span={24}>
        <Title level={3}>Products</Title>
      </Col>

      <Col span={24} data-cy="products-list">
        <Form.Item
          id="order-form_sku"
          data-cy="order-form_sku"
          name={"sku"}
          label="Select product"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            {Object.entries(productsPerGroup)
              .sort()
              .map(([productGroup, products], i) => {
                const productGroupTitle =
                  productGroup !== "undefined" ? productGroup : "Other";
                return (
                  <Col key={`${i}${productGroupTitle}`}>
                    <Title level={5}>{productGroupTitle}</Title>
                    {_.orderBy(products, ["product_price"], ["desc"]).map(
                      (product, index) => {
                        const productKey = `${productGroup}-${index}`;
                        return (
                          <Row key={productKey}>
                            <Col className="product mb-2 p-2" span={24}>
                              <Radio key={productKey} value={product.id}>
                                <Text
                                  strong
                                  id="product"
                                  data-cy="order-form-sku"
                                >
                                  ${product.product_price}
                                </Text>
                                <Text strong> - {product.product_sku}</Text>
                                <Text> - {product.product_name}</Text>
                              </Radio>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                  </Col>
                );
              })}
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};

const ProductListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);
export default ProductListContainer;
