import { Button, Form, Input, PageHeader, Space, Spin, Typography } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useExecuteRunbook, useRunbook } from "api/runbook";
import { parseValue, RunbookInput } from "./Inputs";
import MarkdownInstructions from "./MarkdownInstructions";

const { Text } = Typography;

const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
  // labelWrap: true, // needs ant 4.18
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const payloadStyle = {
  fontFamily: "monospace",
  background: "#eee",
};

const PayloadPreview = ({ payload, ...props }) => (
  <Input.TextArea
    readOnly
    style={payloadStyle}
    {...props}
    value={JSON.stringify(payload, null, 2)}
  />
);

const parseFormPayload = (runbookParams, values) =>
  _.mapValues(runbookParams, (param, key) => parseValue(param, values[key]));

const Runbook = ({
  id,
  label,
  description,
  instructions,
  params,
  category,
}) => {
  const initialValues = _.mapValues(params, "default");

  const history = useHistory();
  const [form] = Form.useForm();
  const [result, setResult] = useState(null);
  const [payload, setPayload] = useState(() =>
    parseFormPayload(params, initialValues)
  );

  const execute = useExecuteRunbook({
    id,
    onSuccess: setResult,
    onError(error) {
      setResult(error?.response?.data ?? "Unknown error");
    },
  });

  const handleReset = () => form.resetFields();

  return (
    <div>
      <PageHeader
        title={label}
        onBack={() => history.push(`/runbooks/category/${category}`)}
      />
      <div className="px-3">
        <Text>{description}</Text>
        {instructions && <MarkdownInstructions instructions={instructions} />}
        <Form
          {...formLayout}
          form={form}
          onFinish={() => execute.mutate(payload)}
          initialValues={initialValues}
          onValuesChange={(_, values) =>
            setPayload(parseFormPayload(params, values))
          }
        >
          {Object.entries(params).map(([name, param]) => (
            <RunbookInput form={form} key={name} name={name} param={param} />
          ))}
          <Form.Item label="Payload">
            <PayloadPreview payload={payload} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button onClick={handleReset}>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={execute.isLoading}
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
        {result && <pre>{JSON.stringify(result, null, 2)}</pre>}
      </div>
    </div>
  );
};

const RunbookWrapper = ({ id }) => {
  const { data, isLoading } = useRunbook({ id });
  if (data) {
    return <Runbook {...data} />;
  } else if (isLoading) {
    return <Spin />;
  } else {
    return `Error fetching runbook with id: ${id}`;
  }
};

export default RunbookWrapper;
