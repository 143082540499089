import { Form, Input, Select, Typography, DatePicker } from "antd";
import moment from "moment";

import { REWORK_STATUS_OPTIONS } from "api/rework";

const COMPLETE_STATUS = "COMPLETED";

const EditReworkFormModal = ({ record, form }) => {
  const handleStatusFieldChange = (value) => {
    if (value === COMPLETE_STATUS && !form.getFieldValue("date_completed")) {
      form.setFieldValue("date_completed", moment());
    } else if (value !== COMPLETE_STATUS) {
      form.setFieldValue("date_completed", null);
    }
  };

  const handleDateCompletedFieldChange = (value) => {
    if (value) {
      form.setFieldValue("status", COMPLETE_STATUS);
    }
  };

  return (
    <div>
      <Typography.Title level={3}>Edit Rework</Typography.Title>
      <div>
        <b>Kit ID: </b>
        {record.kit?.kit_id}
      </div>
      <div>
        <b>Shipping Kit ID: </b>
        {record.kit?.shipping_kit_id}
      </div>
      <Form className="mt-5" layout="vertical" form={form}>
        <Form.Item
          name="reason_for_rework_and_requested_changes"
          label="Reason for Rework and Requested Changes"
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item name="notes_for_lab_team" label="Notes">
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          help={
            'Reworks with a completed date are automatically marked as "Completed"'
          }
        >
          <Select
            className="w-50"
            options={REWORK_STATUS_OPTIONS()}
            onChange={handleStatusFieldChange}
          />
        </Form.Item>

        <Form.Item
          name="date_completed"
          label="Lab or report processing completed date"
          className="mt-5"
          wrapperCol={{ span: 12 }}
        >
          <DatePicker
            showToday="true"
            style={{ width: "100%" }}
            onChange={handleDateCompletedFieldChange}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditReworkFormModal;
