const orders = (state = [], action) => {
  switch (action.type) {
    case "ORDERS_GET":
      if (action.payload) {
        return action.payload.data?.sort((a, b) => a.id - b.id);
      }
      return {};
    default:
      return state;
  }
};

export default orders;
