import { Form, Select } from "antd";
import React from "react";

export default function FacilityTypeSelector({
  facilityTypes,
  initialValue,
  onSelect,
}) {
  const options = (facilityTypes ?? []).map((t) => {
    return {
      value: t.id,
      label: t.display_text,
    };
  });

  return (
    <>
      <Form.Item
        name="facility_type"
        label="Select the option that best describes your building sample location"
        className="mb-0"
        initialValue={initialValue?.id}
      >
        <Select
          data-cy="select-facility-type"
          options={options}
          placeholder="Select an option"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(value) => {
            if (onSelect) {
              onSelect(value);
            }
          }}
        />
      </Form.Item>
    </>
  );
}
