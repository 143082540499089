import { Tag, Tooltip } from "antd";
import moment from "moment";

import {
  PROGRAM_CDC_CODE,
  PROGRAM_NIDA_CODE,
  PROGRAM_CITY_OF_BERKELEY_CODE,
  PROGRAM_BIOBOT_NETWORK_CODE,
  PROGRAM_HHS_CODE,
  PROGRAM_FLU_CODE,
  PROGRAM_VISUALIZATION_CODE,
  PROGRAM_MADPH_WWTP_CODE,
  PROGRAM_MADPH_BUILDING_CODE,
  PROGRAM_MADOC_CODE,
  PROGRAM_MAINE_RFP_CODE,
  PROGRAM_PA_RFP_CODE,
  PROGRAM_ARMY_CODE,
  PROGRAM_JPMC_CODE,
  PROGRAM_GOLDMAN_CODE,
  PROGRAM_USAFA_CODE,
  PROGRAM_DC_RFP_BUILDING_CODE,
  PROGRAM_DC_RFP_WARD_CODE,
  PROGRAM_MWRA_CODE,
  PROGRAM_CAMBRIDGE_CODE,
  BOSTON_PHC_CODE,
  CDC_MPX_PROGRAM_CODE,
  CDC_MPX_2X_PROGRAM_CODE,
  MADPH_MPX_PROGRAM_CODE,
  ME_RFP_2023_PROGRAM_CODE,
  PROGRAM_GOUCHER_2022_CODE,
  PROGRAM_GOUCHER_COVID_2022_CODE,
  PROGRAM_SAN_JOAQUIN_2022_CODE,
  PROGRAM_LOUISVILLE_MSD_CODE,
  PROGRAM_PFIZER_2023_CODE,
  PROGRAM_MONTGOMERY_CTY_2023_CODE,
  PROGRAM_MONTGOMERY_CTY_BUILDING_2023_CODE,
  PROGRAM_MAHOC_2022_CODE,
  PROGRAM_NEWARK_LIBERTY_AIRPORT_CODE,
  PROGRAM_FLU_RSV_1X,
  PROGRAM_FLU_RSV_2X,
  PROGRAM_FLU_RSV_3X,
} from "configs/constants";
import Text from "antd/lib/typography/Text";

const ProgramTag = ({
  displayName,
  shortDisplayName,
  code,
  startDate,
  endDate,
}) => {
  let tagColor;
  switch (code) {
    case "liveProgram":
      tagColor = "green";
      break;
    case "pastProgram":
      tagColor = "default";
      break;
    case PROGRAM_CDC_CODE:
    case PROGRAM_NIDA_CODE:
    case PROGRAM_CITY_OF_BERKELEY_CODE:
    case PROGRAM_HHS_CODE:
    case PROGRAM_MADPH_WWTP_CODE:
    case PROGRAM_MADPH_BUILDING_CODE:
    case PROGRAM_MAINE_RFP_CODE:
    case PROGRAM_PA_RFP_CODE:
    case PROGRAM_ARMY_CODE:
    case PROGRAM_DC_RFP_BUILDING_CODE:
    case PROGRAM_DC_RFP_WARD_CODE:
    case PROGRAM_MWRA_CODE:
    case PROGRAM_CAMBRIDGE_CODE:
    case BOSTON_PHC_CODE:
    case CDC_MPX_PROGRAM_CODE:
    case CDC_MPX_2X_PROGRAM_CODE:
    case ME_RFP_2023_PROGRAM_CODE:
    case MADPH_MPX_PROGRAM_CODE:
    case PROGRAM_SAN_JOAQUIN_2022_CODE:
    case PROGRAM_MONTGOMERY_CTY_2023_CODE:
    case PROGRAM_MONTGOMERY_CTY_BUILDING_2023_CODE:
    case PROGRAM_MAHOC_2022_CODE:
    case PROGRAM_LOUISVILLE_MSD_CODE:
    case PROGRAM_NEWARK_LIBERTY_AIRPORT_CODE:
    case PROGRAM_FLU_RSV_1X:
    case PROGRAM_FLU_RSV_2X:
    case PROGRAM_FLU_RSV_3X:
      tagColor = "magenta"; // governmental
      break;
    case PROGRAM_GOUCHER_2022_CODE:
    case PROGRAM_GOUCHER_COVID_2022_CODE:
      tagColor = "orange"; // public sector non-governmental
      break;
    case PROGRAM_FLU_CODE:
      tagColor = "purple";
      break;
    case PROGRAM_BIOBOT_NETWORK_CODE:
    case PROGRAM_VISUALIZATION_CODE:
      tagColor = "green"; // viz
      break;
    case PROGRAM_MADOC_CODE:
    case PROGRAM_JPMC_CODE:
    case PROGRAM_GOLDMAN_CODE:
    case PROGRAM_USAFA_CODE:
    case PROGRAM_PFIZER_2023_CODE:
      tagColor = "geekblue"; // enterprise
      break;
    default:
      tagColor = "black";
  }

  let tooltip;
  if (startDate && endDate) {
    tooltip = (
      <Text style={{ color: "white" }}>
        Enrollment Start: {startDate}
        <br />
        Enrollment End: &nbsp;&nbsp;{endDate}
      </Text>
    );

    const today = moment().format("YYYY-MM-DD");
    if (endDate < today || startDate > today) tagColor = "default";
  }

  let programNameToDisplay = "OTHER";
  if (displayName) {
    programNameToDisplay = displayName;
  }
  if (shortDisplayName) {
    programNameToDisplay = shortDisplayName;
  }

  return (
    <>
      <Tooltip title={tooltip}>
        <Tag color={tagColor} style={{ textTransform: "uppercase" }}>
          {programNameToDisplay}
        </Tag>
      </Tooltip>
    </>
  );
};
export default ProgramTag;
