import _ from "lodash";

import {
  AliquotStatus,
  AssayName,
  ManualReviewStatus,
} from "../../../api/pipelinesAPI/types";

export const assayDisplayMap: Record<AssayName, string> = {
  [AssayName.COVID]: "Covid",
  [AssayName.RESP]: "Respiratory Panel",
  [AssayName.MPX]: "Monkeypox",
  [AssayName.NORO]: "Noro",
};

export function castStatuses(data: any[]): AliquotStatus[] {
  return data.map((x) => {
    const status: keyof typeof ManualReviewStatus = x.status;
    return {
      ...x,
      aliquot_status_id: x.aliquot_status_id,
      status: status,
      qpcr_aliquot: x.qpcr_aliquot,
    };
  });
}

export function missingLogPredicate(s: AliquotStatus): boolean {
  return s.status === "flagged_missing_log";
}

/*
 Given a dictionary that maps a category to a numeric rank, will sort a list of items based on that category.
 The category function should map an item to a category.
 */
const rankedListComparator = (
  ranks: Record<string, number>,
  category: (item: any) => string
) => (l: any, r: any): number => {
  if (_.isNil(l)) {
    return 1;
  } else if (_.isNil(r)) {
    return -1;
  }

  const lRank = ranks[category(l)] || 100; // Any unrecognized items will go last
  const rRank = ranks[category(r)] || 100;

  return lRank - rRank;
};

export const kitReviewOrderComparator = rankedListComparator(
  {
    [ManualReviewStatus.STATUS_PENDING_RERUN]: 1,
    [ManualReviewStatus.STATUS_FLAGGED_RERUN]: 1,
    [ManualReviewStatus.STATUS_PENDING_REVIEW]: 2,
    [ManualReviewStatus.STATUS_NO_OVERRIDE]: 3,
    [ManualReviewStatus.STATUS_APPROVED]: 3,
    [ManualReviewStatus.STATUS_REJECTED]: 3,
    [ManualReviewStatus.STATUS_INVALID]: 3,
    [ManualReviewStatus.STATUS_MISSING_LOG]: 3,
  },
  (status: AliquotStatus) => status.status
);

export const assayReviewOrderComparator = rankedListComparator(
  {
    [AssayName.COVID]: 1,
    [AssayName.RESP]: 2,
    [AssayName.NORO]: 3,
    [AssayName.MPX]: 4,
  },
  (assayName: AssayName) => assayName
);

export function createKitReviewOrder(statuses: AliquotStatus[]) {
  return _.uniq(
    statuses
      .sort(kitReviewOrderComparator)
      .map((s) => s.qpcr_aliquot.tube.kit.kit_name)
  );
}
