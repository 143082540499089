import { Button, Modal } from "antd";

import ModalFooter from "components/ModalFooter";

import ProgramEnrollmentForm from "./ProgramEnrollmentForm";

const ProgramEnrollmentModal = ({
  title = "Program Enrollment",
  onCancel,
  onOk,
  initialValues,
  ...props
}) => {
  return (
    <Modal destroyOnClose={true} onCancel={onCancel} footer={null} {...props}>
      <ProgramEnrollmentForm
        preserve={false}
        showButtons={true}
        initialValues={initialValues}
        onFinish={onOk}
        buttonsComponent={({ loading }) => (
          <ModalFooter>
            <Button onClick={onCancel}>Cancel</Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              OK
            </Button>
          </ModalFooter>
        )}
      />
    </Modal>
  );
};

export default ProgramEnrollmentModal;
