import { useEffect, useState } from "react";
import { Select, message } from "antd";
import { useOrganizationList } from "api/organization";
import { useUserContext } from "context";

export default function OrgImpersonationSelect({
  actionOrderReset,
  actionOrganizationGet,
}) {
  const { organizationId, updateUser } = useUserContext();

  const { data: organizations, isLoading } = useOrganizationList(
    { _limit: 5000 },
    { onError: () => message.error("Error loading organizations") }
  );

  const [activeAccountId, setActiveAccountId] = useState(organizationId);

  useEffect(() => {
    actionOrganizationGet({
      id: activeAccountId,
    })
      .then((res) => {
        const { data } = res?.payload;

        if (!data) {
          message.error("Error loading account");
        } else {
          updateUser({
            organizationId: data[0].id,
            organizationName: data[0].organization_name,
            organizationContactEmail: data[0].contact_email,
          });
          // This message annoyingly appears on first pageload for now,
          // but that behavior will go away with the changes coming in META-2153
          message.success("Account switched");
        }
      })
      .catch((err) => {
        message.error("Error loading account");
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId]);

  let selectAccount;

  selectAccount = (
    <Select
      id="select-account"
      data-test-id="select-account"
      value={organizations ? activeAccountId : null}
      loading={isLoading}
      onChange={(value) => {
        actionOrderReset();
        setActiveAccountId(value);
      }}
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      className="full-width"
    >
      {organizations?.length > 0 &&
        organizations.map((item) => {
          return (
            <Select.Option
              key={item.id}
              value={item.id}
              data-test-id={item.organization_name}
            >
              {item.organization_name}
            </Select.Option>
          );
        })}
    </Select>
  );

  if (organizations?.length === 1) {
    selectAccount = <h3>{organizations[0].organization_name}</h3>;
  }

  return selectAccount;
}
