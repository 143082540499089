import { Table } from "antd";
import { columnSearchProps } from "../../components/Table/SearchFilter";
import { useTableQueryParams } from "../../components/Table/helpers";
import { DemoCard, DemoContainer } from "../util";
import {
  useShippingLocationList,
  useShippingLocationCount,
  useOrganizationFilter,
} from "./queries";

import usStates from "../../data/us/admin.json";
import caProvinces from "../../data/ca/admin.json";

// Enum-like filters can be hard-coded
const stateFilter = [
  ...usStates.map(({ name, abbreviation }) => ({
    text: name,
    value: abbreviation,
  })),
  ...caProvinces.map(({ name }) => ({ text: name, value: name })),
];

const countryFilter = [
  { text: "USA", value: "USA" },
  { text: "Canada", value: "Canada" },
];

const DemoTable = () => {
  // Filters that are derived from related data should use another query
  const { data: orgFilter } = useOrganizationFilter();

  // queryParams managed as a single piece of state
  const { queryParams, handleTableChange } = useTableQueryParams();

  // Separate query for table data and total row count
  const { data: locations, isLoading } = useShippingLocationList(queryParams);
  const { data: locationCount } = useShippingLocationCount(queryParams);

  const columns = [
    {
      title: "Organization",
      dataIndex: ["organization", "organization_name"],
      key: "organization",
      filters: orgFilter,
      filterMultiple: true,
      filterSearch: true,
      sorter: true,
    },
    {
      title: "Shipping Location",
      dataIndex: "name",
      key: "name_contains",
      sorter: true,
      ...columnSearchProps({ placeholder: "Search location name" }),
    },
    {
      title: "Address",
      render: ({ address_line_1, address_line_2 }) =>
        [address_line_1, address_line_2].filter(Boolean).join(" // "),
    },
    {
      title: "State",
      dataIndex: "state_id",
      key: "state_id",
      sorter: true,
      filters: stateFilter,
      filterMultiple: true,
      filterSearch: true,
    },
    {
      title: "Country",
      dataIndex: "country_id",
      key: "country_id",
      sorter: true,
      filters: countryFilter,
    },
  ];

  return (
    <Table
      rowKey="id"
      scroll={{ x: "min-content" }}
      loading={isLoading}
      columns={columns}
      pagination={{ total: locationCount }}
      dataSource={locations}
      onChange={handleTableChange}
    />
  );
};

const ReactQueryTableDemo = () => {
  return (
    <DemoContainer>
      <DemoCard title="Ant <Table> with react query">
        <DemoTable />
      </DemoCard>
    </DemoContainer>
  );
};

export default ReactQueryTableDemo;
