import axios from "axios";
import { identityRoot, mkQuery, setPublicToken } from "./util";

const PUBLIC_TOKEN_EXPIRATION = 1800 * 1000;

const baseKey = {
  service: "identity",
  resource: "public-token",
};

export const usePublicToken = mkQuery({
  key: ({ tokenType }) => [{ ...baseKey, tokenType }],
  query: async ({ tokenType }) => {
    const res = await axios({
      method: "post",
      url: `${identityRoot}/userTokens/types/${encodeURIComponent(tokenType)}`,
    });
    const token = res.data.accessToken;
    if (res.status === 201 && token) {
      setPublicToken(token);
      return token;
    }
    return null;
  },
  options: {
    staleTime: PUBLIC_TOKEN_EXPIRATION * 0.75,
    cacheTime: PUBLIC_TOKEN_EXPIRATION,
  },
});
