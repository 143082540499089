import { DatePicker, Form, Input } from "antd";
import React from "react";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  required,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputObj = inputType === "date" ? <DatePicker /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: required ?? true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputObj}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
