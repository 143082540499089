import { QueryClient } from "react-query";

const isRetriableError = (error) => {
  if (process.env.NODE_ENV === "test") {
    return false;
  }
  const status = error?.response?.status;
  if (status) {
    // Retry server errors, and any client errors that are retriable without
    // modification.
    return status >= 500 || status === 409 || status === 429;
  } else {
    // react-query retries everything by default, so keep that behavior for
    // non-http errors.
    return true;
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20 * 1000,
      retry: (failureCount, error) =>
        failureCount < 3 && isRetriableError(error),
      // this will be the default once v4 is released
      notifyOnChangeProps: "tracked",
    },
  },
});

export default queryClient;
