import { Row, Col, Typography } from "antd";
const { Text } = Typography;

export default function MaintenanceWarningBanner() {
  return (
    <Row className="info-banner block text-lg px-4 py-2">
      <Col>
        <Text>
          Biobot will undergo scheduled maintenance on Friday, October 28th at
          6:00 PM ET for approximately two hours. During this time, we will not
          be able to accept any form submissions. We apologize for any
          inconvenience during our server upgrade.
        </Text>
      </Col>
    </Row>
  );
}
