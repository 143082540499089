import {
  METHOD_TEXT_CONTAINS,
  strapiFilterProps,
} from "components/Table/StrapiFilter";

export const createProgramTableColumns = () => [
  {
    title: "Programs",
    dataIndex: "name",
    key: "name",
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_TEXT_CONTAINS],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Program Name",
    }),
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    sorter: true,
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
    render: (record) => {
      return <>{record === "4000-12-31" ? "TBD" : record}</>;
    },
    sorter: true,
  },
  {
    title: "Sample Frequency",
    dataIndex: "weekly_samples_desired",
    key: "weekly_samples_desired",
    render: (record) => {
      return record ? `${record}x per week` : "-";
    },
  },
  {
    title: "Products (SKUs)",
    dataIndex: "product_deliverables",
    key: "product_deliverables",
    width: 475,
    render: (record) => {
      return record.length
        ? record
            .map(
              ({ product }) =>
                `${product?.product_name} (${product?.product_sku})`
            )
            .join("; ")
        : "-";
    },
  },
  {
    title: "Short Name",
    dataIndex: "short_name",
    key: "short_name",
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_TEXT_CONTAINS],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Program Short/Internal Name",
    }),
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_TEXT_CONTAINS],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Program Code",
    }),
  },
];
