import {
  Col as Column,
  Row,
  Button,
  Form,
  DatePicker,
  Spin,
  message,
} from "antd";

import fileDownload from "js-file-download";

import { useState } from "react";

import { runFn } from "../helpers/run-function";

import { InitialsParameter } from "./InitialsParameter";

function getFilename(headers) {
  if (!headers["content-disposition"]) {
    return "download.csv";
  }

  return headers["content-disposition"].split("filename=")[1].split(";")[0];
}

function GTMolecularExportFn(props) {
  const { name } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const withHandling = (fn) => async (values) => {
    setLoading(true);

    try {
      await fn(values);

      form.resetFields();
    } catch (error) {
      message.error("Something went wrong!");

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFormSubmit = withHandling(async (values) => {
    const {
      initials,
      date_range: [start_date, end_date],
    } = values;

    const parameters = {
      author: initials,
      startDate: start_date.format("YYYY-MM-DD"),
      endDate: end_date.format("YYYY-MM-DD"),
    };

    const response = await runFn(name, { parameters }, { download: true });

    const fileName = getFilename(response.headers);

    fileDownload(response.data, fileName);
  });

  const DateRangeParameter = () => {
    const label = "Date Range";

    const name = "date_range";

    const rules = [
      {
        required: true,
        message: "Date Range is required!",
      },
    ];

    const formItemProps = {
      label,
      name,
      rules,
      required: true,
    };

    return (
      <Form.Item {...formItemProps}>
        <DatePicker.RangePicker style={{ width: "100%" }} />
      </Form.Item>
    );
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={onFormSubmit}>
        <Row gutter={12}>
          <Column span={6}>
            <DateRangeParameter />
          </Column>
        </Row>

        <Row gutter={12}>
          <Column span={6}>
            <InitialsParameter />
          </Column>
        </Row>

        <Row gutter={12}>
          <Column span={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Run
              </Button>
            </Form.Item>
          </Column>
        </Row>
      </Form>
    </Spin>
  );
}

export { GTMolecularExportFn };
