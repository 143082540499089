import { Button, Table, Typography } from "antd";

import { displayDate, renderPath } from "utils/table";
import QCIssueList from "components/QC/QCIssueList";
import QCReviewStatusTag from "components/QC/QCReviewStatusTag";
import { EditOutlined } from "@ant-design/icons";
import { AssayReviewState } from "reducers/reducerManualReview";

import { AliquotStatus, ManualReviewStatus } from "api/pipelinesAPI/types";

interface Props {
  assayReviewState: AssayReviewState;
  originalStatuses: AliquotStatus[];
  kitReviewOrder: string[];
  onKitSelected: (kitName: string) => void;
}

const StatusesTable = ({
  assayReviewState,
  originalStatuses,
  kitReviewOrder,
  onKitSelected,
}: Props) => {
  const kitReviewRank = Object.fromEntries(
    kitReviewOrder.map((kitName, index) => [kitName, index])
  );
  const sortedStatuses = originalStatuses.sort((a, b) => {
    const aRank = kitReviewRank[a.qpcr_aliquot.tube.kit.kit_name];
    const bRank = kitReviewRank[b.qpcr_aliquot.tube.kit.kit_name];

    return aRank - bRank;
  });

  const columns = [
    {
      title: "Kit Name",
      render: renderPath("qpcr_aliquot.tube.kit.kit_name"),
    },
    {
      title: "Aliquot Name",
      render: renderPath("qpcr_aliquot.aliquot_name"),
    },
    {
      title: "Date Processed",
      dataIndex: "created_at",
      render: displayDate(),
    },
    {
      title: "Issues",
      dataIndex: "qpcr_aliquot_qc_issues",
      render: (issues: any[]) => <QCIssueList issues={issues} />,
    },
    {
      title: "Review Status",
      dataIndex: "status",
      render: (status: ManualReviewStatus) => (
        <QCReviewStatusTag status={status} />
      ),
    },
    {
      title: null,
      render: (_: any) => <p>{`\u{2192}`}</p>,
    },
    {
      title: "Status Override",
      render: (status: AliquotStatus) => {
        const override = assayReviewState.getOverride(status.aliquot_status_id);
        const kitName = status.qpcr_aliquot.tube.kit.kit_name;
        const callback = (_: any) => onKitSelected(kitName);

        if (override?.status === ManualReviewStatus.STATUS_NO_OVERRIDE) {
          return (
            <Button type="primary" onClick={callback}>
              Review
            </Button>
          );
        } else {
          return (
            <span>
              <QCReviewStatusTag status={override.status} />
              <Button type="primary" onClick={callback}>
                <EditOutlined />
              </Button>
            </span>
          );
        }
      },
    },
    {
      title: "Reviewer Notes",
      render: (status: AliquotStatus) => {
        const override = assayReviewState.getOverride(status.aliquot_status_id);

        if (override.status === ManualReviewStatus.STATUS_NO_OVERRIDE) {
          return <Typography.Text>--</Typography.Text>;
        } else {
          return <Typography.Text>{override.notes || "--"}</Typography.Text>;
        }
      },
    },
  ];

  return (
    <Table
      data-cy="aliquot-details-table"
      rowKey="aliquot_status_id"
      loading={false}
      columns={columns}
      dataSource={sortedStatuses}
      scroll={{ x: "max-content" }}
      pagination={{ pageSize: 30 }}
    />
  );
};

export default StatusesTable;
