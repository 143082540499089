import { Button, Col, List, Popconfirm, Row, Typography } from "antd";

import "./LandingPage.css";
import {
  ResetAssayAction,
  UpdateActiveAssayAction,
} from "reducers/reducerManualReview";
import React, { useContext } from "react";
import ReviewWorkflowBreadcrumbs from "../ReviewWorkflowBreadcrumbs";

import {
  AssayName,
  ManualReviewStatus,
} from "../../../../api/pipelinesAPI/types";
import { ReviewReducerContext } from "../reviewContext";
import { assayDisplayMap, assayReviewOrderComparator } from "../util";

const LandingPage = () => {
  const { state, dispatch } = useContext(ReviewReducerContext);

  const handleActivateAssay = (assayName: AssayName | null) => {
    dispatch(new UpdateActiveAssayAction(assayName));
  };

  const handleResetAssay = (assayName: AssayName) => {
    dispatch(new ResetAssayAction(assayName));
  };

  const nextButton = (assayName: AssayName, text: string) => (
    <Button onClick={(_) => handleActivateAssay(assayName)} type="primary">
      {text}
    </Button>
  );

  const resetButton = (assayName: AssayName) => (
    <Popconfirm
      title="Are you sure to reset all status overrides?"
      onConfirm={(_) => handleResetAssay(assayName)}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary" danger>
        Reset
      </Button>
    </Popconfirm>
  );

  return (
    <>
      <div>
        <Row justify="start" className="ml-3">
          <Col>
            <ReviewWorkflowBreadcrumbs />
          </Col>
        </Row>
        <Row justify="start" className="ml-3 mr-3">
          <Col offset={4}>
            <Typography.Title level={4}>Manual Review</Typography.Title>
          </Col>
        </Row>
        <Row justify="start" className="ml-3 mr-3 mt-1">
          <Col offset={4} span={16}>
            <Typography.Text>
              Select an assay to begin the manual review workflow. You will be
              guided through each outstanding kit's available aliquot data to
              update their QC statuses. Your overrides will not be applied until
              you complete the full workflow and submit the review. Your
              overrides will be saved locally on your browser. If you navigate
              away from the flow or refresh you can always come back to this
              page to pick up where you left off.
            </Typography.Text>
          </Col>
        </Row>
        <Row justify="start" className="ml-3 mr-3 mt-5">
          <Col offset={4} span={16}>
            <List>
              {Object.keys(state.assayStates)
                .sort(assayReviewOrderComparator)
                .map((assayName) => {
                  const assayEnum = assayName as AssayName;

                  const assayState = state.assayStates[assayEnum];
                  const numPending = assayState.pendingAliquotStatuses.length;
                  const numOveridden = Object.values(
                    assayState.aliquotOverridesMap
                  ).filter(
                    (override) =>
                      override.status !== ManualReviewStatus.STATUS_NO_OVERRIDE
                  ).length;
                  const missingLogs = assayState.pendingAliquotStatuses.filter(
                    (s) => s.status === "flagged_missing_log"
                  );

                  const actions = [];

                  if (numPending === 0) {
                    actions.push(nextButton(assayEnum, "Start"));
                  } else if (numOveridden > 0) {
                    actions.push(resetButton(assayEnum));
                    actions.push(nextButton(assayEnum, "Continue"));
                  } else {
                    actions.push(nextButton(assayEnum, "Start"));
                  }

                  return (
                    <List.Item
                      actions={actions}
                      className="assay-review-list"
                      key={assayName + "-review-item"}
                    >
                      <List.Item.Meta
                        title={assayDisplayMap[assayName as AssayName]}
                        description={`${numPending} pending aliquots (${missingLogs.length} missing logs)`}
                      />
                      <div>
                        ({numOveridden} of {numPending - missingLogs.length}{" "}
                        statuses overridden)
                      </div>
                    </List.Item>
                  );
                })}
            </List>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LandingPage;
