import Form from "./Form";
import WithLoading from "components/WithLoading";
import { renderClone } from "utils/react";

const WizardStep = ({ wrapperComponent, children }) => (
  <Form submitDisabled>
    <Form.Item>
      {wrapperComponent
        ? renderClone(wrapperComponent, { children })
        : children}
    </Form.Item>
  </Form>
);

/**
 * {@link WithLoading} wrapper specialized to work inside a Wizard. In
 * particular, it adds next/previous buttons.
 */
const WizardWithLoading = ({ wrapperComponent, ...props }) => (
  <WithLoading
    {...props}
    wrapperComponent={<WizardStep wrapperComponent={wrapperComponent} />}
  />
);

export default WizardWithLoading;
