import axios from "axios";

import { useQuery } from "react-query";

const NPDES_ASSET_URL = [
  process.env.REACT_APP_BIOBOT_ASSETS_BUCKET,
  "npdes.json",
].join("/");

export const useNPDESIds = ({ enabled = true }) =>
  useQuery({
    /**
     * @returns {Promise<string[]>}
     */
    queryFn: () => {
      return axios.get(NPDES_ASSET_URL).then(({ data }) => data.ids);
    },

    /**
     * @type {string[]}
     */
    queryKey: ["NPDES_IDS"],

    /**
     * @type {boolean}
     */
    enabled,

    /**
     * @type {number}
     */
    staleTime: Infinity,
  });
