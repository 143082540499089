import { Button, Col, DatePicker, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import moment from "moment";

const { RangePicker } = DatePicker;

/**
 * This component provides out-of-the-box search capabilities that integrates with the EditableTable component.
 * Whenever the search state is updated, a predefined query param object is passed to the onSearchParamsUpdated
 * callback so the parent component can pass the state change along to the EditableTable component. The parent
 * component can also register some pre-canned custom filters that are displayed as buttons alongside the search
 * box and date range.
 * @param defaultDateLower - the initial value of the date range picker's lower bound
 * @param defaultDateUpper - the initial value of the date range picker's upper bound
 * @param customFilters - an array of pre-canned custom filters that can be toggled on and off by the user. when
 *                        the button is clicked this component will add the customFilter's 'param' prop to the
 *                        param objects passed via onSearchParamsUpdated.
 *
 *                        filter schema:
 *
 *                        {
 *                          label: string - the button's label as seen by the user
 *                          param: string - the field name that will be populated on the search param object
 *                                          passed by onSearchParamsUpdated when the button is clicked. This
 *                                          param should reflect the query parameter you want to pass to
 *                                          the table's fetchRows callback.
 *                           active: boolean - the state of the button.
 *                        }
 * @param onSearchParamsUpdated - called any time data has changed in a way that should force the table
 *                                to call fetchRows with updated query parameters. the callback should be
 *                                of the form: (updatedParams) => void. The parent component should handle
 *                                this event by passing the updatedParams to the EditableTable's fetchParams
 *                                prop.
 * @param searchPlaceholderText - sets the placeholder text for the search bar
 */
const TableSearch = ({
  defaultDateLower,
  defaultDateUpper,
  customFilters,
  onSearchParamsUpdated,
  disableSearch,
  disableDatePicker,
  searchPlaceholderText,
  isDisplayInline,
  defaultSearch,
  ...otherProps
}) => {
  const [searchInput, setSearchInput] = useState();

  const customFilterButtons = (customFilters ?? []).map((filterMeta) => {
    return (
      <Button
        key={filterMeta.param}
        className="mx-1"
        size="large"
        type={filterMeta.active ? "primary" : ""}
        onClick={(e) => {
          if (filterMeta.active) {
            e.currentTarget.blur();
          }

          filterMeta.active = !filterMeta.active;

          const updatedParams = { currentPage: 1 };
          updatedParams[filterMeta.param] = filterMeta.active;
          onSearchParamsUpdated(updatedParams);
        }}
      >
        {filterMeta.label}
      </Button>
    );
  });

  return (
    <>
      {!disableSearch && (
        <Row className="mb-2">
          <Col span={!isDisplayInline && 7}>
            <Input
              placeholder={
                searchPlaceholderText ?? "Search Kit ID or Internal ID"
              }
              data-cy="table__search-input"
              defaultValue={defaultSearch}
              value={searchInput}
              onChange={({ currentTarget: { value } }) => {
                setSearchInput(value);
                if (value === "") {
                  onSearchParamsUpdated({ searchInput: null, _page: 1 });
                }
              }}
              onPressEnter={() => {
                onSearchParamsUpdated({
                  searchInput: searchInput,
                  _page: 1,
                });
              }}
              prefix={<SearchOutlined className="input__prefix-icon" />}
              allowClear
            />
          </Col>
          <Col>
            <Button
              data-cy="table__search-btn"
              className="ml-1"
              type="primary"
              onClick={() => {
                onSearchParamsUpdated({
                  searchInput: searchInput,
                  _page: 1,
                });
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
      )}
      <Row style={{ alignItems: "center" }} className="mb-2">
        {!disableDatePicker && (
          <Col>
            <RangePicker
              data-cy="range-picker"
              showTime
              format="YYYY-MM-DD HH:mm"
              className="mr-1"
              defaultValue={[defaultDateLower, defaultDateUpper]}
              {...otherProps}
              onChange={(date) => {
                const lowerShifted = date
                  ? moment(date[0].toISOString().slice(0, -1))
                  : null;
                const upperShifted = date
                  ? moment(date[1].toISOString().slice(0, -1))
                  : null;
                onSearchParamsUpdated({
                  _page: 1,
                  lower: date ? lowerShifted.toISOString() : null,
                  upper: date ? upperShifted.toISOString() : null,
                });
              }}
            />
          </Col>
        )}
        {customFilterButtons && <Col>{customFilterButtons}</Col>}
      </Row>
    </>
  );
};

export default TableSearch;
