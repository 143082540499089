import { Button, message, Tooltip, Typography } from "antd";
import { columnSearchProps } from "components/Table/SearchFilter";
import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_CONTAINS,
  strapiFilterProps,
} from "components/Table/StrapiFilter";
import _ from "lodash";
import moment from "moment";

import {
  CheckCircleOutlined,
  CopyOutlined,
  ExclamationCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const VENDOR_EVENT_TYPE_FILTERS = [
  {
    text: "Import Kits Received",
    value: "import-kits-received",
  },
  {
    text: "Import Orders Fulfilled",
    value: "import-orders-fulfilled",
  },
  {
    text: "Export Kit Assays",
    value: "export-kit-assay-assignments",
  },
  {
    text: "Export Sequencing Selection",
    value: "export-seq-selection",
  },
  {
    text: "Export Orders Placed",
    value: "export-orders-placed",
  },
  {
    text: "Cancel Order",
    value: "run-cancel-order",
  },
  {
    text: "Resend Kit Assays",
    value: "run-kit-assays",
  },
];

export const vendorEventTableColumns = [
  {
    title: "Event Type",
    dataIndex: ["event_type", "name"],
    sorter: true,
    filters: VENDOR_EVENT_TYPE_FILTERS,
  },
  {
    title: "Event Author",
    dataIndex: "event_author",
    sorter: true,
    ...columnSearchProps({ placeholder: "Search by Author" }),
  },
  {
    title: "Event Timestamp",
    dataIndex: "created_at",
    render: (val) => moment(val).format("YYYY/MM/DD HH:mm (Z)"),
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
    }),
  },
  {
    title: "Status",
    dataIndex: "event_parameters",
    render: (val) => {
      try {
        return (
          <>
            {_.isNil(JSON.parse(val).data_transfer?.success) ? (
              "-"
            ) : JSON.parse(val).data_transfer?.success ? (
              <Text type="success">
                <CheckCircleOutlined />
              </Text>
            ) : (
              <Text type="danger">
                <ExclamationCircleFilled />
              </Text>
            )}
          </>
        );
      } catch (e) {
        // Handle cases when event_parameters isn't parseable as JSON
        return "-";
      }
    },
  },
  {
    title: (
      <>
        <Tooltip
          title={
            "Hover to view full event parameters, use button to copy to clipboard."
          }
        >
          <Text>Event Parameters</Text>
          <QuestionCircleOutlined className="pl-2" />
        </Tooltip>
      </>
    ),
    dataIndex: "event_parameters",
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_TEXT_CONTAINS],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search Event Contents",
    }),
    render: (val) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Tooltip title={val}>
          <span>{val.length > 65 ? val.substring(0, 60) + "..." : val}</span>
        </Tooltip>
        <Button
          type={"primary"}
          icon={<CopyOutlined />}
          onClick={() =>
            navigator.clipboard
              .writeText(val)
              .then(() => message.success("Copied!"))
          }
        />
      </div>
    ),
  },
];
