import React, { useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import _ from "lodash";

export default function SamplingSiteTypeSelector({
  samplingSiteTypes,
  initialValueSiteType,
  initialValueSiteSpecify,
  form,
  isInternal,
}) {
  const [typeId, setTypeId] = useState();
  const [otherSelected, setOtherSelected] = useState();

  const options = (samplingSiteTypes ?? []).map((type) => {
    return {
      label: type.site_type_name,
      value: type.sampling_site_type_id,
    };
  });

  useEffect(() => {
    if (typeId === 5 || (_.isNil(typeId) && initialValueSiteType === 5)) {
      setOtherSelected(true);
    } else {
      setOtherSelected(false);
      form.setFieldsValue({ sampling_site_type_specify: null });
    }
  }, [form, initialValueSiteType, typeId]);

  return (
    <>
      <Form.Item
        label="What type of sampling site?"
        name="sampling_site_type_id"
        initialValue={initialValueSiteType}
        className="mb-1"
        rules={[
          {
            required: !isInternal,
            message: "Select location type",
          },
        ]}
      >
        <Radio.Group
          style={{ display: "grid" }}
          options={options}
          onChange={(e) => setTypeId(e.target.value)}
        />
      </Form.Item>

      {otherSelected && (
        <Form.Item
          name="sampling_site_type_specify"
          initialValue={initialValueSiteSpecify}
          className="mb-0"
          rules={[
            {
              required: true,
              message: "Enter other site type",
            },
          ]}
        >
          <Input placeholder="Enter other site type" />
        </Form.Item>
      )}
    </>
  );
}
