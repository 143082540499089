import Selector from "./Selector";

const selectorOptions = [
  { label: "End of Day", value: "EOD" },
  { label: "One Business Day", value: "ONE_BUSINESS_DAY" },
  { label: "2-3 Business Days", value: "TWO_THREE_BUSINESS_DAYS" },
  { label: "One Week", value: "ONE_WEEK" },
];

export default function ReworkUrgencySelector({ className }) {
  return (
    <Selector
      label="Urgency"
      options={selectorOptions}
      className={className}
      formItem={{
        placeholder: "Select Urgency",
        width: "40%",
        name: "urgency",
      }}
    />
  );
}
