import axios from "axios";

import qs from "qs";

import { merge } from "lodash";

import { authorizationHeader, orderServiceRoot } from "api/util";

const getConfig = (id) => ({
  url: `${orderServiceRoot}/functions/${id}`,

  method: "post",

  headers: authorizationHeader(),

  paramsSerializer: qs.stringify,
});

const getDownloadConfig = () => ({
  params: {
    as_csv: true,
  },

  responseType: "blob",
});

function runFn(id, data, { download = false } = {}) {
  const downloadConfig = download ? getDownloadConfig() : {};

  const config = merge({}, getConfig(id), downloadConfig);

  return axios({ ...config, data });
}

export { runFn };
