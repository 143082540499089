import React from "react";
import { Col, Row, Typography } from "antd";
import { formatDate } from "utils/time";

const Signature = ({ sample }) => {
  const renderCell = ({ title, value }) =>
    value && (
      <Col sm={8} xs={24} style={{ marginBottom: "3%" }}>
        <Row wrap="false">
          <Col span={20}>
            <Typography.Text strong>{title}</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col>{value}</Col>
        </Row>
      </Col>
    );

  return (
    <div className="mt-5">
      <Typography.Title level={4}>Submitted by</Typography.Title>
      <Row gutter={[16, 40]}>
        {renderCell({
          title: "Name",
          value: `${sample.author?.first_name} ${sample.author?.last_name}`,
        })}
        {renderCell({
          title: "Email",
          value: sample.author?.email,
        })}
        {renderCell({
          title: "Date",
          value: formatDate(sample.author?.created_at, sample.time_zone),
        })}
      </Row>
    </div>
  );
};

export default Signature;
