import moment from "moment";
import { Button, message, PageHeader, Row, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import { toLocaleString } from "utils/time";
import fileDownload from "js-file-download";
import { fetchTubesCsv, useTubeCount, useTubeList } from "../../../api/tube";
import { useTableQueryParams } from "../../../components/Table/helpers";
import SelectionStatusTag from "../../../components/SelectionStatusTag";
import { selectionStatusHeader } from "../Kits/helpers";
import ProgramTag from "../../../components/ProgramTag";
import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
  strapiFilterProps,
} from "../../../components/Table/StrapiFilter";
import ProductTag from "../../../components/ProductTag";
import React from "react";
import { Link } from "react-router-dom";

export default function TubesReceivedToday() {
  const todayStart = moment.utc().startOf("day");
  const todayEnd = moment.utc().endOf("day");

  const { queryParams, pagination, handleTableChange } = useTableQueryParams({
    defaultPageSize: 100,
    defaultSort: "kit.date_received:DESC",
    defaultFilters: {
      "kit.date_received": [
        {
          method: METHOD_DAY_RANGE_EXCLUSIVE_END,
          value: [todayStart, todayEnd],
        },
      ],
    },
  });

  const allTubes = useTubeList({
    ...queryParams,
    _joins: [
      "vessel_type",
      "kit.products",
      "kit.organization",
      "kit.orders",
      "kit.selection_statuses.program",
      "kit.sample.sampling_location_id.program_enrollment.program",
    ],
  });
  const { data: allTubesCount } = useTubeCount(queryParams);

  const downloadTubesCSV = () => {
    return fetchTubesCsv(queryParams)
      .then((response) => {
        fileDownload(response, "received_today.csv");
      })
      .catch((err) => {
        message.error("Something went wrong!");
      });
  };

  const columns = [
    {
      title: "Tube ID",
      dataIndex: "shipping_tube_id",
      key: "shipping_tube_id",
    },
    {
      title: "Internal Tube ID",
      dataIndex: "tube_id",
      key: "tube_id",
    },
    {
      title: "Vessel Type",
      dataIndex: ["vessel_type", "name"],
      key: "vessel_type.name",
    },
    {
      title: "Kit ID",
      dataIndex: ["kit", "shipping_kit_id"],
      key: "kit.shipping_kit_id",
    },
    {
      title: "Internal Kit ID",
      dataIndex: ["kit", "kit_id"],
      key: "kit.kit_id",
    },

    {
      title: "Sampling Location",
      dataIndex: ["kit", "sample", "sampling_location_id"],
      render: (location) => {
        if (!location?.id) {
          return "Not logged";
        }

        return (
          <Link to={`/admin/sampling-locations/${location?.id}`}>
            {location?.sampling_location_name || ""}
          </Link>
        );
      },
    },
    {
      title: "Sample Collect Date",
      dataIndex: ["kit", "sample", "sample_collection_date_time"],
      render: (record) => (record ? toLocaleString(record) : "-"),
    },
    {
      title: "Kit Received at Biobot",
      dataIndex: ["kit", "date_received"],
      key: "kit.date_received",
      sortKey: "kit.date_received",
      defaultSortOrder: "descend",
      className: "data-cy-kit-received-biobot",
      sorter: true,
      render: (date) => (date ? toLocaleString(date) : "-"),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_DAY_RANGE_EXCLUSIVE_END,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
        defaultFilterValue: [todayStart, todayEnd],
      }),
    },
    {
      title: "SKU",
      dataIndex: ["kit"],
      render: (kit) => {
        return (
          <>
            {kit?.products?.map((x) => (
              <ProductTag sku={x?.product_sku} productName={x?.product_name} />
            ))}
          </>
        );
      },
    },
    {
      title: () => selectionStatusHeader,
      dataIndex: ["kit", "selection_statuses"],
      render: (selection_statuses) =>
        selection_statuses?.map((x) => (
          <SelectionStatusTag
            key={x.id}
            status={x.selection_status}
            reason={x.reason}
            displayName={x.program?.name}
            shortDisplayName={x.program?.short_name}
          />
        )),
    },
    {
      title: "Program Enrollments",
      dataIndex: [
        "kit",
        "sample",
        "sampling_location_id",
        "program_enrollment",
      ],
      render: (programs) =>
        programs?.map(
          ({
            program: { id, name, code, short_name: shortName },
            start_date: startDate,
            end_date: endDate,
          }) => (
            <ProgramTag
              key={id}
              displayName={name}
              shortDisplayName={shortName}
              code={code}
              startDate={startDate}
              endDate={endDate}
            />
          )
        ),
    },
    {
      title: "TAT",
      dataIndex: ["kit"],
      render: (kit) => {
        const product = kit?.product || kit?.orders?.[0]?.product;
        return <span>{product?.turn_around_time || "-"}</span>;
      },
    },
    {
      title: "International",
      dataIndex: ["kit", "organization"],
      render: (org) => {
        const country = org?.country;
        return _.isNil(country) ? "-" : country === "USA" ? "No" : "Yes";
      },
    },
    {
      title: "LC-MS Pilot",
      dataIndex: ["kit", "sample", "sampling_location_id", "eligible_for_lcms"],
      render: (val) => (_.isNil(val) ? "-" : val ? "Yes" : "No"),
    },
    {
      title: "Additional R&D",
      dataIndex: ["kit", "organization", "additional_randd_agreement"],
      render: (val) => (_.isNil(val) ? "-" : val ? "Yes" : "No"),
    },
    {
      title: "Organization",
      dataIndex: ["kit", "organization", "organization_name"],
      render: (orgName) => orgName ?? "-",
    },
  ];

  return (
    <div>
      <PageHeader
        title="Tubes Received by Day"
        className="mb-3"
        footer={
          <Row>
            <Button
              onClick={() => {
                downloadTubesCSV();
              }}
              className="ml-1 mr-3"
              type="primary"
              shape="round"
              data-cy="download-tubes"
            >
              <DownloadOutlined />
            </Button>
            Total Tubes: {allTubesCount ?? 0}
          </Row>
        }
      />

      <Table
        rowKey="id"
        scroll={{ x: "min-content" }}
        loading={allTubes.isLoading}
        columns={columns}
        pagination={{ ...pagination, total: allTubesCount }}
        dataSource={allTubes.data}
        onChange={handleTableChange}
      />
    </div>
  );
}
