import { Alert, Spin } from "antd";
import { renderClone } from "utils/react";

export const Loading = ({ message }) => <Spin tip={message} />;

export const Error = ({ message }) => <Alert message={message} type="error" />;

const Wrapper = ({ children }) => children;

/**
 * Wrapper component for loading and error states. Shows `children` once
 * `isLoading` and `isError` are false.
 *
 * @param props.isLoading - shows loading state when true.
 * @param props.isError -  shows error state when true.
 * @param props.query - shortcut for react-query queries. Instead of providing
 *                      both isLoading and isError, you may simply pass the
 *                      entire query result.
 * @param props.loadingMessage - message displayed in the loading state.
 * @param props.errorMessage - message displayed in the error state.
 * @param props.loadingComponent - component used to render the loading state.
 *                                 Should take a `message` arg. Defaults to
 *                                 antd's <Spin>.
 * @param props.errorComponent - component used to render the error state.
 *                               Defaults to antd's <Alert>.
 * @param props.wrapper - optional wrapper component for loading and error
 *                        states.
 */
const WithLoading = ({
  query = null,
  isLoading = null,
  isError = null,
  loadingMessage = null,
  errorMessage = "Something went wrong",
  loadingComponent = Loading,
  errorComponent = Error,
  wrapperComponent = Wrapper,
  children,
}) => {
  const queries = [query].flat();
  if (isLoading ?? queries.some((q) => q?.isLoading)) {
    return renderClone(wrapperComponent, {
      children: renderClone(loadingComponent, { message: loadingMessage }),
    });
  }

  if (isError ?? queries.some((q) => q?.isError)) {
    return renderClone(wrapperComponent, {
      children: renderClone(errorComponent, { message: errorMessage }),
    });
  }

  return children;
};

export default WithLoading;
