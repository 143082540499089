import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";

import { getSignedS3DownloadUrl } from "api/fileUpload";
import { toLocaleString } from "utils/time";

const VarSeqUploadFileModal = ({ isOpen, upload, onCancel }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClick = async () => {
    setIsDownloading(true);
    const fileUrl = await getSignedS3DownloadUrl("varseq-uploads", upload?.id);
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = upload?.file_name;
        link.click();
        link.remove();
      })
      .catch((error) => console.error(error));

    setIsDownloading(false);
  };

  return (
    <Modal
      title={
        <Typography.Title style={{ marginBottom: 0 }} level={4}>
          VarSeq Batch Sentinel File
        </Typography.Title>
      }
      className="qpcr-upload-file-modal"
      destroyOnClose={true}
      open={isOpen}
      onCancel={onCancel}
      footer={
        <>
          <Button
            size="small"
            className="mr-1"
            loading={isDownloading}
            onClick={handleDownloadClick}
          >
            Download JSON <DownloadOutlined />
          </Button>
        </>
      }
    >
      <p>{upload?.file_name}</p>
      <p className="text-sm">
        <i>Uploaded at: {toLocaleString(upload?.created_at)}</i>
      </p>
    </Modal>
  );
};

export default VarSeqUploadFileModal;
