import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Button, DatePicker, Form, message, Row } from "antd";
import Text from "antd/lib/typography/Text";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";

import { toLocaleString } from "utils/time";
import { useUpdateKit, useKitReceivedByLab } from "api/kit";

const EditableDateReceivedCell = ({ kit }) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const updateDateReceived = useUpdateKit();
  const updateReceivedByLab = useKitReceivedByLab();

  const handleSubmit = (values) => {
    if (!values.date_received) {
      return;
    }

    let updateKit = null;
    setLoading(true);

    const data = { ...values };

    if (kit.date_received) {
      data.id = kit.id;
      updateKit = updateDateReceived;
    } else {
      data.shipping_kit_id = kit.shipping_kit_id;
      updateKit = updateReceivedByLab;
    }

    updateKit
      .mutateAsync(data)
      .then(() => {
        message.success("Saved!");
        setEditing(false);
      })
      .catch(() => message.error("An error occurred!"))
      .then(() => setLoading(false));
  };

  const handleEditingCanceled = () => {
    setEditing(false);
    form.resetFields();
  };

  const received = kit.date_received ? moment.utc(kit.date_received) : null;

  const checkEditReceivedIneligibility = () => {
    if (_.isNil(received)) {
      return false;
    }
    return (
      received <
      moment.utc(`${process.env.REACT_APP_EFFIGY_LATEST_AIRTABLE_DATE}`)
    );
  };

  if (!editing) {
    return (
      <Row justify="space-between" align="center">
        <Text>
          {kit?.date_received ? toLocaleString(kit.date_received) : "-"}
        </Text>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setEditing(true)}
          disabled={checkEditReceivedIneligibility()}
          title={
            checkEditReceivedIneligibility()
              ? "This kit is ineligible for editing due to project effigy."
              : null
          }
        />
      </Row>
    );
  }

  return (
    <Form
      form={form}
      initialValues={{ date_received: received }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row justify="space-between" align="center">
        <Form.Item name="date_received">
          <DatePicker
            showTime
            onChange={() => form.submit()}
            disabled={loading}
          />
        </Form.Item>
        <Button
          type="primary"
          icon={<CloseOutlined />}
          onClick={handleEditingCanceled}
          loading={loading}
        />
      </Row>
    </Form>
  );
};

export default EditableDateReceivedCell;
