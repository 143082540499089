import { Form as AntdForm } from "antd";
import React, { useContext } from "react";
import { renderClone } from "utils/react";

export const FormItemContext = React.createContext({
  disabled: false,
  labelComponent: null,
});

const useFormItem = (props) => {
  const ctx = useContext(FormItemContext);
  return {
    ...props,
    disabled: props.disabled ?? ctx.disabled,
    labelComponent: props.labelComponent ?? ctx.labelComponent,
  };
};

/**
 * An antd.Form.Item with additional props:
 *
 * @param props.disabled - disables this form item and its children.
 * @param props.labelComponent - a Component or Element used for rendering
 *                               labels. Use this with a string `label` so that
 *                               `messageVariables` work correctly.
 */
const FormItem = ({ children, label, ...rawProps }) => {
  const { disabled, labelComponent, ...props } = useFormItem(rawProps);

  const renderedLabel =
    labelComponent && label
      ? renderClone(labelComponent, { children: label })
      : label;

  const messageVariables =
    typeof label === "string"
      ? Object.assign({ name: label, label }, props.messageVariables)
      : props.messageVariables;

  const renderChildren = (children) => {
    if (disabled && props.name && React.Children.count(children) === 1) {
      // Antd uses FormItem to control form inputs, but only in a specific
      // (very common) case: the FormItem has a `name` prop, and there is only
      // a single child. Given those conditions, we can assume this is a
      // regular form input, which means it can handle `disabled`.
      const child = React.Children.only(children);
      return React.cloneElement(child, {
        disabled: child.props?.disabled ?? disabled,
      });
    } else {
      return children;
    }
  };

  return (
    <AntdForm.Item
      {...props}
      label={renderedLabel}
      messageVariables={messageVariables}
    >
      {typeof children === "function"
        ? (form) => renderChildren(children(form))
        : renderChildren(children)}
    </AntdForm.Item>
  );
};

export default FormItem;
