import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceFetchCsv,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
} from "./orderService";

const model = "tubes";

export const useTube = orderServiceFindOneQuery({ model });
export const useTubeList = orderServiceListQuery({ model });
export const useTubeCount = orderServiceCountQuery({ model });
export const useCreateTube = orderServiceCreateMutation({ model });
export const useUpdateTube = orderServiceUpdateMutation({ model });

export const fetchTubesCsv = ({ _where, _sort }) =>
  orderServiceFetchCsv({ model: `${model}/received`, _where, _sort });
