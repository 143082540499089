import { FnRun as ExportKitAssayAssignments } from "./fn-gtm-export-kit-assay-assignments";

import { FnRun as ExportOrders } from "./fn-gtm-export-orders";

import { FnRun as ExportRerunRequests } from "./fn-gtm-export-rerun-requests";

import { FnRun as ExportSeqSelection } from "./fn-gtm-export-seq-selection";

import { FnRun as ImportKitsReceived } from "./fn-gtm-import-kits-received";

import { FnRun as ImportOrderFulfillments } from "./fn-gtm-import-order-fulfillments";

import { FnRun as RunCancelOrder } from "./fn-gtm-run-cancel-order";

import { FnRun as RunKitAssays } from "./fn-gtm-run-kit-assays";

const pages = {
  "gtm-export-kit-assay-assignments": ExportKitAssayAssignments,
  "gtm-export-orders": ExportOrders,
  "gtm-export-rerun-requests": ExportRerunRequests,
  "gtm-export-seq-selection": ExportSeqSelection,
  "gtm-import-kits-received": ImportKitsReceived,
  "gtm-import-order-fulfillments": ImportOrderFulfillments,
  "gtm-run-cancel-order": RunCancelOrder,
  "gtm-run-kit-assays": RunKitAssays,
};

export default pages;
