import { Form, InputNumber, Typography } from "antd";

export default function SeparatedSewerPercentage({ initialValue }) {
  return (
    <>
      <Form.Item
        label="Percent influent from separated sewer (%)"
        name="influent_sewer_perc"
        initialValue={initialValue}
        className="mb-0"
      >
        <InputNumber
          min={0}
          max={100}
          className="full-width"
          placeholder="Enter as percent value"
        />
      </Form.Item>
      <Typography.Text type="secondary">
        What percent of your influent comes from separated/sanitary-only lines?
        (e.g. 5%, 95%).
      </Typography.Text>
    </>
  );
}
