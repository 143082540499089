import React, { useState } from "react";
import { Button, PageHeader, Table, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import fileDownload from "js-file-download";
import moment from "moment";

import {
  useUpcomingOrders,
  useUpcomingOrdersCount,
  exportUpcomingOrders,
} from "api/order";
import { useTableQueryParams } from "components/Table/helpers";
import { getUpcomingOrdersColumns } from "./helpers";

const UpcomingOrdersPage = () => {
  const { queryParams, handleTableChange, pagination } = useTableQueryParams({
    defaultPageSize: 50,
  });
  const upcomingOrders = useUpcomingOrders(queryParams);
  const { data: upcomingOrdersCount } = useUpcomingOrdersCount(queryParams);
  const [csvIsDownloading, setCsvIsDownloading] = useState(false);

  const downloadUpcomingOrdersCsv = () => {
    setCsvIsDownloading(true);
    const date = moment().format("MM-DD-YYYY");
    exportUpcomingOrders(queryParams)
      .then((data) => fileDownload(data, `${date}-upcoming-orders.csv`))
      .catch((e) => message.error(e))
      .finally(() => setCsvIsDownloading(false));
  };

  return (
    <>
      <PageHeader title="Upcoming Orders" />
      <div className="ml-3">
        <Button
          type="primary"
          size="small"
          shape="round"
          className="my-3"
          loading={csvIsDownloading}
          onClick={downloadUpcomingOrdersCsv}
        >
          Download as CSV
          <DownloadOutlined />
        </Button>
        <Table
          data-cy="upcoming-orders-table"
          rowKey="id"
          loading={upcomingOrders.isLoading}
          columns={getUpcomingOrdersColumns()}
          dataSource={upcomingOrders.data}
          onChange={handleTableChange}
          pagination={{ ...pagination, total: upcomingOrdersCount }}
          scroll={{ x: "max-content" }}
        />
      </div>
    </>
  );
};

export default UpcomingOrdersPage;
