import { Form, Input } from "antd";

function InitialsParameter() {
  const label = "Initials";

  const name = "initials";

  const rules = [
    {
      required: true,
      message: "Initials is required!",
    },
    {
      min: 2,
      max: 2,
      message: "Initials must be 2 characters!",
    },
  ];

  const formItemProps = {
    label,
    name,
    rules,
    required: true,
  };

  return (
    <Form.Item {...formItemProps}>
      <Input />
    </Form.Item>
  );
}

export { InitialsParameter };
