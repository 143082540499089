import { Form, Row, Col, Input, Select } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { requiredRule } from "utils/rules";

const SampleRerunBatchFormFields = ({
  name,
  fieldKey,
  onRemove,
  ...restRerunBatchField
}) => {
  const rerunBatchOptions = [
    { value: "blank_failure", label: "Blank Failure" },
    { value: "repeat_failure", label: "Repeat Failures" },
    { value: "other", label: "Other" },
  ];

  return (
    <Row>
      <Col flex="auto">
        <Row gutter={16}>
          {/* Rerun Batch Notes */}
          <Col span={6}>
            <Form.Item
              name={[name, "rerun_batch_notes"]}
              fieldKey={[fieldKey, "rerun_batch_notes"]}
              {...restRerunBatchField}
              initialValue={"blank_failure"}
            >
              <Select options={rerunBatchOptions} />
            </Form.Item>
          </Col>

          {/* Rerun Batch Samples */}
          <Col span={12}>
            <Form.Item
              name={[name, "rerun_batch_sample_ids"]}
              fieldKey={[fieldKey, "rerun_batch_sample_ids"]}
              rules={[
                requiredRule("Sample IDs are required"),
                //{ validator: validateSampleID },
              ]}
              {...restRerunBatchField}
            >
              <Input.TextArea placeholder="Input sample IDs here" />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      {/* Separate Col grouping to prevent wrapping */}
      <Col>
        <MinusCircleOutlined onClick={onRemove} style={{ marginTop: "8px" }} />
      </Col>
    </Row>
  );
};

export default SampleRerunBatchFormFields;
