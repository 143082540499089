import _ from "lodash";

const getDefaultState = () => {
  return {
    kitsTotal: 0,
    kitsPage: [],
  };
};

const kits = (state = getDefaultState(), action) => {
  switch (action.type) {
    case "KITS_GET":
      const kitsState = { ...state };
      kitsState["kitsPage"] = action.payload.data;
      return kitsState;
    case "KITS_TOTAL_GET":
      const totalState = { ...state };
      totalState["kitsTotal"] = action.payload.data;
      return totalState;
    case "GET_SAMPLES_BY_ORG":
      const samplesState = { ...state };
      const sampleMap = _.keyBy(action?.payload?.data, "kit.id");
      // populate sample-less kits if relevant... should not be doing this on FE?
      const samples = state.kitsPage.map((kit) => {
        const newKit = { ...kit };
        if (!kit.sample && sampleMap[kit.id]) {
          newKit.sample = sampleMap[kit.id];
        }
        return newKit;
      });
      samplesState["kitsPage"] = samples;
      return samplesState;
    default:
      return state;
  }
};

export default kits;
