import { MultipleChoiceFilter } from "./MultipleChoiceFilter";

const multipleChoiceFilterProps = ({ choices, toStrapiFilter }) => {
  const filterDropdown = (props) => {
    return (
      <MultipleChoiceFilter
        choices={choices}
        toStrapiFilter={toStrapiFilter}
        {...props}
      ></MultipleChoiceFilter>
    );
  };

  return { filterDropdown };
};

export { multipleChoiceFilterProps };
