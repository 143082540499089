import produce from "immer";
import { logout } from "utils";
const getDefaultState = () => {
  return {};
};

const organization = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "ORGANIZATION_GET":
      if (action.error) {
        logout();
      } else {
        nextState = produce(state, () => action.payload.data[0]);
      }
      return nextState;
    case "ORGANIZATION_ADD":
      nextState = produce(state, () => action.payload.data);
      return nextState;
    case "ORDER_ADD":
      nextState = produce(state, (draftState) => {
        draftState.orders.push(action.payload.data);
      });
      return nextState;
    case "SITE_EDIT":
      nextState = produce(state, (draftState) => {
        draftState.sites.forEach((site) => {
          if (site.id === action.payload.data.id) {
            site.SiteName = action.payload.data.SiteName;
          }
        });
      });
      return nextState;
    case "ADDRESS_SAVE":
      nextState = produce(state, (draftState) => {
        draftState.shipping_locations.push(action.payload.data);
      });
      return nextState;
    case "SITE_SAVE":
      return state;
    default:
      return state;
  }
};

export default organization;
