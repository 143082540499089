import { assayDisplayMap } from "../pages/admin/ManualReview/util";
import { immerable, produce } from "immer";
import _ from "lodash";
import { ManualReviewActionType } from "../pages/admin/ManualReview/types/pipelinesAPI";
import {
  AliquotStatus,
  AliquotStatusId,
  AssayName,
  KitName,
  ManualReviewStatus,
} from "../api/pipelinesAPI/types";

export interface StatusOverride {
  status: ManualReviewStatus;
  notes: string | null;
}

export class AssayReviewState {
  [immerable] = true;

  kitNameUnderReview: KitName | null;
  aliquotOverridesMap: Record<AliquotStatusId, StatusOverride>;
  pendingAliquotStatuses: AliquotStatus[];

  constructor(
    kitNameUnderReview: KitName | null,
    aliquotOverridesMap: Record<AliquotStatusId, StatusOverride>,
    pendingAliquotStatuses: AliquotStatus[]
  ) {
    this.kitNameUnderReview = kitNameUnderReview;
    this.aliquotOverridesMap = aliquotOverridesMap;
    this.pendingAliquotStatuses = pendingAliquotStatuses;
  }

  getOverride(statusId: AliquotStatusId): StatusOverride {
    return (
      this.aliquotOverridesMap[statusId] || {
        status: ManualReviewStatus.STATUS_NO_OVERRIDE,
        notes: null,
      }
    );
  }

  getPendingStatus(statusId: AliquotStatusId): AliquotStatus | undefined {
    return this.pendingAliquotStatuses.find(
      (s) => s.aliquot_status_id === statusId
    );
  }

  countKitsWithStatusOverrides(
    kitReviewOrder: KitName[],
    originalStatuses: AliquotStatus[]
  ): number {
    // There can be multiple aliquot statuses for any given kit. This map is used to lookup statuses for each kit.
    const kitNameToStatusMap = _.groupBy(originalStatuses, (s) =>
      _.get(s, "qpcr_aliquot.tube.kit.kit_name")
    );

    return kitReviewOrder.filter((kitName) => {
      const aliquotStatusesLinkedToKit = kitNameToStatusMap[kitName];
      return aliquotStatusesLinkedToKit.every((status) => {
        return (
          this.getOverride(status.aliquot_status_id).status !==
          ManualReviewStatus.STATUS_NO_OVERRIDE
        );
      });
    }).length;
  }

  nextKit(kitReviewOrder: KitName[]): KitName {
    if (_.isNil(this.kitNameUnderReview)) {
      return kitReviewOrder[0];
    } else {
      const currentIndex = kitReviewOrder.findIndex(
        (k) => k === this.kitNameUnderReview
      );
      return kitReviewOrder[currentIndex + 1];
    }
  }
}

export class ManualReviewState {
  [immerable] = true;

  activeAssayName: AssayName | null;
  assayStates: Record<AssayName, AssayReviewState>;

  constructor(
    activeAssayName: AssayName | null,
    assayStates: Record<AssayName, AssayReviewState>
  ) {
    this.activeAssayName = activeAssayName;
    this.assayStates = assayStates;
  }

  activeAssayState(): AssayReviewState {
    if (_.isNil(this.activeAssayName)) {
      throw new Error("Expected active assay name.");
    }

    return this.assayStates[this.activeAssayName];
  }

  activeAssayDisplayName(): string {
    if (_.isNil(this.activeAssayName)) {
      throw new Error("Expected active assay name.");
    }

    return assayDisplayMap[this.activeAssayName];
  }
}

export interface ManualReviewAction {
  type: ManualReviewActionType;
}

export class RefreshPendingStatusesAction implements ManualReviewAction {
  type: ManualReviewActionType;
  statuses: AliquotStatus[];

  constructor(statuses: AliquotStatus[]) {
    this.type = ManualReviewActionType.REFRESH_PENDING_STATUSES;
    this.statuses = statuses;
  }
}

export class UpdateOverrideAction implements ManualReviewAction {
  type: ManualReviewActionType;
  assayName: AssayName;
  aliquotStatusId: AliquotStatusId;
  status: ManualReviewStatus | null;
  notes: string | null;

  constructor(
    assayName: AssayName,
    aliquotStatusId: AliquotStatusId,
    status: ManualReviewStatus,
    notes: string | null
  ) {
    this.type = ManualReviewActionType.UPDATE_OVERRIDE;
    this.assayName = assayName;
    this.aliquotStatusId = aliquotStatusId;
    this.status = status;
    this.notes = notes;
  }
}

export class UpdateActiveAssayAction implements ManualReviewAction {
  type: ManualReviewActionType;
  assayName: AssayName | null;

  constructor(assayName: AssayName | null) {
    this.type = ManualReviewActionType.UPDATE_ACTIVE_ASSAY;
    this.assayName = assayName;
  }
}

export class UpdateKitUnderReviewAction implements ManualReviewAction {
  type: ManualReviewActionType;
  assayName: AssayName;
  kitName: KitName | null;

  constructor(assayName: AssayName, kitName: KitName | null) {
    this.type = ManualReviewActionType.UPDATE_KIT_UNDER_REVIEW;
    this.assayName = assayName;
    this.kitName = kitName;
  }
}

export class ResetAssayAction implements ManualReviewAction {
  type: ManualReviewActionType;
  assayName: AssayName;

  constructor(assayName: AssayName) {
    this.type = ManualReviewActionType.RESET_ASSAY;
    this.assayName = assayName;
  }
}

const initialState = new ManualReviewState(
  null,
  {} as Record<AssayName, AssayReviewState>
);

export const manualReviewInitializer = (
  initialValue = initialState
): ManualReviewState => {
  const data = localStorage.getItem("localManualReview");

  if (_.isNil(data)) {
    return initialValue;
  }

  const json = JSON.parse(data);

  // @ts-ignore - Typescript enums are brutal
  const assayStates: Record<AssayName, AssayReviewState> = {};
  Object.entries(json.assayStates).forEach(
    ([assayName, assayState]: [any, any]) => {
      const assay: keyof typeof AssayName = assayName;

      // @ts-ignore
      assayStates[assay] = new AssayReviewState(
        assayState.kitNameUnderReview,
        assayState.aliquotOverridesMap,
        [] // We should always make a server call to refresh the list of outstanding aliquots
      );
    }
  );

  const state: ManualReviewState = new ManualReviewState(
    json.activeAssayName,
    assayStates
  );

  return state;
};

export const manualReviewReducer = (
  state: ManualReviewState,
  action: ManualReviewAction
): ManualReviewState => {
  if (action instanceof RefreshPendingStatusesAction) {
    const statusesByAssay = _.groupBy(
      action.statuses,
      (s) => s.assay.assay_name
    );

    return produce(state, (draft) => {
      Object.entries(statusesByAssay).forEach(
        ([assayName, pendingStatuses]) => {
          if (_.isNil(draft.assayStates[assayName as AssayName])) {
            // @ts-ignore
            draft.assayStates[assayName as AssayName] = new AssayReviewState(
              null,
              {},
              pendingStatuses
            );
          } else {
            // @ts-ignore
            draft.assayStates[
              assayName as AssayName
            ].pendingAliquotStatuses = pendingStatuses;
          }
        }
      );
    });
  } else if (action instanceof UpdateOverrideAction) {
    return produce(state, (draft) => {
      const aliquotMap = draft.assayStates[action.assayName];
      aliquotMap.aliquotOverridesMap[action.aliquotStatusId] = {
        status: action.status || ManualReviewStatus.STATUS_NO_OVERRIDE,
        notes: action.notes,
      };
    });
  } else if (action instanceof UpdateActiveAssayAction) {
    return produce(state, (draft) => {
      draft.activeAssayName = action.assayName;
    });
  } else if (action instanceof UpdateKitUnderReviewAction) {
    return produce(state, (draft) => {
      draft.activeAssayName = action.assayName;
      draft.assayStates[action.assayName].kitNameUnderReview = action.kitName;
    });
  } else if (action instanceof ResetAssayAction) {
    return produce(state, (draft) => {
      draft.activeAssayName = null;
      draft.assayStates[action.assayName].aliquotOverridesMap = {};
    });
  } else {
    throw new Error(`Invalid action: ${action}`);
  }
};
