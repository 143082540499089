import { Button, Col, PageHeader, Row, Table, Tooltip } from "antd";
import { DownloadOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";

import fileDownload from "js-file-download";

import React from "react";
import { Link, withRouter } from "react-router-dom";

import moment from "moment";

import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_CONTAINS,
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
  strapiFilterProps,
} from "components/Table/StrapiFilter";

import { useOrderTable, useOrderCount, exportOrders } from "api/order";

import { useTableQueryParams } from "components/Table/helpers";

import OrderFulfillmentPriorityTag from "components/OrderFulfillmentPriorityTag";

import { trackingNumberLink } from "utils/components";

const MAX_RECORDS_LIMIT = 2500;

const OrderShipments = () => {
  const { queryParams, pagination, handleTableChange } = useTableQueryParams({
    defaultPageSize: 25,
    defaultSort: "created_at:DESC",
  });

  const orders = useOrderTable({
    ...queryParams,
    _joins: ["fulfillment_provider.vendor_name"],
  });

  const { data: ordersCount } = useOrderCount(queryParams);

  const handleDownloadCSV = async (data) => {
    const ordersCSV = await exportOrders({
      _where: queryParams._where,
      _sort: queryParams._sort,
      _limit: ordersCount,
    });

    fileDownload(ordersCSV, "order_shipments.csv");
  };

  const columns = [
    {
      title: "Fulfillment Priority",
      key: "fulfillment_priority",
      dataIndex: ["fulfillment_priority"],
      sortDirections: ["ascend", "descend", "ascend"],
      filters: [
        { value: "none", text: "None", label: "None" },
        { value: "low", text: "Low", label: "Low" },
        { value: "medium", text: "Medium", label: "Medium" },
        { value: "high", text: "High", label: "High" },
      ],
      onFilter: (value, record) => {
        return record.fulfillment_priority === value;
      },
      render: (priority) => {
        return <OrderFulfillmentPriorityTag priority={priority} />;
      },
    },
    {
      title: "Order ID",
      key: "id",
      dataIndex: ["order_id"],

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Order ID`,
      }),
    },
    {
      title: "Shipping Order #",
      key: "shipping_order_id",
      dataIndex: ["shipping_order_number"],

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Shipping Order #`,
      }),
    },
    {
      title: "Organization",
      key: "order_placement.organization.organization_name",
      dataIndex: ["organization_name"],

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Organization`,
      }),
    },
    {
      title: "Date Order Placed",
      key: "created_at",
      dataIndex: ["date_order_placed"],
      render: (dateOrderPlaced) => {
        return moment(dateOrderPlaced).format("MM/DD/YYYY");
      },

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
      }),
    },
    {
      title: "Requested Shipping Date",
      key: "requested_shipping_date",
      dataIndex: ["requested_shipping_date"],
      render: (requestedShippingDate) => {
        return requestedShippingDate
          ? moment(requestedShippingDate).format("MM/DD/YYYY")
          : "-";
      },

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
      }),
    },
    {
      title: "Kits per Cycle",
      key: "kits",
      dataIndex: ["kits_per_cycle"],
    },
    {
      title: "Cycle #",
      key: "cycle",
      dataIndex: [],
      render: (order) => {
        return `${order.cycle_number} of ${order.total_cycles}`;
      },
    },
    {
      title: "Shipping Method",
      key: "order_placement.shipping_speed",
      dataIndex: ["shipping_method"],

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Shipping Method`,
      }),
    },
    {
      title: "Shipping Location",
      key: "shipping_location",
      dataIndex: [],
      render: (record) =>
        `[${record.shipping_location_id}] ${record.shipping_location_name}`,
    },
    {
      title: "Name for Shipping",
      key: "shipping_location.contact_name",
      dataIndex: ["name_for_shipping"],

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Name for Shipping`,
      }),
    },
    {
      title: "Shipping Address 1",
      key: "shipping_location.address_line_1",
      dataIndex: ["shipping_address_1"],

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Shipping Address 1`,
      }),
    },
    {
      title: "Shipping Address 2",
      key: "shipping_location.address_line_2",
      dataIndex: ["shipping_address_2"],

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Shipping Address 2`,
      }),
    },
    {
      title: "City",
      key: "shipping_location.city",
      dataIndex: ["shipping_city"],

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search City`,
      }),
    },
    {
      title: "State",
      key: "shipping_location.state_id",
      dataIndex: ["shipping_state"],

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search State`,
      }),
    },
    {
      title: "Postal Code",
      key: "shipping_location.postal_code",
      dataIndex: ["shipping_postal_code"],

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Postal Code`,
      }),
    },
    {
      title: "Phone (Shipping)",
      key: "shipping_location.phone_number",
      dataIndex: ["shipping_phone_number"],

      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Phone (Shipping)`,
      }),
    },
    {
      title: "Kit Provider",
      key: "fulfillment_provider.vendor_name",
      dataIndex: ["fulfillment_provider_name"],
    },
  ];

  const expandableConfig = () => ({
    rowExpandable: (order) => order.kits && order.kits.length > 0,
    expandedRowRender: (order) => {
      const nestedColumns = [
        {
          title: "Kit ID",
          key: "shipping_kit_id",
          dataIndex: [],
          render: (kit) => {
            return (
              <Link to={`/kit/${kit.id}`} key={kit.shipping_kit_id}>
                {kit.shipping_kit_id ?? "ID Pending"}
              </Link>
            );
          },
        },
        {
          title: "Internal ID",
          key: "kit_id",
          dataIndex: [],
          render: (kit) => {
            return (
              <Link to={`/kit/${kit.id}`} key={kit.kit_id}>
                {kit.kit_id ?? "ID Pending"}
              </Link>
            );
          },
        },
        {
          title: "Configuration",
          key: "configuration.name",
          dataIndex: ["configuration", "name"],
        },
        {
          title: "Date Shipped",
          key: "date_shipped",
          dataIndex: ["date_shipped"],
          render: (dateShipped) => {
            return dateShipped
              ? moment(dateShipped).format("MM/DD/YYYY hh:mmA")
              : "-";
          },
        },
        {
          title: "Outbound Tracking Number",
          key: "outbound_tracking_number",
          dataIndex: ["outbound_tracking_number"],
          render: (records) => trackingNumberLink(records),
        },
        {
          title: "Inbound Tracking Number",
          key: "inbound_tracking_number",
          dataIndex: ["inbound_tracking_number"],
          render: (records) => trackingNumberLink(records),
        },
      ];

      return (
        <Table
          columns={nestedColumns}
          dataSource={order.kits}
          pagination={false}
          rowKey="id"
          bordered
        />
      );
    },
  });

  return (
    <div className="admin-order-shipments-page" style={{ height: "100%" }}>
      <PageHeader
        title="Order Shipments"
        className="mb-3"
        style={{
          padding: 0,
          marginBottom: "15px",
        }}
        footer={
          <Row justify="space-between">
            <Col span={16}>
              <Row>
                <Button
                  onClick={() => {
                    handleDownloadCSV();
                  }}
                  className="ml-1"
                  type="primary"
                  shape="round"
                >
                  <DownloadOutlined />

                  {ordersCount > MAX_RECORDS_LIMIT ? (
                    <Tooltip
                      placement="right"
                      fill="red"
                      title={`More than ${MAX_RECORDS_LIMIT} records requested for download. CSV results will be truncated.`}
                    >
                      <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
                    </Tooltip>
                  ) : null}
                </Button>
              </Row>
            </Col>
          </Row>
        }
      />

      <Table
        className="admin-order-shipments-table"
        rowKey="order_id"
        loading={orders.isLoading}
        columns={columns}
        expandable={expandableConfig()}
        pagination={{ ...pagination, total: ordersCount }}
        dataSource={orders.data}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default withRouter(OrderShipments);
