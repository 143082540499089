import { Row, Col, Typography } from "antd";
import { fixtures } from "../../fixtures";
import { useSignupContext } from "context/SignUpContext";

const { Title, Text, Paragraph, Link } = Typography;

export default function Description() {
  const { variant } = useSignupContext();
  const formFixtures = fixtures[variant];

  return (
    <>
      <Row className="mb-1">
        <Col span={22} className="mb-1">
          <Text>{formFixtures.description}</Text>
        </Col>
        <Text className="mb-4">
          If you experience any issues submitting this form, please contact us
          at <Link href="mailto:support@biobot.io">support@biobot.io</Link>.
        </Text>
        {formFixtures.participationDetails && (
          <>
            <Col span={22}>
              <Title level={3}>Program Information</Title>
            </Col>
            <Col span={22} className="mb-2">
              <Paragraph>
                <Title level={5}>Participation Details:</Title>
                <ul>
                  {formFixtures.participationDetails.map((field, index) => (
                    <li key={index}>{field}</li>
                  ))}
                </ul>
              </Paragraph>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}
