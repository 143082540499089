import { Card, Col, notification, Row } from "antd";
import React from "react";

export const DemoContainer = ({ children }) => (
  <Row gutter={[32, 32]}>
    {React.Children.map(children, (child, idx) => (
      <Col span={24} key={idx}>
        {child}
      </Col>
    ))}
  </Row>
);

const cardText = (text, className) =>
  typeof text === "string" ? <span className={className}>{text}</span> : text;

export const DemoCard = ({ children, description, title, ...props }) => (
  <Card
    {...props}
    title={
      <Card.Meta
        // Card sets whiteSpace: no-wrap which is no good for long descriptions
        style={{ whiteSpace: "normal" }}
        title={cardText(title, "text-xl")}
        description={cardText(description, "text-md")}
      />
    }
  >
    {children}
  </Card>
);

export const callbackMessage = (callbackName) => (...args) => {
  notification.open({
    message: `Callback: ${callbackName}`,
    description: (
      <pre style={{ fontSize: "12px", maxHeight: "10rem", overflow: "auto" }}>
        {callbackName}({JSON.stringify(args, null, 2).slice(1, -1)})
      </pre>
    ),
  });
  console.log(callbackName, ...args);
};
