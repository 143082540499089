import { METHOD_NOT_EMPTY } from "components/Table/StrapiFilter";
import KitsPage from "pages/admin/Kits/Kits";

export default function KitIssues() {
  return (
    <KitsPage
      defaultDateReceivedFilterMethod={METHOD_NOT_EMPTY}
      defaultDateReceivedFilterValue={true}
      customFilterExtension={{
        kit_notes: [
          {
            method: METHOD_NOT_EMPTY,
            value: true,
          },
        ],
      }}
      pageTitle="Kit Issues"
    />
  );
}
