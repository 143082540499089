import produce from "immer";
import { logout } from "utils";

const getDefaultState = () => {
  return {};
};

const session = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "SESSION_TOKEN_GET":
      const token = localStorage.getItem("token");
      if (token && action.error) {
        nextState = produce(state, (draftState) => "refresh");
      }
      nextState = produce(state, (draftState) => action.payload.data);
      return nextState;
    case "SESSION_TOKEN_REFRESH":
      if (action.error) {
        logout();
      }
      localStorage.setItem("token", action.payload.data.accessToken);
      localStorage.setItem("refreshToken", action.payload.data.refreshToken);
      nextState = produce(state, (draftState) => true);
      return nextState;
    default:
      return state;
  }
};

export default session;
