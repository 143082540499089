import produce from "immer";

const getDefaultState = () => {
  return {};
};

const address = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "ADDRESS_GEOCODE":
      nextState = produce(state, (draftState) => action.payload.data);
      return nextState;
    default:
      return state;
  }
};

export default address;
