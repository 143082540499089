import { Button, Checkbox, Col, Radio, Row } from "antd";
import React, { useState } from "react";

import { METHOD_PROGRAM_SELECTION_STATUSES } from "components/Table/StrapiFilter";

/**
 *
 *
 * @param   {object} options
 * @returns {React.Component}
 */
const ProgramSelectionStatusFilter = ({
  programs,
  confirm,
  clearFilters,
  selectedKeys,
  setSelectedKeys,
}) => {
  /**
   * The initial state of programSelectionStatusFilters.
   *
   * @example
   * ```
   * {
   *   1: ['selected'],
   *   2: [],
   *   3: ['ineligible'],
   * }
   * ```
   *
   * @type {Record<number, string[]>}
   */
  const programSelectionStatusFiltersInitialState = programs.reduce(
    (initialState, program) => {
      return { ...initialState, [program.id]: [] };
    },
    {}
  );

  const [
    programSelectionStatusFilters,
    setProgramSelectionStatusFilters,
  ] = useState(programSelectionStatusFiltersInitialState);

  /**
   * Update the programSelectionStatusFilters with the given filters.
   *
   * @param   {Record<number, string[]>} filters
   * @returns {void}
   */
  const updateProgramSelectionStatusFilters = (filters) => {
    setProgramSelectionStatusFilters({
      ...programSelectionStatusFilters,
      ...filters,
    });
  };

  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const onSelectedProgramsChange = (checkedPrograms) => {
    /**
     * The programs which were unselected since the last state update.
     *
     * @type {number[]}
     */
    const unselectedPrograms = selectedPrograms.filter((program) => {
      return !checkedPrograms.includes(program);
    });

    /**
     * The programs which were reselected since the last state update.
     *
     * @type {number[]}
     */
    const reselectedPrograms = checkedPrograms.filter((program) => {
      return !selectedPrograms.includes(program);
    });

    /**
     * The program selection status filters to be reset.
     *
     * When a program is unselected from the programs list, we can reset all its filters as well.
     *
     * @type {Record<number, string[]>}
     */
    const unselectedFilters = unselectedPrograms.reduce(
      (unselected, program) => {
        return { ...unselected, [program]: [] };
      },
      {}
    );

    /**
     * The program selection status filters to be defaulted.
     *
     * When a program is selected from the programs list, we want to default its filter to 'selected'.
     *
     * @type {Record<number, string[]>}
     */
    const reselectedFilters = reselectedPrograms.reduce(
      (reselected, program) => {
        return { ...reselected, [program]: ["selected"] };
      },
      {}
    );

    setSelectedPrograms(checkedPrograms);

    updateProgramSelectionStatusFilters({
      ...unselectedFilters,
      ...reselectedFilters,
    });
  };

  const onProgramSelectionStatusChange = (program) => (event) => {
    updateProgramSelectionStatusFilters({ [program.id]: [event.target.value] });
  };

  const onSubmitProgramSelectionStatusFilters = () => {
    const strapiFilter = {
      method: METHOD_PROGRAM_SELECTION_STATUSES,
      value: Object.entries(programSelectionStatusFilters)
        .map(([programId, selectionStatuses]) =>
          selectionStatuses.map((selectionStatus) =>
            [programId, selectionStatus].join(":")
          )
        )
        .flat(1),
    };

    setSelectedKeys([strapiFilter]);

    confirm();
  };

  const onClearProgramSelectionStatusFilters = () => {
    updateProgramSelectionStatusFilters(
      programSelectionStatusFiltersInitialState
    );

    setSelectedPrograms([]);

    setSelectedKeys([]);

    clearFilters();

    confirm();
  };

  const programSelectionStatusOptions = [
    {
      label: "Selected",
      value: "selected",
    },
    {
      label: "Eligible",
      value: "eligible",
    },
    {
      label: "Ineligible",
      value: "ineligible",
    },
    {
      label: "Ambiguous",
      value: "eligibility_ambiguous",
    },
  ];

  return (
    <div className="p-1" style={{ width: "425px" }}>
      <Checkbox.Group
        style={{ width: "100%" }}
        value={selectedPrograms}
        onChange={onSelectedProgramsChange}
      >
        {programs.map((program) => {
          return (
            <Row key={program.id}>
              <Col span={24}>
                <Checkbox value={program.id} key={program.code}>
                  {program.name}
                </Checkbox>
              </Col>

              <Col span={24} style={{ padding: "5px 25px" }}>
                {selectedPrograms.includes(program.id) ? (
                  <Radio.Group
                    options={programSelectionStatusOptions}
                    onChange={onProgramSelectionStatusChange(program)}
                    value={
                      programSelectionStatusFilters?.[program.id]?.[0] ?? null
                    }
                    size="small"
                    optionType="button"
                    buttonStyle="solid"
                  ></Radio.Group>
                ) : null}
              </Col>
            </Row>
          );
        })}
      </Checkbox.Group>

      <Button
        type="primary"
        size="small"
        disabled={selectedPrograms.length === 0}
        onClick={onSubmitProgramSelectionStatusFilters}
      >
        OK
      </Button>

      <Button
        type="link"
        size="small"
        disabled={selectedKeys.length === 0}
        onClick={onClearProgramSelectionStatusFilters}
      >
        Reset
      </Button>
    </div>
  );
};

/**
 *
 *
 * @param   {object} options
 * @returns {}
 */
const programSelectionStatusFilterProps = (programs) => {
  const filterDropdown = (props) => {
    return (
      <ProgramSelectionStatusFilter
        programs={programs}
        {...props}
      ></ProgramSelectionStatusFilter>
    );
  };

  return { filterDropdown };
};

export { ProgramSelectionStatusFilter, programSelectionStatusFilterProps };
