import { Form, Select, Col, Row, Typography } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

const { Title } = Typography;

const shippingOptions = [
  {
    key: "FedEx Ground",
    value: "FedEx Ground",
  },
  {
    key: "FedEx Priority Overnight",
    value: "FedEx Priority Overnight",
  },
  {
    key: "FedEx Standard Overnight",
    value: "FedEx Standard Overnight",
  },
  {
    key: "FedEx 2 Day",
    value: "FedEx 2 Day",
  },
  {
    key: "FedEx Express Saver",
    value: "FedEx Express Saver",
  },
  {
    key: "FedEx International Priority",
    value: "FedEx International Priority",
  },
  {
    key: "FedEx International Economy",
    value: "FedEx International Economy",
  },
];

const ShippingSpeed = (props) => {
  return (
    <Row className="mb-3">
      <Col span={24}>
        <Title level={3}>Shipping Speed</Title>
      </Col>

      <Col span={8}>
        <Form.Item
          name={`shipping-speed`}
          label={`Shipping speed`}
          initialValue={"FedEx 2 Day"}
          rules={[
            {
              required: false,
            },
          ]}
          tooltip={{
            title:
              "The shipping speed of the first cycle can be selected below. Any additional cycles will be sent via Fedex Ground.",
            icon: <InfoCircleFilled className="ml-1" />,
          }}
        >
          <Select data-cy="shipping-speed" options={shippingOptions} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ShippingSpeed;
