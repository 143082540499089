import { Form, Input, Typography } from "antd";

const { Text } = Typography;

export default function Note({ locationType, initialValue }) {
  const label =
    locationType === "facility"
      ? "Additional Building Notes"
      : "Additional Notes on Catchment Area";

  const placeholder =
    locationType === "facility"
      ? "e.g. Masks are required in common spaces. When a single case the individual and people who came in contact " +
        "with them are quarantined. "
      : "e.g. Fairfield County intersects with my catchment area, but falls in CT rather than NY.";

  const text =
    locationType === "facility"
      ? "Please add any additional information about your building, especially if is info about covid policies or " +
        "protocols."
      : "Please add any additional information about your catchment area, especially if an additional intersecting " +
        "county was not available in the dropdown options, or if your catchment area is associated with another regional " +
        "jurisdiction.";

  return (
    <>
      <Form.Item
        label={label}
        name="notes"
        initialValue={initialValue}
        className="mb-0"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input.TextArea rows={4} placeholder={placeholder} />
      </Form.Item>
      <Text type="secondary">{text}</Text>
    </>
  );
}
