import { UploadOutlined } from "@ant-design/icons";

import {
  Col as Column,
  Row,
  Collapse,
  Button,
  Form,
  Spin,
  Upload,
  message,
} from "antd";

import Papa from "papaparse";

import { useState } from "react";

import { runFn } from "../helpers/run-function";

import { InitialsParameter } from "./InitialsParameter";

function GTMolecularImportFn(props) {
  const { name, refineParameters = ($) => $ } = props;

  const [form] = Form.useForm();

  const [success, setSuccess] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const withHandling = (fn) => async (values) => {
    setLoading(true);

    try {
      await fn(values);

      form.resetFields();
    } catch (error) {
      message.error("Something went wrong!");

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFormSubmit = withHandling(async (values) => {
    const { initials, upload } = values;

    const items = await new Promise((resolve, reject) => {
      Papa.parse(upload, {
        header: true,
        complete: (results) => resolve(results.data),
        error: reject,
        skipEmptyLines: true,
      });
    });

    const parameters = refineParameters({ author: initials, items });

    const response = await runFn(name, { parameters });

    if (response.data.errors.length > 0) {
      setErrors(response.data.errors);

      message.warning("Some imports failed. Please check the logs.");
    } else {
      message.success("Import successful.");
    }

    setSuccess(response.data.data);
  });

  const uploadFormItemProps = {
    label: "CSV Upload",
    name: "upload",
    required: true,
    valuePropName: "file",
    getValueFromEvent: (event) => event.file.originFileObj,
  };

  const uploadProps = {
    maxCount: 1,
    accept: ".csv",
    showUploadList: true,
    customRequest: ({ onSuccess }) => {
      setTimeout(() => onSuccess("ok"), 0);
    },
  };

  return (
    <Spin
      tip={"Processing... This may take up to 2 minutes."}
      spinning={loading}
    >
      <Form form={form} layout="vertical" onFinish={onFormSubmit}>
        <Row gutter={12}>
          <Column span={6}>
            <Form.Item {...uploadFormItemProps}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </Column>
        </Row>

        <Row gutter={12}>
          <Column span={6}>
            <InitialsParameter />
          </Column>
        </Row>

        <Row gutter={12}>
          <Column span={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Run
              </Button>
            </Form.Item>
          </Column>
        </Row>

        <Row gutter={12}>
          <Column span={12}>
            <Collapse>
              <Collapse.Panel header="Success" key="1">
                <pre>
                  {success.length ? JSON.stringify(success, null, 2) : null}
                </pre>
              </Collapse.Panel>
              <Collapse.Panel header="Errors" key="2">
                <pre>
                  {errors.length ? JSON.stringify(errors, null, 2) : null}
                </pre>
              </Collapse.Panel>
            </Collapse>
          </Column>
        </Row>
      </Form>
    </Spin>
  );
}

export { GTMolecularImportFn };
