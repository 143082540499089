import axios from "axios";
import qs from "qs";
import { authorizationHeader, mkQuery, datasetServiceRoot } from "./util";

export const datasetServiceKey = ({ model, endpoint, ...args }) => [
  { service: "datasets", model, endpoint, ...args },
];

// -- Raw requests --

export const datasetServiceFetchList = async ({ model }) => {
  const { data } = await axios({
    method: "get",
    url: `${datasetServiceRoot}/${model}`,
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  });
  return data;
};

// -- React query hooks --

export const datasetListQuery = ({ model }) =>
  mkQuery({
    key: () => datasetServiceKey({ model, endpoint: "list" }),
    query: datasetServiceFetchList,
    options: {
      staleTime: 86400000,
      cacheTime: 86400000,
    },
  });
