import { PageHeader } from "antd";
import { useRouteMatch } from "react-router-dom";

import Runbook from "./Runbook";
import RunbooksTable from "./RunbooksTable";

const RunbooksPage = () => {
  const {
    params: { id, categoryId },
  } = useRouteMatch();
  if (id) {
    return <Runbook id={id} />;
  }
  return (
    <div>
      <PageHeader title="Runbooks" />
      <RunbooksTable categoryId={categoryId} />
    </div>
  );
};

export default RunbooksPage;
