import { createRef, useState } from "react";
import { Modal, Form, Button, Row, Col } from "antd";
import Fields from "./Fields";
import axios from "axios";
import moment from "moment";
import Loader from "components/Loader/Loader";

const SubmissionStatus = ({ status }) => {
  const emoji = status === 200 ? "🎉" : "💩";
  const message =
    status === 200
      ? "Thank you! Your form has been submitted."
      : "Uh oh, there seems to be a problem. Our team is on the case.";

  return (
    <>
      <p
        style={{
          fontSize: "50px",
        }}
      >
        {emoji}
      </p>
      <h3>{message}</h3>
    </>
  );
};

export const AccountAddModal = ({ visible, onCancel, onOrgCreated }) => {
  const formRef = createRef();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const [country, setCountry] = useState("USA");

  const createOrg = (values) => {
    const data = {
      organization_name: values["Account name"].trim(),
      account_type: "government",
      organization_meta: {
        billing_email: values.billing_email,
        contact_name: values.contact_name || values["Name"],
        contact_email: values["Email"],
        contact_title: values["title"],
        contact_phone: values["Phone"],
        address_line_1: values["Address line 1"],
        address_line_2: values["Address line 2"],
        city: values["City"],
        post_code: values["Post code"],
        province: values["Province"],
        country: values["Country"],
        salesforce_account_id: values.salesforce_account_id,
        tax_id: values.tax_id,
        tax_id_number: values.tax_id_number,
        tax_status: values.tax_status.target.value,
      },
    };

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}organizations?ts=${moment().format(
          "x"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setStatus(200);
        onOrgCreated(res.data);
      })
      .catch((err) => {
        setStatus(500);
      })
      .then(() => setLoading(false));
  };

  if (loading) {
    return <Loader />;
  }

  if (status) {
    return <SubmissionStatus status={status} />;
  }

  return (
    <Modal
      title=""
      width={"80%"}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <Form
        ref={formRef}
        layout="vertical"
        initialValues={{
          tax_status: { target: { value: "Taxable" } },
        }}
        onFinish={(values) => createOrg(values)}
        onFieldsChange={(values) => {
          if (values && values[0] && values[0].name[0] === "Country") {
            setCountry(values[0].value);
          }
        }}
      >
        <h1>Add Account</h1>
        <Fields country={country} />
        <Row>
          <Col sm={24}>
            <Button id="accounts-modal-button" type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
