import { useState, useEffect, useRef } from "react";
import { Col, Radio, Row, Typography } from "antd";

const { Title } = Typography;

const ConfirmOrg = ({ organization, onConfirmationChanged }) => {
  const [value, setValue] = useState(null);

  const refForScroll = useRef(null);
  const executeScroll = () => refForScroll.current.scrollIntoView();

  const handleChange = (val) => {
    setValue(val);
    onConfirmationChanged(val);
  };

  useEffect(() => {
    setValue(null);
    executeScroll();
  }, [organization]);

  return (
    <div ref={refForScroll}>
      <Row>
        <Col span={22}>
          <Title level={4}>
            It looks like your organization is {organization.organization_name}.
            Can you confirm whether this is correct?*
          </Title>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <Radio.Group
            onChange={(e) => handleChange(e.target.value)}
            value={value}
          >
            <Radio
              value={true}
              className="full-width"
              data-cy="yes-organization"
            >
              Yes, my organization is {organization.organization_name}
            </Radio>
            <Radio value={false} data-cy="no-organization">
              No, my organization is not {organization.organization_name}
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmOrg;
