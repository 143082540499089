import { Alert, Form as AntdForm } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { assignIgnoreNull, callEach } from "utils";
import { renderClone, useIsMounted } from "utils/react";
import FormItem, { FormItemContext } from "./FormItem";
import useWizardContext from "./useWizardContext";
import WizardButtons from "./WizardButtons";

class FormError {
  constructor(message) {
    this.message = message;
  }
}

const AlertError = ({ message, clearError }) => (
  <Alert
    message={message}
    type="error"
    closable={!!clearError}
    onClose={clearError}
  />
);

const useWizardProps = (props) => {
  const wizard = useWizardContext();
  const wizardFormName = props.name || `step${wizard.step}`;

  // Tell the wizard that it has a new form so the wizard can track values as
  // forms are mounted and unmounted.
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (wizard.onFormMount) {
      if (process.env.NODE_ENV !== "production" && !props.name) {
        console.warn(
          `Form in Wizard missing 'name' prop. Falling back to '${wizardFormName}'`
        );
      }
      wizard.onFormMount(wizardFormName, props.initialValues);
    }
    if (wizard.onFormUnmount) {
      return () => wizard.onFormUnmount(wizardFormName);
    }
  }, [wizardFormName, wizard.onFormMount, wizard.onFormUnmount]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // Derive props from wizard context. Rules of thumb:
  // (1) Callbacks are merged: form callback first, wizard callback second.
  // (2) Other values use the form prop and fall back to the wizard.
  const wizardProps = {
    disabled: !wizard.isCurrentStep,
    buttonsComponent: wizard.buttonsComponent || WizardButtons,
    showButtons: wizard.isCurrentStep,
    onFinish: wizard.onNext && _.partial(wizard.onNext, wizardFormName),
    onValuesChange:
      wizard.onValuesChange && _.partial(wizard.onValuesChange, wizardFormName),
  };

  return assignIgnoreNull(wizardProps, props, {
    initialValues:
      wizard.getInitialValues(wizardFormName) || props.initialValues,
    onFinish: callEach(props.onFinish, wizardProps.onFinish),
    onValuesChange: callEach(props.onValuesChange, wizardProps.onValuesChange),
  });
};

/**
 * An antd.Form with additional props:
 *
 * @param props.buttonsComponent - component used to render buttons at the
 *                                 bottom of the form. Uses WizardButtons by
 *                                 default.
 * @param props.disabled - marks this form as disabled. All FormItem children
 *                         are also disabled. When rendered in a Wizard,
 *                         previous steps are disabled by default.
 * @param props.errorComponent - custom component used to show onFinish errors.
 * @param props.labelComponent - the default labelComponet for all Form.Items
 * @param props.normalizeValues - normalization function called before
 *                                onValuesChange and onFinish.
 * @param props.onFinish - same as antd.Form.onFinish, but if an async
 *                         callback is provided, marks the submit button
 *                         as loading until the promise resolves.
 * @param props.showButtons - hides buttonsComponent when false.
 */
const WizardForm = React.forwardRef((rawProps, ref) => {
  const {
    buttonsComponent = WizardButtons,
    children,
    className,
    disabled,
    errorComponent = AlertError,
    defaultErrorMessage = "Something went wrong!",
    labelComponent,
    normalizeValues = _.identity,
    onFinish,
    onValuesChange,
    showButtons,
    submitDisabled,
    ...props
  } = useWizardProps(rawProps);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMounted = useIsMounted();

  const formContextValue = useMemo(() => ({ disabled, labelComponent }), [
    disabled,
    labelComponent,
  ]);

  const classes = classNames(className, "biobot-form", {
    "biobot-form-disabled": disabled,
  });

  const handleFinish = async (rawValues) => {
    // Give the promises a little bit of time to resolve before we show the
    // loading spinner.
    const values = normalizeValues(rawValues);
    const loadingTimeout = setTimeout(() => setLoading(true), 100);
    try {
      setError(null);
      if (onFinish) {
        await Promise.resolve(onFinish(values));
      }
    } catch (err) {
      if (isMounted) {
        if (err?.response?.status === 400 || err?.response?.status === 401) {
          setError(
            `Something went wrong, please refresh the page and try to submit this form again. If problems persist, please contact us at support@biobot.io`,
            5
          );
        } else if (err instanceof FormError) {
          setError(err.message);
        } else {
          setError(defaultErrorMessage);
        }
      }
    } finally {
      clearTimeout(loadingTimeout);
      if (isMounted) {
        setLoading(false);
      }
    }
  };

  const handleValuesChange = onValuesChange
    ? (changedValues, allValues) => {
        onValuesChange(changedValues, normalizeValues(allValues));
      }
    : null;

  const renderChildren = (children) => (
    <FormItemContext.Provider value={formContextValue}>
      {children}
      {error && (
        <FormItem>
          {renderClone(errorComponent, {
            clearError: () => setError(null),
            message: error,
          })}
        </FormItem>
      )}
      {showButtons && (
        <FormItem>
          {renderClone(buttonsComponent, { loading, submitDisabled })}
        </FormItem>
      )}
    </FormItemContext.Provider>
  );

  return (
    <AntdForm
      {...props}
      ref={ref}
      className={classes}
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
    >
      {typeof children === "function"
        ? (values, form) => renderChildren(children(values, form))
        : renderChildren(children)}
    </AntdForm>
  );
});

const Form = Object.assign(WizardForm, {
  Item: FormItem,
  useForm: AntdForm.useForm,
  useWizardProps,
  Error: FormError,
});

export default Form;
