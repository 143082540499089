import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceCustomMutation,
  orderServiceCustomRoute,
  orderServiceExport,
  orderServiceFetchList,
  orderServiceFindOneQuery,
  orderServiceKey,
  orderServiceListQuery,
  orderServiceUpdateMutation,
} from "./orderService";
import { authorizationHeader, mkQuery, orderServiceRoot } from "./util";

const model = "kits";

export const useKit = orderServiceFindOneQuery({ model });
export const useKitList = orderServiceListQuery({ model });
export const useKitCount = orderServiceCountQuery({ model });
export const useCreateKit = orderServiceCreateMutation({ model });
export const useUpdateKit = orderServiceUpdateMutation({ model });

export const useKitReceivedByLab = orderServiceCustomMutation({
  model,
  path: "received_by_lab",
  method: "post",
});

export const usePublicKit = mkQuery({
  key: ({ shippingKitId }) =>
    orderServiceKey({ shippingKitId, model, endpoint: "publicFindOne" }),
  axiosConfig: ({ shippingKitId }) => ({
    method: "get",
    url: `${orderServiceRoot}/kit-ids/${encodeURIComponent(shippingKitId)}`,
    headers: authorizationHeader(),
  }),
});

export const fetchTriageCSV = () =>
  orderServiceCustomRoute({ model, path: "lab_triage_csv" });

export const exportKits = (query) => orderServiceExport({ model, ...query });

export const fetchKitsForLocation = async (locationId, collectionCutoff) => {
  // @ts-ignore
  return orderServiceFetchList({
    model: "kits",
    _where: {
      [`sample.sampling_location_id.id`]: locationId,
      [`sample.sample_collection_date_time_gte`]: collectionCutoff.toISOString(),
    },
    _joins: ["sample.sampling_location_id"],
  }).then((response) => {
    return response.map((kit) => kit);
  });
};
