import { Helmet } from "react-helmet";
import { Col, Form, PageHeader, Row, Spin } from "antd";
import { useState, useMemo } from "react";
import moment from "moment";

import { useKitConfigurationList } from "api/kitConfiguration";
import { useFindByShippingOrderId } from "api/order";

import Instructions from "./Instructions";
import { ShippingOrderIdSearch } from "./ShippingOrderIdSearch";
import { FailedOrderSearch } from "./FailedOrderSearch";
import { OrderFulfillmentForm } from "./OrderFulfillmentForm";

const OrderFulfillment = () => {
  const [shippingOrderIdForm] = Form.useForm();

  const [shippingOrderId, setShippingOrderId] = useState(null);

  const kitConfigurationsQuery = useKitConfigurationList(
    {
      _where: {
        active: true,
      },
    },
    {
      enabled: Boolean(shippingOrderId),
    }
  );

  const orderShipmentQuery = useFindByShippingOrderId(
    {
      id: shippingOrderId,
      fulfillment_provider: "biobot",
    },
    {
      enabled: Boolean(shippingOrderId),
    }
  );

  const areQueriesLoading =
    kitConfigurationsQuery.isLoading || orderShipmentQuery.isLoading;

  const kitConfigurationOptions = useMemo(() => {
    if (orderShipmentQuery.data?.date_shipped) {
      // If the order has already been fulfilled, we need to filter the kit configurations.
      // Currently we only support one kit configuration per order, so we can just filter to the first one.
      const configurationId = orderShipmentQuery.data?.kits[0].configuration;

      return kitConfigurationsQuery.data.filter(
        (config) => config.id === configurationId
      );
    }

    return kitConfigurationsQuery.data ?? [];
  }, [kitConfigurationsQuery.data, orderShipmentQuery.data]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>Fulfill Order</title>
      </Helmet>

      <Spin spinning={areQueriesLoading}>
        <Row>
          <Col span={24}>
            <PageHeader title="Fulfill Order" className="pl-0"></PageHeader>
          </Col>
        </Row>

        <Instructions></Instructions>

        <Form form={shippingOrderIdForm} layout={"vertical"}>
          <ShippingOrderIdSearch
            onSearch={(value) => setShippingOrderId(value)}
          ></ShippingOrderIdSearch>
        </Form>

        {orderShipmentQuery.data && kitConfigurationsQuery.data && (
          <OrderFulfillmentForm
            orderId={orderShipmentQuery.data.id}
            shippingOrderId={shippingOrderId}
            kits={orderShipmentQuery?.data.kits ?? []}
            kitConfigurationOptions={kitConfigurationOptions}
            isPreviouslyFulfilled={Boolean(
              orderShipmentQuery.data.date_shipped
            )}
            datePreviouslyFulfilled={
              orderShipmentQuery.data.date_shipped
                ? moment(orderShipmentQuery.data.date_shipped).format(
                    "MMMM Do YYYY"
                  )
                : null
            }
            onFinish={() => {
              setShippingOrderId(null);
              shippingOrderIdForm.resetFields();
            }}
            key={shippingOrderId}
          />
        )}

        {orderShipmentQuery.isError && (
          <FailedOrderSearch error={orderShipmentQuery.error} />
        )}
      </Spin>
    </>
  );
};

export default OrderFulfillment;
