import Title from "antd/lib/typography/Title";
import React from "react";

import MetadataDetailTable from "components/MetadataDetailTable/MetadataDetailTable";
import { KitMetaFull } from "../types/orderService";

interface Props {
  kitMeta: KitMetaFull;
}

const LocationDetails = ({ kitMeta }: Props) => {
  const locDetailCells = [
    [
      {
        title: "Organization",
        value: kitMeta.organization?.organization_name,
      },
      {
        title: "Sampling Location",
        value: kitMeta.sample?.sampling_location_id?.sampling_location_name,
      },
      {
        title: "City",
        value: kitMeta.sample?.sampling_location_id?.city,
      },
      {
        title: "State",
        value: kitMeta.organization?.province,
      },
    ],
    [
      {
        title: "Location Type",
        value: kitMeta.sample?.sampling_location_id?.location_type,
      },
      {
        title: "Est. Population",
        value:
          kitMeta.sample?.sampling_location_id?.estimated_population_served,
      },
      {
        title: "Sample Matrix",
        value: kitMeta.sample?.sampling_location_id?.sample_matrix,
      },
      {
        title: "Daily Flow (MGD)",
        value: kitMeta.sample?.sampling_location_id?.influent_daily_flow_mgd,
      },
    ],
  ];

  return (
    <>
      <Title level={3}>Location Details</Title>
      <MetadataDetailTable tableCellsArray={locDetailCells} />
    </>
  );
};

export default LocationDetails;
