import _ from "lodash";
import { Button, Col, Form, Input, Row, Tooltip, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { kitIDRule } from "utils/rules";

export function KitFulfillment({ kits, setKitsFulfilledCount }) {
  async function validateKits(__, values) {
    const expectedNumKits = kits?.length || 0;
    if (values?.length !== expectedNumKits) {
      return Promise.reject(
        new Error(
          "Number of kits provided does not match number of kits in order."
        )
      );
    }

    const uniqueIds = new Set(
      values.filter(_.identity).map((x) => x.shipping_kit_id)
    );

    if (uniqueIds.size !== expectedNumKits) {
      return Promise.reject(
        new Error("Each kit must have a unique shipping kit id.")
      );
    }
  }

  return (
    <>
      <Typography.Title level={4}>Kits</Typography.Title>
      <Form.List
        name="kit_shipping_metas"
        rules={[
          {
            validator: validateKits,
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} gutter={16} align="middle" className="mb-1">
                <Col span={5}>
                  <Form.Item
                    {...restField}
                    label={
                      <>
                        <Text>Shipping Kit Id</Text>
                        <Tooltip title="This is called 'Kit ID' in the Kits table. (app.biobot.io/admin/kits)">
                          <QuestionCircleOutlined className="pl-1" />
                        </Tooltip>
                      </>
                    }
                    name={[name, "shipping_kit_id"]}
                    rules={[
                      {
                        required: true,
                        message: "Shipping Kit Id is required",
                      },
                      kitIDRule,
                      { len: 9 },
                    ]}
                  >
                    <Input
                      placeholder="Enter shipping kit id"
                      onPressEnter={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    {...restField}
                    label={
                      <>
                        <Text>Outbound Tracking Number</Text>
                        <Tooltip title="This is called 'Outbound tracking number' in the Kits table. (app.biobot.io/admin/kits)">
                          <QuestionCircleOutlined className="pl-1" />
                        </Tooltip>
                      </>
                    }
                    name={[name, "outbound_tracking_number"]}
                    rules={[
                      {
                        required: true,
                        message: "Outbound Tracking Number is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter outbound tracking number"
                      onPressEnter={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    {...restField}
                    label={
                      <>
                        <Text>Inbound Tracking Number</Text>
                        <Tooltip title="This is called 'Inbound tracking number' in the Kits table. (app.biobot.io/admin/kits)">
                          <QuestionCircleOutlined className="pl-1" />
                        </Tooltip>
                      </>
                    }
                    name={[name, "inbound_tracking_number"]}
                    rules={[
                      {
                        required: true,
                        message: "Inbound Tracking Number is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter inbound tracking number"
                      onPressEnter={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <MinusCircleOutlined
                    onClick={() => {
                      setKitsFulfilledCount(fields.length - 1);
                      remove(name);
                    }}
                  />
                </Col>
              </Row>
            ))}
            <Row className="mb-5">
              <Col>
                <Button
                  type="dashed"
                  data-cy="add_kit_btn"
                  onClick={() => {
                    setKitsFulfilledCount(fields.length + 1);
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add kit
                </Button>
                <Form.ErrorList errors={errors} />
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
}
