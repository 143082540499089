import { DatePicker } from "antd";
import _ from "lodash";
import moment from "moment";

const valueProps = ["value", "defaultValue", "defaultPickerValue"];
const callbackProps = ["onChange", "disabledDate"];

const transformCallback = (callback) => (momentVal) =>
  callback(momentVal?.format("YYYY-MM-DD"));

const transformValue = (stringVal) =>
  stringVal == null ? stringVal : moment(stringVal);

const transformProps = (props) =>
  _.assign(
    {},
    props,
    _.mapValues(_.pick(props, valueProps), transformValue),
    _.mapValues(_.pick(props, callbackProps), transformCallback)
  );

/** Like antd's DatePicker, but for strings */
const DateStringPicker = (props) => <DatePicker {...transformProps(props)} />;

export default DateStringPicker;
