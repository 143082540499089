import { Form, Select, message } from "antd";
import { useOrganizationList } from "api/organization";

export default function OrgSelect({ setSelectedOrgForLinking }) {
  const { data: orgList, isLoading, isError } = useOrganizationList({
    _limit: -1,
  });

  if (isError) message.error("Error loading organizations");

  return (
    <Form.Item
      name="correct_org_id"
      label="Correct Org ID"
      rules={[{ required: true }]}
    >
      <Select
        showSearch
        loading={isLoading}
        placeholder="Correct Org ID"
        onChange={(val, optionProperties) => {
          setSelectedOrgForLinking(optionProperties.name);
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {orgList?.length > 0 &&
          orgList?.map((org, index) => (
            <Select.Option
              key={index}
              value={org.id}
              name={org.organization_name}
            >
              {org.display_id}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}
