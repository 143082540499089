const siteMetadata = (state = {}, action) => {
  switch (action.type) {
    case "ADD_METADATA_VALUE":
      if (action.payload) {
        return { ...state, ...action.payload };
      }
      return {};
    default:
      return state;
  }
};

export default siteMetadata;
