import { Input, Typography } from "antd";
import { Link as RouterLink } from "react-router-dom";
import { Form, Wizard } from "../components/Wizard";
import { callbackMessage, DemoCard, DemoContainer } from "./util";
const { Title, Text } = Typography;

// Read through the comments for a demonstration of Wizard features.

const Step1 = (props) => {
  return (
    <Form {...props}>
      <Form.Item>
        <Title level={5}>Step 1</Title>
      </Form.Item>
      <Form.Item
        name="first_name"
        label="First name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

const Step2 = (props) => {
  return (
    <Form {...props}>
      <Form.Item>
        <Title level={5}>Step 2</Title>
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

const ReviewStep = ({ result, ...props }) => {
  return (
    <Form {...props}>
      <Form.Item>
        <Title level={5}>Review</Title>
      </Form.Item>
      <Form.Item>
        <pre>{JSON.stringify(result, null, 2)}</pre>
      </Form.Item>
    </Form>
  );
};

const callbacks = {
  // Called when the entire Wizard has finished
  onFinish: callbackMessage("onFinish"),
  // Called when each Form in the Wizard has finished
  onFormFinish: callbackMessage("onFormFinish"),
};

const formProps = {
  layout: "vertical",
  // labelComponent (on Form or Form.Item) renders labels, while allowing the
  // label text to be used for error messages.
  labelComponent: <Text className="text-lg" />,
};

const ExampleWizard = (props) => (
  <Wizard {...props} {...callbacks}>
    {/*
      Wizard uses either regular elements as children, or a function returning
      children. The function takes { values, forms }, which are the same args
      sent to onFormFinish
    */}
    {({ values, forms }) => (
      <>
        <Step1
          name="name1"
          initialValues={{ user: { first_name: "Bob" } }}
          {...formProps}
        />
        <Step2
          name="name2"
          layout="vertical"
          initialValues={{ user: { last_name: "Ross" } }}
          {...formProps}
        />
        <ReviewStep name="review" result={{ values, forms }} {...formProps} />
      </>
    )}
  </Wizard>
);

// singlePage=false (the default) renders each form on a separate page.
const MultiPageWizard = () => <ExampleWizard />;

// singlePage=true renders previous forms disabled and without buttons
// showBackAlways shows the back button (disabled) on the first form
const SinglePageWizard = () => <ExampleWizard singlePage showBackAlways />;

const DemoMulti = () => {
  const description = (
    <div className="text-md">
      <p>
        Expects children to be enhanced{" "}
        <RouterLink to="/demo/form">Form</RouterLink> components (forms can be
        nested under other components or fragments, they do not need to be
        direct children, just descendants).
      </p>
      <p>Features:</p>
      <ul>
        <li>
          <code>onFormFinish</code> callback (<code>onFinish</code> for each
          individual form).
        </li>
        <li>
          <code>onFinish</code> callback on the last step, takes{" "}
          <code>{"{ values, forms }"}</code>.
        </li>
        <li>Back / Next buttons added automatically.</li>
        <li>Next changes to Submit on the last step.</li>
        <li>Form state is saved when moving back and forth between steps.</li>
        <li>
          <code>labelComponent</code> is used for all labels in all forms.
        </li>
        <li>
          <code>children</code> can be React elements, or a function taking{" "}
          <code>{"{ values, forms }"}</code>.
        </li>
      </ul>
    </div>
  );

  return (
    <DemoCard title="Multi-page Wizard" description={description}>
      <MultiPageWizard />
    </DemoCard>
  );
};

const DemoSingle = () => {
  const description = (
    <div className="text-md">
      <p>Additional features:</p>
      <ul>
        <li>
          <code>singlePage</code>: previous steps are rendered disabled and
          without buttons.
        </li>
        <li>
          <code>showBackAlways</code>: back button appears (and is disabled) on
          the first step.
        </li>
      </ul>
    </div>
  );

  return (
    <DemoCard title="Single-page Wizard" description={description}>
      <SinglePageWizard />
    </DemoCard>
  );
};

const WizardDemo = () => (
  <DemoContainer>
    <DemoMulti />
    <DemoSingle />
  </DemoContainer>
);

export default WizardDemo;
