import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
  orderServiceCustomMutation,
} from "./orderService";

const model = "sampling-location-program-enrollment";

export const useSamplingLocationProgramEnrollment = orderServiceFindOneQuery({
  model,
});

export const useSamplingLocationProgramEnrollmentList = orderServiceListQuery({
  model,
});

export const useSamplingLocationProgramEnrollmentCount = orderServiceCountQuery(
  { model }
);

export const useCreateSamplingLocationProgramEnrollment = orderServiceCreateMutation(
  { model }
);

export const useUpdateSamplingLocationProgramEnrollment = orderServiceUpdateMutation(
  { model }
);

export const useBulkUpdateSamplingLocationProgramEnrollments = orderServiceCustomMutation(
  {
    model,
    path: "bulk-update",
    method: "post",
  }
);
