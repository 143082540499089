import { InfoCircleFilled } from "@ant-design/icons";
import { Select, Form, Row, Col, Typography } from "antd";

const OrderCycles = (props) => {
  const { Title } = Typography;

  return (
    <Row className="mb-3">
      <Col span={24}>
        <Title level={3}>Order Cycles</Title>
      </Col>

      <Col span={8} data-cy="order-cycles">
        <Form.Item
          name={"cycles"}
          label="Number of cycles"
          rules={[
            {
              required: true,
            },
          ]}
          tooltip={{
            title:
              "Each cycle is 4 weeks in length. We recommend sampling once a week for a minimum of 6 cycles (6 months).",
            icon: <InfoCircleFilled className="ml-1" />,
          }}
        >
          <Select data-cy="select-cycles" placeholder={"Select cycles..."}>
            <Select.Option key={1} value={1}>
              1 cycle (one-time order)
            </Select.Option>
            <Select.Option key={2} value={2}>
              2 cycles (2 months)
            </Select.Option>
            <Select.Option key={3} value={3}>
              3 cycles (3 months)
            </Select.Option>
            <Select.Option key={4} value={4}>
              4 cycles (4 months)
            </Select.Option>
            <Select.Option key={5} value={5}>
              5 cycles (5 months)
            </Select.Option>
            <Select.Option key={6} value={6}>
              6 cycles (6 months)
            </Select.Option>
            <Select.Option key={7} value={7}>
              7 cycles (7 months)
            </Select.Option>
            <Select.Option key={8} value={8}>
              8 cycles (8 months)
            </Select.Option>
            <Select.Option key={9} value={9}>
              9 cycles (9 months)
            </Select.Option>
            <Select.Option key={10} value={10}>
              10 cycles (10 months)
            </Select.Option>
            <Select.Option key={11} value={11}>
              11 cycles (11 months)
            </Select.Option>
            <Select.Option key={12} value={12}>
              12 cycles (12 months)
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default OrderCycles;
