import React from "react";
import { Col, Row, Typography, Button } from "antd";

const MissingSampleLogInfo = ({ onLogSampleClick }) => {
  return (
    <Row justify="center" className="SamplingInfo__wrapper">
      <Col>
        <Typography.Title level={3}>
          This kit has not been associated with a sample log.
        </Typography.Title>
      </Col>
      <Col span={24} className="center text-center">
        <Button
          data-cy="missing-log-sample"
          type="primary"
          onClick={onLogSampleClick}
        >
          Log Sample
        </Button>
      </Col>
    </Row>
  );
};

export default MissingSampleLogInfo;
