import { orderServiceListQuery } from "./orderService";
import { optionsTemplate, defaultOptionsForStaticModels } from "./util";

const model = "report-review-statuses";

export const useReportReviewStatusList = orderServiceListQuery({
  model,
  options: defaultOptionsForStaticModels,
});

const selectOptions = optionsTemplate(
  {
    label: "status",
    value: "status",
    id: "id",
  },
  { sortBy: "id" } // 1. New 2. Under Review 3. Ready To Send 4. Do Not Send
);

export const useReportReviewStatusOptions = () =>
  useReportReviewStatusList(
    {
      _joins: false,
    },
    { select: selectOptions }
  );
