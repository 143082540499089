import { Form, InputNumber, Typography } from "antd";

export default function Capacity({ initialValue, isInternal }) {
  return (
    <>
      <Form.Item
        label="WWTP design capacity (MGD)"
        name="capacity_mgd"
        initialValue={initialValue}
        className="mb-0"
        rules={[
          {
            required: !isInternal,
            message: "Enter your best estimate",
          },
        ]}
      >
        <InputNumber
          min={0.0000001}
          style={{ width: "100%" }}
          placeholder="Enter in million gallons per day"
          data-cy="capacity_mgd"
        />
      </Form.Item>
      <Typography.Text type="secondary">
        Enter the wastewater treatment plant design capacity in millions of
        gallons per day (MGD).
      </Typography.Text>
    </>
  );
}
