import { DownloadOutlined } from "@ant-design/icons";
import { PageHeader, Table, Button, message } from "antd";
import { useState } from "react";
import moment from "moment";
import fileDownload from "js-file-download";

import {
  usePcrUploadCount,
  usePcrUploadList,
  useReuploadPcrUploadFile,
  fetchPcrUploadCsv,
} from "api/fileUpload";
import { useTableQueryParams } from "components/Table/helpers";
import { METHOD_NOT_EMPTY } from "components/Table/StrapiFilter";
import { getPcrUploadTableColumns } from "./helpers";
import PcrUploadFileModal from "./PcrUploadFileModal";
import "./PcrUploads.css";

const PcrUploadsPage = () => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedUpload, setSelectedUpload] = useState();
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [csvIsDownloading, setCsvIsDownloading] = useState(false);
  const { mutateAsync: reuploadPcrFile } = useReuploadPcrUploadFile();

  const { queryParams, handleTableChange, pagination } = useTableQueryParams({
    defaultSort: "date_of_qpcr:DESC",
    defaultFilters: {
      date_of_qpcr: [
        {
          method: METHOD_NOT_EMPTY,
          value: true,
        },
      ],
    },
  });
  const { data: pcrUploadCount } = usePcrUploadCount(queryParams);
  const pcrUploads = usePcrUploadList(queryParams);

  const handleFileClick = (upload) => {
    setIsUploadModalOpen(true);
    setSelectedUpload(upload);
  };

  const handleUploadSubmit = async (upload, file) => {
    setIsUploadingFile(true);
    message.loading("Replacing PCR Upload File...", 0);
    await reuploadPcrFile({ upload, file })
      .then((result) => {
        message.destroy();
        const text = `Successfully replaced "${upload.file_name}" with "${result.file_name}"`;
        message.success(text, 6);
        pcrUploads.refetch();
        setIsUploadModalOpen(false);
      })
      .catch((e) => {
        message.destroy();
        const errMsg = e.message || "Something went wrong reuploading file";
        message.error(errMsg);
      })
      .finally(() => setIsUploadingFile(false));
  };

  const downloadPcrUploadsCSV = () => {
    setCsvIsDownloading(true);
    const date = moment().format("MM-DD-YYYY");
    fetchPcrUploadCsv(queryParams)
      .then((data) => fileDownload(data, `${date}-qpcr-uploads.csv`))
      .catch(message.error)
      .finally(() => setCsvIsDownloading(false));
  };

  return (
    <>
      <PageHeader title="PCR Uploads" />
      <div className="ml-3">
        <Button
          type="primary"
          size="small"
          shape="round"
          className="my-3"
          loading={csvIsDownloading}
          onClick={downloadPcrUploadsCSV}
        >
          Download as CSV
          <DownloadOutlined />
        </Button>
        <Table
          data-cy="qpcr-uploads-table"
          rowKey="id"
          loading={pcrUploads.isLoading}
          columns={getPcrUploadTableColumns({ handleFileClick })}
          dataSource={pcrUploads.data}
          onChange={handleTableChange}
          pagination={{ ...pagination, total: pcrUploadCount }}
          scroll={{ x: "max-content" }}
        />
        <PcrUploadFileModal
          isOpen={isUploadModalOpen}
          upload={selectedUpload}
          isUploadingFile={isUploadingFile}
          onCancel={() => setIsUploadModalOpen(false)}
          onSubmit={handleUploadSubmit}
        />
      </div>
    </>
  );
};

export default PcrUploadsPage;
