import React, { useState, useEffect, useImperativeHandle } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Typography,
  message,
  Form,
  Popconfirm,
  Space,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditableCell from "./EditableCell";
import _ from "lodash";

const { Text } = Typography;

/**
 * This table comes with out of the box support for:
 * - state management for fetching data
 * - showing row counts
 * - pagination
 * - an optional "Edit" column that allows the parent component to configure which table columns are
 *   editable and define an on save callback option
 * - integration with the TableSearch component for specifying query parameters to use as table filters
 * @param rowKey - the field name on each row record that represents the unique key. this will also be used when
 *                 overriding row data
 * @param columns - forwards the columns to the underlying antd table component. Uses base schema documented here:
 *                  https://ant.design/components/table/#Column. Additionally supports making a column editable by
 *                  specifying the field `edit: true` in column meta. If any column has edit: true, then an additional
 *                  column containing an edit button will be added automatically.
 * @param paramsForFetch - this parameter object will be proxied to the fetchRows and fetchRowCount functions (defined
 *                         below). The fetch functions will be invoked if this prop changes in the parent component.
 *                         It is recommended that the user set this as a state variable in the parent. Also, it is
 *                         designed to be used in conjunction with the TableSearch component's onSearchParamsUpdated
 *                         callback. While params will be passed to the fetchRows(Counts) as is, the tabl component
 *                         will use the following directly if provided:
 *                         {
 *                           _page: number - the pagination bar will be set to whatever value is specified
 *                           _limit: number - the pagination page size will be set to whatever vlaue is specified
 *                         }
 * @param fetchRows - called any time paramsForFetch changes. expect a function of type (params) => Promise([rows]). if
 *                    this query fails (aside from 404s, which set row data to empty array), a toast message will be
 *                    displayed to the user
 * @param fetchRowCount - called any time paramsForFetch changes. expect a function of type (params) => Promise(number).
 *                    if this query fails (aside from 404s, which set row data to empty array), a toast message will be
 *                    displayed to the user. If this prop is not provided, the table will assume there is no pagination
 *                    and will display all records and row counts based on the fetchRows response.
 * @param saveEditedRow - a callback the parent can use to specify what happens when row edits are saved. expects a
 *                        function of type (rowData, formData) => Promise(rowData). formData contains the fields which
 *                        are being edited in the UI. rowData is the underlying record powering the table's row
 *                        template. The handler should make an API calls it needs to save the db and should return a
 *                        copy of the rowData with the edited formData fields merged into it. This returned rowData
 *                        record will override the table's internal state for that row, thus rerendering the row with
 *                        the new data.
 * @param editButtonDisabledForRow - a callback that determines whether the user can click the edit button for a given
 *                                   row in the table. expects a function of type (rowData) => boolean. This can be
 *                                   used to disable editing of individual rows where editing may not make sense.
 * @param onSearchParamsUpdated - a callback that is invoked any time the table is updating the search params. currently
 *                                only used to update the page when the pagination buttons are clicked. the parent
 *                                component should provide a handler to update its searchParam state.
 * @param onTableChange - proxy for antd's onTableChange event (https://ant.design/components/table/#Table) so the
 *                        parent can hook into table events like filter changes.
 * @param overrideRowTotalColor - a callback function that is invoked any time the total rows is recalculated after a
 *                                fetch. some pages need to display totals in different colors based on the row count.
 *                                expects a function of type (number) => string | some color enum like "red", "black"
 * @param pageSize - configures the number of rows to show on each page
 *
 * @param embedEditColNextToEditableCell - configures where the edit column is placed in the table; default is the rightmost column
 */
const EditableTable = React.forwardRef(
  (
    {
      rowKey,
      columns,
      paramsForFetch,
      fetchRows,
      fetchRowCount,
      saveEditedRow,
      editButtonDisabledForRow,
      onSearchParamsUpdated,
      onTableChange,
      overrideRowTotalColor,
      pageSize,
      embedEditColNextToEditableCell,
      ...otherProps
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState();
    const [rowsTotal, setRowsTotal] = useState();
    const [rowTotalColor, setRowTotalColor] = useState("black");
    const [editingKey, setEditingKey] = useState("");

    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      // This method can be used to override the table's record data from the parent container using the ref prop.
      // This should only be used in scenarios where re-fetching the underlying table data is not possible/desired.
      // For example, in the kits received view the user can edit the kits sample log through a modal controlled by
      // this component's parent component. When the sample log is saved the parent can use this call to override
      // the kit record that this belongs to. This function expects a dictionary of rowKey -> rowData.
      overrideRows: (updatedRows) => {
        const newRows = rows.map((r) => {
          let rowId = r[rowKey];
          const updatedRow = updatedRows[rowId];
          return updatedRow ?? r;
        });

        setRows(newRows);
      },
      addRow: (newRow) => {
        setRows([newRow, ...rows]);
      },
    }));

    useEffect(() => {
      setLoading(true);
      const paramCopy = _.clone(paramsForFetch);

      const promises = [fetchRows(paramCopy)];
      if (fetchRowCount) {
        promises.push(fetchRowCount(paramCopy));
      }

      Promise.all(promises)
        .then((response) => {
          setRows(response[0].data);

          if (fetchRowCount) {
            setRowsTotal(response[1].data);
          } else {
            setRowsTotal(response[0].data.length);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setRows([]);
            setRowsTotal(0);
          } else {
            message.error("Something went wrong!");
          }
        })
        .then(() => {
          setLoading(false);
        });
    }, [paramsForFetch, fetchRowCount, fetchRows]);

    useEffect(() => {
      if (overrideRowTotalColor) {
        const textColor = overrideRowTotalColor(rowsTotal);
        if (textColor) {
          setRowTotalColor(textColor);
        }
      }
    }, [rowsTotal, overrideRowTotalColor]);

    const pagination = !fetchRowCount
      ? false
      : {
          defaultCurrent: 1,
          current: paramsForFetch._page,
          defaultPageSize: paramsForFetch._limit ?? (fetchRowCount ? 30 : null),
          total: rowsTotal,
          position: ["bottomLeft"],
          showSizeChanger: false,
          onChange: (page) => {
            onSearchParamsUpdated({ _page: page });
          },
        };

    const isEditing = (record) => record.id === editingKey;
    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.inputType ?? "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
    const isEditable = mergedColumns.some((col) => col.editable);
    if (isEditable) {
      const editCol = {
        title: "Edit",
        render: (_, row) => {
          const editingRow = isEditing(row);
          if (editingRow) {
            return (
              <Space
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  alignItems: "center",
                }}
              >
                <Button
                  type="link"
                  onClick={() => save(row.id)}
                  style={{ padding: "0" }}
                >
                  Save
                </Button>
                <Popconfirm
                  title="Sure to cancel?"
                  onConfirm={cancel}
                  style={{ margin: "0" }}
                >
                  <Button type="link">Cancel</Button>
                </Popconfirm>
              </Space>
            );
          } else {
            return (
              <Button
                type="default"
                disabled={
                  editButtonDisabledForRow
                    ? editButtonDisabledForRow(row)
                    : false
                }
                icon={<EditOutlined />}
                onClick={() => edit(row)}
              />
            );
          }
        },
      };

      if (embedEditColNextToEditableCell) {
        // Adds the editing field to the right of the first editable column
        const indexOfEditCol = mergedColumns.findIndex((col) => col.editable);
        mergedColumns.splice(indexOfEditCol + 1, 0, editCol);
      } else {
        // Places as rightmost column
        mergedColumns.push(editCol);
      }
    }

    const edit = (record) => {
      setEditingKey(record.id);
    };

    const cancel = () => {
      setEditingKey("");
      form.resetFields();
    };

    const save = async (id) => {
      try {
        const formData = await form.validateFields();
        const newData = [...rows];
        const index = newData.findIndex((item) => id === item.id);

        if (index > -1) {
          const row = newData[index];
          const updatedRow = await saveEditedRow(row, formData);
          newData[index] = updatedRow;

          setRows(newData);
          setEditingKey("");
          form.resetFields();
        }
      } catch (errInfo) {
        console.log("Validate Failed:", errInfo);
      }
    };

    return (
      <>
        <Row className="px-3 mb-1">
          <Col>
            <Text>
              Total Rows:{" "}
              <span style={{ color: rowTotalColor }}>
                {rowsTotal ? rowsTotal : "0"}
              </span>
            </Text>
          </Col>
        </Row>

        <Form form={form} component={false}>
          <Table
            data-cy="editable-table"
            className="px-3"
            rowKey={rowKey}
            loading={loading}
            columns={mergedColumns}
            dataSource={rows}
            pagination={pagination}
            onChange={onTableChange}
            {...otherProps}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
        </Form>
      </>
    );
  }
);

export default EditableTable;
