import { Link } from "react-router-dom";
import { Row } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

export default function Instructions() {
  return (
    <>
      <Row className="mt-5">
        <Title level={5}>Instructions:</Title>
      </Row>
      <Row className="mb-5">
        <ol>
          <li>
            <Text>
              Locate the order you want to fulfill in the{" "}
              <Link to="order-shipments">Order Shipments view.</Link>
            </Text>
          </li>
          <li>
            <Text>
              Copy the cell value for "Shipping Order #" and paste into Shipping
              Order Id field.
            </Text>
          </li>
          <li>
            <Text>
              Add a row in the form for each kit and scan the values in to their
              respective fields.
            </Text>
          </li>
          <li>
            <Text>Submit</Text>
          </li>
          <li>
            <Text>
              Ping @eng-on-call in #eng-support if you have any issues.
            </Text>
          </li>
        </ol>
      </Row>
    </>
  );
}
