import axios from "axios";
import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceFetchCsv,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
} from "./orderService";
import { orderServiceRoot } from "./util";

const model = "organizations";

export const useOrganization = orderServiceFindOneQuery({ model });
export const useOrganizationList = orderServiceListQuery({
  model,
  defaults: { _joins: false },
});
export const useOrganizationCount = orderServiceCountQuery({ model });
export const useCreateOrganization = orderServiceCreateMutation({ model });
export const useUpdateOrganization = orderServiceUpdateMutation({ model });

export const fetchOrganizationList = (params) => {
  return axios.get(`${orderServiceRoot}/organizations`, params);
};

export const fetchOrganizationCsv = ({ _where, _sort }) => {
  return orderServiceFetchCsv({ model, _where, _sort });
};
