import { Button as AntButton } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

export default function TableCellButton({
  onClick,
  color,
  children,
  cypressTestId,
}) {
  return (
    <AntButton
      style={{ borderColor: color }}
      onClick={onClick}
      data-cy={cypressTestId}
    >
      {children}
    </AntButton>
  );
}

// presets
export const LogSampleButton = ({ onClick }) => {
  const color = "#FA8C16";
  return (
    <TableCellButton
      cypressTestId="log-sample-button"
      data-cy="log-sample-button"
      color={color}
      onClick={onClick}
    >
      Log Sample
      <ExclamationCircleFilled style={{ color }} />
    </TableCellButton>
  );
};
