import { React } from "react";
import { Provider } from "react-redux";
import ReduxPromise from "redux-promise";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { UserProvider } from "context";
import reducers from "reducers";
import Track from "components/Track/Track";
import ReactGA from "react-ga";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Routes from "Routes";
import { ConfigProvider } from "antd";
import queryClient from "./api/queryClient";
import "./styles/App.less";
import { ReactQueryDevtools } from "react-query/devtools";
ReactGA.initialize("UA-124209075-2", {
  testMode: process.env.NODE_ENV === "test",
});

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(ReduxPromise))
);
window.store = store;

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <UserProvider>
            <ConfigProvider
              getPopupContainer={(node) =>
                node?.closest(".ant-modal") ? node?.parentNode : document.body
              }
            >
              <QueryClientProvider client={queryClient}>
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Routes />
                    <Track />
                  </QueryParamProvider>
                </Router>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ConfigProvider>
          </UserProvider>
        </DndProvider>
      </Provider>
    </div>
  );
};

export default App;
