import { Col, Row, Input, Radio, Form, Typography } from "antd";

const options = [
  {
    value: "time-proportional 24-hr composite",
    title: "Time-proportional composite",
    description:
      "Pumping wastewater at a fixed time interval for a fixed volume",
  },
  {
    value: "flow-proportional 24-hr composite",
    title: "Flow-proportional composite",
    description:
      "Pumping wastewater at time intervals proportional to sewer flow",
  },
  {
    value: "manual composite",
    title: "Manual composite",
    description: "Grab samples collected and combined to create a composite",
    input: "manual_composite_description",
  },
  {
    value: "grab sample",
    title: "Grab sample",
    description: "One-time sampling, non-composite samples",
  },
  {
    value: "passive sample",
    title: "Passive sample",
    description: "Passively collected sample",
  },
];

const { Title, Text } = Typography;

export default function SamplingMethod({ method, isRequired }) {
  return (
    <Row className="mb-5">
      <Col span={24}>
        <Form.Item
          className="mb-0 block"
          name="sampling_type"
          label={<Title level={4}>Sampling method</Title>}
          rules={[
            {
              required: isRequired,
              message: "Select a sampling method.",
            },
          ]}
        >
          <Radio.Group className="w-100">
            <Row gutter={48}>
              {options.map(({ value, title, description, input }, index) => (
                <Col
                  className="mb-3"
                  sm={8}
                  xs={24}
                  key={[value, index].join()}
                >
                  <Row>
                    <Col span={24}>
                      <Radio value={value}>
                        <Text strong className="block">
                          {title}
                        </Text>
                        {description && (
                          <Text type="secondary">{description}</Text>
                        )}
                      </Radio>
                    </Col>
                  </Row>
                  {input === "manual_composite_description" && (
                    <Form.Item
                      key={index}
                      name={input}
                      className="pl-3 mb-0 block"
                    >
                      <Input
                        data-cy="sampling-method__manual-composite-method-details-input"
                        disabled={method !== "manual composite"}
                        placeholder="Enter sampling details"
                      />
                    </Form.Item>
                  )}
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
}
