import { Select, Table } from "antd";
import Title from "antd/lib/typography/Title";

import { displayDate, renderPath } from "utils/table";
import QCReviewStatusTag from "components/QC/QCReviewStatusTag";
import TextArea from "antd/es/input/TextArea";
import { UpdateOverrideAction } from "reducers/reducerManualReview";
import React, { useContext } from "react";

import {
  AliquotStatus,
  AssayName,
  ManualReviewStatus,
} from "api/pipelinesAPI/types";
import { ReviewReducerContext } from "../reviewContext";

interface Props {
  aliquotStatuses: AliquotStatus[];
}

const AliquotStatuses = ({ aliquotStatuses }: Props) => {
  const { state, dispatch } = useContext(ReviewReducerContext);

  const assayState = state.activeAssayState();

  const columns = [
    {
      title: "Aliquot Name",
      render: renderPath("qpcr_aliquot.aliquot_name"),
    },
    {
      title: "Date of qPCR",
      render: (_: any) => (
        <p>{displayDate()("Tue, 07 Nov 2023 12:40:18 GMT")}</p>
      ),
    },
    {
      title: "Current Review Status",
      dataIndex: "status",
      render: (status: ManualReviewStatus) => (
        <QCReviewStatusTag status={status} />
      ),
    },
    {
      title: "Review Notes",
      dataIndex: "notes",
      width: "300px",
    },
    {
      title: "Status Override",
      width: "200px",
      render: (status: AliquotStatus) => {
        // The overrides map should never be empty but there is a rare edge case where the kit under review has reruns
        // and the original aliquot is not in a pending state. Reviewers shouldn't submit final statuses for aliquots
        // that need to be rerun, but mistakes can happen. The state's assay cache will only have aliquots that are in
        // a pending state, so looking up the historical aliquot status id will return null. Having a fallback status
        // will result in the historical status override being added to the cache if the reviewer changes the state.
        const override = assayState.getOverride(status.aliquot_status_id);

        const options = [
          {
            label: "No Override",
            value: ManualReviewStatus.STATUS_NO_OVERRIDE,
          },
          {
            label: "Approved",
            value: ManualReviewStatus.STATUS_APPROVED,
          },
          {
            label: "Rejected",
            value: ManualReviewStatus.STATUS_REJECTED,
          },
          {
            label: "Pending Rerun",
            value: ManualReviewStatus.STATUS_PENDING_RERUN,
          },
          {
            label: "Invalid",
            value: ManualReviewStatus.STATUS_INVALID,
          },
        ];

        return (
          <Select
            defaultValue={
              override?.status || ManualReviewStatus.STATUS_NO_OVERRIDE
            }
            onChange={(value) =>
              dispatch(
                new UpdateOverrideAction(
                  state.activeAssayName as AssayName,
                  status.aliquot_status_id,
                  value,
                  override.notes
                )
              )
            }
            options={options}
          />
        );
      },
    },
    {
      title: "Notes Override",
      width: "300px",
      render: (status: AliquotStatus) => {
        // See notes above about why this fallback is necessary.
        const override = assayState.getOverride(status.aliquot_status_id);

        return (
          <TextArea
            rows={3}
            onChange={(e) =>
              dispatch(
                new UpdateOverrideAction(
                  state.activeAssayName as AssayName,
                  status.aliquot_status_id,
                  override.status,
                  e.currentTarget.value
                )
              )
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <Title level={3}>Aliquots Under Review</Title>
      <Table
        data-cy="aliquot-statuses-table"
        rowKey="aliquot_status_id"
        loading={false}
        columns={columns}
        dataSource={aliquotStatuses}
        scroll={{ x: "max-content" }}
        pagination={false}
        className="mt-3"
      />
    </>
  );
};

export default AliquotStatuses;
