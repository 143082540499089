import { PageHeader, Select, Typography } from "antd";

import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";

import { useProgramList } from "api/program";

import ProgramEnrollmentsTable from "./ProgramEnrollmentsTable";

const ProgramEnrollments = () => {
  const { data: programs, isLoading: isProgramsLoading } = useProgramList();

  const [selectedProgramId, setSelectedProgramId] = useState(null);

  const selectedProgram = useMemo(() => {
    return programs?.find((program) => program.id === selectedProgramId);
  }, [selectedProgramId, programs]);

  return (
    <div className="admin-program-enrollments-page" style={{ height: "100%" }}>
      <PageHeader
        title="Program Enrollments"
        className="mb-3"
        style={{
          padding: 0,
          marginBottom: "15px",
        }}
        footer={
          <div>
            <Select
              options={programs?.map((program) => {
                return { label: program.name, value: program.id };
              })}
              onChange={(programId) => setSelectedProgramId(programId)}
              loading={isProgramsLoading}
              style={{ width: 350 }}
            ></Select>
          </div>
        }
      />

      {selectedProgramId ? (
        <ProgramEnrollmentsTable
          program={selectedProgram}
          key={selectedProgram.id}
        ></ProgramEnrollmentsTable>
      ) : (
        <Typography.Text>
          Please select a program to see its enrollments.
        </Typography.Text>
      )}
    </div>
  );
};

export default withRouter(ProgramEnrollments);
