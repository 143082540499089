import _ from "lodash";
import { formatDateTime } from "utils/time";
import { Typography } from "antd";

export const getTempPassword = () => {
  const tempPassword =
    Math.random().toString(36).slice(2) +
    Math.random().toString(36).toUpperCase().slice(2);
  return tempPassword;
};

export const formatDateField = (val) => {
  if (!_.isNil(val)) {
    return formatDateTime(val);
  } else {
    return <Typography.Text>-</Typography.Text>;
  }
};

export const formatLocationField = (val) => {
  if (_.startCase(val)) {
    return _.startCase(val);
  } else {
    return <Typography.Text>-</Typography.Text>;
  }
};
