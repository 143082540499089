import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, PageHeader } from "antd";
import { getOrders, getColumns } from "./helpers";
import { actionOrganizationGet } from "actions";
import { useUserContext } from "context";

const mapStateToProps = ({ organization }) => ({
  orders: organization.orders,
});

const mapDispatchToProps = {
  actionOrganizationGet,
};

const Orders = ({ orders, actionOrganizationGet }) => {
  const { organizationId } = useUserContext();

  useEffect(() => {
    if (organizationId) {
      getOrders(organizationId, actionOrganizationGet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <>
      <PageHeader title="Orders" />

      <Table
        columns={getColumns()}
        dataSource={orders}
        pagination={{
          defaultPageSize: 10,
          position: ["bottomLeft"],
        }}
        className="px-3"
      />
    </>
  );
};

const OrdersContainer = connect(mapStateToProps, mapDispatchToProps)(Orders);
export default withRouter(OrdersContainer);
