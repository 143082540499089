import React, { useState } from "react";
import { PageHeader, Table, Modal, Form, message, Popover, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";

import { useTableQueryParams } from "components/Table/helpers";
import { useReworkCount, useReworkList, useUpdateRework } from "api/rework";
import { getColumns, FilterInfo } from "./helpers";
import EditReworkFormModal from "./EditReworkFormModal";

const ReworksRequestedPage = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [modalForm] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState();
  const { mutateAsync: updateRework } = useUpdateRework();

  const {
    queryParams,
    handleTableChange,
    handleResetFilters,
    pagination,
    tableKey,
  } = useTableQueryParams({
    defaultSort: "created_at:DESC",
    defaultPageSize: 30,
  });
  const { data: reworkCount } = useReworkCount(queryParams);
  const reworks = useReworkList({
    ...queryParams,
    _joins: ["kit.order.order_placement.organization"],
  });

  const handleEditClick = (record) => {
    modalForm.resetFields();
    const initialValues = _.pick(record, [
      "status",
      "date_completed",
      "notes_for_lab_team",
      "reason_for_rework_and_requested_changes",
    ]);
    _.forEach(initialValues, (value, name) => {
      const isValidDateCompleted = name === "date_completed" && value;
      const formVal = isValidDateCompleted ? moment(value) : value;
      modalForm.setFieldValue(name, formVal);
    });

    setEditingRecord(record);
    setIsEditModalOpen(true);
  };

  const handleEditModalSave = async () => {
    message.loading("Updating Rework...");
    const { id } = editingRecord;
    const updatePayload = modalForm.getFieldsValue(true);
    await updateRework(
      { id, ...updatePayload },
      {
        onSuccess: () => {
          message.destroy();
          message.success("Successfully updated rework");
          modalForm.resetFields();
          setIsEditModalOpen(false);
        },
        onError: () => {
          message.destroy();
          message.error("Failed to update rework. Please try again.");
        },
        onSettled: () => reworks.refetch(),
      }
    );
  };

  const handleEditModalCancel = () => {
    modalForm.resetFields();
    setEditingRecord(null);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <PageHeader title="Reworks Requested" />
        <Popover
          title={<b>Where are my filters?</b>}
          placement="leftTop"
          content={<FilterInfo />}
        >
          <QuestionCircleOutlined className="mr-3" />
        </Popover>
      </div>

      <div className="flex-col ml-3">
        <Button className="w-fit mb-1" onClick={handleResetFilters}>
          Reset Filters
        </Button>
        <Table
          key={tableKey}
          data-cy="reworks-requested-table"
          rowKey="id"
          columns={getColumns(handleEditClick)}
          loading={reworks.isLoading}
          dataSource={reworks.data}
          pagination={{ ...pagination, total: reworkCount }}
          scroll={{ x: "max-content" }}
          onChange={handleTableChange}
        />
        <Modal
          open={isEditModalOpen}
          closable={false}
          okText="Save"
          onOk={handleEditModalSave}
          onCancel={handleEditModalCancel}
        >
          <EditReworkFormModal form={modalForm} record={editingRecord} />
        </Modal>
      </div>
    </>
  );
};

export default ReworksRequestedPage;
