import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { setSiderCollapsed } from "actions";
import Nav from "containers/Nav/Nav";
import Menu from "containers/Menu/Menu";
import { Layout as AntLayout, Row, Col, Typography, Grid } from "antd";
import "./styles.css";
import { UIProvider } from "context";

const mapStateToProps = (state, _ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  setSiderCollapsed,
};

const { Header, Sider, Content, Footer } = AntLayout;
const { Link } = Typography;
const { useBreakpoint } = Grid;

const Layout = ({
  route,
  menu,
  isPublic,
  footer = true,
  header = true,
  ui,
  setSiderCollapsed,
}) => {
  const screens = useBreakpoint();
  const { siderCollapsed: isSiderCollapsed } = ui;

  function handleSiderState() {
    setSiderCollapsed(screens.lg ? false : true);
  }

  useEffect(() => {
    handleSiderState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screens]);

  const renderMenu = (
    <Sider
      breakpoint="lg"
      collapsedWidth="80"
      width={250}
      onBreakpoint={() => {
        handleSiderState();
      }}
      zeroWidthTriggerStyle={{
        visibility: "hidden",
      }}
      collapsed={isSiderCollapsed}
    >
      <Menu />
    </Sider>
  );

  const renderRoute = <div>{route}</div>;

  const renderFooter = (
    <Footer>
      <Row className="text-center">
        <Col span={24}>
          If you incur any issues please contact{" "}
          <Link href="mailto:support@biobot.io">support@biobot.io</Link>
        </Col>
        <Col span={24}>
          &copy; {new Date().getFullYear()} Biobot Analytics, Inc.{" "}
        </Col>
      </Row>
    </Footer>
  );

  return (
    <UIProvider>
      <AntLayout style={{ minHeight: "100vh" }}>
        {header && (
          <Header className="layout__header">
            <Nav
              link={"/"}
              isPublic={isPublic}
              siderCollapsed={isSiderCollapsed}
            />
          </Header>
        )}

        <AntLayout>
          {menu && !isPublic && renderMenu}
          <Content
            className={classNames("layout__content", {
              "mt-5 mx-2": !isPublic,
            })}
          >
            {route && renderRoute}
            {footer && renderFooter}
          </Content>
        </AntLayout>
      </AntLayout>
    </UIProvider>
  );
};

const LayoutContainer = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default withRouter(LayoutContainer);
