import { Input, message, Select } from "antd";
import _ from "lodash";
import moment from "moment";

import { useProgramList, useProgramOptions } from "api/program";
import {
  useCreateSamplingLocationProgramEnrollment,
  useUpdateSamplingLocationProgramEnrollment,
} from "api/samplingLocationProgramEnrollment";
import DateStringPicker from "components/DateStringPicker";
import { Form } from "components/Wizard";
import { customRule, requiredRule } from "utils/rules";

const formatProgramDate = (date) =>
  date > "0001-01-01" && date < "9999-12-01" ? date : "(none)";

const mkInitialValues = (values) => ({
  ...values,
  // Handle either ids or objects
  program: values?.program?.id ?? values?.program,
  samplingLocation: values?.sampling_location?.id ?? values?.sampling_location,
});

const programDates = (program) => {
  const programStartDate = program?.start_date ?? "0001-01-01";
  const programEndDate = program?.end_date ?? "9999-12-01";
  const today = moment().format("YYYY-MM-DD");
  return {
    programStartDate,
    programEndDate,
    defaultDates: {
      start_date: programEndDate < today ? programStartDate : today,
      end_date: programEndDate,
    },
  };
};

const ProgramEnrollmentForm = ({ onFinish, ...props }) => {
  const [form] = Form.useForm(props.form);
  const initialValues = mkInitialValues(props.initialValues || {});
  const isUpdate = initialValues.id != null;

  const updateEnrollment = useUpdateSamplingLocationProgramEnrollment();
  const createEnrollment = useCreateSamplingLocationProgramEnrollment();

  const { data: programList } = useProgramList({ _joins: false });
  const programOptionsQuery = useProgramOptions();

  const programsById = _.keyBy(programList, "id");

  const handleFinish = async (values) => {
    if (isUpdate) {
      await updateEnrollment.mutateAsync(values);
      message.success("Enrollment updated");
    } else {
      await createEnrollment.mutateAsync(values);
      message.success("Enrollment created");
    }
    if (onFinish) {
      return onFinish(values);
    }
  };

  return (
    <Form
      layout="vertical"
      {...props}
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
    >
      {({ program, start_date, end_date }) => {
        const { programStartDate, programEndDate } = programDates(
          programsById[program]
        );
        const isDateValid = (d) => d >= programStartDate && d <= programEndDate;

        return (
          <>
            <Form.Item name="id" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="sampling_location" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              label="Program"
              name="program"
              disabled={isUpdate}
              rules={[requiredRule()]}
            >
              <Select
                options={programOptionsQuery.data}
                loading={programOptionsQuery.isLoading}
                onChange={(value) => {
                  // Set new default dates when the program changes
                  form.setFieldsValue(
                    programDates(programsById[value]).defaultDates
                  );
                }}
              />
            </Form.Item>
            <Form.Item
              label="Enrollment start"
              name="start_date"
              dependencies={["end_date"]}
              rules={[
                requiredRule(),
                customRule(
                  (value) => !value || !end_date || value <= end_date,
                  "Start date must be on or before end date"
                ),
              ]}
              extra={`Program start: ${formatProgramDate(programStartDate)}`}
            >
              <DateStringPicker disabledDate={_.negate(isDateValid)} />
            </Form.Item>
            <Form.Item
              label="Enrollment end"
              name="end_date"
              dependencies={["start_date"]}
              rules={[
                requiredRule(),
                customRule(
                  (value) => !value || !start_date || value >= start_date,
                  "End date must be on or after start date"
                ),
              ]}
              extra={`Program end: ${formatProgramDate(programEndDate)}`}
            >
              <DateStringPicker disabledDate={_.negate(isDateValid)} />
            </Form.Item>
          </>
        );
      }}
    </Form>
  );
};

export default ProgramEnrollmentForm;
