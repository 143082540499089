import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "./table";
import {
  actionKitsTotalGet,
  actionKitsPageGet,
  actionSampleGetByOrg,
} from "actions";
import Loader from "components/Loader/Loader";

const mapStateToProps = ({ organization, kits }) => ({
  organization,
  // kits,
  kitsTotal: kits.kitsTotal,
  kitsPage: kits.kitsPage,
});

const mapDispatchToProps = {
  actionKitsTotalGet,
  actionKitsPageGet,
  actionSampleGetByOrg,
};

const Kits = (props) => {
  const [loading, setLoading] = useState(true);
  const [samplesLoaded, setSamplesLoaded] = useState(false);

  useEffect(() => {
    setSamplesLoaded(false);
    if (props.organization.id) {
      props.actionKitsTotalGet(props.organization);
      props
        .actionKitsPageGet(props.organization, 0)
        .then(() => props.actionSampleGetByOrg(props.organization.id))
        .then(() => setSamplesLoaded(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organization.id]);

  useEffect(() => {
    if (props.kitsTotal > 0 && props.kitsPage.length > 0 && samplesLoaded) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.kitsPage, samplesLoaded]);

  if (!loading) {
    return (
      <Table kitsPage={props.kitsPage} organization={props.organization} />
    );
  }

  return <Loader />;
};

const KitsContainer = connect(mapStateToProps, mapDispatchToProps)(Kits);
export default withRouter(KitsContainer);
