import { combineReducers } from "redux";
import user from "./reducerUser.js";
import users from "./reducerUsers.js";
import accountUser from "./reducerAccountUser";
import organization from "./reducerOrganization.js";
import products from "./reducerProducts.js";
import address from "./reducerAddress.js";
import lead from "./reducerLead.js";
import order from "./reducerOrder.js";
import accounts from "./reducerAccounts.js";
import session from "./reducerSession.js";
import kits from "./reducerKits.js";
import sites from "./reducerSites.js";
import reports from "./reducerReports.js";
import orderDetail from "./reducerOrderDetail.js";
import roles from "./reducerRoles.js";
import ui from "./ui";
import siteMetadata from "./metadata";
import orders from "./orders";
import sample from "./sample";
import kit from "./kit";

export default combineReducers({
  accountUser,
  user,
  users,
  organization,
  products,
  address,
  lead,
  order,
  accounts,
  session,
  kits,
  sites,
  reports,
  orderDetail,
  roles,
  ui,
  siteMetadata,
  orders,
  sample,
  kit,
});
