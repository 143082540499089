import { Col, Row, Spin, Typography } from "antd";
import ReviewWorkflowBreadcrumbs from "../ReviewWorkflowBreadcrumbs";
import React from "react";

interface Props {
  message: string;
}

const LoadingPage = ({ message }: Props) => {
  return (
    <>
      <Row justify="start" className="ml-3">
        <Col>
          <ReviewWorkflowBreadcrumbs />
        </Col>
      </Row>
      <Row>
        <Col>
          <Spin />
          <Typography.Text>{message}</Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export default LoadingPage;
