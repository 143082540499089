import { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { LoginForm } from "./components.js";
import { actionUserAuth, actionUserTokenGet } from "actions";
import { Row, Col } from "antd";
import Loader from "components/Loader/Loader";

const mapDispatchToProps = {
  actionUserAuth,
  actionUserTokenGet,
};

const Login = ({ actionUserAuth, actionUserTokenGet, history }) => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState();

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <LoginForm
        username={username}
        actionUserAuth={actionUserAuth}
        actionUserTokenGet={actionUserTokenGet}
        history={history}
        setUsername={setUsername}
        loading={loading}
        setLoading={setLoading}
      />

      <Col span={24}>
        <Row>
          <Col className="mr-1">New customer?</Col>
          <Col>
            <Link to="/sign-up">Sign up &raquo;</Link>
          </Col>
        </Row>
        <Row>
          <Col className="center">
            <Link to="/account/forgot">Forgot Password?</Link>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

const LoginContainer = connect(null, mapDispatchToProps)(Login);

export default withRouter(LoginContainer);
