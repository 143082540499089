import axios from "axios";
import moment from "moment";
import _ from "lodash";
import qs from "qs";

export const actionUserAuth = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API}/identity/cognito/login`,
    {
      username: data.username,
      password: data.password,
    }
  );
  return {
    type: "USER_AUTH",
    payload: request,
  };
};

export const actionUserTokenGet = function () {
  const token = localStorage.getItem("token");
  const request = axios.get(
    `${process.env.REACT_APP_API}/identity/userTokens/${token}`
  );
  return {
    type: "SESSION_TOKEN_GET",
    payload: request,
  };
};

export const actionUserTokenRefresh = function () {
  const token = localStorage.getItem("refreshToken");
  const request = axios.get(
    `${process.env.REACT_APP_API}/identity/userTokens/refresh/${token}`
  );
  return {
    type: "SESSION_TOKEN_REFRESH",
    payload: request,
  };
};

export const actionForceChangePassword = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API}/identity/cognito/password/change`,
    {
      username: data.username,
      password: data.password,
      newPassword: data.newPassword,
    }
  );
  return {
    type: "USER_FORCE_CHANGE_PASSWORD",
    payload: request,
  };
};

export const actionLeadSave = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}leads?ts=${moment().format("x")}`,
    data
  );
  return {
    type: "LEAD_SAVE",
    payload: request,
  };
};

export const actionLeadAdd = function (data) {
  return {
    type: "LEAD_ADD",
    data: data,
  };
};

export const actionUserIpGet = function () {
  const request = axios.get(
    `https://geolocation-db.com/json/1a811210-241d-11eb-b7a9-293dae7a95e1`
  );
  return {
    type: "LEAD_USER_IP_GET",
    payload: request,
  };
};

export const actionLeadIpGet = function () {
  const request = axios.get(
    `https://geolocation-db.com/json/1a811210-241d-11eb-b7a9-293dae7a95e1`
  );
  return {
    type: "LEAD_USER_IP_GET",
    payload: request,
  };
};

export const actionLeadIpExistsGet = function (ip) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}leads/?ip_address=${ip}`
  );
  return {
    type: "LEAD_IP_EXISTS_GET",
    payload: request,
  };
};

export const actionOrganizationGet = function (data) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}organizations/?id=${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ORGANIZATION_GET",
    payload: request,
  };
};

export const actionOrderEdit = function (data) {
  return {
    type: "ORDER_EDIT",
    data: data,
  };
};

export const actionKitsGet = (data, searchInput) => {
  const request = axios.get(`${process.env.REACT_APP_API_URL}kits`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: {
      organization: data?.id,
      _where: searchInput && {
        _or: [
          { kit_id_contains: searchInput },
          { shipping_kit_id_contains: searchInput },
        ],
      },
    },
    paramsSerializer: qs.stringify,
  });
  return {
    type: "KITS_GET",
    payload: request,
  };
};

export const actionKitsTotalGet = (data, searchInput) => {
  const request = axios.get(`${process.env.REACT_APP_API_URL}kits/count`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: {
      organization: data?.id,
      _where: searchInput && {
        _or: [
          { kit_id_contains: searchInput },
          { shipping_kit_id_contains: searchInput },
        ],
      },
    },
    paramsSerializer: qs.stringify,
  });
  return {
    type: "KITS_TOTAL_GET",
    payload: request,
  };
};

export const actionKitsPageGet = function (data, skipNumber) {
  const url = data
    ? `${process.env.REACT_APP_API_URL}kits?_start=${skipNumber}&_limit=10&organization=${data.id}`
    : `${process.env.REACT_APP_API_URL}kits`;
  const request = axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return {
    type: "KITS_GET",
    payload: request,
  };
};

export const actionKitGet = function (id) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}kits/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return {
    type: "KIT_GET",
    payload: request,
  };
};

export const actionKitPost = function (id, data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}kits/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return {
    type: "KIT_POST",
    payload: request,
  };
};

export const actionSampleGetByOrg = function (id) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}samples?organization=${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "GET_SAMPLES_BY_ORG",
    payload: request,
  };
};

export const actionSamplePost = function (id, data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}sample/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return {
    type: "SAMPLE_POST",
    payload: request,
  };
};

export const actionSampleGet = function (kitId) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}samples?kit=${kitId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return {
    type: "SAMPLE_GET",
    payload: request,
  };
};

export const actionSitesGet = function (data) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}sampling-locations?organization=${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "SITES_GET",
    payload: request,
  };
};

export const actionOrderGet = function (data) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}orders/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ORDER_GET",
    payload: request,
  };
};

export const actionOrdersGet = function (orgId, schedule = true) {
  const request = axios.get(
    `${
      process.env.REACT_APP_API_URL
    }orders?order_placement.organization=${orgId}${
      schedule && `&order_placement.sampling_schedule_null=false`
    }`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return {
    type: "ORDERS_GET",
    payload: request,
  };
};

export const actionAccountsGet = function (data) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}identity/all`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return {
    type: "ACCOUNTS_GET",
    payload: request,
  };
};

export const actionOrganizationSet = function (data) {
  return {
    type: "ORGANIZATION_SET",
    data: data,
  };
};

export const actionOrganizationAdd = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}organizations?ts=${moment().format("x")}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ORGANIZATION_ADD",
    payload: request,
  };
};

export const actionSiteAdd = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}sampling-locations`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "SITE_ADD",
    payload: request,
  };
};

export const actionSiteEdit = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}sampling-locations/${
      data.id
    }?ts=${moment().format("x")}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "SITE_EDIT",
    payload: request,
  };
};

export const actionProductsGet = function (accountType = null) {
  let url = `${process.env.REACT_APP_API_URL}products/`;

  if (!_.isNil(accountType)) {
    url += `?product_account_type=${accountType}&_joins=product_group`;
  }
  const request = axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return {
    type: "PRODUCTS_GET",
    payload: request,
  };
};

export const actionOrderAdd = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}order-placements?ts=${moment().format(
      "x"
    )}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ORDER_ADD",
    payload: request,
  };
};

export const actionSiteSave = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}sites/${data.id}?ts=${moment().format(
      "x"
    )}`,
    data.record
  );
  return {
    type: "SITE_SAVE",
    payload: request,
  };
};

export const actionResendTemporaryPassword = function (email) {
  const request = axios.put(
    `${process.env.REACT_APP_API}/identity/cognito/admin/resendTemporaryPassword/${email}`,
    {}
  );
  return {
    type: "USER_RESEND_TEMP_PASSWORD",
    payload: request,
  };
};

export const actionSiteDelete = function (data) {
  return {
    type: "SITE_DELETE",
    data: data,
  };
};

export const actionUserCreateFlow = function (data) {
  return {
    type: "USER_CREATE_FLOW",
    data: data,
  };
};

// This geocoding api is the only place we're using MAPBOX_API_KEY, but this
// function isn't ever used, so I've removed that environment variable for now.
// If you need it again, you can look it up in our mapbox account (credentials
// in 1password) - MR
export const actionGeocodeAddress = function (address) {
  const request = axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      address
    )}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
  );
  return {
    type: "ADDRESS_GEOCODE",
    payload: request,
  };
};

export const actionAddressSave = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}shipping-locations?ts=${moment().format(
      "x"
    )}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ADDRESS_SAVE",
    payload: request,
  };
};

export const actionShippingLocationAdd = function (data) {
  return {
    type: "SHIPPING_LOCATION_ADD",
    data: data,
  };
};

export const actionShippingLocationSet = function (data) {
  return {
    type: "SHIPPING_LOCATION_SET",
    data: data,
  };
};

export const actionShippingLocationKitsEdit = function (data) {
  return {
    type: "SHIPPING_LOCATION_KITS_EDIT",
    data: data,
  };
};

export const actionShippingLocationDelete = function (data) {
  return {
    type: "SHIPPING_LOCATION_DELETE",
    data: data,
  };
};

export const actionOrderReset = function () {
  return {
    type: "ORDER_RESET",
  };
};

export const setSiteMetadataValue = function (payload) {
  return {
    type: "ADD_METADATA_VALUE",
    payload,
  };
};

export const actionAccountsSet = function (data) {
  return {
    type: "ACCOUNTS_SET",
    data: data,
  };
};

export const actionReportsGet = function (data) {
  const request = axios.get(
    `${process.env.REACT_APP_API}/report/v1/reports/accounts/${data.accountId}?limit=2000`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "REPORTS_GET",
    payload: request,
  };
};

export const actionCasesGet = function (data) {
  const request = axios.get(
    `${process.env.REACT_APP_API}/report/dataset/regions/${data.regionId}/covid_cases?from_date=${data.startDate}&to_date=${data.endDate}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "CASES_GET",
    payload: request,
  };
};

export const actionAccountDelete = function (data) {
  const request = axios.delete(
    `${process.env.REACT_APP_API_URL}identity/account/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ACCOUNT_DELETE",
    payload: request,
  };
};

export const actionWelcomeEmailSend = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}notifications`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_WELCOME_EMAIL_SEND",
    payload: request,
  };
};

export const actionLeadGet = function (id) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}leads/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return {
    type: "LEAD_GET",
    payload: request,
  };
};

export const actionLeadDelete = function (id) {
  const request = axios.delete(`${process.env.REACT_APP_API_URL}leads/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return {
    type: "LEAD_DELETE",
    payload: request,
  };
};

export const actionUserAccountsEdit = function (data) {
  const request = axios.put(
    `${
      process.env.REACT_APP_API_URL
    }identity/access/user/grant?ts=${moment().format("x")}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_ACCOUNTS_EDIT",
    payload: request,
  };
};

export const actionAccountUserGet = function (id) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}identity/account/user/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_ACCOUNT_GET",
    payload: request,
  };
};

export const actionRolesGet = function (id) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}identity/account/roles`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ROLES_GET",
    payload: request,
  };
};

export const actionUserRolesEdit = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}identity/account/user/roles/${data.userId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_EDIT",
    payload: request,
  };
};

export const actionLeadsGet = function (data) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}leads?became_organization=false`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "LEADS_GET",
    payload: request,
  };
};

export const actionUserAdd = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API}/identity/cognito/admin/addUser`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_ADD",
    payload: request,
  };
};

export const actionUserEdit = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}identity/account/user/${data.id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_EDIT",
    payload: request,
  };
};

export const actionUsersGet = function (accountId) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}identity/account/users/${accountId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USERS_GET",
    payload: request,
  };
};

/**
 *
 * @param {boolean} siderCollapsed - whether the sider should be collapsed or open
 * @returns the opposite state
 */
export const setSiderCollapsed = (siderCollapsed) => ({
  type: "SET_SIDER_COLLAPSED",
  payload: siderCollapsed,
});
