import { Tag } from "antd";

import { ManualReviewStatus } from "../../api/pipelinesAPI/types";

interface Props {
  status: ManualReviewStatus;
}

const QCReviewStatusTag = ({ status }: Props) => {
  let display;
  let color;

  if (status === ManualReviewStatus.STATUS_APPROVED) {
    display = "Approved";
    color = "green";
  } else if (status === ManualReviewStatus.STATUS_REJECTED) {
    display = "Rejected";
    color = "red";
  } else if (status === ManualReviewStatus.STATUS_PENDING_REVIEW) {
    display = "Pending Review";
    color = "gold";
  } else if (status === ManualReviewStatus.STATUS_MISSING_LOG) {
    display = "Missing Log";
    color = "gold";
  } else if (status === ManualReviewStatus.STATUS_PENDING_RERUN) {
    display = "Pending Rerun";
    color = "purple";
  } else if (status === ManualReviewStatus.STATUS_FLAGGED_RERUN) {
    display = "Rerun Sample";
    color = "purple";
  } else if (status === ManualReviewStatus.STATUS_INVALID) {
    display = "Invalid";
    color = "black";
  } else if (status === ManualReviewStatus.STATUS_NO_OVERRIDE) {
    display = "Not Yet Reviewed";
    color = undefined;
  }

  return <Tag color={color}>{display}</Tag>;
};

export default QCReviewStatusTag;
