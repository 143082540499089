import { Row, Col, Typography, Form, Button, Input } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

function ContactQuestions({ index }) {
  return (
    <Col className="mb-2">
      <Row gutter={40}>
        <Col sm={8} xs={24}>
          <Form.Item label="Full Name" name={[index, "full_name"]}>
            <Input placeholder="Contact Name" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={40}>
        <Col sm={8} xs={24}>
          <Form.Item
            label="Email Address"
            name={[index, "email"]}
            validateTrigger="onBlur"
            normalize={(value) => value.trim()}
            rules={[
              {
                type: "email",
                message: "Enter email",
              },
            ]}
          >
            <Input placeholder="Email address" />
          </Form.Item>
        </Col>

        <Col sm={8} xs={24}>
          <Form.Item label="Job Title" name={[index, "job_title"]}>
            <Input placeholder="Job title" />
          </Form.Item>
        </Col>

        <Col sm={8} xs={24}>
          <Form.Item label="Organization" name={[index, "organization_name"]}>
            <Input placeholder="Organization name" />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
}

export default function AdditionalContacts() {
  return (
    <>
      <Row className="mt-4">
        <Col>
          <Title level={5}>
            If you would like other contacts to be added to results
            notifications, please indicate their contact information below.
          </Title>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col span={24}>
          <Form.List name="additional_contacts" initialValue={[{}]}>
            {(fields, { add }) => (
              <>
                {fields.map((field, index) => (
                  <ContactQuestions key={index} index={index} />
                ))}
                <Row>
                  <Button type="primary" onClick={() => add()}>
                    Add Contact <PlusCircleOutlined />
                  </Button>
                </Row>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </>
  );
}
