import { Layout, Menu, Typography } from "antd";
import { Link, Route, useLocation, useRouteMatch } from "react-router-dom";
import FormDemo from "./FormDemo";
import ReactQueryTableDemo from "./ReactQueryTableDemo";
import { DemoCard, DemoContainer } from "./util";
import WithLoadingDemo from "./WithLoadingDemo";
import WizardDemo from "./WizardDemo";
const { Paragraph } = Typography;

const demoRoutes = [
  { path: "wizard", component: WizardDemo },
  { path: "form", component: FormDemo },
  { path: "with-loading", component: WithLoadingDemo },
  { path: "react-query-table", component: ReactQueryTableDemo },
];

const useAbsoluteRoutes = (routes) => {
  const match = useRouteMatch();
  return routes.map((item) => ({
    ...item,
    fullUrl: `${match.url}/${item.path}`,
    fullPath: `${match.path}/${item.path}`,
  }));
};

const Landing = () => (
  <DemoContainer>
    <DemoCard title="Demos">
      <Paragraph>Select a demo from the menu on the left.</Paragraph>
    </DemoCard>
  </DemoContainer>
);

const Demo = () => {
  const { path: demoPath } = useRouteMatch();
  const routes = useAbsoluteRoutes(demoRoutes);
  const location = useLocation();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Sider breakpoint="sm" collapsedWidth="0">
        <Menu mode="inline" selectedKeys={[location.pathname]}>
          {routes.map(({ fullUrl, path }) => (
            <Menu.Item key={fullUrl}>
              <Link to={fullUrl}>{path}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
      <Layout.Content style={{ padding: "32px", background: "#ddd" }}>
        <Route exact path={demoPath} component={Landing} />
        {routes.map(({ fullPath, component }) => (
          <Route key={fullPath} path={fullPath} component={component} />
        ))}
      </Layout.Content>
    </Layout>
  );
};

export default Demo;
