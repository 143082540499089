import { Form, Col as Column, Row, Tooltip, Select } from "antd";

const KitConfiguration = (props) => {
  return (
    <Row className="mb-3">
      {/* Commented out until the selector is unhidden because there's a need.
          Not removed from the form to retain input data for the order models */}
      {/* <Column span={24}>
        <Typography.Title level={3}>Kit Configuration</Typography.Title>
      </Column> */}

      <Column span={8} data-cy="order-cycles">
        <Form.Item
          name={"kit_configuration"}
          label="Kit configuration"
          hidden={true}
          rules={[
            {
              required: true,
            },
          ]}
          tooltip={{
            title:
              "Select the kit configuration for this order. Kit Configuration can be changed during order fulfillment.",
          }}
        >
          <Select
            placeholder={"Select kit configuration"}
            data-cy="kit-configuration"
          >
            {props.kitConfigs?.map((kitConfig) => (
              <Select.Option key={kitConfig.id} value={kitConfig.id}>
                <Tooltip title={kitConfig.description}>
                  {kitConfig.name}
                </Tooltip>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Column>
    </Row>
  );
};

export { KitConfiguration };
