import {
  Button,
  Col,
  Row,
  Form,
  Select,
  Switch,
  Modal,
  Typography,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";

import { requiredRule } from "utils/rules";

const { Title, Text } = Typography;

const ReportsActionButtons = ({
  unlockStickyCols,
  setUnlockStickyCols,
  bulkUpdateStatuses,
  inlineUpdateStatuses,
  reviewStatusOptions,
  isInlineEditing,
  setIsInlineEditing,
  setIsModalVisible,
  isModalVisible,
  isUserAbleToEditReports,
  isBulkUpdateLoading,
  totalReportCount,
  handleClearFilters,
}) => {
  const [bulkUpdateForm] = Form.useForm();

  const batchEditStatusSubmit = async () => {
    const formData = await bulkUpdateForm.validateFields();
    bulkUpdateStatuses(formData);
    bulkUpdateForm.resetFields();
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <Switch
            checkedChildren="Edit Selected Statuses"
            unCheckedChildren="Edit Selected Statuses"
            defaultChecked
            onChange={() => setIsInlineEditing(!isInlineEditing)}
            disabled={!isUserAbleToEditReports}
          />
        </Col>
      </Row>
      <Form form={bulkUpdateForm}>
        <Row gutter={4}>
          <Col>
            <Button
              id="button"
              className="mb-1"
              onClick={() => {
                setIsModalVisible(true);
              }}
              type="primary"
              icon={<EditOutlined />}
              disabled={!isUserAbleToEditReports}
            >
              Bulk Edit
            </Button>
          </Col>
          <Col>
            <Button
              id="button"
              onClick={() => {
                inlineUpdateStatuses();
              }}
              type="primary"
              icon={<SaveOutlined />}
              disabled={!isInlineEditing || !isUserAbleToEditReports}
            >
              Save Selections
            </Button>
          </Col>
          <Col>
            <Button
              id="button"
              onClick={() => setUnlockStickyCols(!unlockStickyCols)}
              type="primary"
              icon={unlockStickyCols ? <LockOutlined /> : <UnlockOutlined />}
            >
              {unlockStickyCols
                ? "Lock First 4 Columns"
                : "Unlock First 4 Columns"}
            </Button>
          </Col>
          <Col>
            <Button id="button" onClick={handleClearFilters}>
              Clear Filters
            </Button>
          </Col>
        </Row>
        <Row>
          <Text>
            Total reports matching filter criteria: {totalReportCount}
          </Text>
        </Row>
      </Form>
      <Modal
        visible={isModalVisible}
        footer={null}
        width="50%"
        onCancel={() => setIsModalVisible(false)}
      >
        <Title level={3} className="text-thick">
          Bulk Edit Report Statuses
        </Title>
        <Row className="mb-2">
          <Text>
            This updates the status of all reports on the page. Reports on
            subsequent pages will not be updated.
          </Text>
        </Row>
        <Row>
          <Col span={12}>Status To Apply</Col>
        </Row>
        <Form form={bulkUpdateForm}>
          <Row gutter={4}>
            <Col>
              <Form.Item
                name="review_status"
                initialValue={null}
                rules={[requiredRule("Please select a status")]}
                className="mr-2"
              >
                <Select placeholder="Review Status">
                  {(reviewStatusOptions || []).map((status) => (
                    <Select.Option key={status.value} value={status.value}>
                      {status.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button
                id="button"
                onClick={() => {
                  batchEditStatusSubmit();
                }}
                type="primary"
                icon={<SaveOutlined />}
              >
                Bulk Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ReportsActionButtons;
