import { Form, Select, Col, Row } from "antd";
import Title from "antd/lib/typography/Title";

const options = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const BiobotNetworkOrder = () => {
  return (
    <>
      <Row>
        <Col span={16}>
          <Title level={3}>Biobot Network</Title>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={8}>
          <Form.Item
            name={"is_biobot_network"}
            label="Biobot Network Order?"
            rules={[
              {
                required: true,
              },
            ]}
            tooltip={{
              title:
                "Will the kits in this order be used for Biobot Network testing?",
            }}
          >
            <Select data-cy="is-biobot-network" options={options} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BiobotNetworkOrder;
