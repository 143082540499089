import "./view.css";

import { HomeOutlined } from "@ant-design/icons";

import { Divider } from "antd";

import { withRouter } from "react-router-dom";

import { useFunction } from "api/functions";

import { ViewTemplate } from "../ViewTemplate";

import Pages from "./pages";

const Heading = (props) => {
  const { fn } = props;

  return <>{fn.details.label}</>;
};

const Content = (props) => {
  const { fn } = props;

  const FunctionRunForm = Pages[fn.name];

  const FunctionRunFormNotFound = () => {
    return <div>Function Run Form Not Found</div>;
  };

  return (
    <>
      <div style={{ whiteSpace: "pre-line" }}>{fn.details.description}</div>

      <Divider></Divider>

      {FunctionRunForm ? <FunctionRunForm /> : <FunctionRunFormNotFound />}
    </>
  );
};

function RunView({ match: route }) {
  const fnQuery = useFunction({ id: route.params.name });

  return (
    <ViewTemplate
      {...{
        navigation: ([fn]) => [
          {
            to: "/",
            label: <HomeOutlined />,
          },
          {
            to: "/admin/functions",
            label: "Functions",
          },
          {
            to: `/admin/functions/${fn.data.name}`,
            label: fn.data.details?.label,
          },
        ],

        heading: ([fn]) => {
          return <Heading fn={fn.data}></Heading>;
        },

        content: ([fn]) => {
          return <Content fn={fn.data}></Content>;
        },

        queries: [fnQuery],
      }}
    ></ViewTemplate>
  );
}

export default withRouter(RunView);
