import { Typography, Form, Select, Row } from "antd";
import _ from "lodash";
import { Link } from "react-router-dom";

import {
  strapiFilterProps,
  METHOD_TEXT_CONTAINS,
  METHOD_TEXT_NOT_CONTAINS,
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
} from "components/Table/StrapiFilter";
import SelectionStatusTag from "../../../components/SelectionStatusTag";
import ProductTag from "../../../components/ProductTag";
import { formatDateField } from "../Accounts/helpers";
import { programSelectionStatusFilterProps } from "components/ProgramSelectionStatusFilter";
import { todayStart, todayEnd } from "utils/time";

const { Paragraph, Text } = Typography;

const capitalizeTextField = (text) => {
  if (_.startCase(text)) {
    return _.startCase(text);
  } else {
    return <Typography.Text>-</Typography.Text>;
  }
};

const defaultFormatValue = (val) => {
  if (val) {
    return val;
  } else {
    return <Typography.Text>-</Typography.Text>;
  }
};

const reissueFilterOptions = [
  { value: "first", text: "First" },
  { value: "reissue", text: "Reissue" },
];

const reworkTypeOptions = [
  { value: "INTERNAL_REISSUE", text: "Internal reissue" },
  { value: "EXTERNAL_REISSUE", text: "External reissue" },
  { value: "REPORT_REISSUE", text: "Report reissue" },
  { value: "LAB_RERUN", text: "Lab Rerun" },
  { value: "OTHER", text: "Other" },
];

const reissueDeliveryOptions = [
  { value: "SEND_MANUALLY", text: "Send Manually" },
  { value: "REISSUE_EMAIL", text: "Reissue Email" },
  { value: "STANDARD_REPORT_EMAIL", text: "Standard Report Email" },
];

const statusTextMapping = {
  "Do Not Send": {
    text: "Do Not Send",
    color: "danger",
  },
  New: {
    text: "New",
    color: "secondary",
  },
  "Ready To Send": {
    text: "Ready To Send",
    color: "success",
  },
  "Under Review": {
    text: "Under Review",
    color: "warning",
  },
  unknown: {
    text: "unknown",
    color: "secondary",
  },
};

const reportStatusOptions = [
  {
    value: 1,
    text: "New",
    description: "The report has not been reviewed yet",
  },
  {
    value: 2,
    text: "Under Review",
    description:
      "DQOps has flagged the report as needing further attention before approval",
  },
  {
    value: 3,
    text: "Ready To Send",
    description:
      "The report has been approved by DQOps and it is ready to be sent to the customer",
  },
  {
    value: 4,
    text: "Do Not Send",
    description: "The report has been flagged for being unfit for distribution",
  },
];

export const createReportsTableColumns = (
  inlineStatusForm,
  isInlineEditing,
  programs,
  reviewStatusOptions,
  productOptions,
  unlockStickyCols
) => {
  return [
    {
      title: "Jenkins Job",
      dataIndex: ["report_generation_job_id"],
      key: "report_generation_job_id",
      fixed: !unlockStickyCols && "left",
      sorter: true,
      width: 110,
      render: (jobId) => capitalizeTextField(jobId),
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Jenkins Job",
      }),
    },
    {
      title: "Internal Kit ID",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.kit_id",
      fixed: !unlockStickyCols && "left",
      width: 100,
      render: (reportKits) => {
        return (
          <>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                <Link to={`/kit/${reportKit.kit.id}`}>
                  {reportKit.kit.kit_id}
                </Link>
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Internal Kit ID",
      }),
    },
    {
      title: "Organization",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.sample.organization.organization_name",
      fixed: !unlockStickyCols && "left",
      width: 130,
      render: (reportKits) => {
        return (
          <>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                <Link
                  to={`/organizations/${reportKit.kit.sample?.organization?.id}`}
                  style={{ wordBreak: "break-word" }}
                >
                  {reportKit.kit.sample?.organization?.organization_name}
                </Link>
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Organization",
      }),
    },
    {
      title: "Sampling Location",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.sample.sampling_location_id.sampling_location_name",
      fixed: !unlockStickyCols && "left",
      width: 120,
      render: (reportKits) => {
        return (
          <>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                <Link
                  to={`/admin/sampling-locations/${reportKit.kit.sample?.sampling_location_id?.id}`}
                  style={{ wordBreak: "break-word" }}
                >
                  {
                    reportKit.kit.sample?.sampling_location_id
                      ?.sampling_location_name
                  }
                </Link>
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Sampling Location",
      }),
    },
    {
      title: "Analyzed Tube ID",
      dataIndex: ["report_tubes"],
      key: "report_tubes.tube.tube_id",
      width: 110,
      render: (reportTubes) => {
        return (
          <>
            {(reportTubes ?? []).map((reportTube) => (
              <Row key={reportTube.id}>
                {reportTube.tube?.tube_id || "No tube_id found"}
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Tube ID",
      }),
    },
    {
      title: "Shipping Kit ID",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.shipping_kit_id",
      width: 100,
      render: (reportKits) => {
        return (
          <>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                <Row key={reportKit.id}>
                  <Link to={`/kit/${reportKit.kit.id}`}>
                    {reportKit.kit.shipping_kit_id ?? "ID Pending"}
                  </Link>
                </Row>
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Shipping Kit ID",
      }),
    },
    {
      title: "Report SKU",
      dataIndex: ["product"],
      key: "product.product_sku",
      width: 100,
      render: (reportSku) => {
        return (
          <ProductTag
            sku={reportSku?.product_sku}
            productName={reportSku?.product_name}
          />
        );
      },
      filters: productOptions,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: "Reviewer",
      dataIndex: ["reviewer"],
      key: "reviewer",
      sorter: true,
      width: 120,
      render: (name) => defaultFormatValue(name),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Reviewer",
      }),
    },
    {
      title: "Date Approved",
      dataIndex: ["date_approved"],
      key: "date_approved",
      width: 130,
      render: (dateApproved) => formatDateField(dateApproved),
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
      }),
    },
    {
      title: "Status",
      dataIndex: [],
      key: "approval_status.status",
      width: 150,
      render: (reportStatus) => (
        <>
          {isInlineEditing ? (
            <Form form={inlineStatusForm}>
              <Form.Item
                name={reportStatus?.id}
                initialValue={null}
                style={{ marginBottom: "0px" }}
              >
                <Select
                  placeholder={
                    statusTextMapping[reportStatus?.approval_status?.status]
                      ?.text ?? "Select Review Status"
                  }
                >
                  {(reportStatusOptions || []).map((status) => (
                    <Select.Option key={status.value} value={status.value}>
                      {status.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          ) : (
            <>
              <Text
                type={
                  statusTextMapping[reportStatus.approval_status.status]
                    ?.color ?? statusTextMapping?.unknown?.color
                }
              >
                {statusTextMapping[reportStatus.approval_status.status]?.text ??
                  "-"}
              </Text>
            </>
          )}
        </>
      ),
      filters: reviewStatusOptions?.map((status) => ({
        text: status.text,
        value: status.text,
      })),
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS, METHOD_TEXT_NOT_CONTAINS],
        searchPlaceholder: "Search Status",
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        defaultFilterValue: "New",
      }),
    },
    {
      title: "Review Notes",
      dataIndex: ["report_review_notes"],
      key: "report_review_notes",
      width: 150,
      editable: true,
      render: (reportNotes) => (
        <Paragraph ellipsis={{ rows: 2, tooltip: reportNotes }}>
          {reportNotes ?? "-"}
        </Paragraph>
      ),
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Review Notes",
      }),
    },
    {
      title: "Rework Type",
      dataIndex: ["rework_id", "type_of_rework"],
      key: "rework_id.type_of_rework",
      width: 100,
      filters: reworkTypeOptions,
      render: (reworkType) => capitalizeTextField(reworkType),
    },
    {
      title: "Emailed By",
      dataIndex: ["emailed_by"],
      key: "emailed_by",
      width: 120,
      render: (emailedByText) => capitalizeTextField(emailedByText),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Emailed By",
      }),
    },
    {
      title: "Date Emailed",
      dataIndex: ["date_report_emailed"],
      key: "date_report_emailed",
      width: 130,
      render: (dateEmailed) => formatDateField(dateEmailed),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_DAY_RANGE_EXCLUSIVE_END,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_EMPTY,
        defaultFilterValue: "_null",
      }),
    },
    {
      title: "Report ID",
      dataIndex: ["id"],
      key: "id",
      width: 100,
      render: (id) => defaultFormatValue(id),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Report ID",
      }),
    },
    {
      title: "S3 Bucket",
      dataIndex: ["report_files"],
      key: "report_files.s3_bucket",
      width: 150,
      render: (reportFileIds) => {
        return (
          <>
            {reportFileIds.length
              ? reportFileIds.map((file) => (
                  <Row key={file.id}>{capitalizeTextField(file.s3_bucket)}</Row>
                ))
              : "-"}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search S3 Bucket",
      }),
    },
    {
      title: "Date Generated",
      dataIndex: ["date_report_generated"],
      key: "date_report_generated",
      width: 130,
      render: (dateGenerated) => formatDateField(dateGenerated),
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
        defaultFilterValue: [todayStart, todayEnd],
      }),
    },
    {
      title: "Report Type",
      dataIndex: ["report_type", "report_type"],
      key: "report_type.report_type",
      width: 100,
      render: (type) => defaultFormatValue(type),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Report Type",
      }),
    },
    {
      title: "Report Version",
      dataIndex: ["report_pipeline_version"],
      key: "report_pipeline_version",
      width: 100,
      render: (reportVersion) => capitalizeTextField(reportVersion),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Report Version",
      }),
    },
    {
      title: "Template Version",
      dataIndex: ["template_version"],
      key: "template_version",
      width: 110,
      render: (version) => defaultFormatValue(version),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Template Versions",
      }),
    },
    {
      title: "Report Issue",
      dataIndex: ["report_issue"],
      key: "report_issue",
      width: 100,
      render: (reportIssueType) => capitalizeTextField(reportIssueType),
      filters: reissueFilterOptions,
    },
    {
      title: "Reissue Delivery",
      dataIndex: ["rework_id", "delivery_method"],
      key: "rework_id.delivery_method",
      width: 100,
      filters: reissueDeliveryOptions,
      render: (reworkDeliveryType) => capitalizeTextField(reworkDeliveryType),
    },
    {
      title: "Plate Number(s)",
      dataIndex: ["report_tubes"],
      key: "report_tubes.plate_number",
      width: 120,
      render: (reportTubes) => {
        return (
          <>
            {(reportTubes ?? []).map((reportTube) => (
              <Row key={reportTube.id}>{reportTube.plate_number}</Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Plate #",
      }),
    },
    {
      title: "Send Errors",
      dataIndex: ["send_report_errors"],
      key: "send_report_errors",
      width: 160,
      render: (sendErrors) => (
        <Paragraph ellipsis={{ rows: 2, tooltip: sendErrors }}>
          {sendErrors ?? "-"}
        </Paragraph>
      ),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Send Report Errors",
      }),
    },
    {
      title: "Program Selection",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.selection_statuses",
      width: 300,
      render: (reportKits) => {
        return (
          <div>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                {reportKit.kit.selection_statuses?.map((programStatus) => (
                  <SelectionStatusTag
                    key={programStatus.id}
                    status={programStatus.selection_status}
                    reason={programStatus.reason}
                    displayName={programStatus.program?.name}
                    shortDisplayName={programStatus.program?.short_name}
                  />
                ))}
              </Row>
            ))}
          </div>
        );
      },
      ...programSelectionStatusFilterProps(programs),
    },
    {
      title: "S3 Folder",
      dataIndex: ["report_files"],
      key: "report_files.s3_folder",
      width: 150,
      render: (reportFileIds) => {
        return (
          <>
            {reportFileIds.length
              ? reportFileIds.map((file) => (
                  <Row key={file.id}>{defaultFormatValue(file.s3_folder)}</Row>
                ))
              : "-"}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TEXT_CONTAINS,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search S3 Folder",
      }),
    },
    {
      title: "Environment",
      dataIndex: ["environment"],
      key: "environment",
      width: 130,
      render: (env) => defaultFormatValue(env),
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        searchPlaceholder: "Search Environment",
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        defaultFilterValue: "prod",
      }),
    },
  ];
};
