import React from "react";
import { Form, Select } from "antd";

export default function StatesSelector({
  states,
  onSelect,
  initialValue,
  label,
  name,
  required,
}) {
  const options = (states ?? []).map((state) => {
    return {
      label: state.state_name,
      value: state.state_id.toString(),
    };
  });

  const handleSelection = (val) => {
    if (onSelect) {
      onSelect(parseInt(val));
    }
  };

  return (
    <Form.Item
      label={label ?? "State"}
      name={name ?? "state_id"}
      initialValue={initialValue?.toString()}
      className="mb-0"
      rules={[
        {
          required: required ?? false,
          message: "Select State",
        },
      ]}
    >
      <Select
        data-cy="state-input"
        options={options}
        showSearch
        placeholder="Select state"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSelect={handleSelection}
      />
    </Form.Item>
  );
}
