import { Typography, Table } from "antd";
import _ from "lodash";
import { Link } from "react-router-dom";
import { formatDateField, formatLocationField } from "./helpers";

export const columns = [
  {
    title: "Organization",
    key: "organization_name",
    dataIndex: [],
    render: (record) => (
      <Link to={`/organizations/${record.id}`}>{record.organization_name}</Link>
    ),
  },
  {
    title: "Organization ID",
    dataIndex: "display_id",
  },
  {
    title: "HHS Participant?",
    dataIndex: "hhs_participant",
    render: (val) => {
      if (val === true) {
        return <Typography.Text>Yes</Typography.Text>;
      } else if (val === false) {
        return <Typography.Text>No</Typography.Text>;
      } else {
        return <Typography.Text>-</Typography.Text>;
      }
    },
    filters: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
      {
        text: "Not Specified",
        value: "not_specified",
      },
    ],
    filterMultiple: false,
  },
  {
    title: "Flu Pilot 2021?",
    dataIndex: "flu_pilot_participant_2021",
    render: (val) => {
      if (val === "test") {
        return <Typography.Text>Test</Typography.Text>;
      } else if (val === "do_not_test") {
        return <Typography.Text>Do Not Test</Typography.Text>;
      } else {
        return <Typography.Text>-</Typography.Text>;
      }
    },
    filters: [
      {
        text: "Test",
        value: "test",
      },
      {
        text: "Do Not Test",
        value: "do_not_test",
      },
      {
        text: "Not Specified",
        value: "not_specified",
      },
    ],
    filterMultiple: false,
  },
  {
    title: "Network Enrollment Date",
    dataIndex: "network_enrollment_date",
    render: (val) => formatDateField(val),
    filters: [
      {
        text: "Specified",
        value: "specified",
      },
      {
        text: "Not Specified",
        value: "not_specified",
      },
    ],
    filterMultiple: false,
  },
  {
    title: "Total Population",
    dataIndex: "estimated_population_served",
    render: (val) => {
      if (!_.isNil(val) && !isNaN(val)) {
        return val.toLocaleString("en-US");
      } else {
        return <Typography.Text>-</Typography.Text>;
      }
    },
  },
];

export const expandableConfig = {
  rowExpandable: (org) =>
    org.sampling_locations && org.sampling_locations.length > 0,
  expandedRowRender: (org) => {
    const nestedColumns = [
      {
        title: "Sample Location",
        dataIndex: [],
        key: "sample_location_name_expandable",
        render: (val) => (
          <Link to={`/admin/sampling-locations/${val.id}`}>
            {val.sampling_location_name}
          </Link>
        ),
      },
      // {
      //   title: "Programs",
      //   dataIndex: ["programs"],
      //   key: "programs_expandable",
      //   render: (val = []) =>
      //     val.map((program) => {
      //       return (
      //         <>
      //           {!_.isNil(program) && (
      //             <ProgramTag
      //               code={program?.code}
      //               displayName={program?.display_name}
      //             />
      //           )}
      //         </>
      //       );
      //     }),
      // },
      {
        title: "Submitted",
        dataIndex: ["created_at"],
        key: "created_at_expandable",
        render: (val) => formatDateField(val),
      },
      {
        title: "Updated",
        dataIndex: ["updated_at"],
        key: "updated_at_expandable",
        render: (val) => formatDateField(val),
      },
      {
        title: "Site Type",
        dataIndex: ["location_type"],
        key: "location_type_expandable",
        render: (val) => formatLocationField(val),
      },
    ];

    return (
      <Table
        columns={nestedColumns}
        dataSource={org.sampling_locations}
        pagination={false}
        rowKey="id"
        bordered
      />
    );
  },
};
