import { orderServiceCustomQuery, orderServiceFetchCsv } from "./orderService";

const model = "data-orchestration";

export const useKitStatusList = orderServiceCustomQuery({
  model,
  path: "kit-status-upload",
});

export const fetchKitStatusCsv = () =>
  orderServiceFetchCsv({ model: `${model}/kit-status-upload` });
