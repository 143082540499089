import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { authorizationHeader, pipelineAPIRoot } from "../util";
import { ManualReviewState } from "../../reducers/reducerManualReview";
import { ManualReviewStatus } from "./types";
import qs from "qs";

interface OverrideSubmission {
  aliquot_id: number;
  aliquot_name: string;
  status_override: ManualReviewStatus;
  notes: string | null;
}

export const submitReview = (
  state: ManualReviewState,
  overrides: OverrideSubmission[]
): AxiosPromise<any> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${pipelineAPIRoot}/reviews/`,
    headers: authorizationHeader(),
    data: {
      assay_name: state.activeAssayName,
      overrides: overrides,
    },
  };

  return axios(config);
};

export const downloadKitsMissingLogs = (
  state: ManualReviewState
): AxiosPromise<any> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${pipelineAPIRoot}/kits/statuses`,
    headers: authorizationHeader(),
    params: {
      assay_names: [state.activeAssayName],
      review_statuses: ["flagged_missing_log"],
      as_csv: "True",
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "brackets" });
    },
  };

  return axios(config);
};
