import "./index.css";

import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, PageHeader, message, Modal, Table } from "antd";
import { formatDateTime } from "utils/time";
import moment from "moment";
import axios from "axios";
import SampleLanding from "containers/Sample/Landing";
import _ from "lodash";
import KitDetails from "pages/customer/Kit/components/KitDetails";
import EditableTable from "components/Table/EditableTable";
import TableSearch from "components/Table/TableSearch";
import { LogSampleButton } from "components/Buttons/TableCellButton";
import ProductTag from "../../../components/ProductTag";

const lower = moment().startOf("day");
const upper = moment().endOf("day");

function KitOperationalDashboard() {
  const [paramsForFetch, setParamsForFetch] = useState({
    _page: 1,
    _limit: 30,
    searchInput: null,
    lower: lower ? lower.toISOString() : null,
    upper: upper ? upper.toISOString() : null,
  });

  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState();

  const tableRef = useRef();

  const axiosParams = (params) => {
    let p = {
      _page: params._page,
      _limit: params._limit,
      kit_id: params.searchInput,
      shipping_kit_id: params.searchInput,
      org_name: params.searchInput,
      lower: params.lower,
      upper: params.upper,
    };
    p = _.omitBy(p, _.isNil);

    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: p,
    };
  };

  const getKitsPage = async (params) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}kits/received`,
      axiosParams(params)
    );
  };

  const getKitsTotal = async (params) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}kits/received/count`,
      axiosParams(_.omit(params, ["_page", "_limit"]))
    );
  };

  const getKitDetails = async (kitId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}kits/${kitId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };

  const handleSearchParamsUpdated = (updatedParams) => {
    setParamsForFetch({
      ...paramsForFetch,
      ...updatedParams,
    });
  };

  const handleKitDetailsClicked = (kitRow) => {
    getKitDetails(kitRow.id)
      .then((res) => {
        setModalContent(
          <KitDetails
            kit={res.data}
            onSampleLogButtonClicked={() => handleLogEditClicked(kitRow)}
          />
        );
        setModalOpen(true);
      })
      .catch(() => {
        message.error("Something went wrong!");
      });
  };

  const handleLogEditClicked = (kitRow) => {
    setModalContent(
      <SampleLanding
        kitId={kitRow.id}
        onFormSubmissionSuccess={(updatedSample) =>
          handleSampleSubmitted(kitRow, updatedSample)
        }
      />
    );
    setModalOpen(true);
  };

  // this chunk of code ensure the table record that a log is updated/submitted for reflects the change once it
  // completes on the backend
  const handleSampleSubmitted = (kitRow, updatedSample) => {
    const recCopy = _.clone(kitRow);
    recCopy.sample = updatedSample;
    recCopy.sampling_locaion = updatedSample.sampling_locaion_id;

    tableRef.current.overrideRows({ [recCopy.kit_id]: recCopy });
  };

  // The table editing functionality for this table is actually updating the sample log attached to the kit.
  // We proxy the updated sample log through the kit row that the table operates on.
  const updateSampleLog = async (row, formData) => {
    const sampleId = row.sample.id;
    const sampleLog = formData.sample;

    const updatedRow = {
      ...row,
    };
    updatedRow.sample = {
      ...row.sample,
      ...formData.sample,
    };

    return axios
      .put(`${process.env.REACT_APP_API_URL}samples/${sampleId}`, sampleLog, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => updatedRow);
  };

  const columns = [
    {
      title: "Kit ID",
      render: (record) => {
        return (
          <Button type="link" onClick={() => handleKitDetailsClicked(record)}>
            {record.shipping_kit_id ? record.shipping_kit_id : "-"}
          </Button>
        );
      },
    },
    {
      title: "Internal ID",
      render: (record) => {
        return <Link to={`/kit/${record.id}`}>{record.kit_id}</Link>;
      },
    },
    {
      title: "TAT",
      render: (record) =>
        record.product ? record.product.turn_around_time : "-",
    },
    {
      title: "SKU",
      render: (kit) => {
        return (
          <>
            {kit.products?.map((x) => (
              <ProductTag sku={x.product_sku} productName={x.product_name} />
            ))}
          </>
        );
      },
    },
    {
      title: "Tube IDs",
      render: (record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {record.tubes.map((tube) => {
            return <span>{tube.tube_id}</span>;
          })}
        </div>
      ),
    },
    {
      title: "Sampling Location",
      render: (record) =>
        record.sample?.sampling_location_id
          ? record.sample?.sampling_location_id.sampling_location_name
          : "-",
    },
    {
      title: "Date Received at Biobot",
      key: "action",
      render: (record) => formatDateTime(record.date_received),
    },
    {
      title: "Sampling Log ID",
      render: (record) =>
        record.sample?.id ? (
          record.sample?.id
        ) : (
          <LogSampleButton onClick={() => handleLogEditClicked(record)} />
        ),
    },
    {
      title: "HHS",
      render: (record) => (record.organization?.hhs_participant ? "Yes" : "No"),
    },
    {
      title: "Organization",
      render: (record) => {
        return record.organization?.organization_name
          ? record.organization.organization_name
          : "-";
      },
    },
    {
      title: "R&D",
      render: (record) => {
        return record.organization?.additional_randd_agreement ?? "-";
      },
    },
  ];

  const expandedRowRender = (record) => {
    const nestedColumns = [
      {
        title: "Tube ID",
        render: (r) => {
          const obj = {
            children: r.tube_id ?? "-",
            props: {},
          };

          if (r.tube_id === r.next_tube_id) {
            obj.props.rowSpan = 2;
          } else if (r.tube_id === r.previous_tube_id) {
            obj.props.rowSpan = 0;
          }

          return obj;
        },
        key: "tube_id",
      },
      {
        title: "Tube Filtered Date",
        render: (r) => {
          const obj = {
            children: formatDateTime(r.date_filtered) ?? "-",
            props: {},
          };

          if (r.tube_id === r.next_tube_id) {
            obj.props.rowSpan = 2;
          } else if (r.tube_id === r.previous_tube_id) {
            obj.props.rowSpan = 0;
          }

          return obj;
        },
        key: "date_filtered",
      },
      {
        title: "Aliquot ID",
        render: (r) => {
          return r.aliquot_id ?? "-";
        },
        key: "aliquot_id",
      },
      {
        title: "Date Aliquot Concentrated",
        render: (r) => {
          return formatDateTime(r.date_concentrated) ?? "-";
        },
        key: "date_concentrated",
      },
      {
        title: "qPCR Uploaded",
        render: (r) => {
          return formatDateTime(r.date_of_qPCR) ?? "-";
        },
        key: "date_of_qPCR",
      },
      {
        title: "qPCR Processed",
        render: (r) => {
          return formatDateTime(r.date_qPCR_processed) ?? "-";
        },
        key: "date_qPCR_processed",
      },
    ];

    const data = record.tubes
      .flatMap((tube) => {
        if (tube.aliquots == null || tube.aliquots.length === 0) {
          return [
            {
              tube_id: tube.tube_id,
              date_filtered: tube.date_filtered,
            },
          ];
        }

        return tube.aliquots.map((aliquot) => {
          return {
            tube_id: tube.tube_id,
            date_filtered: tube.date_filtered,
            aliquot_id: aliquot.aliquot_id,
            date_concentrated: aliquot.date_concentrated,
            date_of_qPCR: aliquot.date_of_qPCR,
            date_qPCR_processed: aliquot.date_qPCR_processed,
          };
        });
      })
      .sort((l, r) => {
        if (l.tube_id < r.tube_id) {
          return -1;
        } else if (l.tube_id > r.tube_id) {
          return 1;
        } else {
          if (l.aliquot_id <= r.aliquot_id) {
            return -1;
          } else {
            return 1;
          }
        }
      });

    // This is a hack for rowSpan detection. If the next/previous record's tube_id is the same as the current
    // then combine the rows.
    for (let i = 0; i < data.length - 1; i++) {
      data[i].next_tube_id = data[i + 1].tube_id;
    }
    for (let i = 1; i < data.length; i++) {
      data[i].previous_tube_id = data[i - 1].tube_id;
    }

    return (
      <Table
        columns={nestedColumns}
        dataSource={data}
        pagination={false}
        bordered
      />
    );
  };

  return (
    <>
      <PageHeader
        title="Kits Operational Dashboard"
        className="mb-3"
        footer={
          <TableSearch
            defaultDateLower={lower}
            defaultDateUpper={upper}
            onSearchParamsUpdated={handleSearchParamsUpdated}
            searchPlaceholderText="Search Kit ID, Internal ID or Org Name"
          />
        }
      />
      <Modal
        visible={modalOpen}
        width={"75%"}
        footer={[]}
        onCancel={() => setModalOpen(false)}
        destroyOnClose
      >
        <div className="sample-modal-content">{modalContent}</div>
      </Modal>
      <EditableTable
        ref={tableRef}
        rowKey="kit_id"
        columns={columns}
        paramsForFetch={paramsForFetch}
        fetchRows={getKitsPage}
        fetchRowCount={getKitsTotal}
        saveEditedRow={updateSampleLog}
        // if there is no sample log for kit, then the row shouldn't be editable because the only editable
        // fields apply to sample log data.
        editButtonDisabledForRow={(row) => !row.sample}
        onSearchParamsUpdated={handleSearchParamsUpdated}
        scroll={{ x: "max-content" }}
        data-cy="kit-ops-table"
        className="px-3"
        expandable={{ expandedRowRender }}
        bordered
      />
    </>
  );
}

export default KitOperationalDashboard;
