import { GTMolecularImportFn } from "../../components/GTMolecularImportFn";

const FN_NAME = "gtm-import-order-fulfillments";

function FnRun() {
  function refineParameters(parameters) {
    const { items } = parameters;

    const itemsWithCasts = items.map((item) => {
      const { shipping_location_id, ...data } = item;

      return {
        ...data,
        shipping_location_id: Number(shipping_location_id),
      };
    });

    return Object.assign({}, parameters, { items: itemsWithCasts });
  }

  return (
    <GTMolecularImportFn name={FN_NAME} refineParameters={refineParameters} />
  );
}

export { FnRun };
