import { Col, Row, Select, Typography, Form } from "antd";
import _ from "lodash";

const { Title, Text } = Typography;

const SelectLocation = ({
  locations,
  onLocationSelected,
  formProps,
  locationType = "sampling",
  allowCreate = true,
  showTitle = true,
}) => {
  const options = locations.map((l) => {
    return {
      value: l.id ? l.id.toString() : l.name,
      label:
        locationType === "sampling" ? (
          l.sampling_location_name
        ) : (
          <>
            <Text bold="true">{l.name}</Text>{" "}
            <Text type="secondary">
              ({l.address_line_1} {l.address_line_2 ?? ""})
            </Text>
          </>
        ),
    };
  });

  if (allowCreate) {
    options.unshift({
      value: "new_site",
      label: `+ Add a ${locationType} location`,
    });
  }

  const titleText = allowCreate
    ? `Select or create a unique ${locationType} location name*`
    : "Select a location";

  const getSelectFragment = () => {
    const selectFragment = (
      <Select
        data-cy={`select-${locationType}-location`}
        placeholder={`Select ${locationType} location here`}
        options={options}
        className="full-width"
        dropdownMatchSelectWidth
        onSelect={(value, option) => {
          const selectedLoc = locations.filter((l) => {
            const locationRef = l.id ? l.id.toString() : l.name;
            return locationRef === value;
          })[0];
          if (allowCreate && value === "new_site") {
            onLocationSelected("new_site");
          } else {
            onLocationSelected(selectedLoc);
          }
        }}
      />
    );
    if (!_.isEmpty(formProps)) {
      // Select has to be nested directly under Form.Item to be captured as form input
      return (
        <Form.Item
          {...formProps}
          label={`${_.upperFirst(locationType)} Location`}
        >
          {selectFragment}
        </Form.Item>
      );
    } else {
      return selectFragment;
    }
  };

  return (
    <>
      {showTitle && (
        <Row>
          <Title level={4}>{titleText}</Title>
        </Row>
      )}
      <Row className="mb-3">
        <Col sm={24} xs={24}>
          {getSelectFragment()}
        </Col>
      </Row>
    </>
  );
};

export default SelectLocation;
