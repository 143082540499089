import _ from "lodash";
import Title from "antd/es/typography/Title";
import LocationDetail from "./LocationDetail";

export default function LocationSection({ lead, alreadyActivated }) {
  const program = lead.program;
  const isExistingOrg = !!lead.organization;

  const sortedLocations = _.sortBy(
    lead.lead_sampling_locations,
    "sampling_location.id"
  );

  return (
    <>
      <Title level={5} className="mt-3">
        Sampling Locations
      </Title>
      {(sortedLocations ?? []).map((loc, index) => (
        <LocationDetail
          location={loc}
          index={index}
          alreadyActivated={alreadyActivated}
          program={program}
          key={`location-detail-${index}`}
          isExistingOrg={isExistingOrg}
        />
      ))}
    </>
  );
}
