import { Modal, Form, Button, Typography, message } from "antd";
import { InputField } from "utils/components";
import { simpleNameRule, requiredRule } from "utils/rules";

const { Title } = Typography;

const SamplingLocFormModal = ({
  visible,
  onSubmit,
  onCancel,
  createSamplingLocLoading,
  orgName,
}) => {
  const [form] = Form.useForm();

  const handleFormSubmit = (formValue) => {
    message.info(`${orgName} appreciates you`);
    onSubmit(formValue);
  };

  return (
    <Modal footer={null} visible={visible} onCancel={onCancel} destroyOnClose>
      <Form
        form={form}
        layout="vertical"
        preserve={false}
        onFinish={handleFormSubmit}
      >
        <Title level={3} className="text-thick">
          Create Sampling Location
        </Title>
        <InputField
          id="input-sampling-location-name"
          placeholder="A unique name for this location"
          label="Sampling location name"
          name="sampling_location_name"
          rules={[simpleNameRule, requiredRule("Location name is required")]}
        />
        <Button
          type="primary"
          htmlType="submit"
          disabled={createSamplingLocLoading}
        >
          Create Location
        </Button>
      </Form>
    </Modal>
  );
};

export default SamplingLocFormModal;
