import axios from "axios";
import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceCustomQuery,
  orderServiceFetchCsv,
  orderServiceFindOneQuery,
  orderServiceKey,
  orderServiceListQuery,
  orderServiceUpdateMutation,
} from "./orderService";
import { authorizationHeader, mkQuery, orderServiceRoot } from "./util";

const model = "sampling-locations";

export const useSamplingLocation = orderServiceFindOneQuery({ model });
export const useSamplingLocationList = orderServiceListQuery({ model });
export const useSamplingLocationListDetailed = orderServiceCustomQuery({
  model,
  path: "detailed",
});
export const useSamplingLocationCount = orderServiceCountQuery({ model });
export const useCreateSamplingLocation = orderServiceCreateMutation({ model });
export const useUpdateSamplingLocation = orderServiceUpdateMutation({ model });

export const fetchSamplingLocationCsv = ({ _where, _sort }) =>
  orderServiceFetchCsv({ model, _where, _sort });

export const fetchSamplingLocationDetailedCsv = ({ _where, _sort }) =>
  orderServiceFetchCsv({ model, path: "detailed", _where, _sort });

export const usePublicSamplingLocations = mkQuery({
  key: ({ organizationId }) =>
    orderServiceKey({ model, endoint: "publicFindOne", organizationId }),
  query: async ({ organizationId }) => {
    const encodedOrgId = encodeURIComponent(organizationId);
    const { data } = await axios({
      method: "get",
      url: `${orderServiceRoot}/sampling-locations/${encodedOrgId}/public`,
      headers: authorizationHeader(),
    });
    // FIXME: public locations and private locations have different
    // response formats
    return data.map((location) => ({
      ...location,
      sampling_location_name: location.name,
    }));
  },
});
