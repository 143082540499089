import axios from "axios";
import { useRef, useEffect } from "react";
import _ from "lodash";

export async function refreshToken() {
  const token = localStorage.getItem("refreshToken");
  if (token) {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API}/identity/userTokens/refresh/${token}`
      );
      updateLocalStorage(request.data.accessToken, request.data.refreshToken);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
}

export async function validateToken() {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_API}/identity/userTokens/${token}/validate`
      );
      return request.status === 200;
    } catch (e) {
      localStorage.removeItem("token");
      const tokenRefreshed = await refreshToken();
      return tokenRefreshed ? tokenRefreshed : false;
    }
  }
  return false;
}

function updateLocalStorage(accessToken, refreshToken) {
  localStorage.setItem("token", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/*
 * Like {@link _.assign} except null/undefined values do not overwrite existing
 * non-null values.
 */
export const assignIgnoreNull = _.partialRight(_.assign, (a, b) => b ?? a);

/**
 * Returns a new callback function that runs any number of callbacks in
 * succession. Handles promises and ordinary functions.
 */
export const callEach = (...callbacksOrNull) => {
  const callbacks = callbacksOrNull.filter(Boolean);
  if (callbacks.length > 1) {
    return callbacks.reduce(
      (firstCallback, nextCallback) => async (...args) => {
        await Promise.resolve(firstCallback(...args));
        await Promise.resolve(nextCallback(...args));
      }
    );
  } else {
    return callbacks[0];
  }
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  localStorage.removeItem("organization");

  window.location = "/";
};

export const saveBrowserDataToFile = (
  fullData,
  fileName,
  fileType = "text/plain"
) => {
  // Create a Blob from the data and give it a URL
  const blob = new Blob([fullData], { type: fileType });
  const url = window.URL.createObjectURL(blob);

  // Create a ghost anchor element that links to the blob
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  // Append the link to the DOM and trigger the download
  document.body.appendChild(link);
  link.click();

  // Cleanup the link and revoke the URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
