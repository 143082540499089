import { createContext, useContext, useState } from "react";

const UserContext = createContext();
const useUserContext = () => useContext(UserContext);

function UserProvider({ children }) {
  let userInfo = {
    userName: "",
    userEmail: "",
    organizationContactEmail: "",
    organizationId: null,
    organizationName: "",
  };

  if (localStorage.getItem("user")) {
    userInfo = JSON.parse(localStorage.getItem("user"));
  }

  const [user, setUserData] = useState(userInfo);

  return (
    <UserContext.Provider
      value={{
        ...user,
        updateUser: (updateProps) => {
          setUserData({ ...user, ...updateProps });
          localStorage.setItem(
            "user",
            JSON.stringify({ ...user, ...updateProps })
          );
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider, useUserContext };
