import { Input, Radio, Space } from "antd";
import { Form } from "components/Wizard";
import { requiredRule } from "utils/rules";

const OrgConfirmItem = ({ organization, ...props }) => (
  <Form.Item
    name="organization_confirmed"
    label={`It looks like your organization is ${organization.name}. Can you confirm whether this is correct?`}
    {...props}
  >
    <Radio.Group data-cy="confirm-organization">
      <Space direction="vertical">
        <Radio data-cy="yes-organization" value={true}>
          Yes, my organization is {organization.name}
        </Radio>
        <Radio data-cy="no-organization" value={false}>
          No, my organization is not {organization.name}
        </Radio>
      </Space>
    </Radio.Group>
  </Form.Item>
);

const OrgNameItem = (props) => (
  <Form.Item
    name="organization_name"
    label="What is the name of your organization?"
    {...props}
  >
    <Input
      data-cy="confirm-org__org-name-input"
      placeholder="Enter organization name"
      autoComplete="organization"
    />
  </Form.Item>
);

const OrgConfirmStep = ({ organization, ...props }) => {
  const normalizeValues = (values) => {
    if (values.organization_confirmed) {
      return {
        ...values,
        isNewOrg: false,
        organization,
      };
    } else {
      return {
        ...values,
        isNewOrg: true,
        organization: { name: values.organization_name },
      };
    }
  };

  return (
    <Form {...props} normalizeValues={normalizeValues}>
      {(values) => (
        <>
          <OrgConfirmItem
            organization={organization}
            name="organization_confirmed"
            rules={[requiredRule("Please confirm your organization")]}
          />
          {values?.organization_confirmed === false && (
            <OrgNameItem
              name="organization_name"
              rules={[requiredRule("Please enter an organization name")]}
            />
          )}
        </>
      )}
    </Form>
  );
};

export default OrgConfirmStep;
