import { SettingFilled } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Input, Row, TreeSelect } from "antd";
import _ from "lodash";
import React, { useState } from "react";

import { attributeTree, useModelAttributes } from "./attributes";
import { fuzzyMatch, niceTitle } from "./util";

const treeOptions = (tree) =>
  tree.map(({ key, children }) => ({
    value: key,
    title: key,
    children: children ? treeOptions(children) : null,
  }));

const attributeTreeOptions = (attrs) => treeOptions(attributeTree(attrs));

const keyByKey = (data) => _.keyBy(data, "key");

export const ColumnConfigForm = ({
  model,
  initialValues,
  onFinish,
  onCancel,
  onDelete,
}) => {
  const { data: attrsByKey } = useModelAttributes({ model, select: keyByKey });
  const { data: attributeOptions } = useModelAttributes({
    model,
    select: attributeTreeOptions,
  });

  const [form] = Form.useForm();

  const defaultsForField = (field) => {
    const attr = attrsByKey[field];
    if (attr) {
      return {
        title: niceTitle(attr),
        dataType: attr.type,
      };
    } else {
      return {};
    }
  };

  const handleFieldSelected = (field) => {
    form.setFieldsValue(defaultsForField(field));
  };

  return (
    <Form
      form={form}
      initialValues={{
        ...defaultsForField(initialValues?.key),
        ...(initialValues || {}),
      }}
      onFinish={onFinish}
      requiredMark={false}
    >
      <Form.Item
        name="key"
        label="Field"
        rules={[{ required: true, message: "Field is required" }]}
      >
        <TreeSelect
          className="full-width"
          placeholder="Select a column"
          style={{ minWidth: "20rem" }}
          showSearch
          treeData={attributeOptions}
          dropdownMatchSelectWidth={false}
          filterTreeNode={(search, { title }) => !!fuzzyMatch(title, search)}
          onChange={handleFieldSelected}
        />
      </Form.Item>
      <Form.Item name="title" label="Title">
        <Input />
      </Form.Item>
      <Form.Item name="dataType" label="Data type">
        <Input disabled />
      </Form.Item>
      <Row gutter={8}>
        <Col>
          <Button
            type="link"
            size="small"
            onClick={onDelete}
            disabled={!initialValues}
          >
            Delete
          </Button>
        </Col>
        <Col flex="1" />
        <Col>
          <Button size="small" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button htmlType="submit" type="primary" size="small">
            OK
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const ColumnConfigWidget = ({
  model,
  initialValues,
  onFinish,
  onDelete,
  icon = <SettingFilled style={{ color: "#bfbfbf" }} />,
}) => {
  const [visible, setVisible] = useState(false);
  const withClose = (callback) => (...args) => {
    if (callback) {
      callback(...args);
    }
    setVisible(false);
  };
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onMouseOver={(e) => e.stopPropagation()}
    >
      <Dropdown
        overlay={
          <div className="ant-table-filter-dropdown p-2">
            <ColumnConfigForm
              model={model}
              initialValues={initialValues}
              onFinish={withClose(onFinish)}
              onCancel={withClose(_.noop)}
              onDelete={withClose(onDelete)}
            />
          </div>
        }
        placement="bottomRight"
        trigger={["click"]}
        destroyPopupOnHide
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Button type="text" onClick={() => setVisible(!visible)}>
          {React.cloneElement(icon)}
        </Button>
      </Dropdown>
    </div>
  );
};

export default ColumnConfigWidget;
