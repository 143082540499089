import React, { useState } from "react";
import { Input, Button, Modal, notification } from "antd";
import { orderServiceUpdate } from "../api/orderService";
import { EditOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export default function KitNotes({ kit, onSave, defaultValue = "" }) {
  const [kitNotesModalOpen, setKitNotesModalOpen] = useState(false);

  const handleOpenKitNotesModal = async () => {
    setKitNotesModalOpen(true);
  };

  const handleCloseNotesModal = async () => {
    setKitNotesModalOpen(false);
  };

  const KitNotesModal = ({ kit, visible, closeModal }) => {
    const [kitNotes, setKitNotes] = useState("");

    const handleKitNotesChange = (e) => {
      setKitNotes(e.target.value);
    };

    const updateKitNotes = async () => {
      await orderServiceUpdate({
        model: "kits",
        data: { id: kit.id, kit_notes: kitNotes },
      })
        .then(() => {
          onSave(kitNotes);
          closeModal();
        })
        .catch((e) => {
          notification["error"]({
            duration: 0,
            placement: "top",
            top: 80,
            message: "Error setting kit notes!",
            description: <pre>{e.stack}</pre>,
          });
          closeModal();
        });
    };

    return (
      <Modal
        key={`${kit.id}-kit-notes-modal`}
        title="Edit Kit Notes"
        open={visible}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button key="select" type="primary" onClick={updateKitNotes}>
            Save
          </Button>,
        ]}
      >
        <TextArea
          autosize="true"
          placeholder="Enter some kit notes"
          defaultValue={defaultValue}
          onPressEnter={() => updateKitNotes()}
          onChange={handleKitNotesChange}
        />
      </Modal>
    );
  };

  return (
    <>
      <KitNotesModal
        kit={kit}
        visible={kitNotesModalOpen}
        closeModal={handleCloseNotesModal}
      />
      <Button
        type={kit.kit_notes ? "primary" : "default"}
        onClick={handleOpenKitNotesModal}
      >
        {kit.kit_notes && "*"}
        <EditOutlined />
      </Button>
    </>
  );
}
