import { useState } from "react";
import axios from "axios";
import _ from "lodash";
import { Button, Col, Form, message, Row } from "antd";
import Text from "antd/es/typography/Text";

import OrgOverviewSection from "./form/OrgOverviewSection";
import PrimaryContactSection from "./form/PrimaryContactSection";
import AddressSection from "./form/AddressSection";
import OtherInfoSection from "./form/OtherInfoSection";
import LocationSection from "./form/LocationSection";
import StatusSection from "./form/StatusSection";
import { DataVizInclusionSection } from "./form/DataVizSection";
import { programsCompatibleWithViz } from "configs/constants";

export default function ActivationForm({
  lead,
  onLeadActivated,
  onLeadRejected,
}) {
  const [activating, setActivating] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [selectedOrgForLinking, setSelectedOrgForLinking] = useState();

  const activateLead = async (formValues) => {
    message.info(
      "While the entire digestive process can take several hours, activating leads takes a few seconds. Please be patient.",
      4
    );
    const payload = _.omit(formValues, [
      "display_id",
      "correct_org_id",
      "should_link_org",
    ]);
    payload.lead_id = lead.id;
    payload.organization_id =
      lead.organization?.id || formValues.correct_org_id;
    payload.organization_name = payload.organization_name.trim();

    if (selectedOrgForLinking)
      payload.organization_name = selectedOrgForLinking;

    setActivating(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}leads/${lead.id}/activate`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        onLeadActivated(res.data);
      })
      .catch((err) => {
        message.error("Something went wrong!");
      })
      .then(() => setActivating(false));
  };

  const rejectLead = () => {
    setRejecting(true);

    axios
      .put(`${process.env.REACT_APP_API_URL}leads/${lead.id}/reject`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        onLeadRejected(res.data);
      })
      .catch(() => {
        message.error("Something went wrong!");
      })
      .then(() => setRejecting(false));
  };

  const alreadyActivated = lead.status === "accepted";
  const alreadyRejected = lead.status === "rejected";
  const loading = activating || rejecting;

  return (
    <Form layout="vertical" onFinish={activateLead}>
      <StatusSection lead={lead} />
      <OrgOverviewSection
        lead={lead}
        alreadyActivated={alreadyActivated}
        selectedOrgForLinking={selectedOrgForLinking}
        setSelectedOrgForLinking={setSelectedOrgForLinking}
      />
      <PrimaryContactSection lead={lead} alreadyActivated={alreadyActivated} />
      <AddressSection lead={lead} alreadyActivated={alreadyActivated} />
      <OtherInfoSection lead={lead} alreadyActivated={alreadyActivated} />
      {programsCompatibleWithViz.includes(lead.program) && (
        <DataVizInclusionSection lead={lead} />
      )}
      <LocationSection lead={lead} alreadyActivated={alreadyActivated} />

      <Row gutter={16} justify="start" align="bottom">
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            loading={activating}
            disabled={loading || alreadyActivated}
          >
            Activate
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={rejectLead}
            loading={rejecting}
            disabled={loading || alreadyActivated || alreadyRejected}
          >
            Reject
          </Button>
        </Col>
        <Col>
          {alreadyActivated && (
            <Text>This lead has already been accepted.</Text>
          )}
          {alreadyRejected && <Text>This lead has already been rejected.</Text>}
        </Col>
      </Row>
    </Form>
  );
}
