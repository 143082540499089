import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { flattenFragments } from "utils/react";
import WizardButtons from "./WizardButtons";
import WizardContext from "./WizardContext";
import WizardWithLoading from "./WizardWithLoading";

const finishPayload = (formValues) => ({
  forms: formValues,
  values: _.merge({}, ...Object.values(formValues)),
});

const wizardSteps = (children, formValues) => {
  if (typeof children === "function") {
    return flattenFragments(children(finishPayload(formValues)));
  } else {
    return flattenFragments(children);
  }
};

/**
 * A multi-step form.
 *
 * @param props.buttonsComponent - component used to render back/next/submit
 *                                 buttons (default: WizardButtons).
 * @param props.onFinish - callback called when the last step is submitted.
 *                         Arguments: ({
 *                           forms: { formName: valuesForThisForm },
 *                           values: allValuesMergedTogether
 *                         })
 * @param props.onFormFinish - callback called when any step is submitted.
 *                             Arguments: ( name, { values, step })
 * @param props.onFormValuesChange - callback called when any value changes.
 *                                   Arguments: (
 *                                     name,
 *                                     { changedValues, allValues, step }
 *                                   )
 * @param props.preserve - when false, discards unmounted form values (default:
 *                         true)
 * @param props.showBackAlways - when true, shows a disabled back button on the
 *                               first step. Otherwise, does not show a back
 *                               button on the first step (default: false).
 * @param props.singlePage - when true, renders the current step as well as
 *                           previous steps (in a single page). Previous steps
 *                           are disabled. Otherwise, renders only the current
 *                           step (default: false).
 */
const Wizard = ({
  buttonsComponent = WizardButtons,
  children,
  onFinish = null,
  onFormFinish = null,
  onFormValuesChange = null,
  preserve = true,
  initialValues = {},
  initialStep = 1,
  showBackAlways = false,
  singlePage = false,
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep - 1);
  // valuesRef saves each form's values so that (a) we can merge them all
  // together for onFinish, and (b) in case the user clicks the back button,
  // which would unmount the current form.
  const valuesRef = useRef(initialValues);

  const steps = wizardSteps(children, valuesRef.current);
  const stepCount = steps.length;

  // Form behavior varies depending on which wizard step we're on, e.g.
  // * The first step will not have a back button.
  // * Only the current step is active (other steps are hidden or diasbled).
  // * The last step has a "submit" button that calls onFinish (other steps
  //   have a "next" button that calls onFormFinish).
  //
  // Because behavior largely depends on the step number, each step gets its
  // own WizardContext. Each context is memoized and the value only changes
  // when the step becomes current (or if the Wizard's props change).
  //
  // These default contexts use isCurrentStep: false since steps spend most of
  // their time in the non-active state. When a step becomes active, we copy
  // this context value and set isCurrentStep: true.
  const contextValues = useMemo(
    () =>
      _.range(stepCount).map((step) => {
        const isFirstStep = step === 0;
        const isLastStep = step === stepCount - 1;
        return {
          // common across the whole wizard
          showBackAlways,
          buttonsComponent,
          stepCount,
          // specific to each step
          isCurrentStep: false,
          step,
          isFirstStep,
          isLastStep,
          // callbacks
          getInitialValues: (name) => {
            return valuesRef.current[name];
          },
          onFormMount: (name, initialValues) => {
            if (!valuesRef.current[name]) {
              valuesRef.current[name] = initialValues;
            }
          },
          onFormUnmount: (name) => {
            if (!preserve) {
              delete valuesRef.current[name];
            }
          },
          onValuesChange: (name, changedValues, allValues) => {
            valuesRef.current[name] = allValues;
            if (onFormValuesChange) {
              onFormValuesChange(name, { changedValues, allValues, step });
            }
          },
          onNext: async (name, values) => {
            valuesRef.current[name] = values;
            if (onFormFinish) {
              await Promise.resolve(onFormFinish(name, { values, step }));
            }
            if (onFinish && isLastStep) {
              await Promise.resolve(onFinish(finishPayload(valuesRef.current)));
            }
            if (!isLastStep) {
              setCurrentStep(step + 1);
            }
          },
          onBack: isFirstStep ? null : () => setCurrentStep(step - 1),
        };
      }),
    [
      buttonsComponent,
      onFinish,
      onFormFinish,
      onFormValuesChange,
      preserve,
      setCurrentStep,
      showBackAlways,
      stepCount,
      valuesRef,
    ]
  );

  return (
    <>
      {/* Children are flattened into one array so that `key` works */}
      {[
        // previous steps
        ...(singlePage
          ? steps.slice(0, currentStep).map((step, idx) => (
              <WizardContext.Provider key={idx} value={contextValues[idx]}>
                {step}
              </WizardContext.Provider>
            ))
          : []),
        // current step
        <WizardContext.Provider
          key={currentStep}
          value={{ ...contextValues[currentStep], isCurrentStep: true }}
        >
          {steps[currentStep]}
        </WizardContext.Provider>,
      ]}
    </>
  );
};

Wizard.WithLoading = WizardWithLoading;

export default Wizard;
