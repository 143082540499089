import axios, { AxiosRequestConfig } from "axios";
import _ from "lodash";
import { authorizationHeader, pipelineAPIRoot } from "../util";
import { castStatuses } from "pages/admin/ManualReview/util";
import { AliquotStatus, AssayName, Paginated } from "./types";

export const fetchPendingAliquotStatuses = async (
  assayName?: AssayName
): Promise<Paginated<AliquotStatus[]>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${pipelineAPIRoot}/pcr_aliquot_statuses/pending`,
    headers: authorizationHeader(),
    params: !_.isNil(assayName) ? { assayName: assayName } : null,
  };

  return await axios(config).then((response) => {
    return {
      ...response.data,
      data: castStatuses(response.data.data),
    };
  });
};
