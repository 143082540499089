// external
import { PageHeader, Table, Checkbox, message, Button } from "antd";
import { useState } from "react";
import _ from "lodash";

// api
import {
  useKitProgramSelectionStatusList,
  useKitProgramSelectionStatusCount,
  useKpssAuditLog,
  useKpssAuditLogCount,
  fetchAllSelectionsForAuditLog,
} from "api/kitProgramSelectionStatus";
import { useProgramOptions } from "api/program";

// helpers/constants
import {
  auditLogTableColumns,
  defaultDateReceivedFilterValue,
} from "./constants";
import { useTableQueryParams } from "components/Table/helpers";
import { METHOD_TIME_RANGE_EXCLUSIVE_END } from "components/Table/StrapiFilter";

const KitSelectionStatuses = () => {
  const [isAuditLogSearch, setIsAuditLogSearch] = useState(true);
  const { queryParams, handleTableChange, pagination } = useTableQueryParams({
    defaultSort: "created_at:DESC",
    defaultPageSize: 30,
    defaultFilters: {
      created_at: [
        {
          method: METHOD_TIME_RANGE_EXCLUSIVE_END,
          value: defaultDateReceivedFilterValue,
        },
      ],
    },
  });

  const { data: programOptions } = useProgramOptions();

  const auditLogQuery = useKpssAuditLog(queryParams, {
    enabled: isAuditLogSearch,
  });
  const { data: auditLogCount } = useKpssAuditLogCount(queryParams, {
    enabled: isAuditLogSearch,
  });
  const kpssQuery = useKitProgramSelectionStatusList(
    {
      ...queryParams,
      _joins: ["kit.sample", "program"],
    },
    { enabled: !isAuditLogSearch }
  );
  const { data: kpssCount } = useKitProgramSelectionStatusCount(queryParams, {
    enabled: !isAuditLogSearch,
  });

  const handleCopyPaste = async () => {
    message.loading(`Fetching all Selection Statuses...`);
    const allSelections = await fetchAllSelectionsForAuditLog(queryParams);
    const kitIds = _.map(allSelections, (kpss) => kpss?.kit?.shipping_kit_id);
    const definedKitIds = _.filter(kitIds, (val) => !!val);
    navigator.clipboard.writeText(definedKitIds);
    message.destroy();
    message.success(`Copied ${definedKitIds.length} Kit ID(s) to Clipboard`);
  };

  const handleToggleAuditLogSearch = (e) => {
    setIsAuditLogSearch(e?.target?.checked);
  };

  const isLoading = isAuditLogSearch
    ? auditLogQuery.isLoading
    : kpssQuery.isLoading;
  const dataSource = isAuditLogSearch ? auditLogQuery.data : kpssQuery.data;
  const count = isAuditLogSearch ? auditLogCount : kpssCount;

  return (
    <div className="kit-selection-statuses-page" style={{ height: "100%" }}>
      <PageHeader title="Kit Selection Statuses" />
      <div className="px-3 mt-3">
        <div className="mb-3 flex justify-between items-center">
          <Checkbox
            defaultChecked={isAuditLogSearch}
            onChange={handleToggleAuditLogSearch}
          >
            {`[Audit Log] Only search on kits with previously determined selected statuses`}
          </Checkbox>
          {isAuditLogSearch && (
            <Button
              type="primary"
              enabled={`${isAuditLogSearch}`}
              onClick={handleCopyPaste}
            >
              Copy + Paste Kit IDs
            </Button>
          )}
        </div>
        <Table
          scroll={{ x: "max-content" }}
          rowKey="id"
          loading={isLoading}
          columns={auditLogTableColumns(programOptions)}
          dataSource={dataSource}
          onChange={handleTableChange}
          pagination={{ ...pagination, total: count }}
        />
      </div>
    </div>
  );
};

export default KitSelectionStatuses;
