import React from "react";
const SignUpContext = React.createContext();

const SignupContextProvider = ({ children, defaultValues }) => {
  const store = {
    variant: defaultValues.variant,
  };

  return (
    <SignUpContext.Provider value={store}>{children}</SignUpContext.Provider>
  );
};

export const useSignupContext = () => React.useContext(SignUpContext);

export default SignupContextProvider;
