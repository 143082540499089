import { Form, Input, Typography } from "antd";
import React from "react";

const { Text } = Typography;

export default function ZipCode({ initialValue, isInternal }) {
  return (
    <>
      <Form.Item
        label="Zipcode"
        name="zip_id"
        initialValue={initialValue}
        className="mb-0"
        rules={[
          {
            required: !isInternal,
            message: "Enter zip code",
            pattern: new RegExp(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] \d[A-Z]\d)$/), // Supports US, Mexico, & Canada
          },
        ]}
      >
        <Input autoComplete="postal-code" placeholder="Type zipcode" />
      </Form.Item>
      <Text type="secondary">
        This zipcode should pertain to the address of the specified sampling
        location.
      </Text>
    </>
  );
}
