import produce from "immer";

const getDefaultState = () => {
  return [];
};

const products = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "PRODUCTS_GET":
      if (action.error) {
        console.log(action.error);
      } else {
        nextState = produce(state, (draftState) =>
          action.payload.data.sort((a, b) => a.product_price - b.product_price)
        );
      }
      return nextState;
    default:
      return state;
  }
};

export default products;
