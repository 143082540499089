import { Tag, Tooltip } from "antd";
import {
  SELECTION_STATUS_ELIGIBLE,
  SELECTION_STATUS_SELECTED,
  SELECTION_STATUS_SELECTION_AMBIGUOUS,
  SELECTION_STATUS_INELIGIBLE,
} from "configs/constants";

const SelectionStatusTag = ({
  status,
  reason,
  displayName,
  shortDisplayName,
  includeProgram = true,
}) => {
  let tagColor;

  let statusText;
  switch (status) {
    case SELECTION_STATUS_SELECTED:
      statusText = "Selected";
      tagColor = "green";
      break;
    case SELECTION_STATUS_ELIGIBLE:
      statusText = "Eligible";
      tagColor = "geekblue";
      break;
    case SELECTION_STATUS_SELECTION_AMBIGUOUS:
      statusText = "Ambiguous";
      tagColor = "magenta";
      break;
    case SELECTION_STATUS_INELIGIBLE:
      statusText = "Ineligible";
      tagColor = "default";
      break;
    default:
      statusText = "Other";
      tagColor = "default";
  }

  const programNameToDisplay = shortDisplayName ?? displayName ?? "OTHER";
  const tagText = includeProgram
    ? `${programNameToDisplay}: ${statusText}`
    : statusText;
  return (
    <>
      <Tooltip title={reason}>
        <Tag color={tagColor} style={{ textTransform: "uppercase" }}>
          {tagText}
        </Tag>
      </Tooltip>
    </>
  );
};

export default SelectionStatusTag;
