import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getOrderTotal, getOrderTotalPerCycle, getTotalKits } from "./helpers";
import { Button, Row, Typography, Form } from "antd";
import "./styles.css";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return {
      order: state.order,
      products: state.products,
      ...ownProps,
    };
  } else {
    return null;
  }
};

const OrderSummary = (props) => {
  const [kits, setKits] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPerCycle, setTotalPerCycle] = useState(0);
  const { Title } = Typography;

  useEffect(() => {
    const totalKits = getTotalKits(props.order);
    setKits(totalKits);
    setTotalPerCycle(
      getOrderTotalPerCycle(props.products, props.order, totalKits)
    );
    setTotal(getOrderTotal(props.products, props.order, totalKits));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.order]);

  return (
    <div className="order-summary">
      <Row>
        <Title level={3}>Order Summary</Title>
      </Row>

      <Row className="mb-1">
        Kits configuration:{" "}
        {props.kitConfigs?.find(
          (config) => config.id === props.order.kit_configuration
        )?.name || ""}
      </Row>

      <Row className="mb-1">
        Kits per cycle:
        {kits > 0 && ` ${kits}`}
      </Row>

      <Row className="mb-1">
        Cycles:
        {props.order.cycles > 0 && ` ${props.order.cycles}`}
      </Row>

      <Row className="mb-1">
        Total kits:
        {kits > 0 && !props.order.cycles && ` ${kits}`}
        {kits > 0 && props.order.cycles && ` ${kits * props.order.cycles}`}
      </Row>

      <Row className="mb-1">Total per cycle: {totalPerCycle}</Row>

      <Row className="mb-1">Total: {total}</Row>

      <Row>
        <Form.Item>
          <Button
            className="btn--mt-3-caps"
            data-cy="order-summary-btn"
            type="primary"
            size="large"
            htmlType="submit"
          >
            Place Order
          </Button>
        </Form.Item>
      </Row>
    </div>
  );
};

const OrderSummaryContainer = connect(mapStateToProps)(OrderSummary);

export default OrderSummaryContainer;
