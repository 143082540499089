import axios from "axios";
import _ from "lodash";
import { useCallback } from "react";

import { authorizationHeader, mkQuery, orderServiceRoot } from "api/util";

const modelName = (apiID) => {
  if (apiID.endsWith("s") || apiID === "sampling-location-program-enrollment") {
    return apiID;
  } else {
    return apiID + "s";
  }
};

const includeContentType = (contentType) => contentType.isDisplayed;

export const useContentTypes = mkQuery({
  key: () => [
    {
      service: "biobot-api",
      model: "content-manager",
      endpoint: "list-content-types",
    },
  ],
  query: async () => {
    const res = await axios({
      method: "get",
      headers: authorizationHeader(),
      url: `${orderServiceRoot}/content-manager/content-types`,
    });
    const addModel = (contentType) => {
      return {
        model: modelName(contentType.apiID),
        ...contentType,
      };
    };
    const contentTypes = res.data.data.filter(includeContentType).map(addModel);
    return _.keyBy(contentTypes, "model");
  },
  options: {
    staleTime: 86400000,
    cacheTime: 86400000,
  },
});

/** @typedef { "integer"
 *           | "float"
 *           | "email"
 *           | "string"
 *           | "text"
 *           | "richtext"
 *           | "enumeration"
 *           | "date"
 *           | "datetime"
 *           | "timestamp"
 *           | "json"
 *           | "boolean"
 *           | "relation"
 * } StrapiAttributeType */

/** @typedef ContentTypeAttribute
 * @property {StrapiAttributeType} type
 * @property {string?} model - related model name (one:many)
 * @property {string?} collection - related model name (many:many)
 */

/** @typedef ContentType
 * @property {Record<string, ContentTypeAttribute>} attributes
 */

/** @typedef Attribute
 * @property {string[]} dataIndex
 * @property {string} key
 * @property {string} parentModel
 * @property {string} name
 * @property {StrapiAttributeType} type
 * @property {boolean} isArray
 */

/** Returns an array of attributes for a given model, including `maxDepth`
 * levels of relations.
 *
 * @param {object} opts
 * @param {Record<string, ContentType>} opts.contentTypes
 * @param {string} opts.model
 * @param {number?} maxDepth
 *
 * @returns {Attribute[]}
 */
const nestedAttributes = ({
  contentTypes,
  model,
  prefix = [],
  maxDepth = 2,
}) => {
  if (!contentTypes?.[model] || prefix.length > maxDepth) {
    return [];
  }

  const attributes = _.map(contentTypes[model].attributes, (attr, name) => ({
    ...attr,
    parentModel: model,
    name,
    isArray: attr.collection ? true : false,
    dataIndex: [...prefix, name],
    key: [...prefix, name].join("."),
  }));

  const ownAttrs = attributes;

  const relatedAttrs = attributes
    .filter((attr) => attr.type === "relation")
    .flatMap((attr) =>
      nestedAttributes({
        contentTypes: _.omit(contentTypes, model),
        model: modelName(attr.model || attr.collection),
        prefix: [...prefix, attr.name],
        maxDepth,
      }).map((child) => ({
        ...child,
        // If the parent is an array, the child is also an array
        isArray: child.isArray || attr.isArray,
      }))
    );

  return [...ownAttrs, ...relatedAttrs];
};

/** Transforms an array of attributes into a tree structure, adding a
 * `children` property to parent nodes.
 *
 * @param {Attribute[]} allModelAttributes - all attributes from a given model
 * (the return of nestedAttributes).
 */
export const attributeTree = (allModelAttributes, prefix = []) => {
  const ownAttrs = allModelAttributes.filter(({ dataIndex, name }) =>
    _.isEqual([...prefix, name], dataIndex)
  );
  if (ownAttrs.length) {
    return _.sortBy(ownAttrs, (x) => x.type === "relation").map((attr) => ({
      ...attr,
      children: attributeTree(allModelAttributes, [...prefix, attr.name]),
    }));
  }
};

export const useModelAttributes = ({
  model,
  maxDepth = 2,
  select = _.identity,
}) => {
  const selectAttributes = useCallback(
    (contentTypes) =>
      select(nestedAttributes({ contentTypes, model, maxDepth })),
    [model, maxDepth, select]
  );
  return useContentTypes({}, { select: selectAttributes });
};

const selectOptions = (contentTypes) =>
  Object.keys(contentTypes).map((model) => ({ label: model, value: model }));

export const useModelOptions = () =>
  useContentTypes({}, { select: selectOptions });
