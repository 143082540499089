import { createContext, Dispatch } from "react";
import {
  ManualReviewAction,
  ManualReviewState,
} from "reducers/reducerManualReview";

export interface ReviewReducer {
  state: ManualReviewState;
  dispatch: Dispatch<ManualReviewAction>;
}

// @ts-ignore
export const ReviewReducerContext = createContext<ReviewReducer>(null);
