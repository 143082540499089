import { REWORK_ASSAY_OPTIONS } from "api/rework";
import Selector from "./Selector";

export default function AssaySelector({ className, required, hidden }) {
  return (
    <Selector
      label="Assay"
      options={REWORK_ASSAY_OPTIONS}
      className={className}
      formItem={{
        placeholder: "Select Assay",
        width: "37.5%",
        name: "assay",
        hidden,
        rules: [
          {
            required,
            message: `Assay is required for rework type "Lab Rerun"`,
          },
        ],
      }}
    />
  );
}
