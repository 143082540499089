import _ from "lodash";
import { Link } from "react-router-dom";
import { Row, Typography } from "antd";

import {
  strapiFilterProps,
  METHOD_TEXT_CONTAINS,
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_NOT_EQ,
} from "components/Table/StrapiFilter";
import ProductTag from "../../../components/ProductTag";
import { formatDateField } from "../Accounts/helpers";
import { programSelectionStatusFilterProps } from "components/ProgramSelectionStatusFilter";
import { previousMonthStart, previousMonthEnd } from "utils/time";

const capitalizeTextField = (text) => {
  if (_.startCase(text)) {
    return _.startCase(text);
  } else {
    return <Typography.Text>-</Typography.Text>;
  }
};

const reissueFilterOptions = [
  { value: "first", text: "First" },
  { value: "reissue", text: "Reissue" },
];

export const createInvoicingTableColumns = (programs) => {
  return [
    {
      title: "Organization",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.sample.organization.organization_name",
      render: (reportKits) => {
        return (
          <>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                <Link
                  to={`/organizations/${reportKit.kit.sample?.organization?.id}`}
                >
                  {reportKit.kit.sample?.organization?.organization_name}
                </Link>
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Organization",
      }),
    },
    {
      title: "Sampling Location",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.sample.sampling_location_id.sampling_location_name",
      render: (reportKits) => {
        return (
          <>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                <Link
                  to={`/admin/sampling-locations/${reportKit.kit.sample?.sampling_location_id?.id}`}
                >
                  {
                    reportKit.kit.sample?.sampling_location_id
                      ?.sampling_location_name
                  }
                </Link>
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Sampling Location",
      }),
    },
    {
      title: "Shipping Kit ID",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.shipping_kit_id",
      render: (reportKits) => {
        return (
          <>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                <Row key={reportKit.id}>
                  <Link to={`/kit/${reportKit.kit.id}`}>
                    {reportKit.kit.shipping_kit_id ?? "ID Pending"}
                  </Link>
                </Row>
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Shipping Kit ID",
      }),
    },
    {
      title: "Internal Kit ID",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.kit_id",
      render: (reportKits) => {
        return (
          <>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                <Link to={`/kit/${reportKit.kit.id}`}>
                  {reportKit.kit.kit_id}
                </Link>
              </Row>
            ))}
          </>
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Internal Kit ID",
      }),
    },
    {
      title: "Report SKU",
      dataIndex: ["product"],
      key: "product.product_sku",
      render: (reportSku) => {
        return (
          <ProductTag
            sku={reportSku?.product_sku}
            productName={reportSku?.product_name}
          />
        );
      },
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_NOT_EQ, METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_NOT_EQ,
        searchPlaceholder: "Search Report SKU",
        defaultFilterValue: "SKU0019",
      }),
    },
    {
      title: "SKU Price",
      dataIndex: ["product"],
      key: "product.product_sku",
      render: (reportSku) => {
        return <>{reportSku?.product_price ? reportSku.product_price : "-"}</>;
      },
    },
    {
      title: "Date Emailed",
      dataIndex: ["date_report_emailed"],
      key: "date_report_emailed",
      render: (dateEmailed) => formatDateField(dateEmailed),
      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
        defaultFilterValue: [previousMonthStart, previousMonthEnd],
      }),
    },
    {
      title: "Report Issue",
      dataIndex: ["report_issue"],
      key: "report_issue",
      width: 100,
      render: (reportIssueType) => capitalizeTextField(reportIssueType),
      filters: reissueFilterOptions,
    },
    {
      title: "Program",
      dataIndex: ["report_kits"],
      key: "report_kits.kit.selection_statuses",
      render: (reportKits) => {
        return (
          <div>
            {(reportKits ?? []).map((reportKit) => (
              <Row key={reportKit.id}>
                {reportKit.kit.selection_statuses?.map((programStatus) => (
                  <>{programStatus.program?.name}</>
                ))}
              </Row>
            ))}
          </div>
        );
      },
      ...programSelectionStatusFilterProps(programs),
    },
  ];
};
