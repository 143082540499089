import moment from "moment";
import { METHOD_TIME_RANGE_EXCLUSIVE_END } from "components/Table/StrapiFilter";
import KitsPage from "pages/admin/Kits/Kits";

export default function KitsReceived() {
  const todayStart = moment.utc().startOf("day");
  const todayEnd = moment.utc().endOf("day");

  return (
    <KitsPage
      defaultDateReceivedFilterMethod={METHOD_TIME_RANGE_EXCLUSIVE_END}
      defaultDateReceivedFilterValue={[todayStart, todayEnd]}
      pageTitle="Kits Received"
    />
  );
}
