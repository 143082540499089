export const getOrderTotal = (products, order, totalKits) => {
  const price = getPriceFromSku(products, order);
  if (price && totalKits > 0 && !order.cycles) {
    return `$${numberWithCommas(price * totalKits)}`;
  }
  if (price && totalKits > 0 && order.cycles) {
    return `$${numberWithCommas(price * totalKits * order.cycles)}`;
  }
  return null;
};

export const getOrderTotalPerCycle = (products, order, totalKits) => {
  const price = getPriceFromSku(products, order);
  if (price && totalKits > 0) {
    return `$${numberWithCommas(price * totalKits)}`;
  }
  return null;
};

const getPriceFromSku = (products, order) => {
  let price = 0;
  products.forEach((item) => {
    if (item.id === order.sku) {
      price = item.product_price;
    }
  });
  return price;
};

export const getTotalKits = (order) => {
  let totalKits = 0;
  order["shipping-locations"].forEach((item, index) => {
    totalKits = totalKits + order[`location-${index + 1}-number-of-kits`];
  });
  if (totalKits > 0) {
    return totalKits;
  }
  return null;
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
