import { useProgramList } from "api/program";

export default function ProgramDisplayName({ programCode }) {
  const { data: programList } = useProgramList();
  const findProgramName = (programCode) => {
    return programList?.find((program) => program.code === programCode);
  };
  const programDisplayName = findProgramName(programCode)?.name;

  return <>{programDisplayName ?? "-"}</>;
}
