import _ from "lodash";
import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { useFormReviewStatusOptions } from "api/formReviewStatus";

// note:
// "reviewedByProp" field in case of item being a sample will be "log_reviewed_by"
// and "reviewed_by" in case of item being a sampling location
const ReviewStatusForm = ({
  onFinishCallback,
  item,
  refetchKitDetails,
  reviewedByProp = "reviewed_by",
}) => {
  const [form] = Form.useForm();
  const { data: reviewStatusOptions } = useFormReviewStatusOptions();

  const onFinish = (values) => {
    const params = {};
    _.forEach(values, (val, key) => {
      if (val) params[key] = val;
    });
    const onSuccess = () =>
      message.success("Review Status Updated Successfully");
    const onError = () =>
      message.error("Review Status Update Failed. Please try again.");
    onFinishCallback(params, onSuccess, onError);
  };

  const checkReviewChangeIneligibility = (date_received) => {
    return (
      new Date(date_received) <
      new Date(`${process.env.REACT_APP_EFFIGY_LATEST_AIRTABLE_DATE}`)
    );
  };

  return (
    <div
      className="internal-review-status"
      style={{
        backgroundColor: "#E6F7FF",
        margin: "0 -17px",
        padding: `20px 49px 0`, // 32px + 17px to offset neg margin
      }}
    >
      <Row>
        <Typography.Title level={4}>Internal Review Status</Typography.Title>
      </Row>
      <Form
        form={form}
        name="internal-review-status-form"
        className="antd-internal-review-status-form"
        initialValues={{
          review_status: item?.review_status?.id || item?.review_status || 4,
          reviewer_notes: item?.reviewer_notes,
          [reviewedByProp]: item[reviewedByProp],
        }}
        onFinish={onFinish}
      >
        <Row justify="between" gutter={[24, 24]}>
          <Col md={24} lg={4}>
            <span>Status</span>
            <Form.Item name="review_status">
              <Select>
                {(reviewStatusOptions || []).map((status) => (
                  <Select.Option key={status.value} value={status.value}>
                    {status.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={24} lg={4}>
            <span>Reviewer Initials</span>
            <Form.Item
              name={reviewedByProp}
              rules={[{ required: true, message: "Input Reviewer Initials" }]}
            >
              <Input
                name="reviewerInitials"
                type="text"
                placeholder={item[reviewedByProp] || "Initials"}
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={16}>
            <span>Reviewer Notes</span>
            <Form.Item name="reviewer_notes">
              <Input.TextArea
                style={{ height: "32px" }}
                placeholder={
                  item?.reviewer_notes ||
                  "Specify anything noteworthy about this data."
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item>
            <Button
              span={24}
              type="primary"
              htmlType="submit"
              disabled={
                item.date_received
                  ? checkReviewChangeIneligibility(item.date_received)
                  : false
              }
              title={
                item.date_received
                  ? checkReviewChangeIneligibility(item.date_received)
                    ? "This sample log's review status is not eligible for change due to project effigy."
                    : null
                  : null
              }
            >
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default ReviewStatusForm;
