import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
} from "./orderService";

const model = "samples";

export const useSample = orderServiceFindOneQuery({ model });
export const useSampleList = orderServiceListQuery({ model });
export const useSampleCount = orderServiceCountQuery({ model });
export const useCreateSample = orderServiceCreateMutation({ model });
export const useUpdateSample = orderServiceUpdateMutation({ model });
