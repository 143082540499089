import { Form, Input, Select, Row, Col, Radio, Checkbox } from "antd";

import {
  COUNTRIES,
  PROVINCES_BY_COUNTRY,
  PROVINCE_LABELS_BY_COUNTRY,
} from "configs/constants";

import { organizationNameRule } from "utils/rules";

const { Option } = Select;

export default function Fields({ country }) {
  return (
    <>
      <Row gutter={30}>
        <Col sm={24}>
          <Form.Item
            name="Account name"
            label="Account name"
            rules={[organizationNameRule]}
          >
            <Input placeholder={"My Wastewater Company"} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col sm={12}>
          <Form.Item
            name="Name"
            label="Admin user name"
            rules={[{ required: true }]}
          >
            <Input placeholder={"Jen Terry"} />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            name="Email"
            label="Admin user email"
            rules={[{ required: true }]}
          >
            <Input placeholder={"jen.terry@mywasterwatercompany.com"} />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            name="salesforce_account_id"
            label="Salesforce account ID"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            name="contact_title"
            label="Title"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col sm={24}>
          <h2>Organization address</h2>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="contact_name"
            label="Organization contact name, if different from admin user"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="billing_email"
            label="Organization contact email, if different from admin user"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="Phone"
            label="Phone Number"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item name="tax_id" label="Tax ID" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="tax_id_number"
            label="Tax ID Number"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="tax_status"
            rules={[{ required: false }]}
            label="Tax Status"
            valuePropName="Taxable"
          >
            <Radio.Group defaultValue="Taxable">
              <Radio value="Taxable" defaultChecked>
                Taxable
              </Radio>
              <Radio value="Exempt">Exempt</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="Address line 1"
            label="Organization address line 1"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item name="Address line 2" label="Organization address line 2">
            <Input />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item name="City" label="City" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label={PROVINCE_LABELS_BY_COUNTRY[country]}
            name={`Province`}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
              placeholder={PROVINCE_LABELS_BY_COUNTRY[country]}
            >
              {PROVINCES_BY_COUNTRY[country].map((province, index) => (
                <Option key={index} value={province.abbreviation}>
                  {province.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item name="county" label="County" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            name="Post code"
            label="Zip / Post code"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Country"
            name={`Country`}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={Object.values(COUNTRIES)} />
          </Form.Item>
        </Col>
      </Row>
      <Col sm={12}>
        <Form.Item
          name="recognized_tribal_indian_territory"
          valuePropName="checked"
        >
          <Checkbox>Recognized Tribal Indian Territory</Checkbox>
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item name={"us_territory"} valuePropName="checked">
          <Checkbox>US Territory</Checkbox>
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item
          name="num_of_sampling_locations"
          label="Number of sampling locations"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item
          name="estimated_population_served"
          label="Estimated population served"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
      </Col>
    </>
  );
}
