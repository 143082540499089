import { Tooltip, Tag } from "antd";

import { Link } from "react-router-dom";

import moment from "moment";

import { customChoiceFilterProps } from "components/Table/CustomChoiceFilter";

import {
  METHOD_CUSTOM_FILTER,
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_NOT_EMPTY,
  METHOD_TEXT_CONTAINS,
  strapiFilterProps,
} from "components/Table/StrapiFilter";

/** @typedef {import('antd/es/table').ColumnProps} ColumnProps */

/**
 *
 *
 * @param   {*} props
 * @returns {ColumnProps}
 */
function columnProgramName(props) {
  return {
    title: "Program Name",

    render: ({ program }) => program?.name,

    key: "program.name",
  };
}

/**
 *
 *
 * @param   {*} props
 * @returns {ColumnProps}
 */
function columnSamplingLocationName(props) {
  const filters = strapiFilterProps({
    enabledFilterMethods: [METHOD_TEXT_CONTAINS, METHOD_NOT_EMPTY],
    defaultFilterMethod: METHOD_NOT_EMPTY,
    defaultFilterValue: "null",
  });

  return {
    title: "Sampling Location Name",

    render: ({ sampling_location: samplingLocation }) => (
      <Link
        to={`sampling-locations/${samplingLocation?.id}`}
        key={samplingLocation?.id}
      >
        {samplingLocation?.sampling_location_name}
      </Link>
    ),

    key: "sampling_location.sampling_location_name",

    ...filters,
  };
}

/**
 *
 *
 * @param   {*} props
 * @returns {ColumnProps}
 */
function columnStatus(props) {
  const TODAY = moment().format("YYYY-MM-DD");

  const STATUS_ACTIVE = "active";
  const STATUS_EXPIRED = "expired";
  const STATUS_FUTURE = "future";

  const STATUS_COMPONENTS = {
    [STATUS_ACTIVE]: (
      <Tooltip>
        <Tag color={"green"} style={{ textTransform: "uppercase" }}>
          {STATUS_ACTIVE}
        </Tag>
      </Tooltip>
    ),

    [STATUS_EXPIRED]: (
      <Tooltip>
        <Tag color={"default"} style={{ textTransform: "uppercase" }}>
          {STATUS_EXPIRED}
        </Tag>
      </Tooltip>
    ),

    [STATUS_FUTURE]: (
      <Tooltip>
        <Tag color={"purple"} style={{ textTransform: "uppercase" }}>
          {STATUS_FUTURE}
        </Tag>
      </Tooltip>
    ),
  };

  const STATUS_FILTERS = {
    [STATUS_ACTIVE]: {
      start_date_lte: TODAY,
      end_date_gte: TODAY,
    },

    [STATUS_EXPIRED]: {
      end_date_lt: TODAY,
    },

    [STATUS_FUTURE]: {
      start_date_gt: TODAY,
    },
  };

  /**
   *
   *
   * @param   {string} startDate
   * @param   {string} endDate
   * @returns {string}
   */
  const getProgramEnrollmentStatus = (startDate, endDate) => {
    if (startDate <= TODAY && endDate >= TODAY) {
      return STATUS_ACTIVE;
    }

    if (startDate < TODAY && endDate < TODAY) {
      return STATUS_EXPIRED;
    }

    if (startDate > TODAY && endDate > TODAY) {
      return STATUS_FUTURE;
    }
  };

  return {
    width: "175px",

    title: "Status",

    ...customChoiceFilterProps({
      choices: [
        {
          label: STATUS_ACTIVE.toUpperCase(),
          value: STATUS_ACTIVE,
        },
        {
          label: STATUS_EXPIRED.toUpperCase(),
          value: STATUS_EXPIRED,
        },
        {
          label: STATUS_FUTURE.toUpperCase(),
          value: STATUS_FUTURE,
        },
      ],

      toStrapiFilter: (selections) => {
        const filter = {
          _or: selections.map((selection) => STATUS_FILTERS[selection]),
        };

        return { method: METHOD_CUSTOM_FILTER, value: filter };
      },

      renderChoice: (choice) => {
        return STATUS_COMPONENTS[choice.value];
      },
    }),

    render: ({ start_date: startDate, end_date: endDate }) =>
      STATUS_COMPONENTS[getProgramEnrollmentStatus(startDate, endDate)],
  };
}

/**
 *
 *
 * @param   {*} props
 * @returns {ColumnProps}
 */
function columnStartDate(props) {
  const filters = strapiFilterProps({
    enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
  });

  return {
    title: "Start Date",

    render: ({ start_date }) => start_date,

    key: "start_date",

    ...filters,
  };
}

/**
 *
 *
 * @param   {*} props
 * @returns {ColumnProps}
 */
function columnEndDate(props) {
  const filters = strapiFilterProps({
    enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
  });

  return {
    title: "End Date",

    render: ({ end_date }) => end_date,

    key: "end_date",

    ...filters,
  };
}

/**
 *
 *
 * @param   {*} props
 * @returns {Record<string, any>}
 */
function programEnrollmentsTableColumns(props) {
  return [
    columnProgramName(props),
    columnSamplingLocationName(props),
    columnStatus(props),
    columnStartDate(props),
    columnEndDate(props),
  ];
}

/**
 *
 *
 * @param   {}
 * @returns {}
 */
function useProgramEnrollmentsTableColumns({ programEnrollments }) {
  return {
    programEnrollmentsTableColumns: () =>
      programEnrollmentsTableColumns({ programEnrollments }),
  };
}

export { useProgramEnrollmentsTableColumns };
