const ConfirmationErrorMessage = () => {
  return (
    <h3 id="not-my-org-error-message">
      Sorry about this. Are you sure you’ve submitted the correct organization
      ID? If so, please contact{" "}
      <a href="mailto:support@biobot.io"> support@biobot.io</a> so we can
      resolve the issue.
    </h3>
  );
};

export default ConfirmationErrorMessage;
