export interface Paginated<T> {
  data: T;
  pagination: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  };
}

export type AliquotId = number;
export type AliquotName = string;
export type KitName = string;
export type AliquotStatusId = number;

export enum AssayName {
  COVID = "sars_cov_2_prod",
  RESP = "resp_prod",
  MPX = "mpx_prod",
  NORO = "noro_prod",
}

export enum ManualReviewStatus {
  STATUS_NO_OVERRIDE = "no_override",
  STATUS_APPROVED = "approved",
  STATUS_REJECTED = "rejected",
  STATUS_PENDING_RERUN = "pending_rerun",
  STATUS_PENDING_REVIEW = "pending_review",
  STATUS_INVALID = "invalid",
  STATUS_MISSING_LOG = "flagged_missing_log",
  STATUS_FLAGGED_RERUN = "flagged_rerun_sample",
}

export enum QCAction {
  QC_REJECT = "reject",
  QC_RERUN = "rerun",
  QC_REVIEW = "review",
  QC_WARN = "warn",
  QC_FLAG_MISSING_LOG = "flag_log",
  QC_FLAG_RERUN_SAMPLE = "flag_rerun_sample",
}

export interface Aliquot {
  qpcr_aliquot_id: AliquotId;
  aliquot_name: AliquotName;
  tube: {
    kit: {
      kit_name: KitName;
    };
  };
}

export interface QCIssue {
  qpcr_aliquot_qc_issues_id: number;
  aliquot_status_id: number;
  issue_code: string;
  action: string;
  meta: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface AliquotStatus {
  aliquot_status_id: number;
  status: ManualReviewStatus;
  qpcr_aliquot: Aliquot;
  qpcr_aliquot_qc_issues: QCIssue[];
  notes: string;
  assay: {
    assay_id: number;
    assay_name: string;
  };
}

export interface TargetQuant {
  qpcr_aliquot_primer_target_quant_id: number;
  qpcr_aliquot_id: number;
  copies_per_ml_sewage: number | null;
  copies_per_ml_sewage_adjusted: number | null;
  detection_status: string | null;
  created_at: string;
  updated_at: string | null;

  primer_target_protocol: {
    primer_target_protocol_id: number;
    primer_target_name: string;
    adjustment_function: string | null;
    max_copies_warning_threshold: number | null;
    min_copies_error_threshold: number | null;
    min_copies_warning_threshold: number | null;
    min_replicates_for_detection: number | null;
    notes: string | null;
    created_at: string | null;
    updated_at: string | null;
  };
}

export interface ControlQuant {
  qpcr_aliquot_control_quant_id: number;
  qpcr_aliquot_id: number;
  control_value: number;
  created_at: string | null;
  updated_at: string | null;

  control_protocol: {
    control_name: string;
    control_target: string;
    primer_name: string;
    quant_type: string;
    dCt_reference_ids: string;
    spike_in_concentration: number;
    spike_in_matrix: string;
    spike_in_units: string;
    method_ref: string;
    notes: string;
    created_at: string | null;
    updated_at: string | null;
  };
}

export interface LocationReviewData {
  aliquot_statuses: AliquotStatus[];
  target_quants: TargetQuant[];
  control_quants: ControlQuant[];
}
