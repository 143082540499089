import _ from "lodash";
import Text from "antd/es/typography/Text";
import React from "react";

export const leadInitialValue = (lead, leadField, orgField) => {
  return initialValue(lead, leadField, ["organization", orgField]);
};
export const locInitialValue = (lead, leadField, locField) => {
  return initialValue(lead, leadField, ["sampling_location", locField]);
};

export const initialValue = (object, leadFieldPath, linkedFieldPath) => {
  const leadValue = _.get(object, leadFieldPath);
  const orgValue = _.get(object, linkedFieldPath);

  return {
    fromLead: !_.isNil(leadValue) || _.isNil(orgValue),
    value: leadValue ?? orgValue,
  };
};

export const genLabel = (text, initialValue) => {
  return (
    <div>
      <Text>{text + " "}</Text>
      {!initialValue.fromLead && (
        <Text className="pl-1" style={{ color: "blue", fontSize: "10px" }}>
          (via linked Org)
        </Text>
      )}
    </div>
  );
};
