export const getQueryStringsForPage = (arrayOfOptions, currentUrlParams) => {
  let queryStringObj = {};
  arrayOfOptions.forEach((item) => {
    queryStringObj[item] = currentUrlParams.get(item);
  });
  return queryStringObj;
};

export const getFilterValueFromQueryString = (queryString) => {
  return queryString ? [queryString] : null;
};

export const updateQueryStringForFilters = (
  updatedParams,
  currentUrlParams,
  history,
  arrayOfOptions
) => {
  arrayOfOptions.forEach((filterType) => {
    if (updatedParams[filterType] !== undefined) {
      if (updatedParams[filterType] === null) {
        currentUrlParams.delete(filterType);
        history.push(
          `${window.location.pathname}?${currentUrlParams.toString()}`
        );
      } else {
        currentUrlParams.set(filterType, updatedParams[filterType]);
        history.push(
          `${window.location.pathname}?${currentUrlParams.toString()}`
        );
      }
    }
  });
};
