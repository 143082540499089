import { useState, useEffect } from "react";
import { Row, Col, Form, InputNumber, Typography } from "antd";
import { intergerOnlyRule } from "utils/rules";

const { Title, Text } = Typography;

const PumpingInterval = ({ disabled }) => (
  <Form.Item
    name="sampling_interval"
    label="Pumping time interval (minutes)"
    rules={[
      {
        required: false,
      },
    ]}
    className="block"
  >
    <InputNumber
      data-cy="sample-details__pumping-time-interval-input"
      className="w-100"
      min={0}
      placeholder="Pumping time interval (minutes)"
      disabled={disabled}
    />
  </Form.Item>
);

const NumberSamplesTaken = ({ disabled }) => (
  <>
    <Form.Item
      name="num_subsamples"
      label="Number of subsamples"
      rules={[
        {
          required: false,
        },
        intergerOnlyRule,
      ]}
      className="block"
    >
      <InputNumber
        data-cy="sample-details__num-subsamples-input"
        className="w-100"
        placeholder="Number of subsamples taken"
        disabled={disabled}
        type="number"
      />
    </Form.Item>
    <Text type="secondary">
      e.g. Pumping every 15min over 24hr results in 96 subsamples
    </Text>
  </>
);

const PumpingVolume = ({ disabled }) => (
  <Form.Item
    name="sampling_volume"
    label="Pumping volume interval (gal)"
    rules={[
      {
        required: false,
      },
    ]}
    className="block"
  >
    <InputNumber
      data-cy="sample-details__pumping-volume-input"
      className="w-100"
      min={0}
      placeholder="Pumping volume (gallons)"
      disabled={disabled}
    />
  </Form.Item>
);

export const SampleDetails = ({ method }) => {
  const [disableInterval, setDisableInterval] = useState(true);
  const [disableVolume, setDisableVolume] = useState(true);
  const [disableSubSamples, setDisableSubSamples] = useState(true);

  useEffect(() => {
    if (method.includes("time-proportional")) {
      setDisableInterval(false);
      setDisableVolume(true);
      setDisableSubSamples(false);
      // props.formRef.current?.setFieldsValue({
      //   sampling_volume: null,
      // });
    } else if (method.includes("flow-proportional")) {
      setDisableInterval(true);
      setDisableVolume(false);
      setDisableSubSamples(false);
      // props.formRef.current?.setFieldsValue({
      //   sampling_interval: null,
      // });
    } else if (method.includes("manual")) {
      setDisableInterval(false);
      setDisableVolume(false);
      setDisableSubSamples(false);
    } else if (method.includes("grab")) {
      setDisableInterval(true);
      setDisableVolume(true);
      setDisableSubSamples(true);
      // props.formRef.current?.setFieldsValue({
      //   sampling_interval: null,
      //   sampling_volume: null,
      //   num_subsamples: null,
      // });
    } else if (method === "other") {
      setDisableInterval(true);
      setDisableVolume(true);
      setDisableSubSamples(false);
      // props.formRef.current?.setFieldsValue({
      //   sampling_interval: null,
      //   sampling_volume: null,
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method]);

  return (
    <Row className="mb-5">
      <Col span={24}>
        <Title level={4}>Sampling details</Title>
      </Col>
      <Col span={24}>
        <Row gutter={48}>
          <Col xs={24} sm={24} md={8}>
            <PumpingInterval disabled={disableInterval} />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <PumpingVolume disabled={disableVolume} />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <NumberSamplesTaken disabled={disableSubSamples} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
