// Styles based on `.anticon svg` so this works like any other ant <Icon>
const iconStyle = { height: "1em", display: "inline-block" };

const EmojiIcon = ({ emoji, ...props }) => (
  <span className="anticon" role="img" {...props}>
    <span style={iconStyle}>{emoji}</span>
  </span>
);

export const PoopIcon = (props) => <EmojiIcon emoji="💩" {...props} />;

export const TadaIcon = (props) => <EmojiIcon emoji="🎉" {...props} />;

export default EmojiIcon;
