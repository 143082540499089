import { Form, Input } from "antd";
import { GTMolecularRunFn } from "../../components/GTMolecularRunFn";

const FN_NAME = "gtm-run-kit-assays";

let kitAssaysFormItems = (
  <>
    <Form.Item label="Kit IDs" name="kitIds" required="true">
      <Input.TextArea />
    </Form.Item>
  </>
);

function FnRun() {
  return <GTMolecularRunFn name={FN_NAME} formItems={kitAssaysFormItems} />;
}

export { FnRun };
