import { Form, Input, Typography } from "antd";
import React from "react";

const { Text } = Typography;

export default function PopulationEstimateSource({ initialValue }) {
  return (
    <>
      <Form.Item
        className="mb-0"
        name="population_served_source"
        label="Source of population estimate"
        initialValue={initialValue}
      >
        <Input placeholder="Specify source of estimate" />
      </Form.Item>
      <Text type="secondary">
        e.g. Census 2010, Designed Capacity, number of connections, etc.
      </Text>
    </>
  );
}
