import { Input, Form, Col, Row, Typography, Checkbox } from "antd";

const OrderConfirmEmails = (props) => {
  const { Title } = Typography;

  const emailOptions = [
    { label: "Send Order Confirmation Email", value: "order_confirmation" },

    // This option shouldn't show until CRM-72 is completed
    // { label: "Send Shipping Update Emails", value: "shipping_updates" },
  ];

  return (
    <>
      <Row className="mb-3">
        <Col span={24}>
          <Title level={3}>Confirmation Email</Title>
        </Col>

        <Col span={8} data-cy="confirmation-email">
          <Form.Item
            className="pb-0"
            name="confirmation_email"
            label="Email address for order confirmation"
            validateTrigger="onBlur"
            normalize={(value) => value.trim()}
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter a valid email.",
              },
            ]}
          >
            <Input id="input-confirmation-email" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="send_emails">
            <Checkbox.Group options={emailOptions}></Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default OrderConfirmEmails;
