import {
  orderServiceCustomQuery,
  orderServiceCustomRoute,
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
} from "./orderService";

const model = "kit-program-selection-statuses";

export const useKitProgramSelectionStatus = orderServiceFindOneQuery({ model });

export const useKitProgramSelectionStatusList = orderServiceListQuery({
  model,
});

export const useKpssAuditLog = orderServiceCustomQuery({
  model,
  path: "audit-log",
});

export const useKpssAuditLogCount = orderServiceCustomQuery({
  model,
  path: "audit-log/count",
});

export const useKitProgramSelectionStatusCount = orderServiceCountQuery({
  model,
});
export const useCreateKitProgramSelectionStatus = orderServiceCreateMutation({
  model,
});
export const useUpdateKitProgramSelectionStatus = orderServiceUpdateMutation({
  model,
});

export const fetchAllSelectionsForAuditLog = (queryParams) =>
  orderServiceCustomRoute({
    model,
    path: "audit-log",
    params: { ...queryParams, _limit: -1, _fields: ["kit.shipping_kit_id"] },
  });
