import { Col, Row, Space, Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const _renderTableCell = (
  { title, tooltip, value, hasBottomMargin = true, span },
  key
) =>
  title && (
    <Col sm={6} xs={24} key={key}>
      <Row wrap="false" style={{ marginTop: "19px" }}>
        <Col span={20}>
          <Space wrap="false" align="middle">
            <Typography.Text strong>{title}</Typography.Text>
            {tooltip && (
              <Tooltip span={4} title={tooltip}>
                <InfoCircleOutlined style={{ color: "#00A4FF" }} />
              </Tooltip>
            )}
          </Space>
        </Col>
      </Row>
      <Row style={{ marginBottom: hasBottomMargin ? "19px" : "" }}>
        <Col span={span}>{value || "-"}</Col>
      </Row>
    </Col>
  );

/*
This component renders an antd table given predefined tableCells defined in the following double array pattern:
const tableCellsArray = [
    [ <-- New Array = New Row
      { <-- cell object
        title: "[Title of cell]",
        tooltip: [Tooltip for cell]",
        value: [cell value]
      }
    ],
    [
      {
        title: "[Title of cell]",
        tooltip: [Tooltip for cell]",
        value: [cell value]
      }
    ]
  ]
*/

const MetadataDetailTable = ({ tableCellsArray }) =>
  tableCellsArray ? (
    <div className="metadata-detail-table">
      {tableCellsArray.map((row, y) => {
        return (
          <Row
            gutter={19}
            key={`sample-loc-row-${y}`}
            style={{ borderBottom: "1px solid #D9D9D9" }}
          >
            {row.map((cell, i) =>
              _renderTableCell(cell, `sample-loc-cell-${i}`)
            )}
          </Row>
        );
      })}
    </div>
  ) : null;

export default MetadataDetailTable;
