import { Typography } from "antd";
import { PoopIcon, TadaIcon } from "./EmojiIcon";

const { Link, Paragraph, Text, Title } = Typography;

const propsByStatus = {
  success: {
    icon: <TadaIcon />,
    title: "Submitted.",
    "data-cy": "submission-result--success",
    body: (
      <Paragraph>
        Thanks for spending the time to complete this form. You’re one step
        closer to receiving your analysis. If our team has any further questions
        they will reach out accordingly.
      </Paragraph>
    ),
  },
  error: {
    icon: <PoopIcon />,
    title: "Oh, crap.",
    "data-cy": "submission-result--error",
    body: (
      <>
        <Paragraph>
          There seems to be a problem with your submission. Using the
          suggestions below, please try again:
        </Paragraph>

        <Paragraph>
          <ul>
            <li>
              Refresh your screen and ensure you’ve submitted the form within{" "}
              <Text strong>30 minutes</Text>. Biobot has set this time limit as
              a security precaution.
            </li>
            <li>Retry your submission on a Chrome browser.</li>
            <li>
              If you are unsure of an answer, please leave optional fields
              blank. A field is optional if it does not have an asterisk in the
              input title.
            </li>
          </ul>
        </Paragraph>

        <Paragraph>
          If you’ve tried out the suggestions above and continue having issue
          please <Link href="mailto:support@biobot.io">contact our team</Link>.
        </Paragraph>
      </>
    ),
  },
};

const statusProps = (status) => {
  if (status === "success" || (status >= 200 && status < 300)) {
    return propsByStatus.success;
  } else {
    return propsByStatus.error;
  }
};

const SubmissionResult = ({ status, ...props }) => {
  const { title, icon, body, "data-cy": dataCy } = {
    ...statusProps(status),
    ...props,
  };
  return (
    <div data-cy={dataCy}>
      <Title level={3} data-cy="sumbission-result__title">
        {title} {icon}
      </Title>
      {body}
    </div>
  );
};

export default SubmissionResult;
