import {
  Chart,
  Line,
  Point,
  Legend,
  Interval,
  Tooltip,
  Axis,
  View,
} from "bizcharts";
import moment from "moment";

const LineChart = (props) => {
  const data = [];

  // format results
  props.results.forEach((item) => {
    if (item.sample_date) {
      if (item.attributes.detection === "DETECTED") {
        data.push({
          date: moment(item.sample_date).format("YYYY-MM-DD"),
          concentration: item.attributes.norm_concentration,
        });
      } else {
        data.push({
          date: moment(item.sample_date).format("YYYY-MM-DD"),
          concentration: 0,
        });
      }
    }
  });

  data.sort((a, b) => moment(a.date).format("x") - moment(b.date).format("x"));

  const scale = {
    date: {
      type: "time",
      sync: true,
    },
  };
  return (
    <div className="chart">
      <Chart
        height={250}
        padding={[30, 60, 30, 60]}
        data={data}
        autoFit
        scale={scale}
        animate={false}
      >
        <Axis name="date" />
        <Axis name="concentration" />
        <Tooltip
          crosshairs={{
            type: "y",
          }}
        />
        <View data={props.cases} scale={scale} padding={0}>
          <Axis grid={null} name="cases" position="right" />
          <Axis visible={false} name="average" />
          <Interval color="#dddddd" position="date*cases" />
          <Line color="#B4C8D6" shape="smooth" position="date*average" />
        </View>
        <Point color="#1790FF" shape="circle" position="date*concentration" />
        <Line color="#1790FF" position="date*concentration" />
        <Legend />
      </Chart>
    </div>
  );
};

export default LineChart;
