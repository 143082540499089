import _ from "lodash";
import { Checkbox, Col, Form, Input, Row } from "antd";
import Text from "antd/es/typography/Text";

import { genLabel, locInitialValue } from "../helpers";
import ProgramTag from "components/ProgramTag";
import { useSamplingLocationList } from "api/samplingLocation";
import Loader from "components/Loader/Loader";
import ProgramName from "components/ProgramDisplayName";

export default function LocationDetail({
  location,
  index,
  alreadyActivated,
  program,
  isExistingOrg,
}) {
  const isLinked = !_.isNil(location.sampling_location);
  const initialEstPop = locInitialValue(
    location,
    "estimated_population_served",
    "estimated_population_served"
  );

  const { data: locationDetails, isLoading } = useSamplingLocationList({
    _where: { id: location.sampling_location?.id },
  });

  const now = new Date().toISOString().split("T")[0]; //convert date to YYYY-MM-DD
  const locationProgramEnrollments = locationDetails?.[0]?.program_enrollment?.map(
    (p) => ({
      ...p,
      is_active: p.start_date <= now && now <= p.end_date,
    })
  );

  const liveLocPrograms = locationProgramEnrollments?.filter(
    (program) => program.is_active
  );
  const pastLocPrograms = locationProgramEnrollments?.filter(
    (program) => !program.is_active
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Row className="mt-2 mb-1" key={`loc-title-${index}`}>
        {isLinked && (
          <Text strong>Existing: {location.sampling_location_name}</Text>
        )}
        {!isLinked && (
          <Text strong>New: {location.sampling_location_name}</Text>
        )}
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Item
            name={["sampling_locations", index, "id"]}
            initialValue={location.id}
            style={{ display: "none" }}
          >
            <Input hidden />
          </Form.Item>
          <Form.Item
            name={["sampling_locations", index, "include"]}
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox disabled={alreadyActivated}>
              Include this location in the <ProgramName programCode={program} />
              .
            </Checkbox>
          </Form.Item>
        </Col>
        <Col sm={6}>
          <Form.Item
            name={["sampling_locations", index, "estimated_population_served"]}
            label={genLabel("Population Served", initialEstPop)}
            initialValue={initialEstPop.value}
            className="pr-1"
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
        {isExistingOrg && locationDetails.length === 1 && (
          <>
            <Col span={5} className="ml-3">
              <Row>Live Programs</Row>
              {(liveLocPrograms ?? []).map((liveProgram) => (
                <Row className="mb-1" key={liveProgram.id}>
                  <ProgramTag
                    code={"liveProgram"}
                    displayName={liveProgram.program.name}
                  />
                </Row>
              ))}
            </Col>
            <Col span={5}>
              <Row>Past Programs</Row>
              {(pastLocPrograms ?? []).map((pastProgram) => (
                <Row className="mb-1" key={pastProgram.id}>
                  <ProgramTag
                    code={"pastProgram"}
                    displayName={pastProgram.program.name}
                  />
                </Row>
              ))}
            </Col>
          </>
        )}
        <Col span={5}>
          <Form.Item
            name={["sampling_locations", index, "npdes_id"]}
            label="NPDES ID"
            initialValue={location.sampling_location?.npdes_id}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
