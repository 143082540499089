import { orderServiceListQuery } from "./orderService";
import { optionsTemplate } from "./util";

const model = "products";

export const useProductList = orderServiceListQuery({ model });

const selectOptions = optionsTemplate(
  {
    value: "product_sku",
    label: (product) => `${product.product_sku} (${product.product_name})`,
  },
  { sortBy: "value" }
);

export const useProductOptions = () =>
  useProductList({ _joins: false }, { select: selectOptions });
