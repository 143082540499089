import { Row, Col, Typography } from "antd";
import Capacity from "../form/Capacity";
import ResidentialInfluentPercentage from "../form/ResidentialInfluentPercentage";
import SeparatedSewerPercentage from "../form/SeparatedSewerPercentage";
import NWSSSiteId from "../form/NWSSSiteId";
import EPARegistryId from "../form/EPARegistryId";

const SamplingDetails = ({ location, isInternal }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Row className="mt-5 mb-2">
        <Col span={24}>
          <Title level={4}>Sampling Location Details</Title>
          <Text>
            Additional information helps improve the analysis you receive.
          </Text>
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col lg={8} sm={12} xs={24} className="mb-3">
          <Capacity
            initialValue={location.capacity_mgd}
            isInternal={isInternal}
          />
        </Col>
        <Col lg={8} sm={12} xs={24} className="mb-3">
          <ResidentialInfluentPercentage
            initialValue={location.influent_residential_perc}
          />
        </Col>
        <Col lg={8} sm={12} xs={24} className="mb-3">
          <SeparatedSewerPercentage
            initialValue={location.influent_sewer_perc}
          />
        </Col>
      </Row>
      {isInternal && (
        <Row gutter={[8, 16]}>
          <Col lg={8} sm={12} xs={24} className="mb-3">
            <NWSSSiteId initialValue={location.nwss_site_id} />
          </Col>
          <Col lg={8} sm={12} xs={24} className="mb-3">
            <EPARegistryId initialValue={location.epa_registry_id} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default SamplingDetails;
