import { Form, Select, Col, Row } from "antd";
import Title from "antd/lib/typography/Title";

const options = [
  {
    key: "low",
    value: "Low",
  },
  {
    key: "medium",
    value: "Medium",
  },
  {
    key: "high",
    value: "High",
  },
];

const FulfillmentPriority = () => {
  return (
    <>
      <Row>
        <Col span={16}>
          <Title level={3}>Fulfillment Priority</Title>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={8}>
          <Form.Item
            name="fulfillment_priority"
            label="Fulfillment Priority"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select data-cy="fulfillment-priority" options={options} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default FulfillmentPriority;
