import { message } from "antd";
import { logout } from "utils";

const getDefaultState = () => {
  return {};
};

const user = (state = getDefaultState(), action) => {
  switch (action.type) {
    case "USER_AUTH":
      if (action.payload.data && action.payload.data.accessToken) {
        localStorage.setItem("token", action.payload.data.accessToken);
        localStorage.setItem("refreshToken", action.payload.data.refreshToken);
        return state;
      } else {
        message.error("Username or password incorrect");
        logout();
      }
      return state;
    default:
      return state;
  }
};

export default user;
