import { Form, Input } from "antd";

export default function FacilityTypeSpecify({ initialValue, disabled }) {
  return (
    <>
      <Form.Item
        label="Other description"
        name="facility_type_specify"
        initialValue={initialValue}
        className="mb-0"
        rules={[
          {
            required: !disabled,
            message: 'Must be provided if "other" is selected',
          },
        ]}
      >
        <Input placeholder="Other description" disabled={disabled} />
      </Form.Item>
    </>
  );
}
