import { orderServiceListQuery } from "./orderService";
import { optionsTemplate } from "./util";

const model = "facility-types";

export const useFacilityTypeList = orderServiceListQuery({ model });

const selectOptions = optionsTemplate({ label: "display_text", value: "id" });

export const useFacilityTypeOptions = () =>
  useFacilityTypeList({ _joins: false }, { select: selectOptions });
