import produce from "immer";

const getDefaultState = () => {
  return [];
};

const accounts = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "ACCOUNTS_GET":
      if (action.error) {
        return state;
      } else {
        nextState = produce(
          state,
          (draftState) => action.payload.data.accounts
        );
      }
      return nextState;
    case "ACCOUNTS_SET":
      nextState = produce(state, (draftState) => action.data.value);
      return nextState;
    default:
      return state;
  }
};

export default accounts;
