import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import _ from "lodash";

import {
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
  METHOD_TIME_RANGE_EXCLUSIVE_END,
  strapiFilterProps,
} from "components/Table/StrapiFilter";

import ProgramTag from "components/ProgramTag";
import { columnSearchProps } from "components/Table/SearchFilter";
import { toLocaleString, formatDate } from "utils/time";

const npdesMissingReasons = {
  dont_have_one: "I don't have one",
  dont_know_it: "I don't know it",
};

export const getColumns = ({
  samplingLocationsProgramEnrollmentFilterProps,
  countyNameMap,
  stateNameMap,
  stateOptions,
  reviewStatusOptions,
}) => [
  {
    title: "Organization",
    dataIndex: ["organization", "organization_name"],
    key: "organization.organization_name_contains",
    ...columnSearchProps({ placeholder: "Search Organization Name" }),
  },
  {
    title: "Organization ID",
    dataIndex: ["organization", "display_id"],
    key: "organization",
  },
  {
    title: "Organization Salesforce ID",
    dataIndex: ["organization", "salesforce_account_id"],
    key: "organization.salesforce_account_id",
    ...columnSearchProps({
      placeholder: "Search Organization Salesforce ID",
    }),
  },
  {
    title: "Sample Location",
    dataIndex: [],
    render: (record) => (
      <Link to={`/admin/sampling-locations/${record.id}`}>
        {record?.sampling_location_name || ""}
      </Link>
    ),
    key: "sampling_location_name_contains",
    ...columnSearchProps({ placeholder: "Search Sample Location Name" }),
  },
  {
    title: "Sample Location ID",
    dataIndex: [],
    render: (record) => (
      <Link to={`/admin/sampling-locations/${record.id}`}>{record.id}</Link>
    ),
    key: "id",
    ...columnSearchProps({ placeholder: "Search Sample Location ID" }),
  },
  {
    title: "Programs",
    dataIndex: ["program_enrollment"],
    key: "program_enrollment",
    ...samplingLocationsProgramEnrollmentFilterProps(),
    render: (enrollments) =>
      enrollments?.map((programEnrollment) => (
        <ProgramTag
          key={programEnrollment.program.id}
          displayName={programEnrollment.program.name}
          shortDisplayName={programEnrollment.program.short_name}
          code={programEnrollment.program.code}
          startDate={programEnrollment.start_date}
          endDate={programEnrollment.end_date}
        />
      )),
  },
  {
    title: "Submitted",
    dataIndex: ["created_at"],
    key: "created_at",
    render: (record) => toLocaleString(record),
    defaultSortOrder: "ascend",
    sorter: true,
  },
  {
    title: "Updated",
    dataIndex: ["updated_at"],
    key: "updated_at",
    render: (record) => toLocaleString(record),
    defaultSortOrder: "ascend",
    sorter: true,
  },
  {
    title: "Location Type",
    dataIndex: ["location_type"],
    key: "location_type",
    filters: [
      { text: "Facility", value: "facility" },
      { text: "Community", value: "community" },
    ],
    render: (record) => _.startCase(record),
  },
  {
    title: "Population",
    dataIndex: ["estimated_population_served"],
    key: "estimated_population_served_null",
    filters: [
      { text: "Has Population Data", value: "false" },
      { text: "No Population Data", value: "true" },
    ],
    filterMultiple: false,
  },
  {
    title: "NPDES",
    dataIndex: ["npdes_id"],
    key: "npdes_id_null",
    filters: [
      { text: "Has NPDES ID", value: "false" },
      { text: "No NPDES ID", value: "true" },
    ],
    filterMultiple: false,
  },
  {
    title: "NPDES Missing Reason",
    dataIndex: ["npdes_id_missing_reason"],
    key: "npdes_id_missing_reason",
    filters: Object.entries(npdesMissingReasons).map(([value, text]) => {
      return { text, value };
    }),
    filterMultiple: false,
    render: (reason) => npdesMissingReasons[reason] ?? null,
  },
  {
    title: "Primary County",
    dataIndex: ["county_id"],
    key: "county_id",
    render: (id) => countyNameMap?.[id],
  },
  {
    title: "State",
    dataIndex: ["state_id"],
    key: "state_id",
    render: (id) => stateNameMap?.[id],
    filters: stateOptions,
    filterSearch: true,
    filterMultiple: true,
  },
  {
    title: "Status",
    dataIndex: ["review_status", "status"],
    key: "review_status",
    filters: reviewStatusOptions,
    filterMultiple: false,
  },
  {
    title: "Reviewer",
    dataIndex: ["reviewed_by"],
    key: "reviewed_by",
  },
  {
    title: "Notes",
    dataIndex: ["reviewer_notes"],
    key: "reviewer_notes",
    width: 500,
  },
  {
    title: "Date Joined CDC",
    dataIndex: [],
    render: (record) =>
      record.date_joined_cdc ? toLocaleString(record.date_joined_cdc) : "",
    key: "date_joined_cdc",
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TIME_RANGE_EXCLUSIVE_END,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
    }),
  },
  {
    title: "Date Left CDC",
    dataIndex: [],
    render: (record) =>
      record.date_left_cdc ? toLocaleString(record.date_left_cdc) : "",
    key: "date_left_cdc",
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TIME_RANGE_EXCLUSIVE_END,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
    }),
  },
  {
    title: "Date Joined Biobot Network",
    dataIndex: [],
    render: (record) =>
      record.date_joined_network
        ? toLocaleString(record.date_joined_network)
        : "",
    key: "date_joined_network",
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TIME_RANGE_EXCLUSIVE_END,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
    }),
  },
  {
    title: "Date Left Biobot Network",
    dataIndex: [],
    render: (record) =>
      record.date_left_network ? toLocaleString(record.date_left_network) : "",
    key: "date_left_network",
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_TIME_RANGE_EXCLUSIVE_END,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
    }),
  },
  {
    title: "Date of Most Recent Kit Received",
    dataIndex: ["most_recent_kit", "date_received"],
    render: (dateReceived) =>
      dateReceived ? (
        formatDate(dateReceived)
      ) : (
        <Tooltip title="No Kits Received for this Sampling Location in Last 30 Days">
          <span style={{ cursor: "default" }}>-</span>
        </Tooltip>
      ),
  },
  {
    title: "Number of Kits in the Last 14 Days",
    dataIndex: ["kits_received_in_last_14_days"],
  },
  {
    title: "Number of Kits in the Last 30 Days",
    dataIndex: ["kits_received_in_last_30_days"],
  },
];
