import _ from "lodash";
import { useCallback, useState } from "react";
import { strapiFilterKeyToStrapiQueryParam } from "./StrapiFilter";
import { hasAnyNonNilValue } from "utils/objects";

// updateRecordFunc: a function that takes original record and returns an updated copy of it using data
//      from the provided source - (record, source) -> new record
// setTableData: a setter for the state var that binds to the tables data prop
export const updateTableRecord = (
  recordId,
  tableData,
  updateRecordFunc,
  setTableData
) => {
  const newData = [...tableData];
  const index = newData.findIndex((item) => recordId === item.id);

  if (index > -1) {
    const updatedData = updateRecordFunc(newData[index]);
    newData[index] = updatedData;
    setTableData(newData);
  }
};

const dataIndexSortKey = (dataIndex) =>
  Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex;

const tableSortParam = ({ order, column }) => {
  const field = column?.sortKey ?? dataIndexSortKey(column?.dataIndex);
  if (field && order) {
    if (order === "ascend") {
      return `${field}:ASC`;
    } else {
      return `${field}:DESC`;
    }
  }
};

const tableFilterParam = (filters) =>
  Object.entries(_.omitBy(filters, _.isNil))
    .map(([key, val]) => {
      if (_.isObject(val[0])) {
        return strapiFilterKeyToStrapiQueryParam(filters, key);
      } else {
        return { [key]: val };
      }
    })
    .reduce(_.assign, {});

export const tableQueryParams = (pagination, filters, sorter) => {
  const pageSize = pagination?.pageSize ?? pagination?.defaultPageSize ?? 10;
  const currentPage = pagination?.current ?? 1;
  return _.omitBy(
    {
      _where: tableFilterParam(filters ?? {}),
      _sort: tableSortParam(sorter ?? {}),
      _start: (currentPage - 1) * pageSize,
      _limit: pageSize,
    },
    _.isNil
  );
};

const removeDefaultFilterValues = (columns) =>
  columns.map((col) => _.omit(col, ["defaultFilteredValue"]));

/**
 * @typedef {Object} UseTableQueryParams
 * @property {object} queryParams -- params to pass to a react-query hook
 * @property {function} setQueryParams -- avoid if possible
 * @property {function} handleTableChange -- antd Table `onChange` callback
 * @property {function} handleResetFilters -- method to set filters back to default state
 * @property {function} handleClearFilters -- method to clear all set filters, including defaults
 * @property {number} tableKey -- used to rerender table on filter reset. Necessary with handleResetFilters
 * @property {object} pagination -- antd Table `pagination` value
 */

/**
 * Hook to manage table filters, pagination, and sorting.
 *
 * @param {object} [options]
 * @param {number} options.defaultPageSize
 * @param {object} options.permanentFilters -- filters to apply to all requests
 * @param {object} options.defaultFilters -- filters to apply by default
 * @param {function} options.transformFilters -- transformation function
 *                                               applied to the full object of
 *                                               filters
 * @param {string} options.defaultSort -- "some_col:ASC" or "some_col:DESC"
 *
 * @returns {UseTableQueryParams}
 *
 * @example
 * const { queryParams, pagination handleTableChange } = useTableQueryParams({
 *   defaultPageSize: 50,
 *   permanentFilters: { organization: 20 }
 *   defaultSort: "my_field:DESC",
 * });
 * const { data, isLoading } = useSomeApiList(queryParams);
 * const { data: count } = useSomeApiCount(queryParams);
 * return (
 *   <Table
 *     onChange={handleTableChange}
 *     loading={isLoading}
 *     dataSource={data}
 *     pagination={{ ...pagination, total: count }}
 *   />
 * )
 */
export const useTableQueryParams = ({
  defaultPageSize = 10,
  permanentFilters = {},
  defaultFilters = {},
  transformFilters = _.identity,
  defaultSort,
  defaultColumns = [],
} = {}) => {
  const defaultPagination = { defaultPageSize };
  // to keep table in uncontrolled state, we use a unique new key on reset to trigger a re-render
  // this will take the table back to its original state when handleResetFilters is called
  const [tableKey, setTableKey] = useState(0);
  const [columns, setColumns] = useState(defaultColumns);
  const [filters, setFilters] = useState({});

  const defaultQueryState = () => ({
    _sort: defaultSort,
    ...tableQueryParams(
      defaultPagination,
      transformFilters({ ...permanentFilters, ...defaultFilters }),
      {}
    ),
  });

  const [queryParams, setQueryParams] = useState(defaultQueryState);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      setFilters(filters);
      setQueryParams({
        _sort: defaultSort,
        ...tableQueryParams(
          pagination,
          transformFilters({ ...filters, ...permanentFilters }),
          sorter
        ),
      });
    },
    [setQueryParams, permanentFilters, transformFilters, defaultSort]
  );

  const handleClearFilters = () => {
    const hasActiveDefaultFilters = _.some(columns, "defaultFilteredValue");
    const hasActiveSelectedFilters = hasAnyNonNilValue(filters);
    if (hasActiveSelectedFilters || hasActiveDefaultFilters) {
      setColumns(removeDefaultFilterValues(defaultColumns));
      setQueryParams({
        _sort: defaultSort,
        ...tableQueryParams(
          defaultPagination,
          transformFilters({ ...permanentFilters }),
          {}
        ),
      });
      setFilters({});
      setTableKey(tableKey + 1);
    }
  };

  const handleResetFilters = () => {
    setFilters({});
    setTableKey(tableKey + 1);
    setQueryParams(defaultQueryState());
  };

  return {
    handleTableChange,
    handleResetFilters,
    handleClearFilters,
    tableKey,
    queryParams,
    setQueryParams,
    pagination: defaultPagination,
    columns,
  };
};
