import { Switch, Route } from "react-router-dom";
import { Typography } from "antd";
import PwdResetBegin from "./PwdResetBegin";
import PwdReset from "./PwdReset";
import Login from "../Login/Login";
import NewUser from "./NewUser";
import "./styles.css";

const Auth = () => {
  return (
    <div className="Auth__wrapper">
      <div className="Auth__page">
        <div className="Auth__content">
          <img
            alt="Biobot background"
            className="Login__img"
            width="100%"
            height="409"
            src={`${process.env.PUBLIC_URL}/assets/banner-biobot-small.png`}
          />

          <div style={{ position: "relative" }}>
            <img
              style={{ display: "block", margin: "auto" }}
              alt="Biobot logo white square"
              width="10%"
              height="100%"
              src={`${process.env.PUBLIC_URL}/assets/dark.png`}
            />
          </div>

          <Typography.Title
            className="text-center"
            style={{ color: "#fff", position: "relative", marginTop: "7rem" }}
          >
            Welcome
          </Typography.Title>
          <div className="Login__page">
            <Switch>
              <Route exact path="/account/forgot" component={PwdResetBegin} />
              <Route exact path="/account/reset" component={PwdReset} />
              <Route exact path="/account/new" component={NewUser} />
              <Route exact path="/" component={Login} />
            </Switch>
          </div>
        </div>
        <div className="Auth__footer p-1 text-center">
          <p>
            &copy; {new Date().getFullYear()} Biobot Analytics, Inc. |
            Cambridge, MA
          </p>
        </div>
      </div>
    </div>
  );
};

export default Auth;
