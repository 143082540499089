import React from "react";
import moment from "moment";
import fileDownload from "js-file-download";
import { Link, withRouter } from "react-router-dom";
import { PageHeader, Table, Row, Button, message, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { formatDateField } from "../Accounts/helpers";
import { useKitStatusList, fetchKitStatusCsv } from "api/dataOrchestration";

const KitStatusPage = () => {
  const { data: kitStatusList, isLoading } = useKitStatusList();

  const handleDownloadCSV = async () => {
    const date = moment().format("MM-DD-YYYY");
    return fetchKitStatusCsv()
      .then((response) => {
        fileDownload(response, `${date}-kit-status-report.csv`);
      })
      .catch((err) => {
        message.error("Something went wrong!", err);
      });
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "organization_name",
      key: "organization_name",
      sorter: (a, b) =>
        a?.organization_name?.localeCompare(b?.organization_name),
    },
    {
      title: "Account: Salesforce.com ID",
      dataIndex: "salesforce_account_id",
      key: "salesforce_account_id",
      sorter: (a, b) =>
        a?.salesforce_account_id?.localeCompare(b?.salesforce_account_id),
    },
    {
      title: "Most Recent Date of Kit Received",
      dataIndex: "kit_most_recent_date",
      key: "kit_most_recent_date",
      render: (val) => formatDateField(val),
      sortKey: "kit_most_recent_date",
    },
    {
      title: "First Date of Kit Received",
      dataIndex: "kit_first_ordered_date",
      key: "kit_first_ordered_date",
      render: (val) => formatDateField(val),
    },
    {
      title: "Total Kits Received",
      dataIndex: "kit_total_received",
      key: "kit_total_received",
    },
    {
      title: "Total Kits Ordered",
      dataIndex: "kit_total_ordered",
      key: "kit_total_ordered",
    },
    {
      title: "Most Recent Order Placed Date",
      dataIndex: "order_most_recent_placed_date",
      key: "order_most_recent_placed_date",
      render: (val) => formatDateField(val),
    },
    {
      title: "Most Recent Order ID Shipped",
      dataIndex: "order_most_recent_shipped_id",
      key: "order_most_recent_shipped_id",
    },
    {
      title: "Next Order ID to Ship",
      dataIndex: "order_next_order_shipment_id",
      key: "order_next_order_shipment_id",
    },
    {
      title: "Next Order ID to Ship Date",
      dataIndex: "order_next_order_shipment_date",
      key: "order_next_order_shipment_date",
      render: (val) => formatDateField(val),
    },
    {
      title: "Most Recent Order ID Shipped Date",
      dataIndex: "order_most_recent_shipped_date",
      key: "order_most_recent_shipped_date",
      render: (val) => formatDateField(val),
    },
    {
      title: "Farthest Requested Shipping Date",
      dataIndex: "order_farthest_requested_shipping_date",
      key: "order_farthest_requested_shipping_date",
      render: (val) => formatDateField(val),
    },
    {
      title: "Most Recent Order SKU",
      dataIndex: "product_most_recent_order_sku",
      key: "product_most_recent_order_sku",
    },
    {
      title: "Sampling Location Ids in Org",
      dataIndex: "sampling_location_ids_in_org",
      key: "sampling_location_ids_in_org",
      render: (locString) => {
        const arr = locString?.split("|") || [];
        return (
          <>
            {arr.map((id) => (
              <Tag
                color="default"
                style={{
                  width: "43px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <Link to={`/admin/sampling-locations/${id}`}>{id}</Link>
              </Tag>
            ))}
          </>
        );
      },
    },
    {
      title: "Number of Sampling Locations",
      dataIndex: "sampling_location_count",
      key: "sampling_location_count",
      sorter: (a, b) =>
        parseInt(a?.sampling_location_count) -
        parseInt(b?.sampling_location_count),
    },
    {
      title: "Organization ID",
      dataIndex: [],
      key: "display_id",
      sorter: (a, b) => a?.display_id?.localeCompare(b?.display_id),
      render: (record) => (
        <Link to={`/organizations/${record.organization_id}`}>
          {record.display_id}
        </Link>
      ),
    },
  ];

  return (
    <div className="admin-kits-page" style={{ height: "100%" }}>
      <PageHeader title="Kit Status Report (Bizops)" />
      <Row className="ml-3">
        Total Kits: {kitStatusList?.length ?? 0}
        <Button
          onClick={handleDownloadCSV}
          className="ml-1 mb-1"
          type="primary"
          size="small"
          shape="round"
        >
          <DownloadOutlined />
        </Button>
      </Row>

      <Table
        className="admin-kit-status-table ml-3"
        rowKey="id"
        loading={isLoading}
        columns={columns}
        dataSource={kitStatusList}
        scroll={{ x: "min-content" }}
      />
    </div>
  );
};

export default withRouter(KitStatusPage);
