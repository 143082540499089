import { deviceDetect } from "react-device-detect";
import { Row, Col, Typography } from "antd";
const { Text } = Typography;

export default function BrowserWarning() {
  const device = deviceDetect();

  const browserName = device.browserName?.toLowerCase();
  const isIOS = device.os?.toLowerCase().includes("ios");
  const osVersionFirstChar = device.osVersion?.charAt(0);
  const osVersionFirstCharInt = parseInt(osVersionFirstChar);
  const osVersionSecondChar = device.osVersion?.charAt(1);

  const browserIsLegacyIOS =
    isIOS && osVersionFirstCharInt < 10 && osVersionSecondChar === ".";

  if (browserName === "ie" || browserIsLegacyIOS) {
    return (
      <Row className="browser-warning block text-lg p-1">
        <Col>
          <Text strong>
            Please upgrade your current browser or, for best results, download
            the latest version of Chrome.
          </Text>
        </Col>
      </Row>
    );
  }

  return <></>;
}
