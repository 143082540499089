import qs from "qs";
import { useOrganizationList } from "../../api/organization";
import { authorizationHeader, mkQuery, orderServiceRoot } from "../../api/util";

// -- Shipping Locations ------------------------------------------------------
// _start, _limit, _sort, and _where art params that strapi handles by default

const baseKey = { service: "biobot-api", resource: "shipping-location" };

export const useShippingLocationList = mkQuery({
  key: ({ organizationId, _where, _start = 0, _limit = 10, _sort }) => [
    { ...baseKey, organizationId, _where, _start, _sort, _limit },
  ],
  axiosConfig: ({ organizationId, _where, _start, _limit, _sort }) => ({
    method: "get",
    url: `${orderServiceRoot}/shipping-locations`,
    params: {
      organization: organizationId,
      _sort,
      _where,
      _start,
      _limit,
    },
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  }),
});

// Same thing as useShippingLocationList, except we ignore _start, _limit, and
// _sort, and hit the /count endpoint
export const useShippingLocationCount = mkQuery({
  key: ({ organizationId, _where }) => [
    // countOnly: true is here to distinguish this key from
    // useShippingLocationList with only a _where arg
    { ...baseKey, organizationId, _where, countOnly: true },
  ],
  axiosConfig: ({ organizationId, _where }) => ({
    method: "get",
    url: `${orderServiceRoot}/shipping-locations/count`,
    params: {
      organization: organizationId,
      _where,
    },
    headers: authorizationHeader(),
    paramsSerializer: qs.stringify,
  }),
});

// -- Organizations -----------------------------------------------------------

const makeOrgFilter = (data) =>
  data.map((org) => ({
    text: org.organization_name,
    value: org.id,
  }));

// You can use react query's `select` option to transform data after it's
// received -- for instance, to pick just a couple keys off in this case. Using
// a (stable) named function instead of an inline one is just an optimization
// to avoid extra renders.
export const useOrganizationFilter = () =>
  useOrganizationList({}, { select: makeOrgFilter });
