import _ from "lodash";
import { toLocaleString } from "./time";
import { Typography } from "antd";
import { isClose } from "./math";

export const renderPath = (path, className = null) => (__, record) => {
  const value = _.get(record, path);

  return displayString(className)(value);
};

export const displayString = (className = null) => (value) => {
  return (
    <Typography.Text className={className}>
      {value ? value : "--"}
    </Typography.Text>
  );
};

export const displayDate = () => (value) => {
  return value ? toLocaleString(value) : "--";
};

export const displayDecimal = (numDecimalPlaces, className = "numeric-col") => (
  value
) => {
  let result;

  if (_.isNil(value)) {
    result = "--";
  } else if (isClose(value, 0.0, 0.000001)) {
    result = (0.0).toLocaleString("en-US", {
      minimumFractionDigits: numDecimalPlaces,
    });
  } else if (value < 0.01) {
    result = "< 0.01";
  } else {
    result = value.toLocaleString("en-US", {
      minimumFractionDigits: numDecimalPlaces,
    });
  }

  return <Typography.Text className={className}>{result}</Typography.Text>;
};

export const displayInt = (className = "numeric-col") => (value) => {
  if (_.isNil(value)) {
    return <Typography.Text className={className}>--</Typography.Text>;
  } else {
    return <Typography.Text className={className}>{value}</Typography.Text>;
  }
};

export const displayBool = (className = "boolean-col") => (value) => {
  if (_.isNil(value)) {
    return <Typography.Text className={className}>--</Typography.Text>;
  } else if (Math.abs(value) < 0.00001) {
    return <Typography.Text className={className}>No</Typography.Text>;
  } else {
    return <Typography.Text className={className}>Yes</Typography.Text>;
  }
};
