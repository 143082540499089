import { Button, Checkbox, Col, Radio, Row } from "antd";
import React, { useState } from "react";

const MultipleChoiceFilter = ({
  choices,
  toStrapiFilter,

  confirm,
  clearFilters,
  selectedKeys,
  setSelectedKeys,
}) => {
  // ---
  const selectedChoicesValuesInitialState = choices.reduce((state, choice) => {
    return { ...state, [choice.id]: [] };
  }, {});

  const [selectedChoicesValues, setSelectedChoicesValues] = useState(
    selectedChoicesValuesInitialState
  );

  const updateSelectedChoicesValues = (updatedSelectedChoicesValues) => {
    setSelectedChoicesValues({
      ...selectedChoicesValues,
      ...updatedSelectedChoicesValues,
    });
  };

  // ---
  const selectedChoicesInitialState = [];

  const [selectedChoices, setSelectedChoices] = useState(
    selectedChoicesInitialState
  );

  const onSelectedChoicesChange = (checkedChoices) => {
    /**
     *
     *
     * @type {number[]}
     */
    const unselectedChoices = selectedChoices.filter((choiceId) => {
      return !checkedChoices.includes(choiceId);
    });

    /**
     *
     *
     * @type {number[]}
     */
    const newSelectsChoices = checkedChoices.filter((choiceId) => {
      return !selectedChoices.includes(choiceId);
    });

    /**
     *
     *
     * @type {Record<number, string[]>}
     */
    const unselectedChoicesValues = unselectedChoices.reduce(
      (unselected, choiceId) => {
        return { ...unselected, [choiceId]: [] };
      },
      {}
    );

    /**
     *
     *
     * @type {Record<number, string[]>}
     */
    const newSelectsChoicesValues = newSelectsChoices.reduce(
      (newSelects, choiceId) => {
        return {
          ...newSelects,
          [choiceId]: [
            choices.find((choice) => choiceId === choice.id)?.values[0].value,
          ],
        };
      },
      {}
    );

    setSelectedChoices(checkedChoices);

    updateSelectedChoicesValues({
      ...unselectedChoicesValues,
      ...newSelectsChoicesValues,
    });
  };

  const onSelectedChoicesValuesChange = (choice) => (event) => {
    updateSelectedChoicesValues({ [choice.id]: [event.target.value] });
  };

  const onSubmitFilter = () => {
    const selections = Object.entries(selectedChoicesValues).filter(
      ([, choices]) => choices.length
    );

    setSelectedKeys([toStrapiFilter(selections)]);

    confirm();
  };

  const onResetFilter = () => {
    updateSelectedChoicesValues(selectedChoicesValuesInitialState);

    setSelectedChoices([]);

    setSelectedKeys([]);

    clearFilters();

    confirm();
  };

  return (
    <div
      className="p-1"
      style={{
        width: "425px",
        height: "410px",
      }}
    >
      <Checkbox.Group
        style={{
          marginBottom: "5px",
          paddingBottom: "10px",
          width: "100%",
          height: "360px",
          overflow: "scroll",
          borderBottom: "1px solid #e5e5e5",
        }}
        value={selectedChoices}
        onChange={onSelectedChoicesChange}
      >
        {choices.map((choice) => {
          return (
            <Row key={choice.id}>
              <Col span={24}>
                <Checkbox value={choice.id} key={choice.code}>
                  {choice.label}
                </Checkbox>
              </Col>

              <Col span={24} style={{ padding: "5px 25px" }}>
                {selectedChoices.includes(choice.id) ? (
                  <Radio.Group
                    options={choice.values}
                    onChange={onSelectedChoicesValuesChange(choice)}
                    value={selectedChoicesValues?.[choice.id]?.[0] ?? null}
                    size="small"
                    optionType="button"
                    buttonStyle="solid"
                  ></Radio.Group>
                ) : null}
              </Col>
            </Row>
          );
        })}
      </Checkbox.Group>

      <Button
        type="primary"
        size="small"
        disabled={selectedChoices.length === 0}
        onClick={onSubmitFilter}
      >
        OK
      </Button>

      <Button
        type="link"
        size="small"
        disabled={selectedKeys.length === 0}
        onClick={onResetFilter}
      >
        Reset
      </Button>
    </div>
  );
};

export { MultipleChoiceFilter };
