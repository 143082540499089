import { useState } from "react";
import { Input, Button, message, Row, Col, Typography } from "antd";
import { useUserContext } from "context";

export const LoginForm = ({
  username,
  actionUserAuth,
  actionUserTokenGet,
  history,
  setUsername,
  setLoading,
  loading,
}) => {
  const [password, setPassword] = useState();
  const { updateUser } = useUserContext();

  const { Text } = Typography;

  const handleSubmit = () => {
    if (username && password) {
      setLoading(true);
      actionUserAuth({
        username: username,
        password: password,
      })
        .then((res) => actionUserTokenGet(res.payload.data.accessToken))
        .then((res) => {
          const { user, account } = res.payload.data;

          updateUser({
            userName: user.name,
            userEmail: user.email,
            organizationContactEmail: user.email,
            organizationId: account.id,
            organizationName: account.name,
          });
          history.push("/order");
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      message.error("Username or password incorrect");
    }
  };

  return (
    <Col span={24}>
      <Row>
        <Col span={16}>
          <Text>Username</Text>
          <Input
            id="input-email"
            name="email"
            placeholder="Email address"
            onChange={(e) => setUsername(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={16}>
          <Text>Password</Text>
          <Input.Password
            id="input-password"
            name="password"
            onChange={(e) => setPassword(e.target.value.trim())}
            onPressEnter={handleSubmit}
            visibilityToggle={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            id="login-button"
            size="large"
            type="primary"
            onClick={handleSubmit}
          >
            LOGIN
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
