import React from "react";
import _ from "lodash";
import { Button, Col, Row, Typography } from "antd";
import { samplingInfoRows } from "../constants";
import MissingSampleLogInfo from "./MissingSampleLogInfo";
import Signature from "./Signature";
import MetadataDetailTable from "../../../../components/MetadataDetailTable/MetadataDetailTable";

import moment from "moment";

const SampleLogDetails = ({
  kit,
  onSampleLogButtonClicked,
  refetchKitDetails,
}) => {
  let sampleLogInfo;

  const received = kit.date_received ? moment.utc(kit.date_received) : null;

  const checkEditSampleLogIneligibility = () => {
    if (_.isNil(received)) {
      return false;
    }
    return (
      received <
      moment.utc(`${process.env.REACT_APP_EFFIGY_LATEST_AIRTABLE_DATE}`)
    );
  };

  if (kit.sample) {
    sampleLogInfo = (
      <>
        <Row>
          <Col span={6}>
            <Typography.Title level={4}>Sample Log Details</Typography.Title>
          </Col>
        </Row>

        <MetadataDetailTable
          title="Sample Log Details"
          tableCellsArray={samplingInfoRows(
            kit.sample,
            checkEditSampleLogIneligibility(),
            refetchKitDetails
          )}
          tableKey="SampleLog"
        />
        <Signature sample={kit.sample} />
        <Button
          type="primary"
          data-cy="edit-sample-button"
          onClick={() => onSampleLogButtonClicked(kit)}
          disabled={checkEditSampleLogIneligibility()}
          title={
            checkEditSampleLogIneligibility()
              ? "This sample log is ineligible for editing due to project effigy."
              : null
          }
        >
          Edit Sample Log
        </Button>
      </>
    );
  } else {
    sampleLogInfo = (
      <MissingSampleLogInfo
        onLogSampleClick={() => onSampleLogButtonClicked(kit)}
      />
    );
  }

  return (
    <div className="mx-4" style={{ marginBottom: "50px" }}>
      {sampleLogInfo}
    </div>
  );
};

export default SampleLogDetails;
