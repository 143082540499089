import { useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  Tooltip,
  Typography,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import SampleRerunFormFields from "./SampleRerunFormFields";
import SampleRerunBatchFormFields from "./SampleRerunBatchFormFields";
import SingleUploadFormFields from "./SingleUploadFormFields";
import { requiredRule } from "utils/rules";

import "./SingleBatchFormFields.css";
const { Text } = Typography;

const SingleBatchFormFields = ({
  name,
  fieldKey,
  batchFieldIdx,
  assayName,
  onRemove,
  onUpload,
  onRerunSelected,
  acceptTypes,
  ...restBatchField
}) => {
  // Keep track of how many uploads are currently in the batch to hide add/remove buttons
  const [currUploads, setCurrUploads] = useState(1);

  return (
    <Row gutter={8}>
      <Col span={21}>
        <Card className="my-2">
          {/* Batch Name */}
          <Form.Item
            name={[name, "batch_name"]}
            fieldKey={[fieldKey, "batch_name"]}
            label="Batch Name"
            wrapperCol={{ span: 10 }}
            rules={[requiredRule("Batch Name is required")]}
            {...restBatchField}
          >
            <Input placeholder="e.g. Plate 893A PMMV or Plate893A_PMMV_Cy0" />
          </Form.Item>
          {/* Rerun List */}
          <Row>
            <Text className="mb-1">
              Rerun Samples
              <Tooltip
                title={
                  "Exclude this sample from analysis and create a LAB_RERUN rework request for it."
                }
              >
                <QuestionCircleOutlined className="pl-1" />
              </Tooltip>
            </Text>
          </Row>
          <Form.List name={[name, "rerun_samples"]}>
            {(
              rerunFields,
              { add: addRerun, remove: removeRerun },
              { errors }
            ) => (
              <>
                {rerunFields.map(
                  (
                    {
                      key: rerunKey,
                      name: rerunName,
                      fieldKey: rerunFieldKey,
                      ...restUploadField
                    },
                    rerunFieldIdx
                  ) => (
                    <Space key={rerunKey} style={{ minWidth: "100%" }}>
                      <SampleRerunFormFields
                        name={rerunName}
                        fieldKey={rerunFieldKey}
                        batchFieldIdx={batchFieldIdx}
                        rerunFieldIdx={rerunFieldIdx}
                        onRemove={() => removeRerun(rerunName)}
                        onRerunSelected={onRerunSelected}
                        {...restUploadField}
                      />
                    </Space>
                  )
                )}
                <Form.ErrorList errors={errors} />
                <Button
                  className="mb-4"
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => addRerun()}
                >
                  Add Rerun Sample
                </Button>
              </>
            )}
          </Form.List>
          <Row>
            <Text className="mb-1">
              Batch Reruns
              <Tooltip
                title={
                  <>
                    Exclude many samples from analysis, do not create rework
                    requests - for blank failures, etc.
                    <br />
                    Accepts comma or newline delimited lists of Sample IDs
                  </>
                }
              >
                <QuestionCircleOutlined className="pl-1" />
              </Tooltip>
            </Text>
          </Row>
          <Row>
            <Form.List name={[name, "rerun_batches"]}>
              {(
                rerunBatchFields,
                { add: addRerunBatch, remove: removeRerunBatch },
                { errors }
              ) => (
                <>
                  {rerunBatchFields.map(
                    ({
                      key: rerunBatchKey,
                      name: rerunBatchName,
                      fieldKey: rerunBatchFieldKey,
                      ...restRerunBatchField
                    }) => (
                      <Space key={rerunBatchKey} style={{ minWidth: "100%" }}>
                        <SampleRerunBatchFormFields
                          name={rerunBatchName}
                          fieldKey={rerunBatchFieldKey}
                          onRemove={() => removeRerunBatch(rerunBatchName)}
                          {...restRerunBatchField}
                        />
                      </Space>
                    )
                  )}
                  <Form.ErrorList errors={errors} />
                  <Button
                    className="mb-4"
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={() => addRerunBatch()}
                  >
                    Add Rerun Batch
                  </Button>
                </>
              )}
            </Form.List>
          </Row>
          {/* Upload List */}
          <Form.List
            name={[batchFieldIdx, "uploads"]}
            initialValue={[{ plate_name: "targets,controls" }]}
          >
            {(
              uploadFields,
              { add: addUpload, remove: removeUpload },
              { errors }
            ) => (
              <>
                {uploadFields.map(
                  ({
                    key: uploadKey,
                    name: uploadName,
                    fieldKey: uploadFieldKey,
                    ...restUploadField
                  }) => (
                    <Space key={uploadKey} style={{ minWidth: "100%" }}>
                      <SingleUploadFormFields
                        name={uploadName}
                        fieldKey={uploadFieldKey}
                        batchFieldIdx={batchFieldIdx}
                        acceptTypes={acceptTypes}
                        onUpload={onUpload}
                        onRemove={() => {
                          setCurrUploads(currUploads - 1);
                          removeUpload(uploadName);
                        }}
                        {...restUploadField}
                      />
                    </Space>
                  )
                )}
                <Form.ErrorList errors={errors} />
                {currUploads < 2 && (
                  <Row>
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setCurrUploads(currUploads + 1);
                        addUpload();
                      }}
                    >
                      Add Plate
                    </Button>
                  </Row>
                )}
              </>
            )}
          </Form.List>
        </Card>
      </Col>
      <Col className="flex items-center" span={3}>
        <Button
          icon={<MinusCircleOutlined />}
          type="text"
          danger
          onClick={onRemove}
        >
          Remove Batch
        </Button>
      </Col>
    </Row>
  );
};

export default SingleBatchFormFields;
