import { React, useState } from "react";
import { Typography } from "antd";
import Text from "antd/lib/typography/Text";

import MetadataDetailTable from "components/MetadataDetailTable/MetadataDetailTable";
import { toLocaleString } from "utils/time";
import ProductTag from "components/ProductTag";
import SelectionStatusTag from "components/SelectionStatusTag";
import KitNotes from "components/KitNotes";
import KitAssays from "components/KitAssays";

import EditableDateReceivedCell from "./EditableDateReceivedCell";

const KitDetails = ({ kit }) => {
  const [kitNotes, setKitNotes] = useState(kit.kit_notes || "");
  const handleNotesUpdate = (newNotes) => setKitNotes(newNotes);

  const order = kit.order ?? kit.orders?.[0];
  const outboundTrackingNumbers =
    kit.outbound_tracking_number?.split(",") ?? [];
  const outboundPickUpDates = kit.outbound_pickup_date?.split(",") ?? [];
  const outboundDeliveryDates =
    kit.outbound_est_delivery_date?.split(",") ?? [];
  const outboundTrackingStatuses =
    kit.outbound_tracking_status?.split(",") ?? [];

  const tableCells = [
    [
      {
        title: "Kit Id",
        value: kit.kit_id,
      },
      {
        title: "Shipping Kit Id",
        value: kit.shipping_kit_id,
      },
      {
        title: "Date Created",
        value: toLocaleString(kit.created_at),
      },
    ],
    [
      {
        title: "Order Number",
        value: order?.shipping_order_id,
      },
      {
        title: "Kit Provider",
        value: order.fulfillment_provider?.vendor_name,
      },
      {
        title: "Kit Configuration",
        value: kit.configuration?.name,
      },
    ],
    [
      {
        title: "Requested Shipping Date",
        value: toLocaleString(order?.requested_shipping_date),
      },
      {
        title: "Shipping Speed",
        value: order?.shipping_speed,
      },
      {
        title: "Shipping Location",
        value: `[${order?.shipping_location.id}] - ${order?.shipping_location.name}`,
      },
    ],
    [
      {
        title: "Outbound Tracking Number",
        value: (
          <div className="flex-col">
            {outboundTrackingNumbers.map((trackingNumber) => (
              <a
                key={`tracking-number-${trackingNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}
              >
                FX{trackingNumber}
              </a>
            ))}
          </div>
        ),
      },
      {
        title: "Outbound Pickup Date",
        value: !outboundPickUpDates.length ? null : (
          <div className="flex-col">
            {outboundPickUpDates.map((pickupDate, i) => (
              <Text key={`pickup-date-${i}`}>{toLocaleString(pickupDate)}</Text>
            ))}
          </div>
        ),
      },
      {
        title: "Outbound Estimated Delivery Date",
        value: !outboundDeliveryDates.length ? null : (
          <div className="flex-col">
            {outboundDeliveryDates.map((date, i) => (
              <Text key={`delivery-date-${i}`}>{toLocaleString(date)}</Text>
            ))}
          </div>
        ),
      },
      {
        title: "Outbound Tracking Status",
        value: !outboundTrackingStatuses.length ? null : (
          <div className="flex-col">
            {outboundTrackingStatuses.map((status, i) => (
              <Text key={`tracking-status-${i}`}>{status}</Text>
            ))}
          </div>
        ),
      },
    ],
    [
      {
        title: "Inbound Tracking Number",
        value: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.fedex.com/fedextrack/?trknbr=${kit.inbound_tracking_number}`}
          >
            FX{kit.inbound_tracking_number}
          </a>
        ),
      },
      {
        title: "Inbound Pickup Date",
        value: toLocaleString(kit.inbound_pickup_date),
      },
      {
        title: "Inbound Estimated Delivery Date",
        value: toLocaleString(kit.inbound_est_delivery_date),
      },
      {
        title: "Inbound Tracking Status",
        value: kit.inbound_tracking_status,
      },
    ],
    [
      {
        title: "Date Lab Received",
        value: <EditableDateReceivedCell kit={kit} />,
        span: 22,
      },
      {
        title: "Program Selections",
        value:
          !kit.selection_statuses || !kit.selection_statuses.length ? null : (
            <div className="flex-col">
              {kit.selection_statuses?.map((x) => (
                <SelectionStatusTag
                  key={`selection-status-${x.id}`}
                  status={x.selection_status}
                  reason={x.reason}
                  displayName={x.program?.name}
                  shortDisplayName={x.program?.short_name}
                />
              ))}
            </div>
          ),
      },
      {
        title: "SKUs",
        value:
          !kit.products || !kit.products.length ? null : (
            <div className="flex-col">
              {kit.products?.map((x) => (
                <ProductTag
                  key={`sku-${x.id}`}
                  sku={x.product_sku}
                  productName={x.product_name}
                />
              ))}
            </div>
          ),
      },
      {
        title: "Assays",
        value: <KitAssays products={kit.products} />,
      },
    ],
    [
      {
        title: "Notes",
        value: (
          <>
            <Text>{kitNotes}</Text>
            <br />
            <KitNotes
              kit={kit}
              onSave={handleNotesUpdate}
              defaultValue={kitNotes}
            />
          </>
        ),
      },
    ],
  ];

  return (
    <div className="mx-4" style={{ marginBottom: "50px" }}>
      <Typography.Title level={4}>Kit Details</Typography.Title>
      <MetadataDetailTable
        title="Kit Details"
        tableCellsArray={tableCells}
        tableKey="Kit"
      />
    </div>
  );
};

export default KitDetails;
