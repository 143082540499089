import { Row, Col, Form, Input, Typography, Checkbox, Select } from "antd";
import { isNil } from "lodash";

import AddressQuestions from "./AddressQuestions";
import { PROGRAM_CDC_CODE } from "configs/constants";
import { COUNTRIES, PHONE_NUMBER_REGEX_BY_COUNTRY } from "configs/constants";
import { useSignupContext } from "context/SignUpContext";
import { fixtures } from "../../fixtures";

const { Title } = Typography;

export default function OrganizationQuestions({ form, organization }) {
  const initialAddressValues = {
    country: organization?.country,
    addressLine1: organization?.address_line_1,
    addressLine2: organization?.address_line_2,
    city: organization?.city,
    state: organization?.province,
    zipCode: organization?.postal_code,
  };
  const { variant } = useSignupContext();
  const formFixtures = fixtures[variant];
  const showTribalNationSection = formFixtures.program === PROGRAM_CDC_CODE;

  return (
    <Col className="mb-4">
      <Row>
        <Col>
          <Title level={4}>Organization Name</Title>
        </Col>
      </Row>

      <Row gutter={40} align="bottom">
        <Col lg={8} md={12} sm={12} xs={24}>
          <Form.Item
            label="Organization"
            name="organization_name"
            initialValue={organization?.organization_name}
            rules={[
              {
                required: true,
                message: "Enter organization name",
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder="Organization name"
              disabled={!isNil(organization)}
            />
          </Form.Item>
        </Col>
        {showTribalNationSection && (
          <Col lg={8} md={12} sm={12} xs={24}>
            <Form.Item
              name="recognized_tribal_indian_territory"
              valuePropName="checked"
              initialValue={organization?.recognized_tribal_indian_territory}
            >
              <Checkbox>
                This organization is part of a federally-recognized tribal
                nation
              </Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row>
        <Col>
          <Title level={4}>Organization Country</Title>
        </Col>
      </Row>

      <Row>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Country"
            name={"country"}
            data-cy="country"
            initialValue={initialAddressValues?.country ?? "USA"}
            rules={[
              {
                required: true,
                message: "You must select a country",
                whitespace: true,
              },
            ]}
          >
            <Select options={Object.values(COUNTRIES)} placeholder="Country" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <Title level={4}>Organization Primary Contact</Title>
        </Col>
      </Row>

      <Row>
        <Col sm={8} xs={24}>
          <Form.Item
            label="Full Name"
            name="full_name"
            data-cy="primary-contact"
            rules={[
              {
                required: true,
                message: "Enter primary contact's full name",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Primary Contact Name" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={40}>
        <Col sm={8} xs={24}>
          <Form.Item
            label="Email Address"
            name="email"
            data-cy="email"
            validateTrigger="onBlur"
            normalize={(value) => value.trim()}
            rules={[
              {
                required: true,
                type: "email",
                message: "Enter email",
              },
            ]}
          >
            <Input placeholder="Email address" />
          </Form.Item>
        </Col>

        <Col sm={8} xs={24}>
          <Form.Item
            label="Job Title"
            name="job_title"
            data-cy="job_title"
            rules={[
              {
                required: true,
                message: "Enter job title",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Job title" />
          </Form.Item>
        </Col>

        <Col sm={8} xs={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues["country"] !== currentValues["country"]
            }
          >
            {({ getFieldValue }) => {
              const selectedCountry = getFieldValue("country");
              const pattern = new RegExp(
                PHONE_NUMBER_REGEX_BY_COUNTRY[selectedCountry] ??
                  PHONE_NUMBER_REGEX_BY_COUNTRY.default
              );

              return (
                <Form.Item
                  label="Phone Number"
                  name="phone_number"
                  data-cy="phone_number"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm the phone number is valid",
                      pattern,
                    },
                  ]}
                >
                  <Input placeholder="Phone number" />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <Title level={4}>Organization Address</Title>
        </Col>
      </Row>

      <AddressQuestions
        required={true}
        index={"organization_address"}
        namePath={[]}
        initialValues={initialAddressValues}
        showNameField={false}
      />
    </Col>
  );
}
