import { useDrag, useDrop } from "react-dnd";

const acceptType = "COLUMN_DRAG_DROP";

export const ColumnDrag = ({ children, idx, dragStyle }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: acceptType,
      item: { idx },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [idx]
  );
  return (
    <div
      ref={drag}
      style={{ cursor: "move", ...(isDragging ? dragStyle : {}) }}
    >
      {children}
    </div>
  );
};

export const ColumnDrop = ({ children, idx, onDrop, dropStyle }) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: acceptType,
      drop: (from) => onDrop({ from: from.idx, to: idx }),
      canDrop: (from) => from.idx !== idx,
      collect: (monitor) => ({
        isOver: !!monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [idx, onDrop]
  );
  return (
    <div ref={drop} style={isOver ? dropStyle : null}>
      {children}
    </div>
  );
};
