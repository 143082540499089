import _ from "lodash";
import { datasetListQuery } from "./datasetService";

const model = "counties";

export const useCountyList = datasetListQuery({ model });

const selectNameMap = (data) =>
  _.mapValues(_.keyBy(data, "county_id"), "county_name");

export const useCountyNameMap = () =>
  useCountyList({}, { select: selectNameMap });
