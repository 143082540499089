import _ from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";

import {
  useBulkCreateVarseqUpload,
  useVarseqUploadSchemaVersionList,
  VARSEQ_UPLOAD_ASSAY_OPTIONS,
} from "api/fileUpload";
import { COVID_VARIANTS_ASSAY } from "configs/constants";
import { VersionSelector, UploadResultTable } from "utils/components";
import SingleUploadFormFields from "./SingleUploadFormFields";
import Loader from "../../../components/Loader/Loader";
import { requiredRule } from "../../../utils/rules";

const VarseqUploadFormPage = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResults, setUploadResults] = useState();
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
  const { mutateAsync: bulkCreateVarseqUploads } = useBulkCreateVarseqUpload();
  const {
    data: uploadSchemaVersions,
    isLoading: isSchemaLoading,
  } = useVarseqUploadSchemaVersionList();

  if (_.isNil(isSchemaLoading) || isSchemaLoading) {
    return <Loader />;
  }

  const handleFormSubmit = async (formValue) => {
    setIsLoading(true);
    message.loading("Uploading Varseq sample sheet...", 0);

    const formWithVendor = {
      ...formValue,
      vendor_name: "gtm",
    };
    const results = await bulkCreateVarseqUploads(formWithVendor);
    setUploadResults(results);
    message.destroy();
    setIsResultsModalOpen(true);
    setIsLoading(false);
  };

  const handleFileUpload = async (file, fieldPath) => {
    const fieldVal = form.getFieldValue(fieldPath) || {};
    fieldVal["uploaded_file"] = file;
    form.setFieldValue(fieldPath, fieldVal);
  };

  const handleModalAction = () => {
    setIsResultsModalOpen(false);
    setUploadResults(false);
  };

  return (
    <>
      <PageHeader title="Varseq Upload Submission" />
      <Form
        name="varseq-upload"
        layout="vertical"
        autoComplete="off"
        className="px-3 py-4"
        form={form}
        onFinish={handleFormSubmit}
      >
        {/* Submitted By */}
        <Row gutter={20}>
          <Col span={10}>
            <Form.Item
              name="form_submitted_by"
              label="Submitted By"
              rules={[requiredRule("Form submitted by is required")]}
              required
            >
              <Input placeholder="Initials" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          {/* Sequencing Ran By */}
          <Col span={8}>
            <Form.Item
              name="seq_ran_by"
              label="Person who ran sequencing"
              rules={[requiredRule("Person who ran sequencing is required")]}
              required
            >
              <Input placeholder="Initials" />
            </Form.Item>
          </Col>

          {/* Date of Seq */}
          <Col span={8}>
            <Form.Item
              name="date_of_seq"
              label="Date of Sequencing"
              rules={[requiredRule("Date of Sequencing is required")]}
              required
            >
              <DatePicker
                className="w-100"
                format={(date) => date.utc().format()}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="assay_name"
              label="Assay Name"
              rules={[requiredRule("Assay Name is required")]}
              required
              initialValue={COVID_VARIANTS_ASSAY}
            >
              <Select options={VARSEQ_UPLOAD_ASSAY_OPTIONS} />
            </Form.Item>
          </Col>
          {/* Upload Schema Version */}
          <Col span={8}>
            <VersionSelector
              name="upload_schema_version"
              label="What format is the sample sheet CSV in?"
              versionNumberPrefix="v"
              versions={uploadSchemaVersions}
              required={true}
            />
          </Col>
        </Row>

        {/* Upload List */}
        <Form.List name={"uploads"} initialValue={[{}]}>
          {(fields, { add: addUpload, remove: removeUpload }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restUploadField }) => (
                <>
                  <Divider plain />
                  <SingleUploadFormFields
                    key={key}
                    uploadFieldIdx={name}
                    onUpload={handleFileUpload}
                    onRemove={() => removeUpload(name)}
                    {...restUploadField}
                  />
                </>
              ))}
              <Form.ErrorList errors={errors} />
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => addUpload()}
              >
                Add Upload
              </Button>
            </>
          )}
        </Form.List>

        <Divider className="mt-5" />
        <Space align="center" className="flex justify-center">
          <Popconfirm
            title="Reset Form?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => form.resetFields()}
          >
            <Button disabled={isLoading}>Reset</Button>
          </Popconfirm>
          <Form.Item className="mb-0">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <Modal
        title="Varseq Upload Results"
        open={isResultsModalOpen}
        onOk={handleModalAction}
        onCancel={handleModalAction}
      >
        <UploadResultTable uploadResults={uploadResults} />
      </Modal>
    </>
  );
};

export default VarseqUploadFormPage;
