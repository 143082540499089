import "./SampleCollectionDateTime.css";

import { Button, Row, Col, Form, DatePicker, Typography } from "antd";

import { handleFormFieldChanges } from "../helpers";

import moment from "moment";
import { timeZone } from "utils/time";
import _ from "lodash";

// import { useState } from "react";

// import { create as createHTTPClient } from "axios";
// import qs from "qs";

// import { authorizationHeader, orderServiceRoot } from "api/util";

import { customRule } from "utils/rules";

// const api = createHTTPClient({
//   baseURL: orderServiceRoot,
//   headers: {
//     ...authorizationHeader(),
//   },
//   paramsSerializer: qs.stringify,
// });

export const SampleCollectionDateTime = ({
  formRef,
  method,
  setSubmitDisabled,
  collectionDatetimeWarning,
  setCollectionDatetimeWarning,
  startDatetimeWarning,
  setStartDatetimeWarning,
  isRequired,
  isInternalUser,
  //site,
  sample,
  kitShippingDate,
}) => {
  const { Title, Text } = Typography;

  // const [
  //   submitWithValidationWarnings,
  //   setSubmitWithValidationWarnings,
  // ] = useState(false);

  const ruleSamplingTimeConstraints = customRule((_, fields) => {
    if (fields.sampling_type.includes("grab sample")) {
      return true;
    }

    const samplingStartDateTime = fields?.sampling_start_date;
    const samplingCollectionDateTime = fields?.sampling_collection_date;

    const hoursBetween = moment(samplingCollectionDateTime).diff(
      moment(samplingStartDateTime),
      "hours"
    );

    return hoursBetween >= 1 && hoursBetween <= 48;
  }, "Sampling period must be between 1 and 48 hours. Please check your start and collection times.");

  // const ruleDuplicateSamplingLogValidation = customRule(async (_, fields) => {
  //   if (submitWithValidationWarnings) {
  //     return true;
  //   }

  //   const samplingCollectionDate = fields.sampling_collection_date;

  //   const _where = {
  //     sampling_location_id: site?.id,
  //     sample_collection_date_time_gte: moment(samplingCollectionDate)
  //       .startOf("day")
  //       .format("YYYY-MM-DD HH:mm:ss.SSS"),
  //     sample_collection_date_time_lte: moment(samplingCollectionDate)
  //       .endOf("day")
  //       .format("YYYY-MM-DD HH:mm:ss.SSS"),
  //   };

  //   const sample = await api.get("/samples", { params: { _limit: 1, _where } });
  //   console.log(sample);
  //   return !Boolean(sample.data.length);
  // }, "A sample log has already been submitted for this site on this date. Please check your collection date and time.");

  const ruleSampleStartDateConstraint = customRule((_, fields) => {
    const date_shipped = new Date(kitShippingDate);
    const selected_date = new Date(fields.sampling_collection_date);

    return selected_date >= date_shipped;
  }, "A sample log's collection date cannot be before the kit's shipping date. Please check your collection date and time.");

  const getCollectionDatetimeWarningText = (warning) => {
    return warning === "duplicate"
      ? "Date and time of collection is the same as the date and time of start. Did you mean to log a grab sample?"
      : warning === "warning"
      ? "This time is either far in the past or in the future. Are you sure this is correct?"
      : warning === "duration"
      ? "Collection period is more than 24 hours. Are you sure this is correct?"
      : "Something went wrong validating your input, please reset and try again.";
  };

  const getAllCollectionRangeWarnings = () => {
    if (startDatetimeWarning || collectionDatetimeWarning) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }

    const startWarningText = getCollectionDatetimeWarningText(
      startDatetimeWarning
    );
    const collectionWarningText = getCollectionDatetimeWarningText(
      collectionDatetimeWarning
    );

    return (
      <>
        {startDatetimeWarning && (
          <span>Start Date & Time: {startWarningText}</span>
        )}
        {startDatetimeWarning && collectionDatetimeWarning && <br />}
        {collectionDatetimeWarning && (
          <span>Collection Date & Time: {collectionWarningText}</span>
        )}
      </>
    );
  };

  const handleStartDatetime = (value) => {
    const fieldsToChange = { sampling_start_date: value };

    if (method.includes("composite")) {
      if (!_.isNil(value)) {
        const dateValue = new Date(value);
        const autoCollectionDatetime = new Date(
          dateValue.getTime() + 24 * 60 * 60 * 1000
        ); // Add 24 hours (in milliseconds) to the selected date
        fieldsToChange["sampling_collection_date"] = moment(
          autoCollectionDatetime
        );
      } else {
        fieldsToChange["sampling_collection_date"] = null;
      }
    } else {
      fieldsToChange[
        "sampling_collection_date"
      ] = formRef.current.getFieldValue("sampling_collection_date");
    }

    formRef.current.setFieldsValue(fieldsToChange);

    handleFormFieldChanges({
      formRef: formRef,
      setShowSampleStartDatetimeWarning: setStartDatetimeWarning,
      setShowSampleCollectionDatetimeWarning: setCollectionDatetimeWarning,
      changedFields: [
        {
          name: "sampling_start_date",
          value: value,
        },
        {
          name: "sampling_collection_date",
          value: fieldsToChange["sampling_collection_date"],
        },
      ],
    });
  };

  const handleCollectionDatetime = (value) => {
    const fieldsToChange = { sampling_collection_date: value };

    formRef.current.setFieldsValue(fieldsToChange);

    handleFormFieldChanges({
      formRef: formRef,
      setShowSampleStartDatetimeWarning: setStartDatetimeWarning,
      setShowSampleCollectionDatetimeWarning: setCollectionDatetimeWarning,
      changedFields: [
        {
          name: "sampling_collection_date",
          value: value,
        },
      ],
    });
  };

  const handleCollectionTimeWarningConfirm = () => {
    setStartDatetimeWarning(false);
    setCollectionDatetimeWarning(false);
    setSubmitDisabled(false);
    // setSubmitWithValidationWarnings(true);
  };

  const handleCollectionTimeWarningReset = () => {
    formRef.current.setFieldsValue({
      sampling_start_date: null,
      sampling_collection_date: null,
    });
    handleCollectionTimeWarningConfirm();
    // setSubmitWithValidationWarnings(false);
  };

  return (
    <Row className="mb-5" gutter={48}>
      <Col span={24}>
        <Title level={4}>Sampling time</Title>
      </Col>

      {isInternalUser && (
        <Col span={24}>
          <p>
            <i>
              Times shown are in{" "}
              {(sample?.time_zone ? sample.time_zone : timeZone).replace(
                /_/g,
                " "
              )}
            </i>
          </p>
        </Col>
      )}
      {method.includes("composite") && (
        <Col span={24} className="mb-3">
          <Text>
            For convenience, after you select the start date and time of a
            composite sampling method we default to 24 hours later for the
            collection date and time. If this is not accurate, you may change
            the collection date and time accordingly.
          </Text>
        </Col>
      )}

      {method !== "grab sample" && (
        <Col xs={24} sm={8}>
          <Form.Item
            name="sampling_start_date"
            label={"Sampling Start Date & Time"}
            rules={[
              {
                required: isRequired,
                message: "Enter sampling start datetime",
              },
            ]}
            hasFeedback
            validateStatus={startDatetimeWarning}
            className="block"
          >
            <DatePicker
              disabledDate={(selectedDate) => {
                if (
                  formRef.current?.getFieldValue("sampling_collection_date")
                ) {
                  return (
                    selectedDate >
                    moment(
                      formRef.current.getFieldValue("sampling_collection_date")
                    )
                  );
                } else {
                  return !moment();
                }
              }}
              data-cy="sampling-start-date-picker"
              className="w-100"
              format="YYYY-MM-DD hh:mm a"
              use12Hours="true"
              onChange={(value) => handleStartDatetime(value)}
              onSelect={(value) => handleStartDatetime(value)}
              defaultValue={null}
              showTime="true"
            />
          </Form.Item>
        </Col>
      )}

      <Col xs={24} sm={8}>
        <Form.Item
          name="sampling_collection_date"
          label={"Sampling Collection Date & Time"}
          rules={[
            {
              required: isRequired,
              message: "Enter sampling collection datetime",
            },
            ruleSamplingTimeConstraints,
            //ruleDuplicateSamplingLogValidation,
            ruleSampleStartDateConstraint,
          ]}
          hasFeedback
          validateStatus={collectionDatetimeWarning}
          className="block"
        >
          <DatePicker
            disabledDate={(selectedDate) => {
              if (formRef.current?.getFieldValue("sampling_start_date")) {
                return (
                  selectedDate <
                  moment(formRef.current.getFieldValue("sampling_start_date"))
                );
              } else {
                return !moment();
              }
            }}
            data-cy="sampling-collection-date-picker"
            className="w-100"
            format="YYYY-MM-DD hh:mm a"
            use12Hours="true"
            onChange={(value) => handleCollectionDatetime(value)}
            onSelect={(value) => handleCollectionDatetime(value)}
            defaultValue={null}
            showTime="true"
          />
        </Form.Item>
      </Col>

      {(collectionDatetimeWarning || startDatetimeWarning) && (
        <>
          <Col sm={8}></Col>
          <Col xs={24} sm={16}>
            <Form.Item
              name="handle_collection_warnings"
              label="Our system noticed a potential error. Can you confirm that you entered this field correctly?"
              extra={getAllCollectionRangeWarnings()}
            >
              <Button
                id="sample-form_handle_collection_warnings-confirm"
                type="primary"
                onClick={handleCollectionTimeWarningConfirm}
                className="mr-5"
              >
                Confirm
              </Button>
              <Button type="default" onClick={handleCollectionTimeWarningReset}>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
};

export default SampleCollectionDateTime;
