import axios from "axios";
import _ from "lodash";
import { useQuery } from "react-query";

const microservicesRoot = process.env.REACT_APP_API.replace(/\/$/, "");
export const orderServiceRoot = process.env.REACT_APP_API_URL.replace(
  /\/$/,
  ""
);
export const pipelineAPIRoot = process.env.REACT_APP_PIPELINE_API_URL.replace(
  /\/$/,
  ""
);
export const datasetServiceRoot = `${process.env.REACT_APP_DATASETS_API_URL}/datasets/master_data`;
export const identityRoot = `${microservicesRoot}/identity`;

export const getToken = () =>
  localStorage.getItem("token") || sessionStorage.getItem("publicToken");

export const setPublicToken = (token) => {
  sessionStorage.setItem("publicToken", token);
};

export const authorizationHeader = () => ({
  Authorization: `Bearer ${getToken()}`,
});

/**
 * Creates a function for selecting a query result as an antd array of options,
 * for use as e.g. a Table column filter, or a Select `options` prop.
 *
 * @param {Record<string, string | function>} template - an object describing
 *     the shape of the return object. Keys are the keys of the return object,
 *     values are strings (to pick the value with that name) or functions (for
 *     custom computation). You _must_ provide at least `value` and either
 *     `text` or `label`.
 * @param {object} [options]
 * @param {string | function} options.sortBy - key used to sort the return
 * @returns {(data: object[]) => object[]}
 */
export const optionsTemplate = (
  { value, text, label, ...template_ },
  { sortBy = "label" } = {}
) => {
  const template = {
    ...template_,
    value,
    text: text ?? label,
    label: label ?? text,
  };
  const templateFns = _.mapValues(template, (val) =>
    typeof val === "string" ? (data) => data[val] : val
  );
  const mapFn = (data) => _.mapValues(templateFns, (f) => f(data));
  return (data) => _.sortBy(data.map(mapFn), sortBy);
};

const mkQueryFn = (query, axiosConfig) => {
  if (query) {
    return query;
  }
  if (axiosConfig) {
    return async (...args) => {
      const res = await axios(axiosConfig(...args));
      return res.data;
    };
  }
  throw new Error("Either 'query' or 'axiosConfig' is required");
};

/**
 * Factory function for queries. Returns a custom `useQuery` hook.
 *
 * @param query -- function taking an optional arguments object and returning a
 *                 promise that runs the query.
 * @param axiosConfig -- instead of `query`, function taking the same arguments
 *                       object and returning a config object that will be
 *                       passed to `axios` to run the query.
 * @param key -- function taking an optional arguments object and returning a
 *               queryKey (which must be an array)
 * @param options -- additional options passed to `useQuery`.
 *
 * @returns {useCustomQuery} useQuery hook
 *
 * @example
 * // creating the hook
 * const myQuery = mkQuery({
 *   key: ({ someId }) => [{ service: 'my-service', resource: 'my-query', someId }],
 *   query: async ({ someId }) => {
 *     const { data } = await axios({
 *       method: "get",
 *       url `https://my-service.com/my-query/${encodeURIComponent(someId)}`,
 *     });
 *     return data;
 *   },
 *   // `axiosConfig` can be used instead of `query` in most cases. This
 *   // `axiosConfig` produces an identical result as the above `query`.
 *   axiosConfig: ({ someId }) => ({
 *     method: "get",
 *     url `https://my-service.com/my-query/${encodeURIComponent(someId)}`,
 *   }),
 * });
 *
 * // useQuery hook (declarative, prefer this)
 * const { data, isLoading } myQuery.useQuery({ someId: 10 });
 *
 * // useImperativeQuery hook (imperative, use if necessary)
 * import useImperativeQuery from "./useImperativeQuery";
 * const fetchMyQuery = useImperativeQuery(myQuery.query);
 * <Button onClick={() => fetchMyQuery({ someId: 10 })}>Fetch</Button>
 */
export const mkQuery = ({ key, query, axiosConfig, options = {} }) => {
  const baseOpts = options;
  const queryFn = mkQueryFn(query, axiosConfig);
  const queryArgs = (keyArgs, opts) => ({
    queryKey: key(keyArgs),
    queryFn: ({ queryKey }) => queryFn(queryKey[0]),
    ...baseOpts,
    ...opts,
  });

  /**
   * @callback useCustomQuery
   * @param {object} queryArgs - arguments to the custom query hook
   * @param {object} options - react-query options
   *
   * @property {function} keys - keys factory
   * @property {function} query - query config factory (use with `useQueries`
   *                              or `useImperativeQuery`)
   */
  const useCustomQuery = (...args) => useQuery(queryArgs(...args));
  // function to build a react-query config object, e.g. for useQueries, or
  // useImperativeQuery.
  useCustomQuery.query = queryArgs;
  // function to compute a key for this query (used mostly for invalidations)
  useCustomQuery.key = key;
  return useCustomQuery;
};

export const defaultOptionsForStaticModels = {
  staleTime: 86400000,
  cacheTime: 86400000,
};
