import { Row, Col, Typography, Form, Checkbox } from "antd";
import { checkedValidator } from "../../helpers";
import { fixtures } from "../../fixtures";
import { useSignupContext } from "context/SignUpContext";

const { Title, Text } = Typography;

export default function DataSharingAgreements() {
  const { variant } = useSignupContext();

  const formFixtures = fixtures[variant];
  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>Data Sharing</Title>
        </Col>
      </Row>

      {formFixtures.dataSharingAgreements.map((field) => (
        <Form.Item
          name={field.itemName}
          valuePropName="checked"
          noStyle
          rules={[{ required: true, validator: checkedValidator }]}
          key={field.itemName}
        >
          <Row className="mb-2">
            <Col>
              <Checkbox>
                <Text>{field.text}</Text>
              </Checkbox>
            </Col>
          </Row>
        </Form.Item>
      ))}
    </>
  );
}
