import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Row } from "antd";
import { useEffect, useRef } from "react";

// This is more or less based on the example at
// https://github.com/ant-design/ant-design/blob/1cd87b4a6a2cb9e8252a0d9c15e00a725ba37a0e/components/table/demo/custom-filter-panel.md
// ... but borrowing most of the component code from the default filter:
// * Search input: https://github.com/ant-design/ant-design/blob/1df2ecd66e25a43286ca2da810c7c5e7671429fb/components/table/hooks/useFilter/FilterSearch.tsx#L26-L34
// * Buttons: https://github.com/ant-design/ant-design/blob/1df2ecd66e25a43286ca2da810c7c5e7671429fb/components/table/hooks/useFilter/FilterDropdown.tsx#L401-L413

const buttonRowStyle = {
  padding: "8px",
  borderTop: "1px solid #f0f0f0",
};

const iconStyle = {
  filtered: { color: "#1890ff" },
  input: { color: "rgba(0, 0, 0, 0.25)" },
};

const SearchIcon = ({ variant }) => (
  <SearchOutlined style={iconStyle[variant]} />
);

export const SearchFilter = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  placeholder,
  visible,
}) => {
  const inputRef = useRef();
  useEffect(() => {
    if (visible) {
      requestAnimationFrame(() => inputRef.current.select());
    }
  }, [visible]);
  const handleSubmit = () => {
    setSelectedKeys(selectedKeys.map((s) => s.trim()).filter(Boolean));
    confirm();
  };
  return (
    <>
      <div className="p-1">
        <Input
          ref={inputRef}
          prefix={<SearchIcon variant="input" />}
          placeholder={placeholder || "Search"}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={handleSubmit}
          value={selectedKeys[0]}
          style={{ minWidth: "15rem" }}
        />
      </div>
      <Row justify="space-between" style={buttonRowStyle}>
        <Button
          type="link"
          size="small"
          disabled={selectedKeys.length === 0}
          onClick={clearFilters}
        >
          Reset
        </Button>
        <Button type="primary" size="small" onClick={handleSubmit}>
          OK
        </Button>
      </Row>
    </>
  );
};

export const columnSearchProps = ({ placeholder }) => ({
  filterIcon: (filtered) => <SearchIcon variant={filtered && "filtered"} />,
  filterDropdown: (props) => (
    <SearchFilter {...props} placeholder={placeholder} />
  ),
});
