const PageError = () => {
  return (
    <div className="pl-5" style={{ textAlign: "center", marginTop: "2rem" }}>
      <p
        style={{
          fontSize: "50px",
        }}
      >
        💩
      </p>
      <div style={{ textAlign: "left" }}>
        <p>An error occurred on this page. We suggest:</p>
        <ul>
          <li>Retry loading this page in a Chrome browser.</li>
          <li>Trying again in a few minutes.</li>
        </ul>
        <p>
          If you continue to experience issues submitting your sampling location
          data, please contact us at{" "}
          <a href="mailto: support@biobot.io">support@biobot.io</a>
        </p>
      </div>
    </div>
  );
};

export default PageError;
