import { useEffect } from "react";
import { Modal, Form, Button, Select, Radio } from "antd";

import {
  COUNTRIES,
  PROVINCES_BY_COUNTRY,
  PROVINCE_LABELS_BY_COUNTRY,
  PHONE_NUMBER_REGEX_BY_COUNTRY,
} from "configs/constants";

import { requiredRule } from "utils/rules";
import { InputField } from "utils/components";

const AddressFormModal = ({
  visible,
  onSubmit,
  onCancel,
  locationDetails,
  modalAction,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const initialFormValue =
      modalAction === "edit"
        ? {
            ...locationDetails,
            active: locationDetails?.active || !locationDetails?.name || false,
          }
        : { active: true, country: "USA" };
    form.setFieldsValue(initialFormValue);
  }, [modalAction, locationDetails, form]);

  const handleFormSubmit = (formValue) => {
    const body = locationDetails
      ? { id: locationDetails.id, ...formValue }
      : formValue;
    onSubmit(body);
  };

  const filterStateOptions = (input, option) => {
    return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Modal
      width="50%"
      footer={null}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        preserve={false}
        initialValues={{ country_id: "USA" }}
        onFinish={handleFormSubmit}
      >
        <h1>Shipping Address</h1>

        <InputField
          id="input-address-name"
          placeholder="A name for this address"
          label="Shipping address name"
          name="name"
          rules={[requiredRule("Shipping address name is required")]}
        />

        <InputField
          id="input-address-line-1"
          placeholder="123 Main St"
          label="Address line 1"
          name="address_line_1"
          rules={[requiredRule("Address line 1 is required")]}
        />

        <InputField
          id="input-address-line-2"
          placeholder="Suite 500"
          label="Address line 2"
          name="address_line_2"
        />

        <InputField
          placeholder="City"
          label="City"
          name="city"
          rules={[requiredRule("City is required")]}
        />

        <Form.Item
          label="Country"
          name="country_id"
          rules={[requiredRule("Country is required")]}
        >
          <Select
            id="input-select-country"
            options={Object.values(COUNTRIES)}
          />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues["country_id"] !== currentValues["country_id"]
          }
        >
          {({ getFieldValue }) => {
            const selectedCountry = getFieldValue("country_id");

            const options = PROVINCES_BY_COUNTRY[selectedCountry].map(
              ({ abbreviation, name }) => ({
                value: abbreviation,
                label: name,
              })
            );
            return (
              <Form.Item
                label={PROVINCE_LABELS_BY_COUNTRY[selectedCountry]}
                name="state_id"
                rules={[
                  requiredRule(
                    `${PROVINCE_LABELS_BY_COUNTRY[selectedCountry]} is required`
                  ),
                ]}
              >
                <Select
                  id="input-select-province"
                  showSearch
                  placeholder={PROVINCE_LABELS_BY_COUNTRY[selectedCountry]}
                  filterOption={filterStateOptions}
                  options={options}
                ></Select>
              </Form.Item>
            );
          }}
        </Form.Item>

        <InputField
          id="input-pos-code"
          placeholder="20910"
          label="Zip / Post code"
          name="postal_code"
          rules={[requiredRule("Post code is required"), { max: 10 }]}
        />

        <h2>Shipping Contact</h2>

        <InputField
          id="input-attn"
          placeholder="Ship to Attn. Name"
          label="Ship to Attn."
          name="attn"
        />

        <InputField
          id="input-contact-name"
          placeholder="Contact name"
          label="Shipping contact name"
          name="contact_name"
          rules={[requiredRule("Contact name is required")]}
        />

        <InputField
          id="input-role"
          placeholder="Manager"
          label="Shipping contact job title"
          name="contact_title"
          rules={[requiredRule("Contact title is required")]}
        />

        <InputField
          id="input-contact-email"
          placeholder="contact@company.com"
          label="Shipping contact email"
          name="contact_email"
          rules={[requiredRule("Contact email is required"), { type: "email" }]}
          validateTrigger="onBlur"
          normalize={(value) => value.trim()}
        />

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues["country_id"] !== currentValues["country_id"]
          }
        >
          {({ getFieldValue }) => {
            const selectedCountry = getFieldValue("country_id");
            const pattern = new RegExp(
              PHONE_NUMBER_REGEX_BY_COUNTRY[selectedCountry] ??
                PHONE_NUMBER_REGEX_BY_COUNTRY.default
            );

            return (
              <InputField
                id="input-phone"
                placeholder="+1 000 123 1234"
                label="Shipping contact phone"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please confirm the phone number is valid",
                    pattern,
                  },
                ]}
              />
            );
          }}
        </Form.Item>

        <h2>Shipping Address is active?</h2>

        <Form.Item
          id="input-active"
          name="active"
          rules={[requiredRule("Selection is required")]}
          initialValue={
            locationDetails?.active || !locationDetails?.name || false
          }
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </Modal>
  );
};

export default AddressFormModal;
