import { Tag, Tooltip } from "antd";

const defaultConfig = {
  none: {
    tooltip: "",
    label: "-",
    color: "gray",
    level: 0,
  },
  low: {
    tooltip: "",
    label: "Low",
    color: "blue",
    level: 1,
  },
  medium: {
    tooltip: "",
    label: "Medium",
    color: "orange",
    level: 2,
  },
  high: {
    tooltip: "",
    label: "High",
    color: "red",
    level: 3,
  },
};

const OrderFulfillmentPriorityTag = ({ priority }) => {
  const { tooltip, label, color } =
    defaultConfig[priority] ?? defaultConfig.none;

  return (
    <>
      <Tooltip title={tooltip}>
        <Tag color={color} style={{ textTransform: "uppercase" }}>
          {label}
        </Tag>
      </Tooltip>
    </>
  );
};

export default OrderFulfillmentPriorityTag;
