import { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "containers/Layout";
import Loader from "components/Loader/Loader";
import { validateToken } from "utils";

export default function PrivateRoute({ component: Component, ...options }) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const route = <Route component={Component} {...options} />;

  useEffect(() => {
    validateToken().then((res) => setIsLoggedIn(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn === true) {
    return <Layout route={route} menu={true} isPublic={false} />;
  }

  if (isLoggedIn === false) {
    return <Redirect to="/" />;
  }

  return <Loader />;
}
