import { Col, Row } from "antd";
import Text from "antd/es/typography/Text";
import ProgramName from "components/ProgramDisplayName";

export default function StatusSection({ lead }) {
  let status = <Text type="warning">Pending Review</Text>;

  if (lead.status === "accepted") {
    status = <Text type="success">Activated</Text>;
  }
  if (lead.status === "rejected") {
    status = <Text type="danger">Rejected</Text>;
  }

  return (
    <>
      <Row className="mb-3">
        <Col md={6}>
          <Text>Program: </Text>
          <Text type="warning">
            <ProgramName programCode={lead.program} />
          </Text>
        </Col>
        <Col md={6}>
          <Text>Status: </Text>
          {status}
        </Col>
      </Row>
    </>
  );
}
