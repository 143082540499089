import { Row, Typography } from "antd";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { useKit } from "api/kit";
import {
  useCreateSamplingLocation,
  useSamplingLocationList,
} from "api/samplingLocation";
import SubmissionResult from "components/SubmissionResult";
import WithLoading from "components/WithLoading";
import Wizard from "components/Wizard";
import Form from "./Form";
import LocationStep from "./components/LocationStep";

const { Text } = Typography;

const formProps = {
  layout: "vertical",
  labelComponent: <Text className="text-lg" />,
  wrapperCol: { xs: 24, md: 12 },
};

const SampleLogWizard = ({
  setCompleted,
  kitId,
  isInternalUser,
  onFormSubmissionSuccess,
}) => {
  const kitQuery = useKit({ id: kitId });
  const locationsQuery = useSamplingLocationList(
    { _where: { organization: kitQuery.data?.organization.id } },
    { enabled: kitQuery.data?.organization.id != null }
  );
  const createLocation = useCreateSamplingLocation();

  const handleLocationFinish = ({ isNewLocation, location }) => {
    if (isNewLocation) {
      return createLocation.mutateAsync({
        ...location,
        organization: { id: kitQuery.data.organization.id },
      });
    }
  };

  const kitLabel = kitQuery.data?.shipping_kit_id || kitQuery.data?.kit_id;
  const initialValues = {
    sampling_location: {
      sampling_location_id: kitQuery.data?.sample?.sampling_location_id?.id,
    },
  };

  return (
    <WithLoading
      query={[kitQuery, locationsQuery]}
      loadingMessage="Looking up kit and sample details..."
      wrapperComponent={<Row justify="center" />}
    >
      <div data-cy="sample-form">
        <h2>Sample Log for {kitLabel}</h2>
        <Wizard initialValues={initialValues}>
          {({ forms: { sampling_location } }) => (
            <>
              <LocationStep
                name="sampling_location"
                locations={locationsQuery.data}
                isRequired={!isInternalUser}
                onFinish={handleLocationFinish}
                {...formProps}
              />
              <Form
                setCompleted={setCompleted}
                org={kitQuery.data?.organization}
                kit={kitQuery.data}
                sample={kitQuery.data?.sample}
                site={
                  sampling_location?.isNewLocation
                    ? createLocation.data
                    : sampling_location?.location
                }
                isInternalUser={isInternalUser}
                onFormSubmissionSuccess={onFormSubmissionSuccess}
              />
            </>
          )}
        </Wizard>
      </div>
    </WithLoading>
  );
};

const Landing = (props) => {
  const [status, setResult] = useState(null);

  return status ? (
    <SubmissionResult status={status} />
  ) : (
    <SampleLogWizard setCompleted={setResult} {...props} />
  );
};

export default withRouter(Landing);
