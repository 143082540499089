import { Col, Form, Input, Row, Typography, Checkbox } from "antd";
import { useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import OrgSelect from "components/OrgSelect";
import { organizationNameRule } from "utils/rules";

export default function OrgOverviewSection({
  lead,
  alreadyActivated,
  selectedOrgForLinking,
  setSelectedOrgForLinking,
}) {
  const [shouldLinkOrg, setShouldLinkOrg] = useState(false);
  const linkedOrg = lead.organization;

  let headerText =
    "Activating this lead will create a new organization with the following details:";
  if (alreadyActivated) {
    headerText = "This lead has already been activated and can't be edited!";
  } else if (linkedOrg) {
    headerText = "This lead is already linked to the following organization:";
  }
  if (shouldLinkOrg) {
    headerText =
      "This lead will be linked to an existing organization and update the organization's saved details:";
  }

  const initialOrgName = linkedOrg
    ? linkedOrg.organization_name
    : lead.organization_name;
  const initialDisplayId = linkedOrg
    ? linkedOrg.display_id
    : "This will be autogenerated";
  const initialSalesforceId = linkedOrg?.salesforce_account_id;

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Typography.Text strong>{headerText}</Typography.Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col sm={12} md={12}>
          <Form.Item
            label="Organization Name"
            name="organization_name"
            initialValue={initialOrgName}
            rules={[organizationNameRule]}
          >
            <Input
              placeholder={"My Wastewater Company"}
              disabled={alreadyActivated || linkedOrg || shouldLinkOrg}
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6}>
          <Form.Item
            label="Organization display ID"
            name="display_id"
            initialValue={initialDisplayId}
          >
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col sm={12} md={6}>
          <Form.Item
            name="salesforce_account_id"
            label="Salesforce account ID"
            initialValue={initialSalesforceId}
            rules={[{ required: false }]}
          >
            <Input disabled={linkedOrg || alreadyActivated} />
          </Form.Item>
        </Col>
      </Row>
      {!alreadyActivated && !linkedOrg && (
        <Col>
          <Form.Item
            name={"should_link_org"}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              onChange={() => {
                setShouldLinkOrg(!shouldLinkOrg);
              }}
            >
              This organization already exists
            </Checkbox>
          </Form.Item>
        </Col>
      )}
      {shouldLinkOrg && (
        <Row gutter={16}>
          <Col sm={12} md={12}>
            <Form.Item label="Correct Organization Name">
              <Input
                placeholder={"Select Organization ID"}
                value={selectedOrgForLinking}
                disabled={true}
                rules={[{ required: shouldLinkOrg }]}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6}>
            <OrgSelect setSelectedOrgForLinking={setSelectedOrgForLinking} />
          </Col>
        </Row>
      )}
      {!alreadyActivated && (shouldLinkOrg || linkedOrg) && (
        <Row className="mb-3">
          <Col>
            <WarningOutlined style={{ color: "red" }} />
            <Typography.Text strong type="danger" className="pl-1">
              Warning:
            </Typography.Text>
            <Typography.Text strong className="pl-1">
              Activating this org will override this organization's details with
              the following info:
            </Typography.Text>
          </Col>
        </Row>
      )}
    </>
  );
}
