import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
} from "./orderService";

const model = "shipping-locations";

export const useShippingLocation = orderServiceFindOneQuery({ model });
export const useShippingLocationList = orderServiceListQuery({ model });
export const useShippingLocationCount = orderServiceCountQuery({ model });
export const useCreateShippingLocation = orderServiceCreateMutation({ model });
export const useUpdateShippingLocation = orderServiceUpdateMutation({ model });
