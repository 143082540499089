import Title from "antd/lib/typography/Title";

import MetadataDetailTable from "components/MetadataDetailTable/MetadataDetailTable";
import { displayDate } from "utils/table";
import { KitMetaPartial } from "../types/orderService";

interface Props {
  kitMeta: KitMetaPartial;
}

const SampleDetails = ({ kitMeta }: Props) => {
  const sampleDetailCells = [
    [
      {
        title: "Kit Name (Internal)",
        value: kitMeta.kit_id,
      },
      {
        title: "Kit Name (External)",
        value: kitMeta.shipping_kit_id,
      },
      {
        title: "Date Collected",
        value: displayDate()(kitMeta.sample?.sample_collection_date_time),
      },
      {
        title: "Date Received",
        value: displayDate()(kitMeta.date_received),
      },
    ],
  ];

  return (
    <>
      <Title level={3}>Kit Details</Title>
      <MetadataDetailTable tableCellsArray={sampleDetailCells} />
    </>
  );
};

export default SampleDetails;
