import { Button } from "antd";
import useWizardContext from "./useWizardContext";

export const FormButtons = ({
  backDisabled = false,
  backLabel = "Back",
  loading = false,
  onBack = null,
  showBack = false,
  submitLabel = "Submit",
  submitDisabled = false,
}) => {
  return (
    <>
      {showBack && (
        <Button
          onClick={onBack ? () => onBack() : null}
          disabled={backDisabled}
          data-cy="wizard-buttons__back"
          className="mr-5"
        >
          {backLabel}
        </Button>
      )}
      <Button
        htmlType="submit"
        type="primary"
        loading={loading}
        disabled={submitDisabled}
        data-cy="wizard-buttons__next"
      >
        {submitLabel}
      </Button>
    </>
  );
};

const WizardButtons = ({ loading = false, submitDisabled = false }) => {
  const {
    isFirstStep,
    showBackAlways,
    isLastStep,
    onBack,
  } = useWizardContext();
  const showBack = showBackAlways || !isFirstStep;
  return (
    <FormButtons
      showBack={showBack}
      backDisabled={isFirstStep}
      onBack={onBack}
      submitLabel={isLastStep ? "Submit" : "Next"}
      loading={loading}
      submitDisabled={submitDisabled}
    />
  );
};

export default WizardButtons;
