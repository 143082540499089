import _ from "lodash";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  PageHeader,
  Table,
  Button,
  Form,
  Popconfirm,
  Space,
  message,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import { useUserContext } from "context";
import EditableCell from "../../../components/Table/EditableCell";
import {
  useReportCount,
  useReportList,
  useUpdateReport,
  useBulkUpdateReports,
} from "api/reports";
import { useTableQueryParams } from "components/Table/helpers";
import { createReportsTableColumns } from "./helpers";
import { useReportReviewStatusOptions } from "api/reportReviewStatus";
import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_CONTAINS,
  METHOD_EMPTY,
} from "components/Table/StrapiFilter";
import ReportsActionButtons from "./ReportsActionButtons";
import { useProductOptions } from "api/product";
import { useProgramList } from "api/program";
import { todayStart, todayEnd } from "utils/time";

function Reports() {
  // Editing reports on prod is restricted to certain users
  const { userName, userEmail } = useUserContext();
  const isUserAbleToEditReports =
    process.env.REACT_APP_REPORT_EDITOR_ALLOW_ALL === "true" ||
    process.env.REACT_APP_REPORT_REVIEWER_EMAILS.includes(userEmail);

  const [inlineStatusForm] = Form.useForm();
  const [unlockStickyCols, setUnlockStickyCols] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isInlineEditing, setIsInlineEditing] = useState(
    isUserAbleToEditReports
  );
  const [reportNotesForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    data: reviewStatusOptions,
    isReportStatusOptionsLoading,
  } = useReportReviewStatusOptions();
  const { data: productOptions } = useProductOptions();
  const { data: programs } = useProgramList();

  const defaultColumns = createReportsTableColumns(
    inlineStatusForm,
    isInlineEditing,
    programs,
    reviewStatusOptions,
    productOptions,
    unlockStickyCols
  );

  // query report data and populate table
  const {
    queryParams,
    pagination,
    handleTableChange,
    handleClearFilters,
    tableKey,
    columns,
  } = useTableQueryParams({
    defaultPageSize: 500,
    defaultColumns,
    defaultSort: "report_generation_job_id:DESC",
    defaultFilters: {
      "approval_status.status": [
        {
          method: METHOD_TEXT_CONTAINS,
          value: "New",
        },
      ],
      environment: [
        {
          method: METHOD_TEXT_CONTAINS,
          value: "prod",
        },
      ],
      date_report_emailed: [
        {
          method: METHOD_EMPTY,
        },
      ],
      date_report_generated: [
        {
          method: METHOD_DAY_RANGE_EXCLUSIVE_END,
          value: [todayStart, todayEnd],
        },
      ],
    },
  });

  const { data: reportCount } = useReportCount(queryParams);

  const reports = useReportList({
    ...queryParams,
    _joins: [
      "approval_status",
      "product",
      "report_files",
      "report_kits.kit.sample.sampling_location_id",
      "report_kits.kit.sample.organization",
      "report_kits.kit.selection_statuses.program",
      "report_tubes.tube",
      "report_type",
      "rework_id",
    ],
  });

  /**
   * DQOps has requested that the ability to sort on fields that we currently don't support.
   * Sorting on Organization Name and Sampling Location name are not supported right now
   * both because these fields are highly nested into the object, and because of a many-to-many
   * refactor on kits. Only going to sort using the first kit's values, since sorting on
   * an array of values isn't well defined
   */

  const sortedReports = reports.data?.sort(
    (a, b) =>
      b?.report_generation_job_id - a?.report_generation_job_id || // highest id first
      a?.report_kits?.[0]?.kit?.sample?.organization?.organization_name?.localeCompare(
        b?.report_kits?.[0]?.kit?.sample?.organization?.organization_name // then org name alphabetically
      ) ||
      a?.report_kits?.[0]?.kit?.sample?.sampling_location_id?.sampling_location_name?.localeCompare(
        b?.report_kits?.[0]?.kit?.sample?.sampling_location_id // then sampling loc name alphabetically
          ?.sampling_location_name
      )
  );

  // functions to update report statuses or notes
  const { mutate: updateReport } = useUpdateReport();

  const {
    mutate: bulkUpdateReports,
    isLoading: isBulkUpdateLoading,
  } = useBulkUpdateReports();

  const bulkUpdateStatuses = async (statusForUpdate) => {
    const payload = [];
    _.forEach(reports.data, (report) => {
      payload.push({
        id: report.id,
        approval_status: statusForUpdate.review_status,
        reviewer: userName,
      });
    });

    if (payload.length) {
      bulkUpdateReports(payload, {
        onError: (error) => {
          message.error(
            `Unable to update reports due to a ${error.response?.data?.error} error.`
          );
        },
        onSuccess: () => {
          message.success(
            `Keep up the good work, ${userName}. You just updated report statuses. 🎉`
          );
          setIsModalVisible(false);
        },
      });
    } else {
      message.error(
        "Unable to save new statuses. Please confirm there are rows on the table."
      );
      setIsModalVisible(false);
    }
  };

  const saveNotes = async (id) => {
    try {
      const formData = await reportNotesForm.validateFields();

      // Treat empty text box as setting notes to blank, not "no action"
      if (formData.report_review_notes === undefined) {
        formData.report_review_notes = "";
      }
      const newData = [...reports.data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const row = newData[index];
        const updatedRow = await saveEditedRow(row, formData);
        newData[index] = updatedRow;
        setEditingKey("");
        reportNotesForm.resetFields();
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const isEditing = (record) => record.id === editingKey;
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType ?? "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        required: false,
      }),
    };
  });
  const isEditable = mergedColumns.some((col) => col.editable);
  if (isEditable) {
    const editCol = {
      title: "Edit Notes",
      width: 120,
      render: (_, row) => {
        const editingRow = isEditing(row);
        if (editingRow) {
          return (
            <Space
              style={{
                display: "flex",
                wrap: "nowrap",
                alignItems: "center",
              }}
            >
              <Button
                type="link"
                onClick={() => saveNotes(row.id)}
                style={{ padding: "0" }}
                disabled={!isUserAbleToEditReports}
              >
                Save
              </Button>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={cancel}
                style={{ margin: "0" }}
              >
                <Button type="link">Cancel</Button>
              </Popconfirm>
            </Space>
          );
        } else {
          return (
            <Button
              type="default"
              icon={<EditOutlined />}
              onClick={() => edit(row)}
            />
          );
        }
      },
    };
    // Adds the editing field to the right of the first editable column
    const indexOfEditCol = mergedColumns.findIndex((col) => col.editable);
    mergedColumns.splice(indexOfEditCol + 1, 0, editCol);
  }

  const edit = (record) => {
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
    reportNotesForm.resetFields();
  };

  const saveEditedRow = async (row, formData) => {
    const updatedRow = {
      id: row.id,
      report_review_notes: formData.report_review_notes,
    };

    updateReport(updatedRow, {
      onError: () => {
        message.error(`Unable to update notes for report ${row.id}`);
      },
    });
  };

  const inlineUpdateStatuses = async () => {
    const formData = await inlineStatusForm.validateFields();
    if (_.values(formData).every(_.isNull)) {
      message.error(
        "Unable to save new data. Are you sure you updated statuses on the table?"
      );
    }
    _.forEach(reports.data, (val) => {
      if (formData[val.id]) {
        const matchedReport = _.find(
          reports.data,
          (report) => report.id === val.id
        );
        const updatedReport = {
          id: matchedReport.id,
          approval_status: formData[val.id],
          reviewer: userName,
        };
        updateReport(updatedReport, {
          onError: () => {
            message.error(
              `Unable to update status for report # ${updatedReport.id}`
            );
          },
          onSuccess: () => {
            message.success(
              `Keep up the good work, ${userName}. You just updated report statuses. 🎉`
            );
          },
        });
      }
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <PageHeader
        title="PDF Report Data Review"
        className="px-0"
        footer={
          <ReportsActionButtons
            unlockStickyCols={unlockStickyCols}
            setUnlockStickyCols={setUnlockStickyCols}
            bulkUpdateStatuses={bulkUpdateStatuses}
            inlineUpdateStatuses={inlineUpdateStatuses}
            isInlineEditing={isInlineEditing}
            setIsInlineEditing={setIsInlineEditing}
            reviewStatusOptions={reviewStatusOptions}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            isUserAbleToEditReports={isUserAbleToEditReports}
            isBulkUpdateLoading={isBulkUpdateLoading}
            totalReportCount={reportCount}
            handleClearFilters={handleClearFilters}
          />
        }
      />
      <Form form={reportNotesForm} component={false}>
        <Table
          key={tableKey}
          rowKey={"id"}
          loading={reports.isLoading || isReportStatusOptionsLoading}
          columns={mergedColumns}
          pagination={{
            ...pagination,
            total: reportCount,
            showSizeChanger: true,
            pageSizeOptions: ["50", "100", "250", "500"],
            position: ["topRight", "bottomRight"], // stakeholders requested two pagination controls for this table
          }}
          dataSource={sortedReports}
          onChange={handleTableChange}
          scroll={{ x: "min-content", y: "max-content" }} // override sticky header setting width
          sticky // header is always visible
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </div>
  );
}

export default withRouter(Reports);
