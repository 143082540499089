import { useState } from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col as Column,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";

import moment from "moment";

import { useFulfillOrder } from "api/order";

import { KitFulfillment } from "./KitFulfillment";
import { SuccessfulOrderSearch } from "./SuccessfulOrderSearch";

const OrderFulfillmentForm = ({
  orderId,
  shippingOrderId,
  kits,
  kitConfigurationOptions,
  isPreviouslyFulfilled,
  datePreviouslyFulfilled,
  onFinish,
}) => {
  const [formSubmissionError, setFormSubmissionError] = useState(false);
  const [kitsFulfilledCount, setKitsFulfilledCount] = useState(0);

  const fulfillOrderQuery = useFulfillOrder();

  const [orderFulfillmentForm] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  const onFormSubmitHandler = (values) => {
    setFormSubmissionError(false);

    const fulfillOrder = () => {
      const payload = {
        shipping_order_id: shippingOrderId,
        postgres_order_id: values.postgres_order_id,
        kit_shipping_metas: values.kit_shipping_metas?.map(
          (kitShippingMeta) => ({
            configuration: values.kit_configuration,
            ...kitShippingMeta,
            date_shipped: moment.utc().toISOString(),
          })
        ),
      };

      fulfillOrderQuery
        .mutateAsync(payload)
        .then(() => {
          message.success("Fulfillment records saved");

          onFinish();
        })
        .catch(() => {
          message.error(
            "Unable to fulfill order. Contact @eng-on-call if this error persists."
          );
        });
    };

    isPreviouslyFulfilled
      ? modal.confirm({
          width: 800,
          title: "Are you sure you want to re-fulfill this order?",
          icon: <ExclamationCircleOutlined />,
          content: (
            <>
              <h2>This order was fulfilled on {datePreviouslyFulfilled}</h2>

              <Typography.Text>
                It is OK to re-fulfill this order but know that existing
                fulfillment information will be overwritten.
              </Typography.Text>
            </>
          ),
          okText: "OK - Overwrite Existing Data",
          cancelText: "Quit Without Changes",
          onOk: fulfillOrder,
        })
      : fulfillOrder();
  };

  const onFormSubmitFailedHandler = () => {
    setFormSubmissionError(true);
  };

  const fulfillmentStatusText = isPreviouslyFulfilled
    ? `This order was fulfilled on ${datePreviouslyFulfilled}`
    : "This order has not been fulfilled yet";

  /**
   * Get the initial value for the kit configuration select.
   *
   * @returns {string|null} The initial value for the kit configuration select.
   */
  const getKitConfigurationInitialValue = () => {
    if (kitConfigurationOptions.length === 1) {
      return kitConfigurationOptions[0].id;
    }

    if (kits.every((kit) => kit.configuration === kits[0].configuration)) {
      return kits[0].configuration;
    }

    return null;
  };

  return (
    <>
      <Spin spinning={fulfillOrderQuery.isLoading}>
        <Form
          form={orderFulfillmentForm}
          onFinish={onFormSubmitHandler}
          onFinishFailed={onFormSubmitFailedHandler}
          scrollToFirstError={true}
          layout="vertical"
        >
          <Form.Item name="postgres_order_id" initialValue={orderId} hidden>
            <Input type="hidden" />
          </Form.Item>

          <Row className="mb-5 text-primary">
            <Column span={24}>
              <Typography.Text>{fulfillmentStatusText}</Typography.Text>
            </Column>
          </Row>

          <KitFulfillment
            kits={kits}
            setKitsFulfilledCount={setKitsFulfilledCount}
          ></KitFulfillment>

          {/* Kit Configuration */}
          <Row className="mb-1">
            <Column span={24}>
              <Typography.Title level={4}>Kit Configuration</Typography.Title>

              <Typography.Text>
                Select the kit configuration for this order. Kit configuration
                and vessels cannot be change after an order is fulfilled!
              </Typography.Text>

              <br />

              <Typography.Text>
                This order was originally created with the{" "}
                <Typography.Text italic>Kit Configuration</Typography.Text>:{" "}
                <Typography.Text strong>
                  {kitConfigurationOptions.find(
                    (config) => config.id === kits[0].configuration
                  )?.name ?? "-"}
                </Typography.Text>
              </Typography.Text>
            </Column>
          </Row>
          <Row className="mb-3">
            <Column span={6}>
              <Form.Item
                name="kit_configuration"
                label="Kit Configuration"
                initialValue={getKitConfigurationInitialValue()}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={"Kit Configuration"}
                  disabled={isPreviouslyFulfilled}
                >
                  {kitConfigurationOptions?.map((kitConfiguration) => (
                    <Select.Option
                      key={kitConfiguration.id}
                      value={kitConfiguration.id}
                    >
                      {kitConfiguration.name} ({kitConfiguration.description})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Column>
          </Row>

          <SuccessfulOrderSearch
            orderId={orderId}
            kits={kits}
          ></SuccessfulOrderSearch>

          <Row className="mb-2">
            <Column span={8}>
              <Typography.Text strong className="mr-2">
                Total Kits Fulfilled:
              </Typography.Text>

              <Typography.Text>{kitsFulfilledCount}</Typography.Text>
            </Column>
          </Row>

          {/* Form Submission Errors */}
          <Row className="mt-2">
            <Column span={24}>
              {formSubmissionError && (
                <Typography.Text type="danger" data-cy="unable-submit-alert">
                  Unable to submit form, please see errors above
                </Typography.Text>
              )}
              {fulfillOrderQuery.isError && (
                <Typography.Text type="danger" data-cy="submit-failed-alert">
                  {fulfillOrderQuery.error.response?.data?.message ??
                    JSON.stringify(fulfillOrderQuery.error)}
                </Typography.Text>
              )}
            </Column>
          </Row>

          <Row>
            <Column>
              <Button
                className="mt-2"
                type="primary"
                htmlType="submit"
                disabled={fulfillOrderQuery.isLoading}
              >
                Submit
              </Button>
            </Column>
          </Row>
        </Form>
      </Spin>

      {contextHolder}
    </>
  );
};

export { OrderFulfillmentForm };
