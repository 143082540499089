import _ from "lodash";
import Text from "antd/lib/typography/Text";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

export default function KitDestinations({ record }) {
  const BIO_LOWVOL = "bio_low_volume";
  const BIO_HIGHVOL = "bio_high_volume";
  const CHEM = "chem";
  const RND = "rnd";

  let { kit, selections } = record;

  if (_.isEmpty(selections.statuses) && _.isNil(kit.sample)) {
    return (
      <>
        <Text>
          Unable to triage
          <br />
          due to missing log.
        </Text>
      </>
    );
  }

  let selectedTriage = [];

  for (const status of selections.statuses) {
    if (status.selectionStatus === "selected") {
      const selectedProcesses = _.uniq(
        _.map(
          status.program.product_deliverables,
          (pd) => pd.product?.assay?.lab_process
        )
      );
      if (status.program.code === RND) {
        selectedProcesses.push(RND);
      }

      selectedTriage = _.union(selectedTriage, selectedProcesses);
    }
  }

  return (
    <>
      {selectedTriage.indexOf(BIO_LOWVOL) === -1 ? (
        <Text type="danger">
          <CloseCircleFilled />
        </Text>
      ) : (
        <Text type="success">
          <CheckCircleFilled />
        </Text>
      )}
      <Text> Bio Low-Vol</Text>
      <br />
      {selectedTriage.indexOf(BIO_HIGHVOL) === -1 ? (
        <Text type="danger">
          <CloseCircleFilled />
        </Text>
      ) : (
        <Text type="success">
          <CheckCircleFilled />
        </Text>
      )}
      <Text> Bio High-Vol</Text>
      <br />
      {selectedTriage.indexOf(CHEM) === -1 ? (
        <Text type="danger">
          <CloseCircleFilled />
        </Text>
      ) : (
        <Text type="success">
          <CheckCircleFilled />
        </Text>
      )}
      <Text> Chem</Text>
      <br />
      {selectedTriage.indexOf(RND) === -1 ? (
        <Text type="danger">
          <CloseCircleFilled />
        </Text>
      ) : (
        <Text type="success">
          <CheckCircleFilled />
        </Text>
      )}
      <Text> R&D</Text>
    </>
  );
}
