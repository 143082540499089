import { DownloadOutlined } from "@ant-design/icons";
import { PageHeader, Table, Button, message } from "antd";
import { useState } from "react";
import moment from "moment";
import fileDownload from "js-file-download";

import {
  useVarseqUploadCount,
  useVarseqUploadList,
  fetchVarseqUploadCsv,
} from "api/fileUpload";
import { useTableQueryParams } from "components/Table/helpers";
import { METHOD_NOT_EMPTY } from "components/Table/StrapiFilter";
import { getVarSeqUploadTableColumns } from "./helpers";
import VarSeqUploadFileModal from "./VarSeqUploadFileModal";
import "./VarSeqUploads.css";

const VarSeqUploadsPage = () => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedUpload, setSelectedUpload] = useState();
  const [csvIsDownloading, setCsvIsDownloading] = useState(false);

  const { queryParams, handleTableChange, pagination } = useTableQueryParams({
    defaultSort: "date_of_seq:DESC",
    defaultFilters: {
      date_of_seq: [
        {
          method: METHOD_NOT_EMPTY,
          value: true,
        },
      ],
    },
  });
  const { data: varSeqUploadCount } = useVarseqUploadCount(queryParams);
  const varSeqUploads = useVarseqUploadList(queryParams);

  const handleFileClick = (upload) => {
    setIsUploadModalOpen(true);
    setSelectedUpload(upload);
  };

  const downloadVarseqUploadsCsv = () => {
    setCsvIsDownloading(true);
    const date = moment().format("MM-DD-YYYY");
    fetchVarseqUploadCsv(queryParams)
      .then((data) => fileDownload(data, `${date}-varseq-uploads.csv`))
      .catch(message.error)
      .finally(() => setCsvIsDownloading(false));
  };

  return (
    <>
      <PageHeader title="VarSeq Uploads" />
      <div className="ml-3">
        <Button
          type="primary"
          size="small"
          shape="round"
          className="my-3"
          loading={csvIsDownloading}
          onClick={downloadVarseqUploadsCsv}
        >
          Export to CSV
          <DownloadOutlined />
        </Button>
        <Table
          data-cy="varseq-uploads-table"
          rowKey="id"
          loading={varSeqUploads.isLoading}
          columns={getVarSeqUploadTableColumns(handleFileClick)}
          dataSource={varSeqUploads.data}
          onChange={handleTableChange}
          pagination={{ ...pagination, total: varSeqUploadCount }}
          scroll={{ x: "max-content" }}
        />
        <VarSeqUploadFileModal
          isOpen={isUploadModalOpen}
          upload={selectedUpload}
          onCancel={() => setIsUploadModalOpen(false)}
        />
      </div>
    </>
  );
};

export default VarSeqUploadsPage;
