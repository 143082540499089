import React from "react";
import { Row, Col } from "antd";

const footerStyle = {
  borderTop: "1px solid #f0f0f0",
  padding: "10px 16px",
  // This component will always go in the modal body, which looks a bit
  // different than the footer. We need to make some tweaks:
  // (1) Remove the left and right padding so that the border line extends to
  // the edges.
  marginLeft: "-24px",
  marginRight: "-24px",
  // (2) Shift the extra space at the bottom (24px of body padding) to the top.
  marginBottom: "-24px",
  marginTop: "24px",
};

const ModalFooter = ({ children }) => (
  <div style={footerStyle}>
    <Row justify="end" wrap={false} gutter={8}>
      {React.Children.map(children, (child) => (
        <Col>{child}</Col>
      ))}
    </Row>
  </div>
);

export default ModalFooter;
