import { Select, Form, Col, Row, Typography } from "antd";
const { Option } = Select;

const OrderLegal = (props) => {
  const { Title } = Typography;

  return (
    <Row className="mb-3">
      <Col span={24}>
        <Title level={3}>Purchase Order</Title>
      </Col>
      <Col span={8} data-cy="order-legal">
        <Form.Item
          name="requires_purchase_order"
          label="Purchase order"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select className="full-width" data-cy="order-legal__select">
            <Option value={"true"}>This order requires a Purchase Order</Option>
            <Option value={"false"}>No Purchase Order is required</Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default OrderLegal;
