import { Link } from "react-router-dom";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { formatDateTime, formatDate } from "utils/time";
import { REWORK_STATUS_OPTIONS, REWORK_ASSAY_OPTIONS } from "api/rework";
import { columnSearchProps } from "components/Table/SearchFilter";
import {
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  strapiFilterProps,
} from "components/Table/StrapiFilter";

export const FilterInfo = () => (
  <ul className="pl-1" style={{ maxWidth: "500px" }}>
    <li>
      Start Date/End Date filters can now be found in the "Date Record Created"
      Column
    </li>
    <li>
      Kit ID and Internal Kit ID can be filtered separately in the Shipping Kit
      ID and Kit ID columns respectively
    </li>
    <li>
      Notes, Reason For Rework, and Lab or Report Processing Complete values are
      now editable in one place by clicking the last Edit column
    </li>
  </ul>
);

const orDash = (str) => (str ? str : "-");

const ellipsed = (str) => {
  if (!str || str.length <= 33) {
    return str;
  } else {
    return str.slice(0, 30) + "...";
  }
};

export const COMPLETE_STATUS = "COMPLETED";

export const getColumns = (handleEditClick) => [
  {
    title: "Date record created",
    dataIndex: "created_at",
    render: (created_at) => orDash(formatDateTime(created_at)),
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
    }),
  },
  {
    title: "Shipping Kit ID",
    dataIndex: "kit",
    key: "kit.shipping_kit_id_contains",
    render: (kit) => <Link to={`/kit/${kit.id}`}>{kit.shipping_kit_id}</Link>,
    ...columnSearchProps({ placeholder: "Search by Shipping Kit ID" }),
  },
  {
    title: "Kit ID",
    dataIndex: "kit",
    key: "kit.kit_id_contains",
    render: (kit) => <Link to={`/kit/${kit.id}`}>{kit.kit_id}</Link>,
    ...columnSearchProps({ placeholder: "Search by Kit ID" }),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => orDash(status),
    filters: REWORK_STATUS_OPTIONS(false),
    filterMultiple: false,
  },
  {
    title: "Lab or report processing complete",
    dataIndex: "date_completed",
    render: (value) => orDash(formatDate(value)),
    width: 170,
    ...strapiFilterProps({
      enabledFilterMethods: [
        METHOD_DAY_RANGE_EXCLUSIVE_END,
        METHOD_EMPTY,
        METHOD_NOT_EMPTY,
      ],
    }),
  },
  {
    title: "Organization",
    dataIndex: [
      "kit",
      "order",
      "order_placement",
      "organization",
      "organization_name",
    ],
    render: (organization_name) => orDash(organization_name),
  },
  {
    title: "Type of rework",
    dataIndex: "type_of_rework",
    render: (type_of_rework) => orDash(type_of_rework),
    filters: [
      "LAB_RERUN",
      "INTERNAL_REISSUE",
      "EXTERNAL_REISSUE",
    ].map((value) => ({ text: value, value })),
    filterMultiple: false,
  },
  {
    title: "Assay",
    dataIndex: "assay",
    filters: REWORK_ASSAY_OPTIONS.map(({ label, value }) => ({
      text: label,
      value,
    })),
  },
  {
    title: "Reason for re-work and Database Changes",
    dataIndex: "reason_for_rework_and_requested_changes",
    render: (value) => ellipsed(orDash(value)),
    width: 220,
  },
  {
    title: "Notes",
    dataIndex: "notes_for_lab_team",
    render: (value) => ellipsed(orDash(value)),
    width: 220,
  },
  {
    title: "Urgency",
    dataIndex: "urgency",
    render: (value) => orDash(value),
  },
  {
    title: "Delivery Method",
    dataIndex: "delivery_method",
    render: (value) => orDash(value),
  },
  {
    title: "Person requesting",
    dataIndex: "requester",
    render: (value) => orDash(value),
  },
  {
    title: "Edit",
    dataIndex: "edit",
    render: (_, record) => (
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => handleEditClick(record)}
      />
    ),
  },
];
