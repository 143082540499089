import { Link } from "react-router-dom";
import moment from "moment";

import { formatDateField } from "../Accounts/helpers";
import SelectionStatusTag from "components/SelectionStatusTag";
import {
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
  METHOD_TIME_RANGE_EXCLUSIVE_END,
  strapiFilterProps,
} from "components/Table/StrapiFilter";
import {
  SELECTION_STATUS_ELIGIBLE,
  SELECTION_STATUS_SELECTED,
  SELECTION_STATUS_SELECTION_AMBIGUOUS,
  SELECTION_STATUS_INELIGIBLE,
} from "configs/constants";
import { columnSearchProps } from "components/Table/SearchFilter";

const SELECTION_STATUS_OPTIONS = [
  { value: SELECTION_STATUS_SELECTED, text: "Selected" },
  { value: SELECTION_STATUS_ELIGIBLE, text: "Eligible " },
  {
    value: SELECTION_STATUS_SELECTION_AMBIGUOUS,
    text: "Eligibility Ambiguous",
  },
  { value: SELECTION_STATUS_INELIGIBLE, text: "Ineligible" },
];

const start = moment().subtract(24, "hours");
const end = moment();

export const defaultDateReceivedFilterValue = [start, end];

export const auditLogTableColumns = (programOptions) => {
  return [
    {
      title: "Kit",
      dataIndex: "kit",
      key: "kit.shipping_kit_id",
      render: (kit) => (
        <Link to={`/kit/${kit.id}`}>{kit.shipping_kit_id || "ID Pending"}</Link>
      ),
      ...columnSearchProps({ placeholder: "Search by Shipping Kit ID" }),
    },
    {
      title: "Date Kit Received",
      width: 200,
      dataIndex: ["kit", "date_received"],
      render: formatDateField,
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TIME_RANGE_EXCLUSIVE_END,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
      }),
    },
    {
      title: "Date Sample Logged",
      width: 200,
      dataIndex: ["kit", "sample", "created_at"],
      render: formatDateField,
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TIME_RANGE_EXCLUSIVE_END,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
      }),
    },
    {
      title: "Program",
      dataIndex: ["program", "name"],
      key: "program.id",
      filters: programOptions,
    },
    {
      title: "Selection Status",
      key: "selection_status",
      render: (kpss) => (
        <SelectionStatusTag
          status={kpss.selection_status}
          reason={kpss.reason}
          includeProgram={false}
        />
      ),
      filters: SELECTION_STATUS_OPTIONS,
      filterMultiple: true,
    },
    {
      title: "Status Change Date",
      dataIndex: "created_at",
      render: formatDateField,
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_TIME_RANGE_EXCLUSIVE_END,
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
        ],
        defaultFilterMethod: METHOD_TIME_RANGE_EXCLUSIVE_END,
        defaultFilterValue: defaultDateReceivedFilterValue,
      }),
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
  ];
};
