import { CustomChoiceFilter } from "./CustomChoiceFilter";

const customChoiceFilterProps = ({ choices, toStrapiFilter, renderChoice }) => {
  const filterDropdown = (props) => {
    return (
      <CustomChoiceFilter
        choices={choices}
        toStrapiFilter={toStrapiFilter}
        renderChoice={renderChoice}
        {...props}
      ></CustomChoiceFilter>
    );
  };

  return { filterDropdown };
};

export { customChoiceFilterProps };
