import { Row, Col, Typography, Form, Radio } from "antd";

const { Title, Link } = Typography;

export default function DataVizInclusion() {
  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>Results preferences</Title>
        </Col>
      </Row>
      <Row>
        We are pleased to offer two additional ways for you to receive your
        results from Biobot.
      </Row>
      <Row>
        <ol>
          <li>
            Biobot will automatically share SARS-CoV-2 results from each sample
            directly to participants via email.
          </li>
          <li>
            We invite all participants to join our{" "}
            <Link href="https://biobot.io/data" target="_blank">
              Biobot Data Visualization
            </Link>
            , which enables utilities, Departments of Public Health, and the
            general public to view wastewater data aggregated at the county,
            regional and national levels. Specific wastewater treatment plant
            names are not identified.
          </li>
        </ol>
      </Row>
      <Row className="mb-1">
        At Biobot we have had positive feedback on our data sharing in that it
        provides visibility into Covid-19 wastewater levels within communities.
        Sharing your data publicly will enable the citizens of your community to
        make data-informed decisions around Covid-19 and personal risk, and will
        provide your community leaders with crucial information on the true
        spread of the virus.
      </Row>
      <Row>Please select one option*</Row>
      <Row>
        <Form.Item
          name="public_viz_opt_in"
          rules={[
            {
              required: true,
              message: "Please select results preference",
            },
          ]}
          className="mb-0"
        >
          <Radio.Group>
            <Radio value={true}>
              I would like my results included in Biobot’s publicly available
              data visualization, aggregated at the county level.
            </Radio>
            <Radio value={false}>
              I do not want my results included in Biobot’s publicly available
              data visualization.
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
    </>
  );
}
