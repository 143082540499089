export const convertFormToReworkPayload = (formValues) => ({
  type_of_rework: formValues.type_of_rework,
  reason_for_rework_and_requested_changes:
    formValues.reason_for_rework_and_requested_changes,
  notes_for_lab_team: formValues.notes_for_lab_team,
  urgency: formValues.urgency,
  delivery_method: formValues.delivery_method,
  assay:
    formValues.type_of_rework === "LAB_RERUN" ? formValues.assay : undefined,
  status: formValues.status,
});

export const reworkCreateSuccessMsg = (updatedKits) => {
  const reworkIds = updatedKits.map((kitObj) => kitObj.kit);
  const isMultipleReworks = reworkIds?.length > 1;
  return `Reworks successfully requested for Kit ID${
    isMultipleReworks ? "s" : ""
  } ${reworkIds.join(", ")}`;
};
