import { DownloadOutlined } from "@ant-design/icons";
import { PageHeader, Table, Button, message } from "antd";
import { useState } from "react";
import moment from "moment";
import fileDownload from "js-file-download";

import {
  useLcmsUploadCount,
  useLcmsUploadList,
  useReuploadLcmsUploadFile,
  fetchLcmsUploadCsv,
} from "api/fileUpload";
import { useTableQueryParams } from "components/Table/helpers";
import { METHOD_NOT_EMPTY } from "components/Table/StrapiFilter";
import { getLcmsUploadTableColumns } from "./helpers";
import LcmsUploadFileModal from "./LcmsUploadFileModal";
import "./LcmsUploads.css";

const LcmsUploadsPage = () => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedUpload, setSelectedUpload] = useState();
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [csvIsDownloading, setCsvIsDownloading] = useState(false);
  const { mutateAsync: reuploadLcmsFile } = useReuploadLcmsUploadFile();

  const { queryParams, handleTableChange, pagination } = useTableQueryParams({
    defaultSort: "lcms_reviewed_at:DESC",
    defaultFilters: {
      lcms_reviewed_at: [
        {
          method: METHOD_NOT_EMPTY,
          value: true,
        },
      ],
    },
  });
  const { data: lcmsUploadCount } = useLcmsUploadCount(queryParams);
  const lcmsUploads = useLcmsUploadList(queryParams);

  const handleFileClick = (lcmsUpload) => {
    setIsUploadModalOpen(true);
    setSelectedUpload(lcmsUpload);
  };

  const handleUploadSubmit = async (lcmsUpload, file) => {
    setIsUploadingFile(true);
    message.loading("Replacing LCMS Upload File...", 0);
    await reuploadLcmsFile({ lcmsUpload, file })
      .then((result) => {
        message.destroy();
        const text = `Successfully replaced "${lcmsUpload.file_name}" with "${result.file_name}"`;
        message.success(text, 6);
        lcmsUploads.refetch();
        setIsUploadModalOpen(false);
      })
      .catch((e) => {
        message.destroy();
        const errMsg = e.message || "Something went wrong reuploading file";
        message.error(errMsg);
      })
      .finally(() => setIsUploadingFile(false));
  };

  const downloadLcmsUploadsCSV = () => {
    setCsvIsDownloading(true);
    const date = moment().format("MM-DD-YYYY");
    fetchLcmsUploadCsv(queryParams)
      .then((data) => fileDownload(data, `${date}-lcms-uploads.csv`))
      .catch(message.error)
      .finally(() => setCsvIsDownloading(false));
  };

  return (
    <>
      <PageHeader title="LCMS Uploads" />
      <div className="ml-3">
        <Button
          type="primary"
          size="small"
          shape="round"
          className="my-3"
          loading={csvIsDownloading}
          onClick={downloadLcmsUploadsCSV}
        >
          Download as CSV
          <DownloadOutlined />
        </Button>
        <Table
          data-cy="lcms-uploads-table"
          rowKey="id"
          loading={lcmsUploads.isLoading}
          columns={getLcmsUploadTableColumns({ handleFileClick })}
          dataSource={lcmsUploads.data}
          onChange={handleTableChange}
          pagination={{ ...pagination, total: lcmsUploadCount }}
          scroll={{ x: "max-content" }}
        />
        <LcmsUploadFileModal
          isOpen={isUploadModalOpen}
          lcmsUpload={selectedUpload}
          isUploadingFile={isUploadingFile}
          onCancel={() => setIsUploadModalOpen(false)}
          onSubmit={handleUploadSubmit}
        />
      </div>
    </>
  );
};

export default LcmsUploadsPage;
