import { createRef, useState } from "react";
import { Col, Input, Row, Typography } from "antd";
import { SampleDetails } from "./components/SampleDetails.js";
import {
  handleFormFieldChanges,
  handleFormErrors,
  initialValues,
  createSamplePayload,
  notifySupportOfUnexpectedKit,
  updateSample,
  createSample,
} from "./helpers.js";
import { Form } from "components/Wizard";
import SamplingMethod from "./components/SamplingMethod";
import SampleCollectionDateTime from "./components/SampleCollectionDateTime";
import InfluentInformation from "./components/InfluentInformation";
import Author from "./components/Author";
import { SampleMatrix } from "./components/SampleMatrix";

const { TextArea } = Input;
const { Title } = Typography;

const formRef = createRef();

function significantDataPointMissing(values, isCommunityLocation) {
  if (isCommunityLocation) {
    return !values.flow_rate_day_mgd;
  }
}

export default function Sample({
  isPublic,
  isInternalUser,
  sample,
  kit,
  site,
  siteName,
  org,
  orgName,
  setCompleted,
  onFormSubmissionSuccess,
}) {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [method, setMethod] = useState(
    sample?.sampling_type ? sample.sampling_type : ""
  );
  const [confirm, setConfirm] = useState(false);
  const [
    showSampleStartDatetimeWarning,
    setShowSampleStartDatetimeWarning,
  ] = useState(false);
  const [
    showSampleCollectionDatetimeWarning,
    setShowSampleCollectionDatetimeWarning,
  ] = useState(false);
  const [
    showInfluentFlowInputWarning,
    setShowInfluentFlowInputWarning,
  ] = useState(false);

  // Default to "community" type since initially all sites were community sites
  const isCommunityLocation =
    site?.location_type === "community" || !site?.location_type;

  const handleSubmit = (values) => {
    // Don't save any data if they haven't been shown the confirmation notification yet.
    // Clicking submit again will bypass this check.
    if (!confirm && significantDataPointMissing(values, isCommunityLocation)) {
      setConfirm(true);
      return;
    }

    setSubmitDisabled(true);

    const samplePayload = createSamplePayload(values, kit, site, org);

    let ajaxCall = null; // some function of the form (payload) => Promise

    const requiresHumanIntervention = siteName || orgName;
    if (requiresHumanIntervention) {
      // This path is triggered when the customer searches for a kit that can't be located,
      // thus making it impossible to associate a log with a kit/location/org. In these cases
      // we have a workaround that emails the log info to our support team to manually resolve.
      ajaxCall = (payload) => {
        const copy = {
          ...payload,
          shipping_kit_id: kit.shipping_id,
          existingSiteName: site?.name,
          siteName: siteName,
          org_id: org?.id,
          org_name: org?.name,
          entered_orgName: orgName,
        };

        // this api endpoint doesn't return anything so just resolve to the passed in sample
        return notifySupportOfUnexpectedKit(copy).then((_) => copy);
      };
    } else if (sample) {
      // editing an existing sample log
      ajaxCall = updateSample;
      samplePayload.id = sample.id;
    } else {
      // we are creating a new sample log
      ajaxCall = createSample;
    }

    return ajaxCall(samplePayload)
      .then((response) => {
        if (onFormSubmissionSuccess) {
          onFormSubmissionSuccess(response.data);
        }
        setCompleted(200);
      })
      .catch((e) => {
        setCompleted(400);
        setConfirm(false);
      })
      .then(() => {
        setSubmitDisabled(false);
      });
  };

  return (
    <Form
      submitDisabled={submitDisabled}
      initialValues={initialValues(sample)}
      onFinish={handleSubmit}
      onFinishFailed={(err) => {
        handleFormErrors({
          err,
          setShowSampleStartDatetimeWarning,
          setShowSampleCollectionDatetimeWarning,
        });
      }}
      onFieldsChange={(changedFields, allFields) => {
        setSubmitDisabled(false);
        handleFormFieldChanges({
          formRef,
          changedFields,
          allFields,
          setMethod,
          setConfirm,
          setShowInfluentFlowInputWarning,
          setShowSampleCollectionDatetimeWarning,
          setShowSampleStartDatetimeWarning,
        });
      }}
      scrollToFirstError={true}
      name="sample-form"
      colon={false}
      layout="vertical"
      ref={formRef}
      data-is-community={isCommunityLocation}
    >
      <SamplingMethod method={method} isRequired={!isInternalUser} />
      <SampleDetails method={method} formRef={formRef} />
      <SampleCollectionDateTime
        formRef={formRef}
        method={method}
        isRequired={isPublic && !isInternalUser}
        setSubmitDisabled={setSubmitDisabled}
        startDatetimeWarning={showSampleStartDatetimeWarning}
        setStartDatetimeWarning={setShowSampleStartDatetimeWarning}
        collectionDatetimeWarning={showSampleCollectionDatetimeWarning}
        setCollectionDatetimeWarning={setShowSampleCollectionDatetimeWarning}
        isInternalUser={isInternalUser}
        site={site}
        sample={sample}
        kitShippingDate={kit.date_shipped}
      />
      {isCommunityLocation && (
        <>
          <SampleMatrix
            defaultSampleMatrix={site?.sample_matrix}
            isRequired={!isInternalUser}
          />
          <InfluentInformation
            formRef={formRef}
            isInternalUser={isInternalUser}
            warning={showInfluentFlowInputWarning}
            site={site}
          />
        </>
      )}
      <Row>
        <Col xs={24}>
          <Form.Item
            name="sample_notes"
            label={<Title level={5}>Additional notes</Title>}
            className="block"
          >
            <TextArea
              data-cy="sample-form__addl-info-input"
              className="full-width"
              placeholder="Enter any additional information"
            />
          </Form.Item>
        </Col>
      </Row>
      <Author isRequired={!isInternalUser} />
    </Form>
  );
}
