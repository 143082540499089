import { Row, Col, Typography } from "antd";
import Notes from "../form/Notes";
import SamplingSiteTypeSelector from "../form/SamplingSiteTypeSelector";

const SiteDetails = ({
  location,
  locationType,
  samplingSiteTypes,
  form,
  isInternal,
}) => {
  const { Title } = Typography;

  return (
    <>
      <Row gutter={[48, 16]} className="mb-1">
        <Col span={24}>
          <Title level={4}>
            Which option best reflects where your sample was collected?
          </Title>
        </Col>
      </Row>
      <Row gutter={[48, 16]}>
        <Col sm={16} xs={24} className="mb-3">
          <SamplingSiteTypeSelector
            samplingSiteTypes={samplingSiteTypes}
            initialValueSiteType={location.sampling_site_type_id?.id}
            initialValueSiteSpecify={location.sampling_site_type_specify}
            form={form}
            isInternal={isInternal}
          />
        </Col>
      </Row>
      {locationType === "facility" && (
        <Row gutter={[48, 16]} className="mb-4">
          <Col sm={24} xs={24} className="mb-3">
            <Notes initialValue={location.notes} locationType={locationType} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default SiteDetails;
