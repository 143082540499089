import { Button, Form, Select } from "antd";
import { CloseCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import React from "react";
import _ from "lodash";

export default function AdditionalCountiesSelector({
  counties,
  initialValues,
  form,
}) {
  const options = (counties ?? [])
    .map((c) => {
      return {
        label: c.county_name,
        value: c.county_id,
      };
    })
    .sort((a, b) => a.label - b.label);

  return (
    <>
      <Form.List name="counties_served" initialValue={initialValues}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div
                style={{ position: "relative" }}
                className="pb-1"
                key={`div-${index}`}
              >
                <Form.Item
                  key={index}
                  {...field}
                  label={index === 0 ? "Intersecting County" : null}
                  rules={[
                    {
                      required: index === 0,
                      message: "Select county",
                    },
                  ]}
                  className="mb-0"
                  data-cy={`sampling-location-form_counties_served_${index}_county_id`}
                >
                  <Select
                    autoComplete="chrome-off"
                    data-cy={`select-secondary-county-${index}`}
                    options={options}
                    showSearch
                    placeholder="Select county"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    disabled={_.isNil(options) || options.length === 0}
                    onSelect={(value) => {
                      const countiesServed = form.getFieldValue(
                        "counties_served"
                      );
                      countiesServed[index] = value;
                      form.setFieldsValue({
                        counties_served: countiesServed,
                      });
                    }}
                  />
                </Form.Item>
                {index !== 0 && (
                  <CloseCircleFilled
                    style={{
                      color: "#dbdbdc",
                      position: "absolute",
                      right: "-24px",
                      top: "9px",
                    }}
                    className="pl-1"
                    onClick={() =>
                      fields.length > 1 ? remove(field.name) : null
                    }
                  />
                )}
              </div>
            ))}
            <Button type="primary" onClick={() => add()}>
              <PlusCircleOutlined /> Additional Intersecting County
            </Button>
          </>
        )}
      </Form.List>
    </>
  );
}
