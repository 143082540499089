import axios from "axios";
import qs from "qs";
import { useState, useEffect, useContext } from "react";
import { AutoComplete, Form, Row, Col, Input, message } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { requiredRule } from "utils/rules";
import { PcrUploadFormContext } from "./PcrUploadFormContext";
import { authorizationHeader } from "../../../api/util";
const initTubeOptionsUrl = `${process.env.REACT_APP_API_URL}tubes`;

const SampleRerunFormFields = ({
  name,
  fieldKey,
  batchFieldIdx,
  rerunFieldIdx,
  onRemove,
  onRerunSelected,
  ...restRerunField
}) => {
  const [loading, setLoading] = useState(false);
  const [tubeLookup, setTubeLookup] = useState("");
  const [tubeOptions, setTubeOptions] = useState([]);
  const [validSampleID, setValidSampleID] = useState(false);
  const form = useContext(PcrUploadFormContext);

  const generateAxiosParams = (tubeLookup, cancelToken) => {
    return {
      headers: authorizationHeader(),
      cancelToken: cancelToken,
      params: [
        { tube_id_contains: tubeLookup },
        { shipping_tube_id_contains: tubeLookup },
      ],
      paramsSerializer: (params) => {
        return qs.stringify({
          _where: {
            _or: params,
          },
        });
      },
    };
  };

  const generateTubeOptions = (tubes) => {
    const options = tubes
      .filter(({ tube_id, shipping_tube_id }) => tube_id || shipping_tube_id)
      .map(({ id, tube_id, shipping_tube_id, date_received, kit }) => {
        return {
          value: shipping_tube_id,
          tube_pk: id,
          tube_id: tube_id,
          shipping_tube_id: shipping_tube_id,
          date_received: date_received,
          kit_id: kit.id,
        };
      });

    return options;
  };

  const filterTubeOptions = (options, userInput) => {
    const uppercaseInput = userInput.tube_id
      ? userInput.tube_id.toUpperCase()
      : userInput.toUpperCase();
    const filteredOptions = options
      .filter(({ tube_id, shipping_tube_id }) => {
        return (
          tube_id?.startsWith(uppercaseInput) ||
          shipping_tube_id?.startsWith(shipping_tube_id)
        );
      })
      .sort(({ label }) => label);

    return filteredOptions;
  };

  useEffect(() => {
    setValidSampleID(false);
    if (!tubeLookup || tubeLookup.length < 2) {
      setTubeOptions([]);
    } else {
      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      setLoading(true);
      const params = generateAxiosParams(tubeLookup, source.token);
      axios
        .get(initTubeOptionsUrl, params)
        .then((res) => {
          const options = generateTubeOptions(res.data);
          setTubeOptions(filterTubeOptions(options, tubeLookup));
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          if (!axios.isCancel(e)) {
            message.error("Something went wrong!");
            setLoading(false);
          }
        });
      return () => {
        source.cancel();
      };
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tubeLookup]);

  const handleSelected = (value, option) => {
    setTubeLookup(option.shipping_tube_id);
    onRerunSelected(option.kit_id, [
      "batches",
      batchFieldIdx,
      "rerun_samples",
      rerunFieldIdx,
      "kit_id",
    ]);

    // setTubeLookup will call `setValidSampleID(false)` async, wait for that to settle before revalidating
    setTimeout(() => {
      setValidSampleID(true);
      form.validateFields();
    }, 250);
  };

  const handleSearch = (value, event) => {
    const searchValue = value?.currentTarget?.value || value;
    const exactMatch = tubeOptions.filter((option) => {
      const matchesTubeExactly =
        option.tube_id?.toUpperCase() === searchValue?.toUpperCase();
      const matchesShippingExactly =
        option.shipping_tube_id?.toUpperCase() === searchValue?.toUpperCase();
      return matchesTubeExactly || matchesShippingExactly;
    })[0];

    if (exactMatch) {
      handleSelected(searchValue, exactMatch);
    }
  };

  const validateSampleID = () => {
    return validSampleID
      ? Promise.resolve()
      : Promise.reject("Select a valid Sample ID");
  };

  return (
    <Row>
      <Col flex="auto">
        <Row gutter={16}>
          {/* Rerun Samples */}
          <Col span={6}>
            <Form.Item
              name={[name, "sample_id"]}
              fieldKey={[fieldKey, "sample_id"]}
              rules={[
                requiredRule("Sample ID is required"),
                { validator: validateSampleID },
              ]}
              {...restRerunField}
            >
              <AutoComplete
                options={tubeOptions}
                style={{ width: "100%" }}
                value={tubeLookup}
                onChange={setTubeLookup}
                onSelect={handleSelected}
                notFoundContent="No Sample found with that ID"
              >
                <Input.Search
                  placeholder="Find Sample by Shipping ID or Internal ID"
                  loading={loading}
                  onPressEnter={handleSearch}
                  onSearch={handleSearch}
                />
              </AutoComplete>
            </Form.Item>
          </Col>

          {/* Rerun Notes */}
          <Col span={12}>
            <Form.Item
              name={[name, "rerun_notes"]}
              fieldKey={[fieldKey, "rerun_notes"]}
              {...restRerunField}
            >
              <Input.TextArea rows={1} placeholder="Rerun Notes" />
            </Form.Item>
          </Col>

          <Form.Item
            name={[name, "kit_id"]}
            fieldKey={[fieldKey, "kit_id"]}
            {...restRerunField}
            hidden
          >
            <Input />
          </Form.Item>
        </Row>
      </Col>

      {/* Separate Col grouping to prevent wrapping */}
      <Col>
        <MinusCircleOutlined onClick={onRemove} style={{ marginTop: "8px" }} />
      </Col>
    </Row>
  );
};

export default SampleRerunFormFields;
