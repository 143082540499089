import produce from "immer";

const getDefaultState = () => {
  return [];
};

const orderDetail = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "ORDER_GET":
      console.log(action);
      nextState = produce(state, (draftState) => action.payload.data);
      return nextState;
    default:
      return state;
  }
};

export default orderDetail;
