import { Helmet } from "react-helmet";
import { fixtures } from "../fixtures";
import { useSignupContext } from "context/SignUpContext";

export default function Failed() {
  const { variant } = useSignupContext();
  const formFixtures = fixtures[variant];

  return (
    <div className="page--sign-up">
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{formFixtures.failedTitleText}</title>
      </Helmet>
      <div className="sign-up">
        <div className="sign-up-header" style={{ textAlign: "center" }}>
          <p
            style={{
              fontSize: "100px",
              margin: "0",
            }}
          >
            😢
          </p>
          <h1>​​Sorry about this.</h1>
          <p>An error occurred while trying to submit your form.</p>
          <p>
            Please try to submit again and if you continue to have issues,
            please contact{" "}
            <a href="mailto:support@biobot.io"> support@biobot.io</a>.
          </p>
          <p>
            Stay up to date with Biobot and sign up for our newsletter
            <a href="https://biobot.io/newsletter-thank-you/"> here</a>.
          </p>
        </div>
      </div>
    </div>
  );
}
