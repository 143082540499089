import { Form, Input } from "antd";
import { GTMolecularRunFn } from "../../components/GTMolecularRunFn";

const FN_NAME = "gtm-run-cancel-order";

let cancelOrderFormItems = (
  <>
    <Form.Item label="Order Id" name="orderId" required="true">
      <Input />
    </Form.Item>
  </>
);

function FnRun() {
  return <GTMolecularRunFn name={FN_NAME} formItems={cancelOrderFormItems} />;
}

export { FnRun };
