import { Button, message, PageHeader, Row } from "antd";
import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import fileDownload from "js-file-download";
import { fetchTriageCSV } from "../../../api/kit";

/*
 * A page for downloading some input data used in the lab's kit triaging process.
 */
export default function TriageSheet() {
  const downloadTriageCSV = () => {
    const date = moment().format("MM-DD-YYYY");
    fetchTriageCSV()
      .then((data) => fileDownload(data, `${date}-lab-triage.csv`))
      .catch(message.error);
  };

  return (
    <>
      <PageHeader title="Triage Sheet" className="mb-3" />
      <Row className="px-3 mb-1">
        <Button
          className="mr-1"
          type="primary"
          size="small"
          shape="round"
          onClick={downloadTriageCSV}
        >
          <DownloadOutlined />
        </Button>
      </Row>
    </>
  );
}
