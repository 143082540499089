import { Link } from "react-router-dom";
import { parseDate, parseTime } from "utils/time";
import EditableTimeZoneCell from "containers/Sample/components/EditableTimeZoneCell";

export const samplingInfoRows = (sample, editDisallowed, refetchKitDetails) => [
  // Row 1: Sampling Location / Method
  [
    {
      title: "Sampling Location",
      tooltip:
        "Tell us where you took this sample so that we can link it other samples taken here. If you're sampling somewhere new, please let us know.",
      value: sample?.sampling_location_id?.id ? (
        <Link
          to={`/admin/sampling-locations/${sample.sampling_location_id?.id}`}
        >
          {sample.sampling_location_id?.sampling_location_name}
        </Link>
      ) : null,
    },
    {
      title: "Sampling Method",
      tooltip:
        "What type of composite sampling method are you using? e.g. time proportional, flow-proportional, volume-proportional, grab sample, passive",
      value: sample.sampling_type,
    },
  ],
  // Row 2: Pumping Time Interval / Sampling Volume / Number of Subsamples
  [
    {
      title: "Pumping Time Interval",
      tooltip:
        "How often are you pumping wastewater? This helps us identify potential improvements in your sampling strategy.",
      value: sample.sampling_interval,
    },
    {
      title: "Sampling volume",
      tooltip:
        "How much volume is being taken up at each pump? This helps us identify potential improvements in your sampling strategy.",
      value: sample.sampling_volume,
    },
    {
      title: "Number of subsamples",
      tooltip:
        "How many subsamples were taken through the course of your composite sampling? e.g. pumping once per hour would yield 24 subsamples.",
      value: sample.num_subsamples,
    },
  ],
  // Row 3: Sampling Start Date / Collection Date
  [
    {
      title: "Sampling start date",
      value: parseDate(sample.sample_start_date_time, sample.time_zone),
    },
    {
      title: "Sampling collection date",
      value: parseDate(sample.sample_collection_date_time, sample.time_zone),
    },
    {
      // this changes to local time zone after the form is submitted with any time
      title: "Sample Log Time Zone",
      value: (
        <EditableTimeZoneCell
          sample={sample}
          editDisallowed={editDisallowed}
          refetchKitDetails={refetchKitDetails}
        />
      ),
      span: 24,
    },
  ],
  // Row 4: Sampling Start Time / Collection Time
  [
    {
      title: "Sampling start time",
      value: parseTime(sample?.sample_start_date_time, sample.time_zone),
    },
    {
      title: "Sampling collection time",
      value: parseTime(sample?.sample_collection_date_time, sample.time_zone),
    },
  ],
  // Row 5: Avg Influent Flow
  [
    {
      title: "Avg Influent Flow over Sample Period",
      tooltip:
        "This information helps us provide more accurate data analysis and interpretation.",
      value: sample.flow_rate_day_mgd
        ? `${sample.flow_rate_day_mgd} MGD`
        : null,
    },
  ],
  // Row 6: Sampling Matrix
  [
    {
      title: "Sampling Matrix",
      value: sample.sample_matrix,
    },
  ],
  // Row 7: Additional Notes
  [
    {
      title: "Additional Notes",
      value: sample.sample_notes,
    },
  ],
];
