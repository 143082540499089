import { Form, Select, Col, Row, Tooltip } from "antd";
import Title from "antd/lib/typography/Title";

const KitProvider = ({ kittingVendors }) => {
  return (
    <>
      <Row>
        <Col span={16}>
          <Title level={3}>Kit Provider</Title>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={8}>
          <Form.Item
            name={"fulfillment_provider"}
            label="Kit Provider"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={"Select kitting provider"}
              data-cy="fulfillment-provider"
            >
              {kittingVendors?.map((kittingVendor) => (
                <Select.Option key={kittingVendor.id} value={kittingVendor.id}>
                  <Tooltip title={kittingVendor.vendor_code}>
                    {kittingVendor.vendor_name}
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default KitProvider;
