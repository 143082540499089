import { Row, Col, Typography } from "antd";

const { Text } = Typography;

export default function SampleLogInfoBanner() {
  return (
    <Row className="info-banner block text-xl px-4 py-2">
      <Col>
        <Text>
          Click{" "}
          <a
            href="https://support.biobot.io/hc/en-us/sections/11661771253399-Shipping"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{" "}
          to see answers to our most frequently asked shipping questions!
        </Text>
      </Col>
    </Row>
  );
}
