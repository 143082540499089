import _ from "lodash";
import { datasetListQuery } from "./datasetService";
import { optionsTemplate } from "./util";

const model = "states";

export const useStateList = datasetListQuery({ model });

const selectNameMap = (data) =>
  _.mapValues(_.keyBy(data, "state_id"), "state_cd");

export const useStateNameMap = () =>
  useStateList({}, { select: selectNameMap });

const selectOptions = optionsTemplate({ label: "state_cd", value: "state_id" });

export const useStateOptions = () =>
  useStateList({}, { select: selectOptions });
