import { Helmet } from "react-helmet";
import { Typography } from "antd";
import { fixtures } from "../fixtures";
import { useSignupContext } from "context/SignUpContext";
import { PROGRAM_BIOBOT_NETWORK_CODE } from "configs/constants";

const { Text } = Typography;

export default function Completed() {
  const { variant } = useSignupContext();
  const formFixtures = fixtures[variant];

  return (
    <div className="page--sign-up">
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{formFixtures.completedTitleText}</title>
      </Helmet>
      <div className="sign-up">
        <div
          className="sign-up-header"
          data-cy="sign-up-submitted"
          style={{ textAlign: "center" }}
        >
          <p
            style={{
              fontSize: "100px",
              margin: "0",
            }}
          >
            🔬
          </p>
          <h1>Submitted.</h1>
          <p>
            Our team will review your form and you can expect to hear from us
            within 2 business days.
          </p>
          <p>
            If you have any questions about next steps in the{" "}
            {formFixtures.programName}, please contact{" "}
            <a href="mailto:support@biobot.io"> support@biobot.io</a>.
          </p>
          {formFixtures.program === PROGRAM_BIOBOT_NETWORK_CODE && (
            <Text level={1}>
              Check out our{" "}
              <a
                href="https://biobot.io/data/"
                target="_blank"
                rel="noreferrer"
              >
                latest public health data
              </a>
              !
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}
