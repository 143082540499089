import { PageHeader, Row, Col, Typography } from "antd";
import { withRouter, Link } from "react-router-dom";

const { Link: AntdLink, Paragraph } = Typography;

const PilotSamplePage = () => (
  <Row className="max-960px mt-5 mx-2">
    <Col xs={{ offset: 1, span: 22 }} sm={{ offset: 2, span: 20 }}>
      <PageHeader title="Kit Pilot Sample Log" className="px-0">
        <Paragraph>
          This is a placeholder page for the future pilot of sample logs. Please
          go to the <Link to="/sample">Sample Page</Link> to submit your sample.
          If you experience any issues submitting your sampling data, please
          contact us at{" "}
          <AntdLink href="mailto:support@biobot.io">support@biobot.io</AntdLink>
          .
        </Paragraph>
      </PageHeader>
    </Col>
  </Row>
);

export default withRouter(PilotSamplePage);
