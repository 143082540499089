const sample = (state = null, action) => {
  switch (action.type) {
    case "SAMPLE_GET":
      if (action.payload.data[0]) {
        return action.payload.data[0];
      } else {
        return null;
      }
    default:
      return state;
  }
};
export default sample;
