import _ from "lodash";

import { Table } from "antd";
import { displayDate, displayDecimal, displayString } from "utils/table";
import { isClose } from "utils/math";
import QCIssueList from "components/QC/QCIssueList";
import QCReviewStatusTag from "components/QC/QCReviewStatusTag";
import "./HistoricalQuantTable.css";
import {
  AliquotId,
  AliquotStatus,
  KitName,
  TargetQuant,
} from "../../../../api/pipelinesAPI/types";
import { KitMetaPartial } from "../types/orderService";
import { ColumnsType } from "antd/lib/table";

interface Props {
  kitNameUnderReview?: KitName;
  targetName: string;
  targetQuants: TargetQuant[];
  statusMap: Record<AliquotId, AliquotStatus>;
  kitMap: Record<KitName, KitMetaPartial>;
  pmmvMap: Record<AliquotId, number>;
  onHighlightedRowChange: (aliquotId?: number) => void;
}

const HistoricalQuantTable = ({
  kitNameUnderReview,
  targetName,
  statusMap,
  targetQuants,
  kitMap,
  pmmvMap,
  onHighlightedRowChange,
}: Props) => {
  const columns: ColumnsType<TargetQuant> = [
    {
      title: "Database ID",
      dataIndex: "qpcr_aliquot_primer_target_quant_id",
    },
    {
      title: "Aliquot Name",
      dataIndex: "qpcr_aliquot_id",
      render: (id: AliquotId) =>
        displayString()(statusMap[id]?.qpcr_aliquot?.aliquot_name),
    },
    {
      title: "Kit Name",
      dataIndex: "qpcr_aliquot_id",
      render: (id: AliquotId) =>
        displayString()(statusMap[id]?.qpcr_aliquot.tube?.kit?.kit_name),
    },
    {
      title: "Date Received",
      dataIndex: "qpcr_aliquot_id",
      render: (id: AliquotId) => {
        const kitName = statusMap[id]?.qpcr_aliquot?.tube?.kit?.kit_name;
        return displayDate()(kitMap[kitName]?.date_received);
      },
    },
    {
      title: "Date Collected",
      dataIndex: "qpcr_aliquot_id",
      sortOrder: "ascend",
      sorter: (a: TargetQuant, b: TargetQuant) => {
        const kitAName =
          statusMap[a.qpcr_aliquot_id]?.qpcr_aliquot?.tube?.kit?.kit_name;
        const kitBName =
          statusMap[b.qpcr_aliquot_id]?.qpcr_aliquot?.tube?.kit?.kit_name;

        const dateA = kitMap[kitAName]?.sample?.sample_collection_date_time;
        const dateB = kitMap[kitBName]?.sample?.sample_collection_date_time;

        if (_.isNil(dateA)) {
          return 1;
        } else if (_.isNil(dateB)) {
          return -1;
        } else {
          return dateA.localeCompare(dateB);
        }
      },
      render: (id: AliquotId) => {
        const kitName = statusMap[id]?.qpcr_aliquot?.tube?.kit?.kit_name;
        const collectionDate =
          kitMap[kitName]?.sample?.sample_collection_date_time;
        return displayDate()(collectionDate);
      },
    },
    {
      title: "Date of QPCR",
      render: (quant: TargetQuant) => {
        return displayDate()(quant.created_at);
      },
    },
    {
      title: "Copies /mL Adjusted (Log)",
      dataIndex: "copies_per_ml_sewage_adjusted",
      render: (conc) => {
        const concSafe = conc || 0.0;
        const closeToZero = concSafe >= -0.001 && concSafe <= 0.001;
        const concentration = closeToZero ? 0.0 : Math.log10(conc);
        return displayDecimal(2)(concentration);
      },
    },
    {
      title: "Copies /mL Adjusted",
      dataIndex: "copies_per_ml_sewage_adjusted",
      render: displayDecimal(2),
    },
    {
      title: "Copies /mL Effective",
      render: (quant: TargetQuant) => {
        if (quant.primer_target_protocol.primer_target_name === "pmmv") {
          return "N/A";
        }

        if (
          _.isNil(quant.copies_per_ml_sewage_adjusted) ||
          _.isNil(pmmvMap[quant.qpcr_aliquot_id])
        ) {
          return "N/A";
        }

        const pmmvConc = pmmvMap[quant.qpcr_aliquot_id];
        if (isClose(pmmvConc, 0.0, 0.001)) {
          return "Inf";
        }

        const effectiveConc = quant.copies_per_ml_sewage_adjusted / pmmvConc;
        return displayDecimal(2)(effectiveConc);
      },
    },
    {
      title: "Detection Status",
      dataIndex: "detection_status",
    },
    {
      title: "Review Status",
      dataIndex: "qpcr_aliquot_id",
      render: (id: AliquotId) => (
        <QCReviewStatusTag status={statusMap[id].status} />
      ),
    },
    {
      title: "Auto-QC Issues",
      dataIndex: "qpcr_aliquot_id",
      render: (id: AliquotId) => (
        <QCIssueList issues={statusMap[id].qpcr_aliquot_qc_issues} />
      ),
    },
    {
      title: "Review Notes",
      dataIndex: "qpcr_aliquot_id",
      render: (id: AliquotId) => {
        return statusMap[id].notes;
      },
    },
  ];

  return (
    <Table
      // @ts-ignore
      rowClassName={(record) => {
        const kitName =
          statusMap[record.qpcr_aliquot_id]?.qpcr_aliquot?.tube.kit.kit_name;
        return kitName === kitNameUnderReview
          ? "quant-row-active-kit"
          : undefined;
      }}
      data-cy={targetName + "-review-target-quants-table"}
      rowKey={(record) =>
        `${targetName}-${record.qpcr_aliquot_primer_target_quant_id}`
      }
      loading={false}
      columns={columns}
      dataSource={targetQuants}
      scroll={{ x: "max-content" }}
      pagination={{ pageSize: 30 }}
      onRow={(record, rowIndex) => {
        return {
          onMouseEnter: (event) => {
            onHighlightedRowChange(record.qpcr_aliquot_id);
          },
          onMouseLeave: (event) => {
            onHighlightedRowChange();
          },
        };
      }}
    />
  );
};

export default HistoricalQuantTable;
