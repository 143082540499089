import { Form, Radio } from "antd";
import React, { useEffect, useState } from "react";

export default function AdditionalCountiesRadio({ initialValue, onChange }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    const val = event.target.value;
    setValue(val);
    onChange(val);
  };

  return (
    <>
      <Form.Item
        className="mb-1"
        data-cy="catchment-area"
        label="Does your catchment area intersect with additional counties?"
      >
        <Radio.Group value={value} onChange={handleChange}>
          <Radio data-cy="yes-area" value={true}>
            Yes
          </Radio>
          <Radio data-cy="no-area" value={false}>
            No
          </Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
}
