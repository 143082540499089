import { EnvironmentOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Radio, Select, Space } from "antd";
import { Form } from "components/Wizard";
import { concatRules, requiredRule, simpleNameRule } from "utils/rules";

const NEW_LOCATION_VALUE = "new_location";

const LocationSelectItem = ({
  locations,
  newValue = NEW_LOCATION_VALUE,
  ...props
}) => (
  <Form.Item {...props}>
    <Select placeholder="Select sampling location">
      {[
        newValue && (
          <Select.Option key={newValue} value={newValue}>
            <PlusOutlined /> Add a sampling location name
          </Select.Option>
        ),
        locations.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            <EnvironmentOutlined /> {item.sampling_location_name}
          </Select.Option>
        )),
      ]}
    </Select>
  </Form.Item>
);

const LocationNameItem = ({ rules, ...props }) => (
  <Form.Item
    label="Sampling location name"
    rules={concatRules(rules, simpleNameRule)}
    {...props}
  >
    <Input placeholder="Enter sampling location name" />
  </Form.Item>
);

const LocationTypeItem = (props) => (
  <Form.Item label="What type of sampling location is this?" {...props}>
    <Radio.Group>
      <Space direction="vertical">
        <Radio value={"community"}>Community-level</Radio>
        <Radio value={"facility"}>Facility-level</Radio>
      </Space>
    </Radio.Group>
  </Form.Item>
);

const LocationStep = ({
  locations,
  isRequired,
  newValue = NEW_LOCATION_VALUE,
  ...props
}) => {
  const hasLocations = locations?.length > 0;
  const isNewLocation = (values) =>
    !hasLocations || values.sampling_location_id === newValue;

  const normalizeValues = (values) => {
    if (isNewLocation(values)) {
      return {
        ...values,
        isNewLocation: true,
        location: {
          sampling_location_name: values.sampling_location_name?.trim(),
          location_type: values.sampling_location_type,
        },
      };
    } else {
      return {
        ...values,
        isNewLocation: false,
        location: locations.find(
          ({ id }) => id === values.sampling_location_id
        ),
      };
    }
  };

  const getRequiredRule = (text) => isRequired && [requiredRule(text)];

  return (
    <Form {...props} normalizeValues={normalizeValues}>
      {(values) => (
        <>
          {hasLocations && (
            <LocationSelectItem
              locations={locations}
              newValue={newValue}
              name="sampling_location_id"
              label="Where did you take this sample?"
              rules={getRequiredRule("Sampling location is requried")}
            />
          )}
          {isNewLocation(values) && (
            <>
              <LocationTypeItem
                name="sampling_location_type"
                rules={getRequiredRule("Sampling location type is required")}
              />
              <LocationNameItem
                name="sampling_location_name"
                rules={getRequiredRule("Sampling location name is required")}
                extra="We'll need to collect information about your new sampling location to help generate our results. Our customer service team will be in touch."
              />
            </>
          )}
        </>
      )}
    </Form>
  );
};

export default LocationStep;
