import { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Select } from "antd";
import LineChart from "./components/LineChart.js";
import { actionReportsGet, actionCasesGet, actionSitesGet } from "actions";
import { LoadingOutlined } from "@ant-design/icons";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionReportsGet,
  actionCasesGet,
  actionSitesGet,
};

const Report = (props) => {
  const [site, setSite] = useState();
  const [siteName, setSiteName] = useState();
  // eslint-disable-next-line no-unused-vars
  const [siteRegion, setSiteRegion] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // call action to get data from Reports API
    if (props.organization.id) {
      setLoading(true);
      props.actionSitesGet(props.organization);
      props
        .actionReportsGet({
          accountId: props.organization.id,
        })
        .then((res) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organization.id]);

  useEffect(() => {
    if (props.sites.length > 0) {
      setSite(props.sites[0].id);
      // setSiteRegion(props.sites[0].site_meta.region_code[0]);
      setSiteName(props.sites[0].sampling_location_name);
    }
  }, [props.sites]);

  useEffect(() => {
    // call to get case data from Datasets API
    setLoading(true);
    if (site) {
      props
        .actionCasesGet({
          regionId: siteRegion,
          startDate: moment(
            props.reports.bySite[site].dateRange.startDate
          ).format("YYYY-MM-DD"),
          endDate: moment(props.reports.bySite[site].dateRange.endDate).format(
            "YYYY-MM-DD"
          ),
        })
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteRegion]);

  if (loading) {
    return (
      <div className="report-loader">
        <LoadingOutlined />
      </div>
    );
  }

  return (
    <div>
      <div className={"page ml-3 pt-2 page-report"}>
        <Row>
          <Col xs={24} sm={24}>
            <h1>Reports</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={16}>
            <h2>{siteName}</h2>
            <p>{siteRegion}</p>
          </Col>
          <Col xs={24} sm={8}>
            <Select
              showSearch
              placeholder="Select site..."
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "250px" }}
              value={site}
              onChange={(value, e) => {
                setSite(Number(value));
                setSiteName(e.children);
              }}
            >
              {props.sites.length > 0 &&
                props.sites?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.sampling_location_name}
                  </Select.Option>
                ))}
            </Select>
          </Col>
        </Row>
        {site && props.reports.bySite[site] && (
          <>
            <div className="indicators">
              <Row gutter={30}>
                <Col xs={24} sm={6}>
                  <div className="indicator-wrapper">
                    <h3>Wastewater trend</h3>
                    <div className="indicator"></div>
                  </div>
                </Col>
                <Col xs={24} sm={6}>
                  <div className="indicator-wrapper">
                    <h3>Wastewater level</h3>
                    <div className="indicator"></div>
                  </div>
                </Col>
                <Col xs={24} sm={6}>
                  <div className="indicator-wrapper">
                    <h3>Daily new cases</h3>
                    <div className="indicator"></div>
                  </div>
                </Col>
                <Col xs={24} sm={6}>
                  <div className="indicator-wrapper">
                    <h3>Positivity rate</h3>
                    <div className="indicator"></div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col xs={24} sm={20} offset={2}>
                <h2>Summary</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer nec turpis enim. Nam sagittis, magna id cursus
                  porttitor, dolor risus pellentesque nunc, at tincidunt arcu
                  nulla et mi. Nam pellentesque non neque sit amet vestibulum.
                  Phasellus maximus sem quis feugiat commodo. Nunc in ante
                  gravida metus molestie dapibus. Vivamus fringilla purus a
                  neque commodo, ut accumsan metus euismod.
                </p>
              </Col>
            </Row>
            <div className="cases-chart">
              <Row>
                <Col xs={24} sm={24}>
                  <h2>Timeseries of daily new cases in County</h2>
                  {site && (
                    <LineChart
                      cases={props.reports.cases}
                      results={props.reports.bySite[site].data}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </>
        )}
        <div className="kits-debug">
          <Row gutter={30}>
            {site &&
              props.reports.bySite[site] &&
              props.reports.bySite[site].data.map((item, index) => (
                <Col sm={6}>
                  <div className="result-item" key={index}>
                    <b>Kit id: {item.kit}</b>
                    <br />
                    <div>Site id: {item.siteid}</div>
                    <div>Result: {item.attributes.detection}</div>
                    <div>
                      Sample date:{" "}
                      {moment(item.sample_date).format("YYYY-MM-DD")}
                    </div>
                    <div>
                      Norm concentration: {item.attributes.norm_concentration}
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

const ReportContainer = connect(mapStateToProps, mapDispatchToProps)(Report);
export default ReportContainer;
