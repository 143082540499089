import { orderServiceListQuery } from "./orderService";
import { optionsTemplate, defaultOptionsForStaticModels } from "./util";

const model = "form-review-statuses";

export const useFormReviewStatusList = orderServiceListQuery({
  model,
  options: defaultOptionsForStaticModels,
});

const selectOptions = optionsTemplate({ label: "status", value: "id" });

export const useFormReviewStatusOptions = () =>
  useFormReviewStatusList({ _joins: false }, { select: selectOptions });
