import _ from "lodash";
import { displayDecimal } from "../../../../utils/table";
import "./ChartTooltip.css";

const ChartTooltip = ({
  active,
  payload,
  label,
  targetNames,
  firstPcrResults,
  rerunResults,
}) => {
  if (active && payload && payload.length) {
    const samplesForDate = [...firstPcrResults, ...rerunResults].filter(
      (r) => r.collectionDate === label
    );

    const sampleNames = samplesForDate.map((s) => s.aliquotName);
    const targetValues = {};

    targetNames.forEach((targetName) => {
      targetValues[targetName] = samplesForDate.map(
        (s) => s[`conc_${targetName}`]
      );
    });

    return (
      <div className="chart-tooltip p-2">
        <table>
          <tr>
            <td></td>
            {sampleNames.map((sampleName) => {
              return <th className="chart-tooltip-header">{sampleName}</th>;
            })}
          </tr>
          {_.toPairs(targetValues).map(([targetName, concentrations]) => {
            return (
              <tr>
                <td className="chart-tooltip-target-cell">{targetName}</td>
                {concentrations.map((c) => {
                  return (
                    <td className="chart-tooltip-concentration-cell">
                      {displayDecimal(3)(c)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </table>
      </div>
    );
  }

  return null;
};

export default ChartTooltip;
