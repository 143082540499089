import { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Typography, Table } from "antd";

import { numberWithCommas } from "../OrderForm/helpers.js";
import { getOrder, getKitsPerCycle } from "./helpers.js";
import { actionOrderGet, actionOrganizationGet } from "actions";
import { useUserContext } from "context";
import Loader from "components/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionOrderGet,
  actionOrganizationGet,
};

const Order = (props) => {
  const { organizationId } = useUserContext();

  const productInfoColumns = [
    {
      title: "SKU Selected When Placing Order",
      dataIndex: ["order_meta", "product"],
      key: "product.product_name",
      render: (record) =>
        record.product_name
          ? `${record.product_sku} (${record.product_name})`
          : "No SKU Selected",
    },
    {
      title: "Cost per Sample",
      dataIndex: ["order_meta", "product"],
      key: "product.product_price",
      render: (record) =>
        record.product_price ? `$${record.product_price}` : "N/A",
    },
    {
      title: "Cycle #",
      dataIndex: [],
      key: "cycle",
      render: (record) => {
        return `${record.cycle} of ${record?.order_placement.cycles ?? "-"}`;
      },
    },
    {
      title: "Kits per Cycle",
      dataIndex: ["shipping_locations"],
      key: "number_of_cycles",
      render: (record) => <>{getKitsPerCycle(record)}</>,
    },
    {
      title: "Total Kits",
      dataIndex: [],
      key: "number_of_cycles",
      render: (record) => (
        <>
          {getKitsPerCycle(record.shipping_locations) * record.number_of_cycles}
        </>
      ),
    },
  ];

  const kitTablecolumns = [
    {
      title: "Kit ID",
      dataIndex: [],
      key: "id",
      render: (record) => (
        <Link to={`/kit/${record.id}`}>
          {record?.shipping_kit_id || `ID Pending`}
        </Link>
      ),
    },
  ];

  useEffect(() => {
    getOrder(props, organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  if (!props.orderDetail?.id) return <Loader />;
  return (
    <div className="ml-3 pt-2">
      <Row gutter={20}>
        <Col xs={24} sm={11}>
          <Typography.Title data-cy="order_number" level={3}>
            Order #{props.match.params.id}
          </Typography.Title>
        </Col>
      </Row>
      {props.orderDetail.order_meta && props.orderDetail.order_meta.product && (
        <>
          <Row gutter={20}>
            <Table
              data-cy="product-info-table"
              columns={productInfoColumns}
              dataSource={[props.orderDetail]}
              pagination={{
                hideOnSinglePage: true,
              }}
            />
          </Row>
          <div className="page">
            <Row gutter={30} className="cycle-total pt-3">
              <Col xs={24} sm={12}>
                <h3>Monthly invoice</h3>
              </Col>
              <Col sm={12}>
                <p>
                  $
                  {numberWithCommas(
                    getKitsPerCycle(props.orderDetail.shipping_locations) *
                      props.orderDetail.order_meta.product.product_price
                  )}
                </p>
              </Col>
            </Row>
            <Row gutter={30} className="order-total">
              <Col xs={24} sm={12}>
                <h3>Order total</h3>
              </Col>
              <Col sm={12}>
                <p>
                  $
                  {numberWithCommas(
                    getKitsPerCycle(props.orderDetail.shipping_locations) *
                      props.orderDetail.number_of_cycles *
                      props.orderDetail.order_meta.product.product_price
                  )}
                </p>
              </Col>
            </Row>
            <Row gutter={30} className="order-total">
              <Col xs={24} sm={12}>
                <h3> Weekly Sampling Schedule</h3>
              </Col>
              <Col sm={12}>
                <p>
                  {props.orderDetail?.sampling_schedule?.length ? (
                    <>
                      {props.orderDetail?.sampling_schedule.length}x per week (
                      {props.orderDetail?.sampling_schedule.join(" and ")})
                    </>
                  ) : (
                    "No schedule selected"
                  )}
                </p>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col xs={24} sm={12}>
                <h3>Shipping Address</h3>
                {props.orderDetail.shipping.map((item) => (
                  <p>
                    {item.address_line_1}
                    <br />
                    {item.address_line_2 && (
                      <>
                        {item.address_line_2}
                        <br />
                      </>
                    )}
                    {item.city}
                    <br />
                    {item.state_id}
                    <br />
                    {item.postal_code}
                    <br />
                    {item.country_id}
                    <br />
                  </p>
                ))}
              </Col>
              <Col xs={24} sm={12}>
                <h3>Organization Address</h3>
                <p>
                  {
                    props.orderDetail.organization.organization_meta
                      .address_line_1
                  }
                  <br />
                  {props.orderDetail.organization.organization_meta
                    .address_line_2 && (
                    <>
                      {
                        props.orderDetail.organization.organization_meta
                          .address_line_2
                      }
                      <br />
                    </>
                  )}
                  {props.orderDetail.organization.organization_meta.city}
                  <br />
                  {props.orderDetail.organization.organization_meta.province}
                  <br />
                  {props.orderDetail.organization.organization_meta.post_code}
                  <br />
                  {props.orderDetail.organization.organization_meta.country}
                  <br />
                </p>
              </Col>
            </Row>
          </div>
          <Row>
            <Typography.Title level={5}>
              Kits Included in Order
            </Typography.Title>
          </Row>
          <Row>
            <Table
              columns={kitTablecolumns}
              dataSource={props.orderDetail.kits}
              pagination={{
                defaultPageSize: 50,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
              }}
            />
          </Row>
        </>
      )}
    </div>
  );
};

const OrderContainer = connect(mapStateToProps, mapDispatchToProps)(Order);
export default withRouter(OrderContainer);
