import { Input, Radio, Slider, Typography } from "antd";
import { Link as RouterLink } from "react-router-dom";
import { Form } from "../components/Wizard";
import { callbackMessage, DemoCard, DemoContainer } from "./util";
const { Text, Link } = Typography;

// Read through the comments for a demonstration of Form features.

const fullName = ({ first_name, last_name }) =>
  [first_name, last_name].filter(Boolean).join(" ");

const Step1 = (props) => (
  <Form
    layout="vertical"
    {...props}
    // normalizeValues transforms values before onValuesChange and onFinish.
    // You usually want to spread ...values into the return -- that is, add
    // values, but do not remove values.
    normalizeValues={(values) => ({
      ...values,
      full_name: fullName(values),
    })}
  >
    <Form.Item
      name="first_name"
      label="First name"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
    <Form.Item name="last_name" label="Last name" rules={[{ required: true }]}>
      <Input />
    </Form.Item>
    {/* error and latency controls just to demonstrate submission + errors */}
    <Form.Item
      name="backend_latency"
      label="Simulated backend latency"
      initialValue={1500}
    >
      <Slider min={0} max={5000} step={100} tipFormatter={(x) => `${x} ms`} />
    </Form.Item>
    <Form.Item name="error" label="Submit status" initialValue={false}>
      <Radio.Group>
        <Radio value={false}>Success</Radio>
        <Radio value={true}>Unknown error</Radio>
        <Radio value={"Sorry, that kit has already been logged."}>
          Known error
        </Radio>
      </Radio.Group>
    </Form.Item>
  </Form>
);

const callbacks = {
  // Called when the entire Wizard has finished
  onFinish: async (values, ...rest) => {
    callbackMessage("onFinish")(values, ...rest);
    // While we're waiting for this promise to resolve, the form enters a
    // "loading" state: the submit button gets a spinner, and is disabled
    await new Promise((resolve) =>
      setTimeout(resolve, values.backend_latency ?? 1500)
    );
    if (typeof values.error === "string") {
      // Throwing Form.Error displays its message in an error alert.
      throw new Form.Error(values.error);
    } else if (values.error) {
      // Throwing any other error results in "Something went wrong!" and a
      // console.error()
      throw new Error("Oops");
    }
  },
};

const formProps = {
  // labelComponent (on Form or Form.Item) renders labels, while allowing the
  // label text to be used for error messages.
  labelComponent: <Text className="text-lg" />,
};

const Description = () => (
  <div className="text-md">
    <p>
      Designed to work with <RouterLink to="/demo/wizard">Wizard</RouterLink>,
      but also works well as an improved antd{" "}
      <Link href="https://ant.design/components/form" target="_blank">
        Form
      </Link>{" "}
      for standalone use.
    </p>
    <p>Features:</p>
    <ul>
      <li>
        Async <code>onFinish</code> callback with loading spinner.
      </li>
      <li>
        Error message when <code>onFinish</code> fails.
      </li>
      <li>
        <code>labelComponent</code> (note that validations use label text).
      </li>
      <li>
        <code>normalizeValues</code> (the form payload includes full_name).
      </li>
      <li>Submit button shown by default.</li>
    </ul>
  </div>
);

const FormDemo = () => {
  return (
    <DemoContainer>
      <DemoCard title="Enhanced Form" description={<Description />}>
        <Step1 {...formProps} {...callbacks} />
      </DemoCard>
    </DemoContainer>
  );
};

export default FormDemo;
