import { CalendarOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";

import { DatePicker, Form, Modal, Typography } from "antd";

import React, { useState } from "react";

import moment from "moment";

import { customRule } from "utils/rules";

/**
 * @param   {object} object
 * @returns {object}
 */
function removeEmptyProperties(object) {
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]) => value != null)
  );
}

const useUpdateEnrollmentPeriodMenuItem = ({ program, programEnrollments }) => {
  // --- Menu Item State
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  // --- Menu Item Props
  const key = "update_enrollment_period";

  const onClickHandler = () => {
    open();
  };

  const menuItemProps = {
    icon: <CalendarOutlined />,
    label: "Update Enrollment Period",
    key,
    onClickHandler,
  };

  const Component = ({ onConfirm }) => {
    // --- Datetime State
    const TODAY = moment.utc().startOf("day");
    const TODAY_FORMAT = "YYYY-MM-DD";

    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});

    // --- Confirm Modal State
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const openConfirm = () => setIsConfirmOpen(true);
    const closeConfirm = () => setIsConfirmOpen(false);

    // --- Modal Click Event Handlers
    const onOkClickHandler = async () => {
      try {
        const {
          start_date: startDateMoment,
          end_date: endDateMoment,
        } = await form.validateFields();

        const start_date = startDateMoment?.format(TODAY_FORMAT);
        const end_date = endDateMoment?.format(TODAY_FORMAT);

        setFormData({ start_date, end_date });

        openConfirm();
      } catch (error) {}
    };

    const onCancelClickHandler = () => {
      close();
    };

    const onConfirmOkClickHandler = () => {
      const attributes = removeEmptyProperties(formData);

      onConfirm(attributes);

      closeConfirm();

      close();
    };

    const onConfirmCancelClickHandler = () => {
      closeConfirm();
    };

    const ruleStartDateCannotBeBeforeProgramStartDate = customRule(
      (_, { start_date }) =>
        !start_date || start_date >= moment(program.start_date),
      `Start date cannot be before the program starts: ${program.start_date}!`
    );

    const ruleStartDateCannotBeInThePast = customRule(
      (_, { start_date }) => !start_date || start_date >= TODAY,
      "Please make an eng-on-call request for modifying past enrollment periods."
    );

    const ruleStartDateCannotBeAfterTheEndDate = customRule(
      (_, { start_date, end_date }) =>
        !start_date || !end_date || start_date < end_date,
      "Start date cannot be after the end date!"
    );

    const ruleEndDateCannotBeAfterProgramEndDate = customRule(
      (_, { start_date, end_date }) =>
        !start_date || end_date <= moment(program.end_date),
      `End date cannot be after the program ends: ${program.end_date}!`
    );

    const ruleEndDateCannotBeInThePast = customRule(
      (_, { end_date }) => !end_date || end_date >= TODAY,
      "Please make an eng-on-call request for modifying past enrollment periods."
    );

    return (
      <Modal
        width={530}
        centered
        className="update-enrollment-period-modal"
        open={isOpen}
        onOk={onOkClickHandler}
        onCancel={onCancelClickHandler}
      >
        <Typography.Title level={3} className="text-thick">
          Update Enrollment Period
        </Typography.Title>
        <Typography.Text style={{ marginBottom: 10, display: "block" }}>
          Please confirm the time period you would like to set the selected
          program enrollments to:
        </Typography.Text>

        <Form name="update-enrollment-period" form={form} layout="horizontal">
          <Form.Item
            name="start_date"
            label="Start Date"
            labelCol={{ span: 4 }}
            dependencies={["start_date_in_the_past", "end_date"]}
            rules={[
              ruleStartDateCannotBeBeforeProgramStartDate,
              ruleStartDateCannotBeInThePast,
              ruleStartDateCannotBeAfterTheEndDate,
            ]}
          >
            <DatePicker
              format={TODAY_FORMAT}
              placeholder="Select Start Date"
              style={{ width: 155 }}
            ></DatePicker>
          </Form.Item>

          <Form.Item
            name="end_date"
            label="End Date"
            labelCol={{ span: 4 }}
            rules={[
              ruleEndDateCannotBeAfterProgramEndDate,
              ruleEndDateCannotBeInThePast,
            ]}
            dependencies={["start_date"]}
          >
            <DatePicker
              format={TODAY_FORMAT}
              placeholder="Select End Date"
              style={{ width: 155 }}
            ></DatePicker>
          </Form.Item>
        </Form>

        <Typography.Text>
          Please contact @eng-on-call in the #eng-support channel on Slack to
          set an enrollment start/end date to a date in the past or to make
          changes to an enrollment that has ended.
        </Typography.Text>

        <Modal
          title={
            <>
              <ExclamationCircleTwoTone style={{ fontSize: 16 }} /> Confirm
            </>
          }
          centered
          open={isConfirmOpen}
          onOk={onConfirmOkClickHandler}
          onCancel={onConfirmCancelClickHandler}
        >
          <Typography.Title level={5}>
            {programEnrollments.length} program enrollments will be updated.
          </Typography.Title>

          {formData.start_date ? (
            <div>
              <Typography.Text strong>Start Date</Typography.Text>:{" "}
              {formData.start_date}
            </div>
          ) : null}

          {formData.end_date ? (
            <div>
              <Typography.Text strong>End Date</Typography.Text>:{" "}
              {formData.end_date}
            </div>
          ) : null}
        </Modal>
      </Modal>
    );
  };

  return { key, menuItemProps, Component };
};

export { useUpdateEnrollmentPeriodMenuItem };
