import { useQueryClient } from "react-query";

/**
 * Wrapper hook around queryClient.fetchQuery. Can be used when a query needs
 * to be run imperatively (e.g. in a callback) instead of declaratively. Unlike
 * useMutation, this makes use of react-query's cache. Unlike the `refetch`
 * function returned by useQuery, the function returned by this hook takes
 * query args.
 *
 * This should be used only in situations where it is not possible to use the
 * declarative useQuery (e.g. async validation of form inputs). Before reaching
 * for useImperativeQuery, consider whether it is possible to rearrange your
 * logic to use useQuery.
 *
 * @param {function} configFn - a function taking any number of arguments and
 *                              returning a react-query config object.
 * @returns {function} function that runs a query imperatively. Any args are
 *                     passed to configFn.
 *
 * @example
 * // Given a custom hook defined with api.util.mkQuery
 * const fetchKit = useImperativeQuery(useKit.query);
 *
 * const validateKit = async (id) => {
 *   const kit = await fetchKit({ id });
 *   return !kit.hasSample;
 * }
 */
const useImperativeQuery = (configFn) => {
  const queryClient = useQueryClient();
  return (...args) => queryClient.fetchQuery(configFn(...args));
};

export default useImperativeQuery;
