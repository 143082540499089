import {
  orderServiceCountQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
  orderServiceCustomMutation,
  orderServiceFetchCsv,
} from "./orderService";

const model = "reports";

export const useReportList = orderServiceListQuery({
  model,
});
export const useReportCount = orderServiceCountQuery({ model });
export const useUpdateReport = orderServiceUpdateMutation({ model });
export const useBulkUpdateReports = orderServiceCustomMutation({
  model,
  path: "bulk-update",
  method: "post",
});

export const fetchReportCsv = ({ _where, _sort }) => {
  return orderServiceFetchCsv({ model, _where, _sort });
};
