import { Form, InputNumber, Typography } from "antd";
import React from "react";

const { Text } = Typography;

export default function PopulationEstimate({ initialValue, isInternal }) {
  return (
    <>
      <Form.Item
        label="Estimated population served"
        name="estimated_population_served"
        className="mb-0"
        initialValue={initialValue}
        rules={[
          {
            required: !isInternal,
            message: "Enter the estimated population",
          },
        ]}
      >
        <InputNumber
          min={0}
          className="full-width"
          placeholder="Enter the estimated population"
        />
      </Form.Item>
      <Text type="secondary">
        If you are sampling at a treatment facility, indicate the population
        served by the facility. If you are sampling at an upstream influent,
        please indicate the population represented in that influent.{" "}
      </Text>
    </>
  );
}
