import { orderServiceListQuery } from "./orderService";
import { optionsTemplate } from "./util";

const model = "assays";

export const useAssayList = orderServiceListQuery({ model });

const selectOptions = optionsTemplate(
  {
    value: "id",
    label: (assay) => assay.name,
    code: "code",
  },
  { sortBy: "code" }
);

export const useAssayOptions = () =>
  useAssayList({ _joins: false }, { select: selectOptions });
