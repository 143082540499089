import React from "react";

// The default context should work for rendering a form outside a Wizard.
const WizardContext = React.createContext({
  showBackAlways: false,
  buttonsComponent: null,
  stepCount: 1,
  isCurrentStep: true,
  step: 0,
  isFirstStep: true,
  isLastStep: true,
  getInitialValues: () => null,
  onFormMount: null,
  onFormUnmount: null,
  onValuesChange: null,
  onBack: null,
  onNext: null,
});

export default WizardContext;
