import { parse as parseCsv } from "csv-parse/dist/esm/sync";
import JSON5 from "json5";
import { pick, zipObject } from "lodash";

const normalize = (field) => field.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

const guessHeaders = (headerRow, fieldNames) => {
  const fields = Object.fromEntries(fieldNames.map((f) => [normalize(f), f]));
  const headers = headerRow.map((header) => fields[normalize(header)]);
  const missingFields = fieldNames.filter((x) => !headers.includes(x));
  if (missingFields.length > 0) {
    throw new Error(
      `Header row is missing the following fields: ${missingFields.join(", ")}`
    );
  }
  return headers;
};

const tableToObjects = (rows, fieldNames) => {
  const headers = guessHeaders(rows[0], fieldNames);
  return rows.slice(1).map((row) => pick(zipObject(headers, row), fieldNames));
};

const parseCsvAsJson = (str, fieldNames) => {
  const rows = parseCsv(str);
  if (rows.length < 2) {
    throw new Error("Missing header row");
  }
  return tableToObjects(rows, fieldNames);
};

const parseTsvAsJson = (str, fieldNames) => {
  const rows = parseCsv(str, { delimiter: "\t" });
  if (rows.length < 2) {
    throw new Error("Missing header row");
  }
  return tableToObjects(rows, fieldNames);
};

/** Parses an object or object[] param as json */
const parseJson = (param, str) => {
  const errors = [];
  try {
    return JSON5.parse(str);
  } catch (e) {
    errors.push(e.message);
  }
  if (param.type === "array") {
    const fieldNames = Object.keys(param.element.fields);
    try {
      return parseTsvAsJson(str, fieldNames);
    } catch (e) {
      errors.push(`TSV: ${e.message}`);
    }
    try {
      return parseCsvAsJson(str, fieldNames);
    } catch (e) {
      errors.push(`CSV: ${e.toString()}`);
    }
  }
  throw new Error(`Error parsing as json: ${errors.join("; ")}`);
};

export default parseJson;
