import { PageHeader, Table } from "antd";

import { useVendorEventList, useVendorEventCount } from "api/vendorEvent";
import { useTableQueryParams } from "components/Table/helpers";
import { vendorEventTableColumns } from "./helpers";

const VendorEventsPage = () => {
  const { queryParams, handleTableChange, pagination } = useTableQueryParams({
    defaultSort: "created_at:DESC",
  });
  const vendorEvents = useVendorEventList(queryParams);
  const { data: vendorEventCount } = useVendorEventCount(queryParams);

  return (
    <>
      <PageHeader title="Vendor Data Transfer Events" />
      <div className="ml-3">
        <Table
          data-cy="vendor-events-table"
          rowKey="id"
          loading={vendorEvents.isLoading}
          columns={vendorEventTableColumns}
          dataSource={vendorEvents.data}
          onChange={handleTableChange}
          pagination={{ ...pagination, total: vendorEventCount }}
          scroll={{ x: "max-content" }}
        />
      </div>
    </>
  );
};

export default VendorEventsPage;
