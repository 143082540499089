import { Col, Radio, Row, Typography } from "antd";
import { useEffect, useState } from "react";

const { Title } = Typography;

const SelectLocationType = ({ location, onLocationTypeChanged }) => {
  const [value, setValue] = useState(null);

  const handleChange = (val) => {
    setValue(val);
    onLocationTypeChanged(val);
  };

  useEffect(() => {
    setValue(location.location_type);
  }, [location]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>
            What type of catchment do your samples represent?*
          </Title>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={24}>
          <Radio.Group
            onChange={(e) => handleChange(e.target.value)}
            value={value}
          >
            <Radio value={"community"} className="full-width">
              <strong>Neighborhood or City-level:</strong> Samples representing
              a city or parts of a city, collected from a wastewater treatment
              plant or manhole.
            </Radio>
            <Radio value={"facility"}>
              <strong>Building-level:</strong> Samples representing a building
              (school, workplace, prison, etc.) collected from either a
              clean-out, manhole, or wastewater treatment plant.
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
};

export default SelectLocationType;
