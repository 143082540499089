import moment from "moment";

import { useProgramList as usePrograms } from "api/program";

import { multipleChoiceFilterProps } from "components/Table/MultipleChoiceFilter";
import { METHOD_CUSTOM_FILTER } from "components/Table/StrapiFilter";

const TODAY = moment.utc().startOf("day").toISOString();

const PROGRAM_ENROLLMENT_FIELD = "program_enrollment";

const PROGRAM_ENROLLMENT_STATUS_CURRENTLY_ENROLLED = "currently_enrolled";
const PROGRAM_ENROLLMENT_STATUS_PREVIOUSLY_ENROLLED = "previously_enrolled";

const PROGRAM_ENROLLMENT_STATUSES = [
  {
    label: "Currently Enrolled",
    value: PROGRAM_ENROLLMENT_STATUS_CURRENTLY_ENROLLED,
  },
  {
    label: "Previously Enrolled",
    value: PROGRAM_ENROLLMENT_STATUS_PREVIOUSLY_ENROLLED,
  },
];

/**
 * Get the qualified filter field path.
 *
 * @param   {...string} fields
 * @returns {string}
 */
function getFieldPath(...fields) {
  return [PROGRAM_ENROLLMENT_FIELD].concat(fields).join(".");
}

/**
 * Create the filter queries based on the given selections.
 *
 * @param   {Array<[string, string[]]>} selections
 * @returns {Array<Record<string, string|number>>}
 */
function createFilterQueries(selections) {
  const mapToFilterQueries = (program, status) => {
    switch (status) {
      case PROGRAM_ENROLLMENT_STATUS_CURRENTLY_ENROLLED: {
        return {
          [getFieldPath("program")]: program,
          [getFieldPath("start_date_lte")]: TODAY,
          [getFieldPath("end_date_gte")]: TODAY,
        };
      }

      case PROGRAM_ENROLLMENT_STATUS_PREVIOUSLY_ENROLLED: {
        return {
          [getFieldPath("program")]: program,
          [getFieldPath("start_date_lt")]: TODAY,
          [getFieldPath("end_date_lt")]: TODAY,
        };
      }

      default: {
        return null;
      }
    }
  };

  return selections.map(([program, [status]]) =>
    mapToFilterQueries(program, status)
  );
}

/**
 * Sampling Locations Program Enrollment Filter props helper.
 *
 * @param   {Array<>} programs
 * @returns {Record<string, any>}
 */
function samplingLocationsProgramEnrollmentFilterProps(programs) {
  const choices = programs?.map((program) => {
    const id = program.id;
    const label = program.short_name ?? program.name;

    return { id, label, values: PROGRAM_ENROLLMENT_STATUSES };
  });

  const toStrapiFilter = (selections) => {
    const filter = {
      _or: createFilterQueries(selections),
    };

    return { method: METHOD_CUSTOM_FILTER, value: filter };
  };

  return multipleChoiceFilterProps({ choices, toStrapiFilter });
}

/**
 * Sampling Locations Program Enrollment Filter props hook.
 *
 * @returns {unknown}
 */
function useSamplingLocationsProgramEnrollmentFilterProps() {
  const { data: programs } = usePrograms();

  return {
    samplingLocationsProgramEnrollmentFilterProps: () =>
      samplingLocationsProgramEnrollmentFilterProps(programs),
  };
}

export { useSamplingLocationsProgramEnrollmentFilterProps };
