import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Row, Typography } from "antd";
import { useSignupContext } from "context/SignUpContext";
import { useState } from "react";
import { fixtures } from "../../fixtures";
import SamplingLocationQuestions from "./SamplingLocationQuestions";

const { Title, Text } = Typography;

export default function SamplingLocations({ form, organization }) {
  const { variant } = useSignupContext();
  const formFixtures = fixtures[variant];

  const [shippingLocations, setShippingLocations] = useState(
    organization && formFixtures.showOrgQuestions
      ? organization.shipping_locations
      : []
  );

  const samplingLocations =
    organization && formFixtures.showSamplingLocations
      ? organization.sampling_locations
      : [{}];

  const handleNewShippingLocation = (shippingLocation) => {
    setShippingLocations([...shippingLocations, shippingLocation]);
  };

  return (
    <>
      <Title level={4}>Sampling Locations and Shipping Addresses</Title>
      {!formFixtures.isFormForExistingCustomers && (
        <Row className="mb-3">
          <Col>
            <Text>
              Below you will specify the unique name for each of your sampling
              locations as well as where we should ship sampling kits.
            </Text>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col span={24}>
          <Form.List name="sampling_locations" initialValue={samplingLocations}>
            {(fields, { add }) => (
              <>
                {fields.map((field, index) => (
                  <SamplingLocationQuestions
                    required={index === 0}
                    index={index}
                    key={index}
                    form={form}
                    program={formFixtures?.program}
                    samplingLocation={samplingLocations[index]}
                    shippingLocations={shippingLocations}
                    handleNewShippingLocation={handleNewShippingLocation}
                  />
                ))}
                {formFixtures.allowAdditionalLocations && (
                  <Row>
                    <Divider className="mt-2" />
                    <Button
                      className="mb-4"
                      type="primary"
                      onClick={() => add()}
                    >
                      Add Sampling Location <PlusCircleOutlined />
                    </Button>
                  </Row>
                )}
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </>
  );
}
