import { Col, Popover, Row } from "antd";

import "./QCIssueList.css";
import { QCAction, QCIssue } from "../../api/pipelinesAPI/types";
import _ from "lodash";

const QC_ACTION_RANK: Record<QCAction, number> = {
  [QCAction.QC_REJECT]: 1,
  [QCAction.QC_FLAG_RERUN_SAMPLE]: 2,
  [QCAction.QC_RERUN]: 3,
  [QCAction.QC_REVIEW]: 4,
  [QCAction.QC_FLAG_MISSING_LOG]: 5,
  [QCAction.QC_WARN]: 6,
};

const listItemShortForm = (issue: QCIssue) => {
  return (
    <li key={"qc-issues-short-" + issue.qpcr_aliquot_qc_issues_id}>
      <span className={"action-tag-" + issue.action}>[{issue.action}]</span>{" "}
      <span>{issue.issue_code}</span>
    </li>
  );
};

const listItemLongForm = (issue: QCIssue) => {
  const meta = _.isNil(issue.meta) ? {} : JSON.parse(issue.meta);

  return (
    <div key={"qc-issues-long-" + issue.qpcr_aliquot_qc_issues_id}>
      <Row>
        <Col span={24} className="popover-issue-title">
          <span className={"action-tag-" + issue.action}>[{issue.action}]</span>{" "}
          <span>{issue.issue_code}</span>
        </Col>
      </Row>
      <Row className="meta-content p-1 pl-3">
        <Col span={24}>
          {Object.entries(meta).map(([key, value]: [string, any], i) => {
            return (
              <div
                key={
                  "qc-issue-" + issue.qpcr_aliquot_qc_issues_id + "-meta-" + key
                }
                className={`meta-content-${i % 2}`}
              >
                <span className="meta-content-key">{key}</span>:{" "}
                <span>{value}</span>
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

interface Props {
  issues: QCIssue[];
}

const QCIssueList = ({ issues }: Props) => {
  const nonWarnings = (issues ? issues : []).filter((i) => i.action !== "warn");

  if (!nonWarnings || nonWarnings.length === 0) {
    return <p>No Issues</p>;
  }

  const sorted = nonWarnings.sort((l, r) => {
    // @ts-ignore
    const lRank = QC_ACTION_RANK[l.action];
    // @ts-ignore
    const rRank = QC_ACTION_RANK[r.action];

    if (lRank === null) {
      return 1;
    } else if (rRank == null) {
      return -1;
    } else {
      return lRank - rRank;
    }
  });

  const abbreviatedList = (
    <ul>
      {sorted.slice(0, 3).map(listItemShortForm)}
      <li>...</li>
    </ul>
  );

  const fullListShortForm = <ul>{sorted.map(listItemShortForm)}</ul>;

  const fullListLongForm = (
    <div className="issue-popover mb-3">{sorted.map(listItemLongForm)}</div>
  );

  return (
    <Popover placement="left" content={fullListLongForm}>
      {sorted.length > 3 ? abbreviatedList : fullListShortForm}
    </Popover>
  );
};

export default QCIssueList;
