import { Breadcrumb, Typography } from "antd";
import _ from "lodash";
import {
  UpdateActiveAssayAction,
  UpdateKitUnderReviewAction,
} from "../../../reducers/reducerManualReview";
import React, { useContext } from "react";
import { assayDisplayMap } from "./util";
import { ReviewReducerContext } from "./reviewContext";

const ReviewWorkflowBreadcrumbs = () => {
  const { state, dispatch } = useContext(ReviewReducerContext);

  if (_.isNil(state) || _.isNil(dispatch)) {
    return (
      <Breadcrumb>
        <Breadcrumb.Item key="man-review-root-crumb">
          <Typography.Text>Manual Review</Typography.Text>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  const assayName = state.activeAssayName;
  const kitName = _.isNil(assayName)
    ? null
    : state.assayStates[assayName].kitNameUnderReview;

  const items = [
    <Breadcrumb.Item key="man-review-root-crumb">
      <Typography.Link
        onClick={(event) => {
          event.preventDefault();
          dispatch(new UpdateActiveAssayAction(null));
        }}
      >
        Manual Review
      </Typography.Link>
    </Breadcrumb.Item>,
  ];

  if (!_.isNil(assayName) && !_.isNil(kitName)) {
    items.push(
      <Breadcrumb.Item key="man-review-assay-crumb">
        <Typography.Link
          onClick={(event) => {
            event.preventDefault();
            dispatch(new UpdateKitUnderReviewAction(assayName, null));
          }}
        >
          {assayDisplayMap[assayName]}
        </Typography.Link>
      </Breadcrumb.Item>
    );
    items.push(
      <Breadcrumb.Item key="man-review-kit-crumb">
        <Typography.Text>{kitName || ""}</Typography.Text>
      </Breadcrumb.Item>
    );
  } else if (!_.isNil(assayName)) {
    items.push(
      <Breadcrumb.Item key="man-review-kit-crumb">
        <Typography.Text>{assayDisplayMap[assayName]}</Typography.Text>
      </Breadcrumb.Item>
    );
  }

  return <Breadcrumb>{items}</Breadcrumb>;
};

export default ReviewWorkflowBreadcrumbs;
