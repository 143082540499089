import { Row, Col, Form, Tooltip, InputNumber, Typography } from "antd";
import { WWTP_MGD_LIMIT } from "configs/constants";
import { customRule } from "utils/rules";

export default function InfluentInformation({
  formRef,
  warning,
  site,
  isInternalUser,
}) {
  const MAX_CAPACITY_FACTOR = 5;
  const MAX_CAPACITY_MGD = (site?.capacity_mgd ?? 0) * MAX_CAPACITY_FACTOR;

  const averageInfluentFlowOverSamplePeriodMGDRule = customRule({
    validator(_, fields) {
      if (!fields?.flow_rate_day_mgd || !MAX_CAPACITY_MGD) {
        return true;
      }

      return fields?.flow_rate_day_mgd <= MAX_CAPACITY_MGD;
    },
    message: `The average influent flow over the sample period cannot be greater than ${MAX_CAPACITY_FACTOR} times the influent flow for the facility. Please check your input.`,
    warningOnly: isInternalUser,
  });

  const { Title, Text } = Typography;
  const name = "flow_rate_day_mgd";

  const blankWarning = "Are you sure you want to leave this blank?";
  const largeMGDError = (
    <div className="mb-5">
      You cannot enter values greater than {WWTP_MGD_LIMIT} MGD (
      <b>one billion gallons per day</b>) as that exceeds the expected average
      influent flow for all facilities. Please make sure if your daily influent
      flow is in <b>gallons</b> that you divide by 1,000,000 to get units in
      millions of gallons per day (<b>MGD</b>) before submitting in the form.
    </div>
  );

  return (
    <Row className="mb-5">
      <Col span={24}>
        <Title level={4}>Influent information</Title>
      </Col>
      <Col span={24}>
        <Row gutter={48}>
          <Col sm={12} xs={24} className="mb-3">
            <Tooltip placement="right" title={blankWarning} visible={warning}>
              <Form.Item
                name={name}
                label={"Average influent flow over sample period (MGD)"}
                rules={[
                  {
                    type: "number",
                  },
                  customRule(
                    (value) => !value || value <= WWTP_MGD_LIMIT,
                    largeMGDError
                  ),
                  averageInfluentFlowOverSamplePeriodMGDRule,
                ]}
                hasFeedback
                validateStatus={warning ? "warning" : ""}
                className="mb-0 block"
              >
                <InputNumber
                  data-cy="influent-information__avg-influent-flow-input"
                  className="full-width"
                  placeholder="Average influent flow"
                  min={0}
                />
              </Form.Item>
              <Text type="secondary" className="mb-1">
                Enter the average daily influent flow for your facility during
                the sampling period.
              </Text>
              <Text type="secondary" className="mb-1">
                If you don’t have the information for this sample, please use
                the daily average influent flow for your facility from the last
                year. If you do not have this information, we will use the data
                provided on your Sampling Location Details form.
              </Text>
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
