import { useState } from "react";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Modal, Typography, Upload } from "antd";

import { getSignedS3DownloadUrl } from "api/fileUpload";
import { toLocaleString } from "utils/time";

const LcmsUploadFileModal = ({
  isOpen,
  isUploadingFile,
  lcmsUpload,
  onCancel,
  onSubmit,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [newFile, setNewFile] = useState();

  const handleDownloadClick = async () => {
    setIsDownloading(true);
    const fileUrl = await getSignedS3DownloadUrl(
      "lcms-uploads",
      lcmsUpload?.id
    );
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = lcmsUpload?.file_name;
        link.click();
        link.remove();
      })
      .catch((error) => console.error(error));

    setIsDownloading(false);
  };

  const handleUploadChange = (info) => {
    const file = info?.file;
    if (file?.status === "done") {
      setNewFile(file?.originFileObj || file);
    } else if (file?.status === "error") {
      message.error("Failed to upload file. Please try again.");
    }
  };

  return (
    <Modal
      title={
        <Typography.Title style={{ marginBottom: 0 }} level={4}>
          LCMS Results File
        </Typography.Title>
      }
      className="lcms-upload-file-modal"
      destroyOnClose={true}
      open={isOpen}
      onCancel={onCancel}
      footer={
        <div className="flex justify-between">
          <div>
            <Button
              size="small"
              className="mr-1"
              loading={isDownloading}
              onClick={handleDownloadClick}
            >
              Download Current File <DownloadOutlined />
            </Button>
            <Upload
              accept=".csv"
              maxCount={1}
              onChange={handleUploadChange}
              customRequest={({ onSuccess }) => onSuccess("ok")}
            >
              <Button size="small">
                Upload New File <UploadOutlined />
              </Button>
            </Upload>
          </div>
          <Button
            type="primary"
            size="small"
            disabled={!newFile}
            loading={isUploadingFile}
            onClick={() => onSubmit(lcmsUpload, newFile)}
          >
            Submit
          </Button>
        </div>
      }
    >
      <p>{lcmsUpload?.file_name}</p>
      <p className="text-sm">
        <i>Last Updated: {toLocaleString(lcmsUpload?.updated_at)}</i>
      </p>
    </Modal>
  );
};

export default LcmsUploadFileModal;
