import { connect } from "react-redux";
import _ from "lodash";
import { Form, InputNumber, Button, Select, Row, Col, Typography } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import {
  actionShippingLocationAdd,
  actionShippingLocationDelete,
} from "actions";

const { Option, OptGroup } = Select;
const { Title } = Typography;

const mapStateToProps = (state) => {
  if (state) {
    return {
      order: state.order,
      shippingAddresses: state.organization.shipping_locations,
    };
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionShippingLocationAdd,
  actionShippingLocationDelete,
};

const ShippingLocations = ({
  order,
  shippingAddresses,
  actionShippingLocationAdd,
  actionShippingLocationDelete,
}) => {
  const generateShippingAddresses = (addresses) => {
    const [activeLocations, inactiveLocations] = _.partition(
      addresses,
      "active"
    );

    const generateOptions = (locations) =>
      locations.map((opt, idx) => (
        <Option
          className={`${
            opt.active ? "active" : "inactive"
          }-shipping-location-option`}
          key={`${opt.active ? "active" : "inactive"}-${idx}`}
          value={opt.id}
          disabled={!opt.active}
        >
          {opt.name} -{" "}
          {opt.address_line_1 ?? opt.shipping_location_meta.address_line_1},{" "}
          {opt.city ?? opt.shipping_location_meta.city},{" "}
          {opt.postal_code ?? opt.shipping_location_meta.post_code}
        </Option>
      ));

    return (
      <>
        <OptGroup label="Active" key="Active">
          {generateOptions(activeLocations)}
        </OptGroup>
        <OptGroup label="Inactive" key="Inactive">
          {generateOptions(inactiveLocations)}
        </OptGroup>
      </>
    );
  };

  return (
    <Row className="mb-3">
      <Col span={16}>
        <Title level={3}>Shipping Addresses</Title>
      </Col>

      {order["shipping-locations"].map((item, index) => (
        <Col span={24} key={index} data-cy="shipping-locations">
          <Row>
            <Col span={4}>
              <Form.Item
                name={`location-${index + 1}-number-of-kits`}
                label="Number of kits"
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 1,
                    max: 100,
                  },
                ]}
              >
                <InputNumber placeholder={`1`} />
              </Form.Item>
            </Col>

            <Col offset={1} span={18}>
              <Form.Item
                name={`location-${index + 1}-shipping-address`}
                label="Shipping address"
                rules={[{ required: true }]}
              >
                <Select placeholder="Select shipping address">
                  <Option key="new" value="new">
                    <PlusOutlined /> Add new address
                  </Option>
                  {shippingAddresses &&
                    generateShippingAddresses(shippingAddresses)}
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <CloseOutlined
                onClick={() => actionShippingLocationDelete({ index })}
              />
            </Col>
          </Row>
        </Col>
      ))}

      <Button
        className="btn--caps"
        id="shipping-locations-button"
        type="primary"
        size="large"
        onClick={() => {
          actionShippingLocationAdd({
            index: order["shipping-locations"].length + 1,
          });
        }}
      >
        Ship to multiple addresses
      </Button>
    </Row>
  );
};

const ShippingLocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingLocations);
export default ShippingLocationsContainer;
