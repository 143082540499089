import { Button, Col, PageHeader, Row, Table, Tooltip } from "antd";
import { DownloadOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";

import fileDownload from "js-file-download";

import React from "react";
import { Link, withRouter } from "react-router-dom";

import moment from "moment";

import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_CONTAINS,
  strapiFilterProps,
} from "components/Table/StrapiFilter";

import {
  useOrderPlacementTable,
  useOrderPlacementCount,
  exportOrderPlacements,
} from "api/orderPlacement";

import { useTableQueryParams } from "components/Table/helpers";

import { trackingNumberLink } from "utils/components";
import OrderFulfillmentPriorityTag from "components/OrderFulfillmentPriorityTag";

import { shippingSpeedFilters } from "pages/admin/OrderPlacements/helpers";

const MAX_RECORDS_LIMIT = 5000;

const OrderPlacements = () => {
  const { queryParams, pagination, handleTableChange } = useTableQueryParams({
    defaultPageSize: 25,
    defaultSort: "created_at:DESC",
  });

  const orderPlacements = useOrderPlacementTable({ ...queryParams });

  const { data: orderPlacementsCount } = useOrderPlacementCount(queryParams);

  const handleDownloadCSV = async () => {
    const orderPlacementsCSV = await exportOrderPlacements({
      _where: queryParams._where,
      _sort: queryParams._sort,
      _limit: orderPlacementsCount,
    });

    fileDownload(orderPlacementsCSV, "order_placements.csv");
  };

  const columns = [
    {
      title: "Order Placement ID",
      key: "id",
      dataIndex: ["order_placement_id"],

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Order Placement ID",
      }),
    },
    {
      title: "Organization",
      key: "organization.organization_name",
      dataIndex: ["organization"],
      render: (organization) => {
        return organization ?? "-";
      },

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Organization Name",
      }),
    },
    {
      title: "Shipping Cycles",
      key: "cycles",
      dataIndex: ["shipping_cycles"],
    },
    {
      title: "Shipping Speed",
      key: "shipping_speed",
      dataIndex: ["shipping_speed"],
      filters: shippingSpeedFilters,
      render: (shippingSpeed) => {
        return shippingSpeed ?? "-";
      },
    },
    {
      title: "Confirmation Email Sent",
      key: "send_confirmation_email",
      dataIndex: ["confirmation_email_sent"],
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      render: (confirmationEmailSent) => {
        return confirmationEmailSent ? "Yes" : "No";
      },
    },
    {
      title: "Confirmation Email Recipient",
      key: "confirmation_email",
      dataIndex: ["confirmation_email_recipient"],

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: "Search Email Recipient",
      }),
    },
    {
      title: "Date Placed",
      key: "created_at",
      dataIndex: ["date_placed"],
      render: (datePlaced) => {
        return moment(datePlaced).format("MM/DD/YYYY");
      },

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
      }),
    },
  ];

  const expandableConfig = () => ({
    rowExpandable: (orderPlacement) =>
      orderPlacement.orders && orderPlacement.orders.length > 0,
    expandedRowRender: (orderPlacement) => {
      const nestedColumns = [
        {
          title: "Order #",
          key: "order_id",
          dataIndex: ["order_id"],
        },
        {
          title: "Cycle #",
          key: "cycle_number",
          dataIndex: ["cycle_number"],
        },
        {
          title: "Shipping Kit IDs",
          key: "shipping_kit_ids",
          dataIndex: [],
          render: (order) => {
            const shippingKitIds = order.shipping_kit_ids.map(
              ([id, shipping_kit_id]) => (
                <>
                  <Link to={`/kit/${id}`} key={shipping_kit_id}>
                    {shipping_kit_id ?? "ID Pending"}
                  </Link>
                  <br />
                </>
              )
            );

            return shippingKitIds.length ? shippingKitIds : "-";
          },
        },
        {
          title: "Kit IDs (Biobot)",
          key: "kit_ids",
          dataIndex: [],
          render: (order) => {
            const kitIds = order.kit_ids.map(([id, kit_id]) => (
              <>
                <Link to={`/kit/${id}`} key={kit_id}>
                  {kit_id ?? "ID Pending"}
                </Link>
                <br />
              </>
            ));

            return kitIds.length ? kitIds : "-";
          },
        },
        {
          title: "Date Shipped",
          key: "date_shipped",
          dataIndex: ["date_shipped"],
          render: (dateShipped) =>
            dateShipped ? moment(dateShipped).format("MM/DD/YYYY") : null,
        },
        {
          title: "Shipping Address Name",
          key: "shipping_address_name",
          dataIndex: ["shipping_address_name"],
        },
        {
          title: "Outbound Tracking Numbers",
          key: "outbound_tracking_numbers",
          dataIndex: ["outbound_tracking_numbers"],
          render: (records) => trackingNumberLink(records),
        },
        {
          title: "Inbound Tracking Numbers",
          key: "inbound_tracking_numbers",
          dataIndex: ["inbound_tracking_numbers"],
          render: (records) => trackingNumberLink(records),
        },
        {
          title: "Fulfillment Priority",
          key: "fulfillment_priority",
          dataIndex: [],
          render: (order) => {
            return (
              <OrderFulfillmentPriorityTag
                priority={order.fulfillment_priority}
              />
            );
          },
        },
      ];

      return (
        <Table
          columns={nestedColumns}
          dataSource={orderPlacement.orders}
          pagination={false}
          rowKey="order_id"
          bordered
        />
      );
    },
  });

  return (
    <div className="admin-order-placements-page" style={{ height: "100%" }}>
      <PageHeader
        title="Order Placements"
        className="mb-3"
        style={{
          padding: 0,
          marginBottom: "15px",
        }}
        footer={
          <Row justify="space-between">
            <Col span={16}>
              <Row>
                <Button
                  onClick={() => {
                    handleDownloadCSV();
                  }}
                  className="ml-1"
                  type="primary"
                  shape="round"
                >
                  <DownloadOutlined />

                  {orderPlacementsCount > MAX_RECORDS_LIMIT ? (
                    <Tooltip
                      placement="right"
                      fill="red"
                      title={`More than ${MAX_RECORDS_LIMIT} records requested for download. CSV results will be truncated.`}
                    >
                      <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
                    </Tooltip>
                  ) : null}
                </Button>
              </Row>
            </Col>
          </Row>
        }
      />
      <Table
        className="admin-order-placements-table"
        rowKey="order_placement_id"
        loading={orderPlacements.isLoading}
        columns={columns}
        expandable={expandableConfig()}
        pagination={{ ...pagination, total: orderPlacementsCount }}
        dataSource={orderPlacements.data}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default withRouter(OrderPlacements);
