const metachars = new Set("{}[]()^$.|*+?\\-".split(""));
const escapeRegex = (s) => (metachars.has(s) ? `\\s` : s);

/** Converts `search` into a fuzzy pattern and returns any matches against
 * `text`. */
export const fuzzyMatch = (text, search) => {
  // A real hack :) "does every letter in `search` appear in `text`, in order"
  const pattern = new RegExp(search.split("").map(escapeRegex).join(".*"), "i");
  return text.match(pattern);
};

/** Converts a string to title tase, splitting on spaces, dashes, and
 * understores. */
export const titleCase = (name) =>
  name
    .split(/[-_ ]/)
    .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1))
    .join(" ");

/** Returns a reasonable default title for a column */
export const niceTitle = (attr) => {
  const parts = attr.dataIndex.map(titleCase);
  if (parts.length === 1) {
    return parts[0];
  } else {
    const parentPath = parts.slice(0, -1).join(" -> ");
    return `${parts.slice(-1)[0]} (via ${parentPath})`;
  }
};

const encodeJson = (xs) => xs.filter(Boolean).map(JSON.stringify);
const decodeJson = (xs) => xs.filter(Boolean).map(JSON.parse);

/** A param type for useQueryParam */
export const JsonArrayParam = {
  encode: (xs) => (Array.isArray(xs) ? encodeJson(xs) : encodeJson([xs])),
  decode: (xs) => (Array.isArray(xs) ? decodeJson(xs) : decodeJson([xs])),
};
