import { Button, Input, Form } from "antd";
import axios from "axios";
import { useState } from "react";
import { withRouter, Link } from "react-router-dom";

const PwdResetBegin = () => {
  const [sent, setSent] = useState(false);

  if (sent) {
    return (
      <div>
        <p>Please check your email.</p>
        <p>
          <Link to="/account/reset">Click me</Link> if you have the code.
        </p>
      </div>
    );
  }

  return (
    <>
      <Form
        onFinish={(values) => {
          axios
            .put(
              `${process.env.REACT_APP_API}/identity/cognito/password/forgot/${values.email}`
            )
            .then(() => setSent(true));
        }}
      >
        Your email.
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Button htmlType="submit">email me</Button>
      </Form>
    </>
  );
};

export default withRouter(PwdResetBegin);
