import { message } from "antd";
import _ from "lodash";

export const handleFormFieldChanges = (values, _self) => {
  if (values && values[0] && values[0].value === "Other") {
    _self.setState({ other: true });
  } else {
    _self.setState({ other: false });
  }
  return null;
};

export const checkForPreviousSubmission = (_self, props) => {
  let ip;
  props
    .actionUserIpGet()
    .then((res) => {
      ip = res.payload.data.IPv4;
      return props.actionLeadIpExistsGet(ip);
    })
    .then((res) => {
      if (res.payload.data.length === 0) {
        _self.setState({
          ip_address: ip,
          loading: false,
        });
      } else {
        message.warning(
          "You have already submitted this form. We'll be in touch soon."
        );
        props.history.push("/");
      }
    });
  return null;
};

const lookupShippingAddressUsingRefs = (
  samplingLocation,
  values,
  organization
) => {
  const existingShippingLocations = [
    ...(organization?.shipping_locations ?? []),
    // Newly added shipping locations can be re-used across sampling locations in the form and will be treated
    // the same way as selecting an "existing" location looked up from an existing organization
    ...values.sampling_locations
      .filter((loc) => loc?.new_shipping_location)
      .map((loc) => loc.new_shipping_location),
  ];
  const ref = samplingLocation.shipping_location_ref;
  if (samplingLocation.shipping_location_ref_type === "NEW") {
    const shippingLocation = samplingLocation.new_shipping_location;
    return {
      ref,
      name: shippingLocation.name,
      street_address_1: shippingLocation.address_line_1,
      street_address_2: shippingLocation.address_line_2,
      city: shippingLocation.city,
      state: shippingLocation.state,
      zip_code: shippingLocation.zip_code,
    };
  } else if (samplingLocation.shipping_location_ref_type === "ORGANIZATION") {
    return {
      ref,
      street_address_1: values.address_line_1,
      street_address_2: values.address_line_2,
      city: values.city,
      state: values.state,
      zip_code: values.zip_code,
      name: `${values.organization_name} - Org Address`,
    };
  } else if (samplingLocation.shipping_location_ref_type === "EXISTING") {
    const ref = samplingLocation.shipping_location_ref;
    const shippingLocation = _.find(
      existingShippingLocations,
      (location) => location?.id === parseInt(ref) || location.name === ref
    );
    if (_.isNil(shippingLocation)) {
      console.log(ref);
      console.log(existingShippingLocations);
    }
    return {
      ref,
      name: shippingLocation.name,
      street_address_1: shippingLocation.address_line_1,
      street_address_2: shippingLocation.address_line_2,
      city: shippingLocation.city,
      // If a newly created location was chosen from the dropdown, it will be treated the same
      // as an existing location, so check both
      state: shippingLocation.state_id ?? shippingLocation.state,
      zip_code: shippingLocation.postal_code ?? shippingLocation.zip_code,
      county: shippingLocation.county,
      shipping_location: shippingLocation.id,
    };
  }
  return { ref };
};

export const convertFormValuesToLeadPayload = (
  values,
  organization,
  program
) => {
  console.log("FORM VALUES", values);
  const payload = _.omit(values, [
    "sampling_locations",
    "additional_contacts",
    "address_line_1",
    "address_line_2",
  ]);
  payload.organization = organization?.id;
  payload.organization_name = values?.organization_name
    ? values.organization_name
    : organization?.organization_name;
  payload.program = program;
  payload.street_address_1 = values.address_line_1;
  payload.street_address_2 = values.address_line_2;
  payload.lead_meta = { additional_contacts: values.additional_contacts };
  payload.lead_sampling_locations = values.sampling_locations
    .filter((location) => !location.exclude_from_program)
    .map((samplingLocation) => {
      // Remove extra auto-populated fields
      const leadSamplingLocation = _.pick(samplingLocation, [
        "sampling_location_name",
        "estimated_population_served",
        "shipping_location_ref",
      ]);
      leadSamplingLocation.sampling_location = samplingLocation.id;
      return leadSamplingLocation;
    });
  const shippingLocations = _.uniqBy(
    values.sampling_locations
      .filter((location) => !location.exclude_from_program)
      .map((samplingLocation) =>
        lookupShippingAddressUsingRefs(samplingLocation, values, organization)
      ),
    "ref"
  );
  payload.lead_shipping_locations = shippingLocations;
  console.log("PAYLOAD", payload);
  return payload;
};

// Example from https://ant.design/components/form/#components-form-demo-register
export const checkedValidator = (_, value) => {
  return value === true ? Promise.resolve() : Promise.reject();
};
