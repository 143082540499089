import { Tag, Tooltip } from "antd";

import Text from "antd/lib/typography/Text";
import {
  CDC_PDF_SKU,
  COMMUNITY_EXPEDITED_PDF_SKU,
  COMMUNITY_PLUS_PDF_SKU,
  COMMUNITY_STANDARD_PDF_SKU,
  COVID_VARIANT_SKU,
  ENTERPRISE_EMAIL_SKU,
  FLAT_FILE_EXPORT_SKU,
  HRS_PILOT_SKU,
  HRS_SUBSCRIPTION_SKU,
  LEGACY_PDF_SKU,
  LOCALIZED_PDF_SKU,
  NCBI_EXPORT_SKU,
  NWSS_EXPORT_SKU,
  PUBLIC_VIZ_EXPORT_SKU,
  MPX_NWSS_EXPORT_SKU,
  NONPROFIT_LOCALIZED_PDF_SKU,
} from "../configs/constants";

const ProductTag = ({ sku, productName }) => {
  let tagColor;
  switch (sku) {
    case LEGACY_PDF_SKU:
    case COMMUNITY_PLUS_PDF_SKU:
    case COMMUNITY_STANDARD_PDF_SKU:
    case COMMUNITY_EXPEDITED_PDF_SKU:
    case CDC_PDF_SKU:
      tagColor = "green";
      break;
    case LOCALIZED_PDF_SKU:
    case NONPROFIT_LOCALIZED_PDF_SKU:
      tagColor = "cyan";
      break;
    case HRS_PILOT_SKU:
    case HRS_SUBSCRIPTION_SKU:
      tagColor = "blue";
      break;
    case PUBLIC_VIZ_EXPORT_SKU:
      tagColor = "geekblue";
      break;
    case NWSS_EXPORT_SKU:
    case MPX_NWSS_EXPORT_SKU:
      tagColor = "purple";
      break;
    case NCBI_EXPORT_SKU:
      tagColor = "magenta";
      break;
    case COVID_VARIANT_SKU:
      tagColor = "red";
      break;
    case ENTERPRISE_EMAIL_SKU:
      tagColor = "orange";
      break;
    case FLAT_FILE_EXPORT_SKU:
      tagColor = "gold";
      break;
    default:
      tagColor = "default";
  }

  let tooltip = <Text style={{ color: "white" }}>{productName}</Text>;

  return (
    <>
      <Tooltip title={tooltip}>
        <Tag color={tagColor} style={{ textTransform: "uppercase" }}>
          {sku}
        </Tag>
      </Tooltip>
    </>
  );
};
export default ProductTag;
