import { orderServiceListQuery, orderServiceCountQuery } from "./orderService";
import { optionsTemplate } from "./util";

const model = "programs";

export const useProgramList = orderServiceListQuery({ model });

const selectOptions = optionsTemplate(
  {
    value: "id",
    label: (program) => `${program.name} (${program.code})`,
    code: "code",
  },
  { sortBy: "code" }
);

export const useProgramOptions = () =>
  useProgramList({ _joins: false }, { select: selectOptions });

export const useProgramCount = orderServiceCountQuery({ model });
