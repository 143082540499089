import { useState, useRef } from "react";
import { Row, Col, Button, PageHeader, message } from "antd";
import _ from "lodash";
import axios from "axios";
import fileDownload from "js-file-download";
import { DownloadOutlined } from "@ant-design/icons";
import { AccountAddModal } from "./components.js";
import TableSearch from "components/Table/TableSearch";
import EditableTable from "components/Table/EditableTable";
import { columns, expandableConfig } from "./tableColumns.js";
import { fetchOrganizationList } from "api/organization";
import { authorizationHeader } from "api/util";

export default function Accounts() {
  const [paramsForFetch, setParamsForFetch] = useState({
    _page: 1,
    _limit: 30,
    searchInput: null,
  });
  const [showAddModal, setShowAddModal] = useState();

  const tableRef = useRef();

  const axiosParams = (searchParams) => {
    let p = {
      _start: (searchParams._page - 1) * searchParams._limit,
      _limit: searchParams._limit,
      _sort: `created_at:DESC`,
      organization_name_contains: searchParams.searchInput,
      state: searchParams.state,
      status: searchParams.status,
    };

    if (searchParams.hhs_participant === true) {
      p.hhs_participant = true;
    } else if (searchParams.hhs_participant === false) {
      p.hhs_participant = false;
    } else if (searchParams.hhs_participant === "not_specified") {
      p.hhs_participant_null = true;
    }

    if (searchParams.flu_pilot_participant_2021 === "not_specified") {
      p.flu_pilot_participant_2021_null = true;
    } else {
      p.flu_pilot_participant_2021 = searchParams.flu_pilot_participant_2021;
    }

    if (searchParams.network_enrollement_specified === "specified") {
      p.network_enrollment_date_null = false;
    } else if (searchParams.network_enrollement_specified === "not_specified") {
      p.network_enrollment_date_null = true;
    } else {
      p.network_enrollment_date_null = null;
    }

    p = _.omitBy(p, _.isNil);

    return {
      params: p,
      headers: authorizationHeader(),
    };
  };

  const getOrgs = (searchParams) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}organizations`,
      axiosParams(searchParams)
    );
  };

  const getOrgsCount = (searchParams) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}organizations/count`,
      axiosParams(searchParams)
    );
  };

  const handleSearchParamsUpdated = (updatedParams) => {
    setParamsForFetch({
      ...paramsForFetch,
      ...updatedParams,
    });
  };

  const handleTableChange = (pagination, filters, sorter, { action }) => {
    if (action === "filter") {
      const newParams = {
        _page: 1,
        state: filters.state ? filters.state[0] : null,
        status: filters.status ? filters.status[0] : null,
        hhs_participant: filters.hhs_participant
          ? filters.hhs_participant[0]
          : null,
        flu_pilot_participant_2021: filters.flu_pilot_participant_2021
          ? filters.flu_pilot_participant_2021[0]
          : null,
        network_enrollement_specified: filters.network_enrollment_date
          ? filters.network_enrollment_date[0]
          : null,
      };

      handleSearchParamsUpdated(newParams);
    }
  };

  // this chunk of code ensure the table record that a log is updated/submitted for reflects the change once it
  // completes on the backend
  const handleOrgCreated = (org) => {
    tableRef.current.addRow(org);
  };

  const handleDownloadCSV = () => {
    let params = axiosParams(paramsForFetch);
    params.params._start = 0;
    params.params._limit = -1;
    params.params.as_csv = true;
    fetchOrganizationList(params)
      .then((response) => {
        fileDownload(response.data, "organizations.csv");
      })
      .catch((err) => {
        message.error("Error downloading organizations");
      });
  };

  return (
    <>
      <PageHeader
        title="Organizations"
        className="mb-3"
        footer={
          <Row justify="space-between">
            <Col span={16}>
              <Row>
                <TableSearch
                  searchPlaceholderText="Search org name..."
                  disableDatePicker={true}
                  onSearchParamsUpdated={handleSearchParamsUpdated}
                  isDisplayInline
                />
                <Button
                  onClick={() => {
                    handleDownloadCSV();
                  }}
                  className="ml-1"
                  type="primary"
                  shape="round"
                >
                  <DownloadOutlined />
                </Button>
              </Row>
            </Col>
            <Col>
              <Button
                id="button"
                className="ml-1"
                onClick={() => setShowAddModal(true)}
                type="primary"
              >
                Add Organization
              </Button>
            </Col>
          </Row>
        }
      />
      <EditableTable
        ref={tableRef}
        rowKey="id"
        columns={columns}
        expandable={expandableConfig}
        paramsForFetch={paramsForFetch}
        fetchRows={getOrgs}
        fetchRowCount={getOrgsCount}
        onSearchParamsUpdated={handleSearchParamsUpdated}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        data-cy="reworks-table"
        className="px-3"
      />
      <AccountAddModal
        visible={showAddModal}
        onOrgCreated={handleOrgCreated}
        onCancel={() => setShowAddModal(false)}
        destroyOnClose
      />
    </>
  );
}
