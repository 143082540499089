import { Tooltip, Button } from "antd";
import { columnSearchProps } from "components/Table/SearchFilter";
import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  strapiFilterProps,
} from "components/Table/StrapiFilter";
import { toLocaleString } from "utils/time";

const renderVersion = (version) => (
  <Tooltip title={version?.description}>{version?.version_name}</Tooltip>
);

export const getLcmsUploadTableColumns = ({ handleFileClick }) => [
  {
    title: "Form Submitted By",
    dataIndex: "form_submitted_by",
  },
  {
    title: "LCMS Results File",
    key: "action",
    render: (_, record) => (
      <Button type="link" onClick={() => handleFileClick(record)}>
        {record?.file_name}
      </Button>
    ),
  },
  {
    title: "Batch Name",
    width: 110,
    dataIndex: "batch_name",
    ...columnSearchProps({ placeholder: "Search by Batch Name" }),
  },
  {
    title: "LCMS Ran By",
    dataIndex: "lcms_ran_by",
    ...columnSearchProps({ placeholder: "Search by Name or Initials" }),
  },
  {
    title: "LCMS Reviewed At",
    width: 120,
    dataIndex: "lcms_reviewed_at",
    defaultSortOrder: "descend",
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
    }),
  },
  {
    title: "Date of LCMS",
    width: 120,
    dataIndex: "date_of_lcms",
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
    }),
  },
  {
    title: "Lab Notes",
    width: 280,
    dataIndex: "notes",
    ...columnSearchProps({ placeholder: "Search by Notes" }),
  },
  {
    title: "Protocol Version",
    dataIndex: "lab_protocol_version",
    render: renderVersion,
  },
  {
    title: "Upload Schema Version",
    dataIndex: "upload_schema_version",
    render: renderVersion,
  },
  {
    title: "File Upload Timestamp",
    dataIndex: "created_at",
    sorter: true,
    render: (record) => (record ? toLocaleString(record) : ""),
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
    }),
  },
];
