import _ from "lodash";
import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
} from "./orderService";
import {
  COVID_ASSAY,
  MPX_ASSAY,
  NORO_ASSAY,
  RESP_ASSAY,
} from "configs/constants";

const model = "reworks";

export const useRework = orderServiceFindOneQuery({ model });
export const useReworkList = orderServiceListQuery({ model });
export const useReworkCount = orderServiceCountQuery({ model });
export const useCreateRework = orderServiceCreateMutation({ model });
export const useUpdateRework = orderServiceUpdateMutation({ model });

const REWORK_STATUSES = ["PENDING", "CANCELLED", "COMPLETED"];

export const REWORK_STATUS_OPTIONS = (capitalize = true) =>
  REWORK_STATUSES.map((status) => {
    const displayValue = capitalize ? _.capitalize(status) : status;
    return {
      value: status,
      label: displayValue,
      text: displayValue,
    };
  });

export const REWORK_ASSAY_OPTIONS = [
  { label: "SARS-CoV-2", value: COVID_ASSAY },
  { label: "Monkeypox", value: MPX_ASSAY },
  { label: "Norovirus", value: NORO_ASSAY },
  { label: "Respiratory (Flu/RSV)", value: RESP_ASSAY },
];
