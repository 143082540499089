import React from "react";
import { Form, Select, Typography } from "antd";

const { Text } = Typography;

export default function NpdesSelector({ npdesIds, initialValue, onSelect }) {
  const prepend = [
    {
      label: `I don't have one`,
      value: `dont_have_one`,
    },
    {
      label: `I don't know it`,
      value: `dont_know_it`,
    },
  ];

  const options = prepend.concat(
    (npdesIds ?? []).map((id) => {
      return {
        label: id,
        value: id,
      };
    })
  );

  return (
    <>
      <Form.Item
        label="NPDES ID"
        name="npdes_id"
        initialValue={initialValue}
        className="mb-0"
        rules={[
          {
            required: true,
            message: "Select NPDES ID",
          },
        ]}
      >
        <Select
          autoComplete="chrome-off"
          name="npdes_id"
          data-cy="npdes-input"
          options={options}
          showSearch
          placeholder="Enter NPDES ID"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(value) => {
            if (onSelect) {
              onSelect(value);
            }
          }}
        />
      </Form.Item>
      <Text type="secondary">
        Please enter the NPDES ID associated with your wastewater treatment
        plant.
      </Text>
    </>
  );
}
