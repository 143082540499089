import _ from "lodash";
import { Tag } from "antd";
import { ASSAY_COLOR_MAPPING } from "configs/constants";

// products should be a list of product objects (ie. kit.products)
export default function KitAssays({ products }) {
  const uniqueAssays = _.uniqBy(products, (p) => p.assay?.id);

  return (
    <>
      {_.map(uniqueAssays, (product, index) => (
        <div key={`assay-${index}`}>
          <Tag color={ASSAY_COLOR_MAPPING[product.assay?.name?.toUpperCase()]}>
            {product.assay?.name ?? `Unknown assay for ${product.product_sku}`}
          </Tag>
          {index === uniqueAssays.length - 1 ? "" : <br />}
        </div>
      ))}
    </>
  );
}
