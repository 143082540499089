import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, Tag, PageHeader } from "antd";
import { actionSitesGet, actionSampleGetByOrg } from "actions";
import { useUserContext } from "context";

const mapStateToProps = ({ sites }) => ({
  sites,
});

const mapDispatchToProps = {
  actionSitesGet,
  actionSampleGetByOrg,
};

const Sites = ({ sites, actionSitesGet, actionSampleGetByOrg }) => {
  const [tableRender, setTableRender] = useState();
  const { organizationId } = useUserContext();

  const getSites = () => {
    if (organizationId) {
      actionSitesGet({ id: organizationId });
      actionSampleGetByOrg(organizationId);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSites(), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSites(), [organizationId]);

  useEffect(() => {
    setTableRender(
      sites.map((site) => {
        return {
          id: site.id,
          key: site.id,
          sampling_location_name: site.sampling_location_name,
          last_collection_date: "",
          sample_count: "",
          estimated_population_served: site.estimated_population_served,
        };
      })
    );
  }, [sites]);

  return (
    <>
      <PageHeader title="Sampling Locations" />

      <Table
        columns={[
          {
            title: "Name",
            dataIndex: "sampling_location_name",
            key: "sampling_location_name",
          },
          {
            title: "Last Reported",
            dataIndex: "last_collection_date",
            key: "last_collection_date",
          },
          {
            title: "Samples Analyzed",
            dataIndex: "sample_count",
            key: "sample_count",
          },
          {
            title: "Catchment Population",
            dataIndex: "estimated_population_served",
            key: "estimated_population_served",
          },
          {
            title: "Id",
            key: "id",
            render: (record) => <Tag color="green">{record.id}</Tag>,
          },
        ]}
        dataSource={tableRender}
        pagination={{
          defaultPageSize: 10,
          position: ["bottomLeft"],
        }}
        className="px-3"
      />
    </>
  );
};

const SitesContainer = connect(mapStateToProps, mapDispatchToProps)(Sites);
export default withRouter(SitesContainer);
