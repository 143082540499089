import { Tag, Tooltip } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

export const getOrders = (organizationId, actionOrganizationGet) => {
  actionOrganizationGet({ id: organizationId }).catch((err) => {
    console.log(err);
  });
};

const priorityConfig = {
  none: {
    tooltip: "",
    label: "-",
    color: "gray",
    level: 0,
  },
  low: {
    tooltip: "",
    label: "Low",
    color: "blue",
    level: 1,
  },
  medium: {
    tooltip: "",
    label: "Medium",
    color: "orange",
    level: 2,
  },
  high: {
    tooltip: "",
    label: "High",
    color: "red",
    level: 3,
  },
};

export const getColumns = () => {
  return [
    {
      title: "Order ID",
      dataIndex: ["id"],
      key: "order_id",
      render: (text) => <Link to={`order/${text}`}>Order #{text}</Link>,
    },
    {
      title: "Order date",
      dataIndex: "created_at",
      defaultSortOrder: "DESC",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      key: "order_date",
      render: (text) => moment(text).format("MMM DD, YYYY - h:mm A"),
    },
    {
      title: "Fulfillment Priority",
      dataIndex: ["fulfillment_priority"],
      sorter: (a, b) => {
        const priorityA =
          priorityConfig[a?.fulfillment_priority] ?? priorityConfig.none;
        const priorityB =
          priorityConfig[b?.fulfillment_priority] ?? priorityConfig.none;

        return priorityA < priorityB ? 1 : -1;
      },
      sortDirections: ["ascend", "descend", "ascend"],
      filters: [
        { value: "none", text: "None", label: "None" },
        { value: "low", text: "Low", label: "Low" },
        { value: "medium", text: "Medium", label: "Medium" },
        { value: "high", text: "High", label: "High" },
      ],
      onFilter: (value, record) => {
        return record.fulfillment_priority === value;
      },
      key: "fulfillment_priority",
      render: (text) => {
        const { tooltip, label, color } =
          priorityConfig[text] ?? priorityConfig.none;

        return (
          <>
            <Tooltip title={tooltip}>
              <Tag color={color} style={{ textTransform: "uppercase" }}>
                {label}
              </Tag>
            </Tooltip>
          </>
        );
      },
    },
  ];
};
