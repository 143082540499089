import { Checkbox, Form, Col, Row, Typography } from "antd";

const { Title } = Typography;

const DaysOfWeek = (props) => (
  <Row className="mb-3">
    <Col span={16}>
      <Title level={3}>Sampling Schedule</Title>
    </Col>

    <Col span={24}>
      <Form.Item name="sampling_schedule">
        <Checkbox.Group
          options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]}
        />
      </Form.Item>
    </Col>
  </Row>
);

export default DaysOfWeek;
