import { Space, Form, Select, Typography } from "antd";
import cx from "classnames";
const { Text } = Typography;

export default function Selector({
  label,
  options,
  formItem,
  selectorText = [],
  className,
}) {
  const { placeholder, name, width, hidden, rules = [] } = formItem;

  return (
    <Space direction="vertical" size="small" className={cx("w-100", className)}>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        hidden={hidden}
        className="mb-1"
        style={{ width }}
      >
        <Select placeholder={placeholder} options={options} />
      </Form.Item>
      {selectorText.map((text, i) => (
        <div key={`${name}__selector-text-${i}`} className="pr-1">
          <Text type="secondary">{text}</Text>
        </div>
      ))}
    </Space>
  );
}
