import { Form, Input } from "antd";

export default function NWSSSiteId({ initialValue }) {
  return (
    <Form.Item
      label="NWSS Site ID"
      name="nwss_site_id"
      className="mb-0"
      validateTrigger="onBlur"
      initialValue={initialValue}
      rules={[
        {
          pattern: new RegExp(/^\d{5}-\d{3}-\d{2}-\d{2}-\d{2}$/i),
          message: "NWSS Site ID must match format XXXXX-XXX-XX-XX-XX",
        },
      ]}
    >
      <Input placeholder="Enter NWSS Site ID" />
    </Form.Item>
  );
}
