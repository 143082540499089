import { orderServiceCustomQuery } from "./orderService";

// Includes datasets endpoints
const model = "public";

export const useDatasetCounties = orderServiceCustomQuery({
  model,
  path: "counties",
});

export const useDatasetStates = orderServiceCustomQuery({
  model,
  path: "states",
});

export const useDatasetSamplingSiteTypes = orderServiceCustomQuery({
  model,
  path: "sampling-site-types",
});
