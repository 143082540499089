import _ from "lodash";

import React, { ReactNode, useEffect, useReducer } from "react";
import AssaySummary from "./AssaySummary/AssaySummary";
import AliquotReview from "./AliquotReview/AliquotReview";
import {
  manualReviewInitializer,
  manualReviewReducer,
  RefreshPendingStatusesAction,
} from "reducers/reducerManualReview";
import LandingPage from "./LandingPage/LandingPage";
import { useQuery } from "react-query";
import LoadingPage from "./LoadingPage/LoadingPage";
import { fetchPendingAliquotStatuses } from "api/pipelinesAPI/pcrAliquotStatuses";
import { ReviewReducerContext } from "./reviewContext";

const ReviewWorkflow = () => {
  const [state, dispatch] = useReducer(
    manualReviewReducer,
    undefined,
    manualReviewInitializer
  );

  useEffect(() => {
    localStorage.setItem("localManualReview", JSON.stringify(state));
  }, [state]);

  const { isLoading } = useQuery(
    "all_pending_aliquot_statuses",
    (_) => fetchPendingAliquotStatuses(),
    {
      onSuccess: (d) => dispatch(new RefreshPendingStatusesAction(d.data)),
    }
  );

  let content: ReactNode = null;

  if (isLoading) {
    content = <LoadingPage message="Loading pending aliquot statuses..." />;
  } else {
    if (_.isNil(state.activeAssayName)) {
      content = <LandingPage />;
    } else if (_.isNil(state.activeAssayState().kitNameUnderReview)) {
      content = <AssaySummary />;
    } else {
      content = <AliquotReview />;
    }
  }

  return (
    // @ts-ignore
    <ReviewReducerContext.Provider value={{ state, dispatch }}>
      {content}
    </ReviewReducerContext.Provider>
  );
};

export default ReviewWorkflow;
