import { Checkbox, Form, Input, InputNumber, Select, Typography } from "antd";
import JSON5 from "json5";
import _ from "lodash";
import { arrayTransformRule } from "../../../utils/rules";
import JsonArrayInput from "./JsonArrayInput";
import parseJson from "./parseJson";

const { Text } = Typography;
const { TextArea } = Input;

const isJsonParam = (param) =>
  param.type === "object" || param.element?.type === "object";

export const parseValue = (param, value) => {
  const { type, element } = param;
  if (isJsonParam(param)) {
    try {
      return parseJson(param, value);
    } catch (e) {
      return undefined;
    }
  } else if (type === "array") {
    // Arrays are strings that need to be further parsed based on the `element`
    // type.
    return (value ?? [])
      .map((x) => x.trim())
      .filter(Boolean)
      .map((val) => parseValue(element, val));
  } else if (value == null) {
    return undefined; // removed null/undefined values entirely
  } else if (type === "number" && typeof value === "string") {
    return +value.trim();
  } else if (type === "integer" && typeof value === "string") {
    return parseInt(+value.trim(), 10);
  } else if (type === "string" || type === "enum") {
    return value.trim();
  } else {
    return value;
  }
};

/** Builds an antd validation rule based on a `param` from the backend. */
const basicRule = (param) => {
  const { optional, type, options, regex, min, max, fields, element } = param;
  return _.omitBy(
    {
      required: optional !== true,
      type,
      enum: options,
      pattern: regex,
      min,
      max,
      fields: fields ? _.mapValues(fields, basicRule) : undefined,
      defaultField: element ? basicRule(element) : undefined,
    },
    _.isNil
  );
};

/**
 * Builds an antd validation rule with a top-level transformation (nested
 * fields are not further transformed).
 */
const runbookRule = (name, param) => {
  return arrayTransformRule({
    name,
    transform: (x) => parseValue(param, x),
    ...basicRule(param),
  });
};

const jsonRule = (param) => ({
  async validator(_, value) {
    if (value && value.length > 0) {
      parseJson(param, value);
    }
  },
});

const ArrayTextArea = ({ value, ...props }) => (
  <TextArea {...props} value={(value ?? []).join("\n")} />
);

const arrayItemNormalize = (x) => {
  try {
    if (x.match(/^\s*\[.*\]\s*$/s)) {
      const val = JSON5.parse(x);
      // array items expect an array of strings, so if we parsed beyond just
      // string (e.g. numbers or nested objects) we need to convert these back
      // to strings.
      return val.map((x) => (typeof x === "string" ? x : JSON.stringify(x)));
    }
  } catch (_) {
    // Ignore, fall back to plain string splitting
  }
  return x.split("\n");
};

const formatLabel = (name) =>
  name.replace(/[A-Z]/g, " $&").replace(/^[a-z]/, (x) => x.toUpperCase());

/**
 * A Form.Item component for a single `param`.
 * - Input component is selected based on `param.type`
 * - Rules are constructed based on any constraints in the `param`.
 */
export const RunbookInput = ({ form, name, param }) => {
  const { type, placeholder, description, options } = param;
  const formItemProps = {
    name: name,
    label: formatLabel(name),
    extra: description,
    rules: [runbookRule(name, param)],
  };
  if (isJsonParam(param)) {
    return (
      <Form.Item
        {...formItemProps}
        rules={[jsonRule(param), ...formItemProps.rules]}
        validateTrigger="onBlur"
      >
        {param.type === "array" ? (
          <JsonArrayInput form={form} name={name} param={param} />
        ) : (
          <TextArea />
        )}
      </Form.Item>
    );
  }
  switch (type) {
    case "string":
    case "email":
      return (
        <Form.Item {...formItemProps}>
          <Input placeholder={placeholder} />
        </Form.Item>
      );
    case "enum":
      return (
        <Form.Item {...formItemProps}>
          <Select options={options.map((value) => ({ value }))} />
        </Form.Item>
      );
    case "number":
    case "integer":
      return (
        <Form.Item {...formItemProps}>
          <InputNumber />
        </Form.Item>
      );
    case "boolean":
      return (
        <Form.Item {...formItemProps} valuePropName="checked">
          <Checkbox defaultChecked={placeholder} />
        </Form.Item>
      );
    case "array":
      return (
        <Form.Item {...formItemProps} normalize={arrayItemNormalize}>
          <ArrayTextArea placeholder="Enter one per line" />
        </Form.Item>
      );
    default:
      return (
        <Text type="danger">
          We don't yet have a frontend for the input type of {type}. Please
          build me!!
        </Text>
      );
  }
};
