import { Col, Form, Input, Row, Typography } from "antd";

export default function LocationName() {
  return (
    <>
      <Row gutter={48} className="mb-5">
        <Col sm={16} xs={32}>
          <Form.Item
            label="Sampling Location Name"
            name="sampling_location_name"
            className="mb-0"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "Enter sampling location name",
              },
              {
                pattern: new RegExp("^[a-zA-Z0-9-_ ]*$"),
                message:
                  "Please only use letters, numbers, dashes or underscores",
              },
            ]}
          >
            <Input placeholder="Enter Sampling location name" />
          </Form.Item>
          <Typography.Text type="secondary" style={{ fontStyle: "italic" }}>
            We will use this name to track your sample. If you wish to sample at
            multiple locations (e.g. different influents at your facility),
            please fill this out with a unique identifier for that location.
            Please note, this name will be visible to participating facilities.
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
}
