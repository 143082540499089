import "./sampling-location.css";

import { usePublicToken } from "api/identity";

import { useDatasetStates, useDatasetSamplingSiteTypes } from "api/datasets";

import { useFacilityTypeList } from "api/facilityType";

import { useNPDESIds } from "api/npdes";

import Loader from "components/Loader/Loader";

import SamplingLocationForm from "./SamplingLocationForm";

function SamplingLocation(props) {
  const tokenQuery = usePublicToken({ tokenType: "SITE_METADATA" });

  const statesQuery = useDatasetStates(
    {},
    {
      enabled: Boolean(tokenQuery.data),
    }
  );

  const samplingSiteTypesQuery = useDatasetSamplingSiteTypes(
    {},
    {
      enabled: Boolean(tokenQuery.data),
    }
  );

  const facilityTypeQuery = useFacilityTypeList(
    {},
    {
      enabled: Boolean(tokenQuery.data),
    }
  );

  const npdesIdsQuery = useNPDESIds({
    enabled: Boolean(tokenQuery.data),
  });

  const queries = [
    tokenQuery,
    statesQuery,
    samplingSiteTypesQuery,
    facilityTypeQuery,
    npdesIdsQuery,
  ];

  if (queries.some(($) => $.isLoading)) {
    return (
      <div className="loader-container">
        <Loader></Loader>
      </div>
    );
  }

  return (
    <SamplingLocationForm
      token={tokenQuery.data}
      states={statesQuery.data}
      samplingSiteTypes={samplingSiteTypesQuery.data}
      facilityTypes={facilityTypeQuery.data}
      npdesIds={npdesIdsQuery.data}
      showErrorState={queries.some(($) => $.isError)}
      {...props}
    />
  );
}

export default SamplingLocation;
