import { LoadingOutlined } from "@ant-design/icons";

import { Breadcrumb, Divider, Spin, Typography } from "antd";

import { Link } from "react-router-dom";

function toBreadcrumbItem({ to, label }, key) {
  return (
    <Breadcrumb.Item key={key}>
      {to ? <Link to={to}>{label}</Link> : label}
    </Breadcrumb.Item>
  );
}

function ViewTemplate({
  navigation = () => [],
  heading = () => null,
  content = () => null,
  queries = [],
}) {
  const isPreloading = queries.some(($) => $.isLoading);

  if (isPreloading) {
    const spinProps = {
      tip: "Loading...",
      indicator: (
        <LoadingOutlined style={{ fontSize: 24 }} spin key="loading" />
      ),
    };

    const wrapperStyle = {
      height: "25vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div className="mx-4" style={wrapperStyle}>
        <Spin {...spinProps} key="spin" />
      </div>
    );
  }

  return (
    <div className="mx-4">
      <Breadcrumb>{navigation(queries).map(toBreadcrumbItem)}</Breadcrumb>

      <Typography.Title level={2}>{heading(queries)}</Typography.Title>

      <Divider></Divider>

      {content(queries)}
    </div>
  );
}

export { ViewTemplate };
