import {
  AliquotStatus,
  AssayName,
  ControlQuant,
  KitName,
  LocationReviewData,
  Paginated,
  TargetQuant,
} from "./types";
import axios, { AxiosRequestConfig } from "axios";
import { authorizationHeader, pipelineAPIRoot } from "../util";

export const fetchReviewDataForKits = async (
  assayName: AssayName,
  kitNames: KitName[]
): Promise<Paginated<LocationReviewData>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${pipelineAPIRoot}/kits/review`,
    headers: authorizationHeader(),
    params: {
      assay_name: assayName,
      kit_names: kitNames,
    },
  };

  return await axios(config).then((response) => {
    return {
      ...response.data,
      data: {
        aliquot_statuses: response.data.data.aliquot_statuses.map(
          (s: any) => s as AliquotStatus
        ),
        target_quants: response.data.data.target_quants.map(
          (q: any) => q as TargetQuant
        ),
        control_quants: response.data.data.control_quants.map(
          (q: any) => q as ControlQuant
        ),
      },
    };
  });
};
