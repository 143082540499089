import { Button, Checkbox, Col, Row } from "antd";
import React, { useState } from "react";

const CustomChoiceFilter = ({
  choices,
  toStrapiFilter,
  renderChoice,

  confirm,
  clearFilters,
  selectedKeys,
  setSelectedKeys,
}) => {
  // ---
  const [selectedChoices, setSelectedChoices] = useState([]);

  const onSubmitFilter = () => {
    setSelectedKeys([toStrapiFilter(selectedChoices)]);

    confirm();
  };

  const onResetFilter = () => {
    setSelectedChoices([]);

    setSelectedKeys([]);

    clearFilters();

    confirm();
  };

  return (
    <div
      className="p-1"
      style={{
        width: "fit-content",
        maxWidth: "425px",
        height: "fit-content",
        maxHeight: "410px",
      }}
    >
      <Checkbox.Group
        style={{
          marginBottom: "5px",
          paddingBottom: "10px",
          width: "100%",
          height: "fit-content",
          maxHeight: "360px",
          overflow: "scroll",
          borderBottom: "1px solid #e5e5e5",
        }}
        value={selectedChoices}
        onChange={setSelectedChoices}
      >
        {choices.map((choice) => {
          return (
            <Row key={choice.value}>
              <Col span={24}>
                <Checkbox value={choice.value} key={choice.value}>
                  {renderChoice?.(choice) ?? choice.label}
                </Checkbox>
              </Col>
            </Row>
          );
        })}
      </Checkbox.Group>

      <Button
        type="primary"
        size="small"
        disabled={selectedChoices.length === 0}
        onClick={onSubmitFilter}
      >
        OK
      </Button>

      <Button
        type="link"
        size="small"
        disabled={selectedKeys.length === 0}
        onClick={onResetFilter}
      >
        Reset
      </Button>
    </div>
  );
};

export { CustomChoiceFilter };
