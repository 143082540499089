import {
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceCustomRoute,
  orderServiceCustomQuery,
} from "./orderService";
import { useMutation } from "react-query";

const model = "runbooks";

export const useRunbook = orderServiceFindOneQuery({ model });
export const useRunbookList = orderServiceListQuery({ model });
export const useRunbookListByCategoryId = orderServiceCustomQuery({
  model,
  path: "category/:id",
});

export const useExecuteRunbook = ({ id, ...options }) =>
  useMutation(
    (data) =>
      orderServiceCustomRoute({
        model,
        path: encodeURIComponent(id),
        method: "post",
        data: data,
      }),
    options
  );
