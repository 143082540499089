import { Form, Input } from "antd";

export default function EPARegistryId({ initialValue }) {
  return (
    <Form.Item
      label="EPA Registry ID"
      name="epa_registry_id"
      className="mb-0"
      validateTrigger="onBlur"
      initialValue={initialValue}
      rules={[
        {
          pattern: new RegExp(/^[0-9]+$/i),
          message: "EPA Registry ID must be a number",
        },
      ]}
    >
      <Input placeholder="Enter EPA Registry ID" />
    </Form.Item>
  );
}
