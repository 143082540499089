import _ from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Form,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

import { VersionSelector, UploadResultTable } from "utils/components";
import SingleBatchFormFields from "./SingleBatchFormFields";
import {
  PCR_UPLOAD_ASSAY_OPTIONS,
  PCR_METHOD_TYPE_OPTIONS,
  useBulkCreatePcrUpload,
  usePcrLabProtocolVersionList,
  usePcrUploadSchemaVersionList,
  useStandardCurveVersionList,
  DDPCR_UPLOAD_ASSAY_OPTIONS,
} from "api/fileUpload";
import {
  COVID_ASSAY_PROD,
  DDPCR_METHOD_TYPE,
  RESP_ASSAY_PROD,
} from "configs/constants";
import { useCreateRework } from "api/rework";
import { PcrUploadFormContext } from "./PcrUploadFormContext";

const PcrUploadFormPage = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResults, setUploadResults] = useState();
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
  const [pcrType, setPcrType] = useState(DDPCR_METHOD_TYPE);

  const { mutateAsync: bulkCreatePcrUploads } = useBulkCreatePcrUpload();
  const { mutateAsync: createRework } = useCreateRework();
  const { data: labProtocolVersions } = usePcrLabProtocolVersionList();
  const { data: standardCurveVersions } = useStandardCurveVersionList();
  const { data: pcrUploadSchemaVersions } = usePcrUploadSchemaVersionList();

  /**
   * Maps file upload submission form to array of rework create requests
   * - merges batch and plate level metadata into each individual rework
   * - parses out plates and batches with no reruns
   * @param {*} formValue pcr upload submission form value
   * @returns {Array<Promise<{ success: boolean }>>} array of rework create requests
   */
  const createReworkRequests = async (formValue, failedBatches) => {
    // For a given rerun in the form, send the rework request
    const _createReworkRequest = async (formValue, rerun_sample) => {
      // Construct the create rework payload
      const reworkData = {
        kit: rerun_sample.kit_id,
        kit_ids_to_rework: [rerun_sample.kit_id],
        type_of_rework: "LAB_RERUN",
        urgency: "EOD",
        assay: formValue.assay_name,
        reason_for_rework_and_requested_changes: rerun_sample?.rerun_notes,
        status: "PENDING",
        requester: formValue.form_submitted_by,
      };
      return await createRework(reworkData, {
        onSuccess: (resp) => {
          return resp;
        },
        onError: (e) => {
          console.log(e);
          throw e;
        },
      });
    };

    let mappedRequests = [];
    if (!_.isUndefined(formValue.batches)) {
      mappedRequests = formValue.batches.map((batch) => {
        // no need to include batch if it has no reruns
        if (_.isEmpty(batch.rerun_samples)) return null;
        // skip creating reruns for batches with failed uploads
        if (failedBatches.indexOf(batch.batch_name) >= 0) return null;

        // batch reruns do not initiate rework requests, take no action for batch.rerun_batches

        return _.map(batch.rerun_samples, (rerun_sample) =>
          _createReworkRequest(formValue, rerun_sample)
        );
      });
    }

    return await Promise.all(_.compact(_.flatten(mappedRequests)));
  };

  const _setResultsModal = (resultsContent) => {
    setUploadResults(resultsContent);
    message.destroy();
    setIsResultsModalOpen(true);
    setIsLoading(false);
  };

  const handleFormSubmit = async (formValue) => {
    setIsLoading(true);
    message.loading("Uploading PCR...", 0);

    bulkCreatePcrUploads({
      ...formValue,
      lab_method: pcrType,
    })
      .then(async (results) => {
        const failedBatches = _.uniq(
          _.map(
            _.filter(results, (result) => !result.value.success),
            "value.batch_name"
          )
        );
        createReworkRequests(formValue, failedBatches)
          .then((reworkResults) => {
            _setResultsModal({ ...results, reworkResults });
          })
          .catch((e, reworkResults) => {
            console.log(`Error creating reworks! ${e}`);
            _setResultsModal({ ...results, reworkResults });
          });
      })
      .catch((e, results) => {
        console.log(`Error creating pcr uploads! ${e}`);
        _setResultsModal(results);
      });
  };

  const handleFileUpload = async (file, fieldPath) => {
    const fieldVal = form.getFieldValue(fieldPath) || {};
    fieldVal["uploaded_file"] = file;
    form.setFieldValue(fieldPath, fieldVal);
  };

  const handleModalAction = () => {
    setIsResultsModalOpen(false);
    setUploadResults(false);
  };

  const handleFormValuesChange = (val) => {
    if (val?.qpcr_type) {
      setPcrType(val.qpcr_type);
      form.setFieldsValue({
        assay_name:
          val.qpcr_type !== DDPCR_METHOD_TYPE
            ? COVID_ASSAY_PROD
            : RESP_ASSAY_PROD,
      });
    }
  };

  const handleRerunSampleSelected = (kitId, fieldPath) => {
    form.setFieldValue(fieldPath, kitId);
  };

  return (
    <>
      <PageHeader title="PCR Upload Submission" />
      <Form
        name="qpcr-upload"
        layout="vertical"
        autoComplete="off"
        className="px-3 py-4"
        form={form}
        onValuesChange={handleFormValuesChange}
        onFinish={handleFormSubmit}
      >
        <PcrUploadFormContext.Provider value={form}>
          {/* Submitted By */}
          <Row>
            <Col span={10}>
              <Form.Item name="form_submitted_by" label="Submitted By" required>
                <Input placeholder="Initials" />
              </Form.Item>
            </Col>
          </Row>

          {/* PCR Type */}
          <Row>
            <Col span={10}>
              <Form.Item
                name="qpcr_type"
                label="PCR Type"
                required
                initialValue={pcrType}
              >
                <Select options={PCR_METHOD_TYPE_OPTIONS} />
              </Form.Item>
            </Col>
          </Row>

          {/* Assay Name */}
          <Row>
            <Col span={10}>
              <Form.Item
                name="assay_name"
                label="Assay Name"
                required
                initialValue={RESP_ASSAY_PROD}
              >
                <Select
                  options={
                    pcrType !== DDPCR_METHOD_TYPE
                      ? PCR_UPLOAD_ASSAY_OPTIONS
                      : DDPCR_UPLOAD_ASSAY_OPTIONS
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            {/* PCR Ran By */}
            <Col span={5}>
              <Form.Item name="qpcr_ran_by" label="Person who ran PCR">
                <Input placeholder="Initials" />
              </Form.Item>
            </Col>

            {/* Date of PCR */}
            <Col span={5}>
              <Form.Item name="date_of_qpcr" label="Date of PCR">
                <DatePicker
                  className="w-100"
                  format={(date) => date.utc().format()}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Lab Protocol Version */}
          <Row>
            <Col span={10}>
              <VersionSelector
                name="lab_protocol_version"
                label="Lab Protocol Version"
                versionNumberPrefix="-v"
                groupByAssay={true}
                versions={labProtocolVersions}
                required={true}
              />
            </Col>
          </Row>

          {/* Upload Schema Version */}
          <Row>
            <Col span={10}>
              <VersionSelector
                name="upload_schema_version"
                label="What format is the data in this file in?"
                versionNumberPrefix="v"
                versions={pcrUploadSchemaVersions}
                required={true}
              />
            </Col>
          </Row>

          {/* Standard Curve Version */}
          {pcrType !== DDPCR_METHOD_TYPE ? (
            <Row>
              <Col span={10}>
                <VersionSelector
                  name="standard_curve_version"
                  label="Standard Curve Version"
                  versionNumberPrefix="-v"
                  versions={standardCurveVersions}
                  groupByAssay={true}
                />
              </Col>
            </Row>
          ) : null}

          {/* Batch List */}
          <Form.List name="batches" initialValue={[{}]}>
            {(
              batchFields,
              { add: addBatch, remove: removeBatch },
              { errors }
            ) => (
              <>
                {batchFields.map(
                  (
                    { key, name, fieldKey, ...restBatchField },
                    batchFieldIdx
                  ) => (
                    <SingleBatchFormFields
                      name={name}
                      fieldKey={fieldKey}
                      batchFieldIdx={batchFieldIdx}
                      assayName={form.getFieldValue("assay_name")}
                      onRemove={() => removeBatch(name)}
                      onUpload={handleFileUpload}
                      onRerunSelected={handleRerunSampleSelected}
                      acceptTypes={
                        pcrType !== DDPCR_METHOD_TYPE ? ".xlsx" : ".csv"
                      }
                      {...restBatchField}
                    />
                  )
                )}
                <Form.Item style={{ marginLeft: "auto" }}>
                  <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={() => addBatch()}
                  >
                    Add Batch
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Divider className="mt-5" />
          <Space align="center" className="flex justify-center">
            <Popconfirm
              title="Reset Form?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => form.resetFields()}
            >
              <Button disabled={isLoading}>Reset</Button>
            </Popconfirm>
            <Form.Item className="mb-0">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </Space>
        </PcrUploadFormContext.Provider>
      </Form>

      <Modal
        title="PCR Upload Results"
        open={isResultsModalOpen}
        onOk={handleModalAction}
        onCancel={handleModalAction}
      >
        <UploadResultTable uploadResults={uploadResults} />
      </Modal>
    </>
  );
};

export default PcrUploadFormPage;
