import React, { useState } from "react";
import { Button, Select, Form, Row, message } from "antd";
import Text from "antd/lib/typography/Text";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { updateSample } from "../helpers";
import * as Sentry from "@sentry/react";

import { timeZone } from "utils/time";

const EditableTimeZoneCell = ({
  sample,
  editDisallowed,
  refetchKitDetails,
}) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    if (!values.time_zone) {
      return;
    }

    setLoading(true);

    const samplePayload = { time_zone: values.time_zone };

    let ajaxCall = null; // some function of the form (payload) => Promise

    // editing an existing sample log
    ajaxCall = updateSample;
    samplePayload.id = sample.id;

    return ajaxCall(samplePayload)
      .then((response) => {
        if (sample.onFormSubmissionSuccess) {
          sample.onFormSubmissionSuccess(response.data);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        message.error("An error occurred!");
      })
      .then(() => {
        setLoading(false);
        setEditing(false);
        refetchKitDetails();
        message.success("Saved!");
      });
  };

  const handleEditingCanceled = () => {
    setEditing(false);
    form.resetFields();
  };

  if (!editing) {
    return (
      <Row justify="space-between" align="center">
        <Text>
          {sample?.time_zone ? sample.time_zone?.replace(/_/g, " ") : "-"}
        </Text>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setEditing(true)}
          disabled={editDisallowed}
          title={
            editDisallowed
              ? "This sample log is ineligible for editing due to project effigy."
              : null
          }
        />
      </Row>
    );
  }

  return (
    <Form
      form={form}
      initialValues={{ time_zone: sample?.time_zone || timeZone }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row justify="space-between" align="right">
        <Form.Item name="time_zone">
          <Select
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: "Pacific/Honolulu",
                label: "Hawaii Time",
              },
              {
                value: "US/Alaska",
                label: "Alaska Time",
              },
              {
                value: "America/Los_Angeles",
                label: "Pacific Time (Los Angeles, Seattle)",
              },
              {
                value: "America/Boise",
                label: "Mountain Time (Albuquerque, Boise)",
              },
              {
                value: "America/Chicago",
                label: "Central Time (Chicago, Houston)",
              },
              {
                value: "America/New_York",
                label: "Eastern Time (Boston, New York)",
              },
              {
                value: "America/Moncton",
                label: "Atlantic Time (Puerto Rico)",
              },
              {
                value: "Pacific/Guam",
                label: "Chamorro Time (Guam)",
              },
              {
                value: "Europe/Bucharest",
                label: "Eastern European Time (Lithuania)",
              },
            ]}
            onChange={form.submit}
          />
        </Form.Item>
        <Button
          type="primary"
          icon={<CloseOutlined />}
          onClick={handleEditingCanceled}
          loading={loading}
        />
      </Row>
    </Form>
  );
};

export default EditableTimeZoneCell;
