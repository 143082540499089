import React, { useEffect, useState } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import {
  ExperimentOutlined,
  SolutionOutlined,
  FileSearchOutlined,
  CloudUploadOutlined,
  DropboxOutlined,
  ClusterOutlined,
  GithubOutlined,
  RadarChartOutlined,
  DollarCircleOutlined,
  BarcodeOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Menu as AntMenu } from "antd";
import generateTestId from "utils/GenerateTestId";
import "./Menu.css";
import { useUIContext } from "context";

const { SubMenu } = AntMenu;

export function Menu() {
  const uiContext = useUIContext();
  const { showAdmin } = uiContext;

  const menuConfig = adminMenu;

  let location = useLocation().pathname;
  const [currentRoute, setCurrentRoute] = useState();

  useEffect(() => {
    let initSelectedKey = [];
    let locationIndex = menuConfig
      .findIndex((item) => item.route === location)
      .toString();
    initSelectedKey.push(locationIndex);
    setCurrentRoute(initSelectedKey);
  }, [showAdmin, location, menuConfig]);

  return (
    <AntMenu
      id="nav-menu"
      className="mt-3"
      selectedKeys={currentRoute}
      mode="inline"
    >
      {menuConfig.map((item, index) => {
        if (!item.isSubmenu) {
          return (
            <AntMenu.Item
              key={index}
              icon={item.icon}
              onClick={(e) => {
                setCurrentRoute(e.key);
              }}
              className="ant-menu-item"
              disabled={item.disabled}
            >
              <Link data-test-id={generateTestId(item.label)} to={item.route}>
                {item.label}
              </Link>
            </AntMenu.Item>
          );
        } else if (item.parentMenu === null) {
          return (
            <SubMenu
              key={index}
              icon={item.icon}
              title={item.label}
              data-test-id={generateTestId(item.label)}
            >
              {menuConfig.map((subItem, index) => {
                if (subItem.parentMenu === item.label) {
                  return (
                    <AntMenu.Item
                      style={{ margin: 0 }}
                      key={index}
                      onClick={(e) => {
                        setCurrentRoute(e.key);
                      }}
                      className="sub-menu-item"
                    >
                      <Link
                        data-test-id={generateTestId(subItem.label)}
                        to={subItem.route}
                      >
                        {subItem.label}
                      </Link>
                    </AntMenu.Item>
                  );
                } else {
                  return null;
                }
              })}
            </SubMenu>
          );
        } else {
          return null;
        }
      })}
    </AntMenu>
  );
}

export default withRouter(Menu);

const adminMenu = [
  {
    label: "Programs",
    route: null,
    icon: <ClusterOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: null,
  },
  {
    label: "Program List",
    route: "/admin/programs",
    icon: null,
    isSubmenu: true,
    parentMenu: "Programs",
  },
  {
    label: "Program Enrollments",
    route: "/admin/program-enrollments",
    icon: null,
    isSubmenu: true,
    parentMenu: "Programs",
  },
  {
    label: "Orders",
    route: null,
    icon: <DropboxOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: null,
  },
  {
    label: "Order Kits",
    route: "/order",
    icon: null,
    isSubmenu: true,
    parentMenu: "Orders",
  },
  {
    label: "Order Placements",
    route: "/admin/order-placements",
    icon: null,
    isSubmenu: true,
    parentMenu: "Orders",
  },
  {
    label: "Order Shipments",
    route: "/admin/order-shipments",
    icon: null,
    isSubmenu: true,
    parentMenu: "Orders",
  },
  {
    label: "Upcoming Orders",
    route: "/admin/upcoming-orders",
    icon: null,
    isSubmenu: true,
    parentMenu: "Orders",
  },
  {
    label: "Customer Data",
    reoute: null,
    icon: <SolutionOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: null,
  },
  {
    label: "Leads",
    route: "/leads",
    icon: <FileSearchOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: "Customer Data",
  },
  {
    label: "Organizations",
    route: "/organizations",
    icon: null,
    isSubmenu: true,
    parentMenu: "Customer Data",
  },
  {
    label: "Sampling Locations",
    route: "/admin/sampling-locations",
    icon: null,
    isSubmenu: true,
    parentMenu: "Customer Data",
  },
  {
    label: "Kits",
    route: null,
    icon: <ExperimentOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: null,
  },
  {
    label: "Kits List",
    route: "/admin/kits",
    icon: null,
    isSubmenu: true,
    parentMenu: "Kits",
  },
  {
    label: "Kit Selection Statuses",
    route: "/admin/kits/selection-statuses",
    icon: null,
    isSubmenu: true,
    parentMenu: "Kits",
  },
  {
    label: "Kit Lookup Tool",
    route: "/admin/kit-lookup",
    icon: null,
    isSubmenu: true,
    parentMenu: "Kits",
  },
  {
    label: "Rework Request Form",
    route: "/admin/rework-request",
    icon: null,
    isSubmenu: true,
    parentMenu: "Kits",
  },
  {
    label: "Reworks Requested",
    route: "/admin/reworks-requested",
    icon: null,
    isSubmenu: true,
    parentMenu: "Kits",
  },
  {
    label: "Kits Received",
    route: "/admin/kits-received",
    icon: null,
    isSubmenu: true,
    parentMenu: "Kits",
  },
  {
    label: "Tubes Received",
    route: "/admin/tubes-received",
    icon: null,
    isSubmenu: true,
    parentMenu: "Kits",
  },
  {
    label: "Result Uploads",
    route: null,
    icon: <CloudUploadOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: null,
  },
  {
    label: "PCR Uploads",
    route: "/admin/pcr-uploads",
    icon: null,
    isSubmenu: true,
    parentMenu: "Result Uploads",
  },
  {
    label: "PCR Upload Submission",
    route: "/admin/pcr-upload-form",
    icon: null,
    isSubmenu: true,
    parentMenu: "Result Uploads",
  },
  {
    label: "LCMS Uploads",
    route: "/admin/lcms-uploads",
    icon: null,
    isSubmenu: true,
    parentMenu: "Result Uploads",
  },
  {
    label: "LCMS Upload Submission",
    route: "/admin/lcms-upload-form",
    icon: null,
    isSubmenu: true,
    parentMenu: "Result Uploads",
  },
  {
    label: "Varseq Uploads",
    route: "/admin/varseq-uploads",
    icon: null,
    isSubmenu: true,
    parentMenu: "Result Uploads",
  },
  {
    label: "Varseq Upload Submission",
    route: "/admin/varseq-upload-form",
    icon: null,
    isSubmenu: true,
    parentMenu: "Result Uploads",
  },
  {
    label: "New Protocol Version",
    route: "/admin/create-protocol-version",
    icon: null,
    isSubmenu: true,
    parentMenu: "Result Uploads",
  },
  {
    label: "Biz Ops",
    route: null,
    icon: <RadarChartOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: null,
  },
  {
    label: "Kit Status Report",
    route: "/admin/kit-status-report",
    isSubmenu: true,
    parentMenu: "Biz Ops",
  },
  {
    label: "Invoicing",
    route: "/admin/invoicing",
    icon: <DollarCircleOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: "Biz Ops",
  },
  {
    label: "Kit Issues",
    route: "/admin/kit-issues",
    icon: null,
    isSubmenu: true,
    parentMenu: "Biz Ops",
  },
  {
    label: "Runbooks",
    route: null,
    icon: <GithubOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: null,
  },
  {
    label: "Runbooks - ENG",
    route: "/runbooks/category/eng",
    icon: null,
    isSubmenu: true,
    parentMenu: "Runbooks",
  },
  {
    label: "Runbooks - CX",
    route: "/runbooks/category/cx",
    icon: null,
    isSubmenu: true,
    parentMenu: "Runbooks",
  },
  {
    label: "Integration Functions",
    route: "/admin/functions",
    icon: null,
    isSubmenu: true,
    parentMenu: "Runbooks",
  },
  {
    label: "Data Transfer Events",
    route: "/admin/vendor-events",
    icon: null,
    isSubmenu: true,
    parentMenu: "Runbooks",
  },
  {
    label: "Magic Table",
    route: "/magic-table",
    icon: <span>🔮</span>,
    isSubmenu: false,
    parentMenu: null,
  },
  {
    label: "Previous Ops",
    route: null,
    icon: <LockOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: null,
  },
  {
    label: "Fulfill Orders",
    route: "/admin/order-fulfillment",
    icon: <DropboxOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: "Previous Ops",
  },
  {
    label: "Receive Kits",
    route: "/admin/kit-receipt",
    icon: <BarcodeOutlined className="menu-icon" />,
    isSubmenu: true,
    parentMenu: "Previous Ops",
  },
  {
    label: "Triage Sheet",
    route: "/admin/triage-sheet",
    icon: null,
    isSubmenu: true,
    parentMenu: "Previous Ops",
  },
  {
    label: "Kit Ops Dashboard",
    route: "/admin/kits-operational-dashboard",
    icon: null,
    isSubmenu: true,
    parentMenu: "Previous Ops",
  },
  {
    label: "Manual Review",
    route: "/admin/manual-review",
    icon: null,
    isSubmenu: true,
    parentMenu: "Previous Ops",
  },
  {
    label: "Data Review",
    route: "/admin/data-review",
    icon: null,
    isSubmenu: true,
    parentMenu: "Previous Ops",
  },
];
