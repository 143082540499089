import CA_PROVINCES from "data/ca/admin.json";
import LT_PROVINCES from "data/lt/admin.json";
import US_PROVINCES from "data/us/admin.json";

export const PROGRAM_BIOBOT_NETWORK_CODE = "biobot_network";
export const PROGRAM_CITY_OF_BERKELEY_CODE = "city_of_berkeley";
export const PROGRAM_CDC_CODE = "cdc_2022";
export const PROGRAM_NIDA_CODE = "nida_2023";
export const PROGRAM_HHS_CODE = "hhs_2021";
export const PROGRAM_FLU_CODE = "flu_2021";
export const PROGRAM_VISUALIZATION_CODE = "bn_viz";
export const PROGRAM_MADPH_WWTP_CODE = "madph_2022_wwtp";
export const PROGRAM_MADPH_BUILDING_CODE = "madph_2022_building";
export const PROGRAM_MADOC_CODE = "madoc_2022";
export const PROGRAM_MAINE_RFP_CODE = "maine_rfp_2022";
export const PROGRAM_PA_RFP_CODE = "pa_rfp_2022";
export const PROGRAM_DC_RFP_WARD_CODE = "dc_rfp_2022_ward";
export const PROGRAM_DC_RFP_BUILDING_CODE = "dc_rfp_2022_building";
export const PROGRAM_ARMY_CODE = "army";
export const PROGRAM_JPMC_CODE = "jpmc";
export const PROGRAM_GOLDMAN_CODE = "goldman";
export const PROGRAM_USAFA_CODE = "usafa";
export const PROGRAM_MWRA_CODE = "mwra_2022";
export const PROGRAM_CAMBRIDGE_CODE = "cambridge_2021";
export const BOSTON_PHC_CODE = "boston_phc_2022";
export const CDC_MPX_PROGRAM_CODE = "cdc_2022_mpx";
export const CDC_MPX_2X_PROGRAM_CODE = "cdc_2022_mpx_2X";
export const MADPH_MPX_PROGRAM_CODE = "madph_2022_mpx";
export const ME_RFP_2023_PROGRAM_CODE = "maine_rfp_2023";
export const PROGRAM_GOUCHER_2022_CODE = "goucher_2022";
export const PROGRAM_GOUCHER_COVID_2022_CODE = "goucher_covid_2022";
export const PROGRAM_SAN_JOAQUIN_2022_CODE = "san_joaquin_2022";
export const PROGRAM_LOUISVILLE_MSD_CODE = "louisville_msd";
export const PROGRAM_PFIZER_2023_CODE = "pfizer_2023";
export const PROGRAM_MONTGOMERY_CTY_2023_CODE = "montgomery_cty_2023";
export const PROGRAM_MONTGOMERY_CTY_BUILDING_2023_CODE =
  "montgomery_cty_bldg_2023";
export const PROGRAM_MAHOC_2022_CODE = "mahoc_2022";
export const PROGRAM_NEWARK_LIBERTY_AIRPORT_CODE = "newark_liberty_airport";
export const PROGRAM_FLU_RSV_1X = "flu_rsv_1x";
export const PROGRAM_FLU_RSV_2X = "flu_rsv_2x";
export const PROGRAM_FLU_RSV_3X = "flu_rsv_3x";
export const PROGRAM_CCHCS_CODE = "cchcs";

export const WWTP_MGD_LIMIT = 999; // waste water treatment plant millions of gallons per day limit

export const SELECTION_STATUS_SELECTED = "selected";
export const SELECTION_STATUS_ELIGIBLE = "eligible";
export const SELECTION_STATUS_SELECTION_AMBIGUOUS = "eligibility_ambiguous";
export const SELECTION_STATUS_INELIGIBLE = "ineligible";

export const programsCompatibleWithViz = [PROGRAM_CDC_CODE];

export const LEGACY_PDF_SKU = "SKU0000";
export const COMMUNITY_PLUS_PDF_SKU = "SKU0001";
export const LOCALIZED_PDF_SKU = "SKU0009";
export const COMMUNITY_STANDARD_PDF_SKU = "SKU0012";
export const COMMUNITY_EXPEDITED_PDF_SKU = "SKU0013";
export const NONPROFIT_LOCALIZED_PDF_SKU = "SKU0014";
export const HRS_PILOT_SKU = "SKU0016";
export const PUBLIC_VIZ_EXPORT_SKU = "SKU0017";
export const HRS_SUBSCRIPTION_SKU = "SKU0018";
export const CDC_PDF_SKU = "SKU0019";
export const NWSS_EXPORT_SKU = "SKU0020";
export const NCBI_EXPORT_SKU = "SKU0021";
export const COVID_VARIANT_SKU = "SKU0022";
export const ENTERPRISE_EMAIL_SKU = "SKU0023";
export const FLAT_FILE_EXPORT_SKU = "SKU0024";
export const MPX_NWSS_EXPORT_SKU = "SKU0026";

// Rework assay options
export const COVID_ASSAY = "sars_cov_2";
export const MPX_ASSAY = "mpx";
export const NORO_ASSAY = "noro_prod";

export const QPCR_METHOD_TYPE = "qpcr";
export const DDPCR_METHOD_TYPE = "ddpcr";

export const RESP_ASSAY = "resp_prod";
// ! this will be consolidated into REWORK_ASSAY_OPTIONS via this ticket https://biobot.atlassian.net/browse/IPT-290
// ! COVID_ASSAY and MPX_ASSAY above will be changed to "sars_cov_2_prod" and "mpx_prod" respectively
// ! mpx_rnd will be added as well
// - prior to ticket completion, values below will be used for qpcr-upload model only
export const COVID_ASSAY_PROD = "sars_cov_2_prod";
export const MPX_ASSAY_PROD = "mpx_prod";
export const NORO_ASSAY_PROD = "noro_prod";
export const RESP_ASSAY_PROD = "resp_prod";

export const HRS_ASSAY = "hrs"; // LCMS doesn't have the prod/rnd split that qPCR does

export const COVID_VARIANTS_ASSAY = "varseq_sars_cov_2_prod";

// Use the assay name to determine how to color code the tag
export const ASSAY_COLOR_MAPPING = {
  COVID: "green",
  NORO: "purple",
  HRS: "red",
  "LC-MS": "red",
  "FLU-RSV": "blue",
  RESP: "blue",
  MPOX: "gold",
};

export const COUNTRIES = {
  US: {
    label: "USA",
    value: "USA",
  },
  CA: {
    label: "Canada",
    value: "Canada",
  },
  LT: {
    label: "Lithuania",
    value: "Lithuania",
  },
};

export const PROVINCES_BY_COUNTRY = {
  [COUNTRIES.US.value]: US_PROVINCES,
  [COUNTRIES.CA.value]: CA_PROVINCES,
  [COUNTRIES.LT.value]: LT_PROVINCES,
};

export const PROVINCE_LABELS_BY_COUNTRY = {
  [COUNTRIES.US.value]: "State",
  [COUNTRIES.CA.value]: "Province",
  [COUNTRIES.LT.value]: "County",
};

// eslint-disable-next-line no-useless-escape
const intPhoneNumber = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;

export const PHONE_NUMBER_REGEX_BY_COUNTRY = {
  [COUNTRIES.US.value]: intPhoneNumber,

  // eslint-disable-next-line no-useless-escape
  default: /^(?=.*[0-9])[- +()0-9]+$/,
};
