import AsyncValidator from "async-validator";

/* eslint-disable no-useless-escape */
export const concatRules = (...rules) => rules.flat().filter(Boolean);

export const requiredRule = (message) => ({ required: true, message });

export const simpleNameRule = {
  pattern: /^[a-zA-Z0-9_ -]*$/,
  message: "Please only use letters, numbers, dashes or underscores",
};

export const kitIDRule = {
  pattern: /^[A-Z0-9-]*$/,
  message: "Please only use capital letters, numbers, or dashes",
};

export const phoneNumberRule = {
  pattern: /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/,
  message: "Please confirm the phone number is valid",
};

export const organizationNameRule = {
  pattern: /^(?!.*\s{2,})[\p{L}\d _\-&.()\/#]*$/u,
  message:
    "Please only use letters, numbers, dashes or underscores and ensure there are no extra spaces",
  required: true,
};

export const intergerOnlyRule = {
  pattern: /^[0-9]*$/,
  message: "Please use only whole numbers",
};

/**
 * Shortcut for custom validation rules. Antd requires that custom validators
 * return a promise that rejects on errors. This version requires a function
 * that takes (fieldValue, allFormValues) and returns a boolean indicating if
 * the field passed validation.
 *
 * @example
 * customRule({
 *   validator(value) {
 *     return value < 10;
 *   },
 *   message: "value must be less than 10",
 * });
 *
 * @example
 * // For backward compatibility you can also pass a validation function and
 * // message as separate arguments:
 * customRule((value) => value < 10, "value must be less than 10")
 */
export const customRule = (opts, message_) => {
  if (typeof opts === "function") {
    return customRule({ validator: opts, message: message_ });
  }
  return ({ getFieldsValue }) => ({
    ...opts,
    validator: async (_, value) => {
      const result = await opts.validator(value, getFieldsValue());

      return new Promise((resolve, reject) => {
        return result ? resolve(result) : reject(opts.message);
      });
    },
  });
};

/**
 * Antd does not run transforms before processing array rules, so this is a
 * hack to make sure that happens.
 */
export const arrayTransformRule = ({ name, ...rule }) => {
  if (rule.type !== "array" || !rule.transform) {
    return rule;
  }
  const { transform: _, ...innerRule } = rule;
  return {
    ...rule,
    type: "any",
    async validator(_, value) {
      try {
        await new AsyncValidator({ [name]: innerRule }).validate({
          [name]: value,
        });
      } catch (e) {
        if (!e.errors) {
          throw e;
        } else if (e.errors.length > 5) {
          // eslint-disable-next-line no-throw-literal
          throw [
            ...e.errors.slice(0, 3),
            `(${e.errors.length - 3} more errors)`,
          ];
        } else {
          throw e.errors;
        }
      }
    },
  };
};
