import { Col, Row, Typography } from "antd";

export function SuccessfulOrderSearch({ orderId, kits }) {
  return (
    <Row className="mt-5 mb-3 p-2" style={{ background: "lightblue" }}>
      <Col span={8}>
        <Typography.Text strong className="mr-2">
          Order ID:
        </Typography.Text>
        <Typography.Text>{orderId}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong className="mr-2">
          Number of Kits:
        </Typography.Text>
        <Typography.Text>{kits?.length || 0}</Typography.Text>
      </Col>
    </Row>
  );
}
