import { Typography } from "antd";
import {
  PROGRAM_BIOBOT_NETWORK_CODE,
  PROGRAM_CCHCS_CODE,
  PROGRAM_CDC_CODE,
  PROGRAM_NIDA_CODE,
} from "configs/constants";

const { Link } = Typography;
const biobotNetworkLink = (
  <Link href="https://biobot.io/data" target="_blank">
    biobot.io/data
  </Link>
);
const termsAndConditionsLink = (
  <Link
    href="http://agreements.biobot.io/Standard-Terms-and-Conditions.pdf"
    target="_blank"
  >
    Customer Terms and Conditions
  </Link>
);

const cdcCovidTrackerLink = (
  <Link
    href="https://covid.cdc.gov/covid-data-tracker/#wastewater-surveillance"
    target="_blank"
  >
    CDC’s COVID Data Tracker
  </Link>
);

export const fixtures = {
  BN_PAID_CUSTOMERS: {
    program: PROGRAM_BIOBOT_NETWORK_CODE,
    basePath: "/network-opt-in",
    startTitleText: "The Biobot Network Opt-in: Current Customers",
    description:
      "The Biobot Network is a first-of-its-kind public health partnership between wastewater treatment facilities and Biobot Analytics - the global leader in wastewater epidemiology. The goal of The Biobot Network is to empower the general public, elected officials, and public health leaders by connecting them with Covid-19 & Covid-19 variant data gathered from wastewater treatment facilities.",
    participationDetails: [
      "Current customers will have no interruption in receiving community reports, based on their sampling cadence and report turnaround time",
      <>
        Comprehensive Covid-19 & Covid-19 variant health data will be shared
        publicly at the county level at {biobotNetworkLink}
      </>,
      "Participants will be invited to join Biobot Network specific webinars and will gain early access to new products",
    ],
    isFormForExistingCustomers: true,
    showOrgQuestions: false,
    allowAdditionalContacts: false,
    allowAdditionalLocations: false,
    allowsExclude: true,
    showSamplingLocations: true,
    programAgreements: [
      <>
        I agree to Biobot’s {termsAndConditionsLink}. These Terms and Conditions
        govern all services provided by Biobot, including those that have
        occurred prior to this date.*
      </>,
      "I understand that results from the wastewater samples I provide to Biobot, and those I have provided prior to this agreement, may be displayed at biobot.io/data (aggregated to the county or state level).*",
      "I understand that samples and metadata provided to Biobot are owned by Biobot Analytics, Inc., and will be used to advance the development of new assays, data analysis techniques, and other uses without restriction.*",
      "I understand that completing this form does not guarantee acceptance to The Biobot Network, and that my organization will be notified within 1-2 business days of submission.",
      "I understand that if multiple sampling locations are submitted on this form, it is possible that not all may be accepted into the Biobot Network Program.",
    ],
    programName: "Biobot Network",
    dataSharingAgreements: null,
    completedTitleText:
      "The Biobot Network. Run by Biobot Analytics, Inc. Form Submitted Successfully.",
    failedTitleText:
      "The Biobot Network. Run by Biobot Analytics, Inc. Form Failed to Submit.",
  },
  BN_ALL_CUSTOMERS: {
    program: PROGRAM_BIOBOT_NETWORK_CODE,
    basePath: "/sign-up",
    startTitleText: "The Biobot Network Opt-in",
    description:
      "The Biobot Network is a first-of-its-kind public health partnership between wastewater treatment facilities and Biobot Analytics - the global leader in wastewater epidemiology. The goal of The Biobot Network is to empower the general public, elected officials, and public health leaders by connecting them with public health data gathered from wastewater treatment facilities. This data will enable them to improve the health of their communities. Biobot will analyze wastewater samples for the presence of public health indicators on a weekly basis- starting with COVID-19 and its variants. This work will be provided free of charge to participating facilities.",
    isFormForExistingCustomers: false,
    showOrgQuestions: true,
    allowAdditionalContacts: true,
    allowAdditionalLocations: true,
    allowsExclude: true,
    showSamplingLocations: true,
    participationDetails: [
      "Wastewater treatment plants are admitted to this program on a rolling basis. Once accepted, participating locations will be asked to collect wastewater samples weekly.",
      "Biobot will provide your organization with easy-to-use sampling kits.",
      "Biobot will cover the cost of all sampling kits and shipping required for this program, and there is no cost to your organization to participate.",
      <>
        Comprehensive public health data gathered through this project will be
        shared publicly (at the county level) at {biobotNetworkLink}
      </>,
      "Participants will be invited to join Biobot Network specific webinars and will gain early access to new products",
    ],
    programAgreements: [
      "I understand that completing this form does not guarantee acceptance to the Biobot Network, and that my organization will be notified within 1-2 business days of submission.*",
      "By submitting this form, I agree to sample once per week for the next 6 months.*",
      "I understand that failure to return samples to Biobot once per week could result in removal from the Biobot Network.*",
      "I understand that if multiple sampling locations are submitted on this form, it is possible that some but not all of the sampling locations may be accepted into the Biobot Network Program.*",
    ],
    dataSharingAgreements: [
      {
        itemName: "participant_receive_results",
        text: (
          <>
            I understand that results from the wastewater samples I provide to
            Biobot will be displayed at {biobotNetworkLink} (aggregated to the
            county or state level).*
          </>
        ),
      },
      {
        itemName: "participant_dataviz",
        text: (
          <>
            I understand that results from wastewater samples I have provided to
            Biobot prior to this agreement may be displayed at{" "}
            {biobotNetworkLink} (aggregated to the county or state level).*
          </>
        ),
      },
      {
        itemName: "participant_rnd_qc",
        text:
          "I understand that samples and metadata provided to Biobot are owned by Biobot Analytics, Inc., and will be used to advance the development of new assays, data analysis techniques, and other uses without restriction.*",
      },
      {
        itemName: "participant_terms_of_service",
        text: <>I agree to Biobot’s {termsAndConditionsLink}.*</>,
      },
    ],
    programName: "Biobot Network",
    completedTitleText:
      "The Biobot Network. Run by Biobot Analytics, Inc. Form Submitted Successfully.",
    failedTitleText:
      "The Biobot Network. Run by Biobot Analytics, Inc. Form Failed to Submit.",
  },
  CDC_ALL_CUSTOMERS: {
    program: PROGRAM_CDC_CODE,
    programName: "CDC NWSS Program",
    completedTitleText: "Form Submitted Successfully",
    failedTitleText: "Form Failed to Submit",
    basePath: "/cdc-sign-up",
    startTitleText: "CDC NWSS Enrollment Form",
    allowsExclude: true,
    showSamplingLocations: true,
    allowAdditionalContacts: true,
    isFormForExistingCustomers: false,
    showOrgQuestions: false,
    allowAdditionalLocations: true,
    description: (
      <>
        <p>
          Biobot Analytics has partnered with the Centers for Disease Control
          (CDC) to monitor the presence of Covid-19 and mpox in wastewater as a
          part of the agency’s National Wastewater Surveillance System (NWSS).
          Please complete this form to apply to participate in NWSS managed by
          Biobot.
        </p>
      </>
    ),
    participationDetails: [
      "Wastewater treatment plants are admitted to this program on a rolling basis. If selected, participating locations will be asked to collect wastewater twice per week.",
      "The CDC is responsible for all costs associated with this program, and there is no cost to your organization.",
      "Biobot will provide your organization with easy-to-use sampling kits, including all of the materials needed to collect samples and ship them to Biobot for analysis.",
      "Your wastewater samples will automatically be enrolled in both the SARS-CoV-2 and mpox sampling programs.",
      <>
        Public health data gathered through this program will be returned
        directly to CDC to be shared publicly on {cdcCovidTrackerLink}.
      </>,
      <>
        In addition to having access to results via {cdcCovidTrackerLink},
        Biobot will share results directly with your organization via email, and
        you will receive mpox results directly from the CDC NWSS team.
      </>,
    ],
    dataSharingAgreements: [
      {
        itemName: "participant_cdcdashboard",
        text: (
          <>
            I understand that I will receive results relevant to my location(s)
            for each sample I submit. In addition, these results will be shared
            with CDC and become part of the CDC-NWSS dashboard{" "}
            <Link
              href="https://covid.cdc.gov/covid-data-tracker/#wastewater-surveillance"
              target="_blank"
            >
              Covid Data Tracker
            </Link>
            .*
          </>
        ),
      },
    ],
    programAgreements: [
      "I understand that completing this form does not guarantee acceptance to the CDC-NWSS Program, and that my organization will be notified within 2 business days of submission.*",
      "I understand that if multiple sampling locations are submitted on this form, it is possible that some but not all of the sampling locations may be accepted into the CDC-NWSS Program.*",
      "By submitting this form, I agree to sample two times per week in accordance with my assigned sampling schedule. This will be coordinate with the Biobot team prior to my sampling start date.*",
      "I understand that, per CDC guidelines, failure to sample twice weekly could result in removal from the CDC-NWSS Program.*",
      "I understand that any residual wastewater and RNA remaining after testing will be sent to the CDC Biorepository for archiving.*",
    ],
  },
  NIDA_ALL_CUSTOMERS: {
    programName: "NIDA Program",
    program: PROGRAM_NIDA_CODE,
    basePath: "/nida-sign-up",
    allowsExclude: true,
    allowAdditionalContacts: true,
    allowAdditionalLocations: true,
    showSamplingLocations: true,
    startTitleText: "NIDA-Funded Wastewater Monitoring Program Enrollment Form",
    description: (
      <>
        <p>
          Biobot Analytics has been awarded funding by the National Institute on
          Drug Abuse (NIDA) to monitor the presence of cocaine, fentanyl,
          methamphetamine, naloxone, and xylazine in wastewater as a part of the
          agency’s Wastewater-Based Monitoring Program for Illicit Drugs. Please
          complete this form to apply to participate in the NIDA-funded
          wastewater monitoring program managed by Biobot.
        </p>
      </>
    ),
    participationDetails: [
      "Participating locations will be asked to collect wastewater once every other week.",
      "NIDA is responsible for all costs associated with this program, and there is no cost to your organization.",
      "Biobot will provide your organization with easy-to-use sampling kits, including all of the materials needed to collect samples and ship them to Biobot for analysis.",
      "A digital sample log form must be completed for each sample collected, prior to sending to Biobot. A flow value (in MGD) for the sample collection period is a required value on this form. You must be able to enter a flow value at the time the sample log form is completed.",
      "Public health data gathered through this program will be returned directly to NIDA.",
      "Biobot will share results from your location with your organization, and others as designated on this enrollment form. Results will be available beginning in December 2023 and will be accessible through Biobot’s Data Intelligence Platform.",
    ],
    programAgreements: [
      "I understand that completing this form does not guarantee acceptance to the NIDA Program, and that my organization will be notified within 2 business days of submission.*",
      "I understand that if multiple sampling locations are submitted on this form, it is possible that some but not all of the sampling locations may be accepted into the NIDA Program.*",
      "By submitting this form, I agree to sample once every two weeks in accordance with my assigned sampling schedule. This will be coordinated with the Biobot team prior to my sampling start date.*",
      "I understand that a digital sample log form must be completed for each sample collected, prior to sending to Biobot, and a flow value (in MGD) for the sample collection period is a required value on this form. My facility is able to submit a flow value at the time of sample collection.*",
    ],
    dataSharingAgreements: [
      {
        itemName: "nida_site_access",
        text: (
          <>
            I understand that I will be given access to the results relevant to
            my location(s) for each sample I submit beginning in December 2023.
            In addition, these results will be shared with NIDA.*
          </>
        ),
      },
      {
        itemName: "nida_biobot_access",
        text: (
          <>
            I understand that samples and metadata provided to Biobot can be
            used to advance the development of new assays, data analysis
            techniques, and other uses.*
          </>
        ),
      },
      {
        itemName: "terms_and_conditions",
        text: <>I agree to Biobot’s {termsAndConditionsLink}.*</>,
      },
    ],
  },
  CCHCS_ALL_CUSTOMERS: {
    programName: "CCHCS",
    program: PROGRAM_CCHCS_CODE,
    basePath: "/cchcs-sign-up",
    startTitleText: "CDCR CCHCS Wastewater Monitoring Program Enrollment Form",
    showOrgQuestions: false,
    showSamplingLocations: false,
    allowsExclude: false,
    allowAdditionalContacts: false,
    allowAdditionalLocations: false,
    participationDetails: [
      "Participating locations will be tasked with collecting wastewater twice a week.",
      "Biobot will provide your organization with easy-to-use sampling kits, including all of the materials needed to collect samples and ship them to Biobot for analysis.",
      "A digital sample log form must be completed for each sample collected, prior to sending to Biobot. A flow value (in MGD) for the sample collection period is a required value on this form.",
    ],
    programAgreements: [
      "I understand that a digital sample log form must be completed for each sample collected, prior to sending to Biobot, and a flow value (in MGD) for the sample collection period is a required value on this form. My facility is able to submit a flow value at the time of sample collection.*",
    ],
  },
};
