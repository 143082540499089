import moment from "moment";

const userLanguage = window.navigator.userLanguage || window.navigator.language;
export const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York";

export const toLocaleString = (date) =>
  new Date(date).toLocaleString(userLanguage, {
    timeZone: timeZone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

export const formatDate = (date, timeZone = "America/New_York") =>
  date && new Date(date).toLocaleDateString(("en-US", { timeZone }));

export const formatTime = (date, timeZone = "America/New_York") =>
  date && new Date(date).toLocaleTimeString(("en-US", { timeZone }));

export const parseDate = (date, timeZone) =>
  date && timeZone && moment(date).tz(timeZone).format("M/D/YYYY");

export const parseTime = (time, timeZone) =>
  time && timeZone && moment(time).tz(timeZone).format("hh:mm a");

export const formatDateTime = (date, timeZone = "America/New_York") =>
  date && `${formatDate(date, timeZone)} ${formatTime(date, timeZone)}`;

export const dateIsInFuture = (date) => {
  return date > new Date();
};

export const dateIsMoreThan30DaysOld = (date) => {
  if (date === null) {
    return false;
  }
  const d = new Date();
  return new Date(date) < d.setDate(d.getDate() - 30);
};

export const dateIsOneDayPast = (date) => {
  const d = new Date();
  return date < d.setDate(d.getDate() - 1);
};

export const dateIsToday = (date) => {
  if (date) {
    return date.format("MMM Do YY") === moment().format("MMM Do YY");
  }
};

export const formatToDateTime = (date, time) =>
  date?.set({
    hour: time?.get("hour"),
    minute: time?.get("minute"),
  });

export const todayStart = moment.utc().startOf("day");
export const todayEnd = moment.utc().endOf("day");

export const previousMonthStart = moment
  .utc()
  .subtract(1, "months")
  .startOf("month");
export const previousMonthEnd = moment
  .utc()
  .subtract(1, "months")
  .endOf("month");
