import { Col, Form, Row, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";

export function ShippingOrderIdSearch({ onSearch }) {
  return (
    <>
      <Row>
        <Col span="12">
          <Form.Item
            data-cy="search_shipping_order_id"
            label={
              <>
                <Text>Shipping Order Id</Text>
                <Tooltip title="This is called 'Shipping Order #' in Order Shipments Table.">
                  <QuestionCircleOutlined className="pl-1" />
                </Tooltip>
              </>
            }
            name="shipping_kit_id"
            rules={[
              {
                required: true,
                message: "Shipping Kit Id is required",
              },
            ]}
          >
            <Search
              placeholder="Enter shipping order id"
              onSearch={onSearch}
              size="large"
              enterButton
              onPressEnter={(e) => {
                onSearch(e.target.value);
                e.preventDefault();
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
