import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { logout } from "utils";

function NavOverflowMenu({ history }) {
  return (
    <Dropdown
      trigger={"click"}
      placement={"topRight"}
      overlay={
        <Menu>
          <Menu.Item>
            <a
              id="btn-sign-out"
              href="#sign-out"
              onClick={(e) => {
                e.preventDefault();
                logout();
                history.push("/");
              }}
            >
              Sign Out
            </a>
          </Menu.Item>
        </Menu>
      }
    >
      <Button type="link" className="pl-0 pr-0">
        <SettingOutlined />
      </Button>
    </Dropdown>
  );
}

export default NavOverflowMenu;
