import { Table } from "antd";
import { useRunbookListByCategoryId } from "api/runbook";
import { Link } from "react-router-dom";

const stringCmp = (key) => (a, b) => (a[key] ?? "").localeCompare(b[key] ?? "");

const RunbooksTable = ({ categoryId }) => {
  const runbooksQuery = useRunbookListByCategoryId({ id: categoryId });

  const columns = [
    {
      title: "Runbook",
      key: "label",
      sorter: stringCmp("label"),
      render: ({ id, label }) => (
        <Link to={`/runbooks/${encodeURIComponent(id)}`}>{label}</Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={runbooksQuery.data}
      isLoading={runbooksQuery.isLoading}
      pagination={{ total: runbooksQuery.data?.length }}
      className={"px-3"}
    />
  );
};

export default RunbooksTable;
