import { Col, Form, Input, message, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { trim } from "lodash";

import "./SearchOrg.css";

const SearchOrg = ({ token, onOrgFound, onOrgNotFound }) => {
  const params = new URLSearchParams(window.location.search);
  const orgIdFromQueryString = params.get("org-id"); //optionally prefill org id

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [displayId, setDisplayId] = useState(orgIdFromQueryString);

  const axiosParams = () => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    };
  };

  useEffect(() => {
    if (displayId) {
      setError(false);
      setLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_API_URL}organizations/${displayId}/public`,
          axiosParams()
        )
        .then((response) => onOrgFound(response.data))
        .catch((e) => {
          if (e?.response?.status === 404) {
            setError(true);
            onOrgNotFound();
          } else if (
            e?.response?.status === 400 ||
            e?.response?.status === 401
          ) {
            message.error(
              "Your session has expired. Please refresh the page in order to enter the information required to submit this form.",
              5
            );
          } else {
            message.error(
              "Something went wrong. Please contact support@biobot.io."
            );
          }
        })
        .then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayId]);

  return (
    <>
      <Row align="top" className="mb-5">
        <Col sm={16} xs={24}>
          <Form>
            <label for="org-search">
              <h3>What is your organization ID?</h3>
            </label>
            <Form.Item
              hasFeedback
              validateStatus={error ? "error" : ""}
              className="pb-0 org-search-input"
            >
              <Input.Search
                id="org-search"
                defaultValue={orgIdFromQueryString}
                autoComplete="organization"
                placeholder="BIO12345"
                data-cy="organization-id"
                enterButton
                loading={loading}
                onSearch={(value) => setDisplayId(trim(value))}
              />
            </Form.Item>
            {error ? (
              <Typography.Text type="secondary" data-cy="search-org-invalid-id">
                Please enter valid ID. If you continue to have trouble you can
                contact us at
                <a href="mailto:support@biobot.io"> support@biobot.io</a>
              </Typography.Text>
            ) : (
              <Typography.Text type="secondary">
                This ID was sent to you via email.
              </Typography.Text>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default SearchOrg;
