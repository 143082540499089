import { Col, Form, Input, Row } from "antd";
import React from "react";
import Title from "antd/es/typography/Title";
import { leadInitialValue, genLabel } from "../helpers";

export default function PrimaryContactSection({ lead, alreadyActivated }) {
  const initialContactName = leadInitialValue(
    lead,
    "full_name",
    "contact_name"
  );
  const initialContactEmail = leadInitialValue(lead, "email", "contact_email");
  const initialContactTitle = leadInitialValue(
    lead,
    "job_title",
    "contact_title"
  );
  const initialContactPhone = leadInitialValue(lead, "phone_number", "phone");

  return (
    <>
      <Title level={5} className="mt-3">
        Organization Primary Contact
      </Title>
      <Row gutter={16} className="mt-2">
        <Col sm={12} md={6}>
          <Form.Item
            name="contact_name"
            label={genLabel("Full Name", initialContactName)}
            initialValue={initialContactName.value}
            rules={[{ required: true }]}
          >
            <Input placeholder={"Jen Terry"} disabled={alreadyActivated} />
          </Form.Item>
        </Col>
        <Col sm={12} md={6}>
          <Form.Item
            name="contact_email"
            label={genLabel("Email Address", initialContactEmail)}
            initialValue={initialContactEmail.value}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={"jen.terry@mywasterwatercompany.com"}
              disabled={alreadyActivated}
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6}>
          <Form.Item
            name="contact_title"
            label={genLabel("Job Title", initialContactTitle)}
            initialValue={initialContactTitle.value}
            rules={[{ required: false }]}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
        <Col sm={12} md={6}>
          <Form.Item
            name="phone"
            label={genLabel("Phone Number", initialContactPhone)}
            initialValue={initialContactPhone.value}
            rules={[{ required: false }]}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
