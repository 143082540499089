import { withRouter, Switch } from "react-router-dom";
import PublicRoute from "routes/PublicRoute";
import Completed from "./Completed";
import Failed from "./Failed";
import Start from "./Start";
import { fixtures } from "./fixtures";
import SignupContextProvider from "context/SignUpContext";

const NetworkCustomerOptIn = () => {
  const formVariant = "BN_PAID_CUSTOMERS";
  const formFixtures = fixtures[formVariant];

  return (
    <SignupContextProvider
      defaultValues={{
        variant: formVariant,
      }}
    >
      <Switch>
        <PublicRoute
          exact
          path={`${formFixtures.basePath}/confirm`}
          component={Completed}
        />
        <PublicRoute
          exact
          path={`${formFixtures.basePath}/failed`}
          component={Failed}
        />
        <PublicRoute path={formFixtures.basePath} component={Start} />
      </Switch>
    </SignupContextProvider>
  );
};

export default withRouter(NetworkCustomerOptIn);
