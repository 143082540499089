import { Form, Row, Col, Space, Select, Radio, Typography } from "antd";
import { useState } from "react";

const { Text, Title } = Typography;

const options = [
  {
    label:
      "Raw wastewater without any treatment applied or minimal treatment applied to it (e.g. bar screens), such as from a WWTP influent, a pump station, or a manhole",
    value: "raw wastewater",
  },
  {
    label:
      "Influent wastewater after removal of large solids at a treatment plant but prior to a primary clarifier (post-grit removal)",
    value: "post grit removal",
  },
  {
    label: "Effluent from the primary clarifier",
    value: "primary effluent",
  },
  {
    label: "Effluent from the secondary clarifier",
    value: "secondary effluent",
  },
  {
    label: "Wastewater sampled from within a septic tank",
    value: "septage",
  },
  {
    label:
      "Wastewater sampled from a holding tank, such as from an airplane or ship",
    value: "holding tank",
  },
];

const SampleMatrixField = ({
  isRequired,
  initialValue,
  label,
  placeholder,
}) => (
  <Row className="mb-5">
    <Col span={24}>
      <Title level={4}>Sample Matrix</Title>
    </Col>

    <Col span={24}>
      <Form.Item
        label={label}
        name="sample_matrix"
        initialValue={initialValue}
        rules={[
          {
            required: isRequired,
            message: "Please select an option",
          },
        ]}
      >
        <Select
          name="sample_matrix"
          options={options}
          showSearch
          placeholder={placeholder ?? label}
        />
      </Form.Item>
    </Col>
  </Row>
);

export function SampleMatrix({ isRequired, defaultSampleMatrix }) {
  const [isDefaultMatrix, setIsDefaultMatrix] = useState();
  const locationLevelMatrix = options.find(
    (opt) => opt.value === defaultSampleMatrix
  );

  const handleDefaultMatrixChange = (e) => {
    const isDefault = e?.target?.value;
    setIsDefaultMatrix(isDefault);
  };

  return (
    <>
      {!!defaultSampleMatrix && (
        <Row className="mb-5">
          <Col span={24} className="mb-1">
            <Text className="text-xl">
              It looks like your <Text strong>sample matrix</Text> is usually{" "}
              <Text strong>{locationLevelMatrix?.label}</Text>. Can you confirm
              whether this is correct for this sample?
            </Text>
          </Col>

          <Col span={24}>
            <Radio.Group
              data-cy="default-matrix"
              onChange={handleDefaultMatrixChange}
            >
              <Space direction="vertical">
                <Radio data-cy="yes-default-matrix" value={true}>
                  Yes, this sample is {locationLevelMatrix?.label}
                </Radio>
                <Radio data-cy="no-default-matrix" value={false}>
                  No, this sample is not {locationLevelMatrix?.label}
                </Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {(isDefaultMatrix === false || !defaultSampleMatrix) && (
        <SampleMatrixField
          isRequired={isRequired}
          label="Select an option that reflects your sample"
          options={options.map((opt) => ({
            ...opt,
            disabled: opt.value === defaultSampleMatrix,
          }))}
        />
      )}
    </>
  );
}

export function SampleMatrixForLocation({ isRequired, location }) {
  return (
    <SampleMatrixField
      isRequired={isRequired}
      label="Select an option that reflects how you usually collect samples"
      placeholder="How are your samples most often collected?"
      initialValue={location?.sample_matrix}
    />
  );
}
