import { Col, Form, Input, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

import {
  COUNTRIES,
  PROVINCES_BY_COUNTRY,
  PROVINCE_LABELS_BY_COUNTRY,
} from "configs/constants";

import { leadInitialValue, genLabel } from "../helpers";

export default function AddressSection({ lead, alreadyActivated }) {
  const initialAddr1 = leadInitialValue(
    lead,
    "street_address_1",
    "address_line_1"
  );
  const initialAddr2 = leadInitialValue(
    lead,
    "street_address_2",
    "address_line_2"
  );
  const initialCity = leadInitialValue(lead, "city", "city");
  const initialProvince = leadInitialValue(lead, "state", "province");
  const initialZip = leadInitialValue(lead, "zip_code", "postal_code");

  const initialCountry =
    leadInitialValue(lead, "county", "county")?.value ?? "USA";

  return (
    <>
      <Title level={5} className="mt-3">
        Organization Address Info
      </Title>
      <Row gutter={16} className="mt-2">
        <Col sm={24} md={6}>
          <Form.Item
            name="address_line_1"
            label={genLabel("Address Line 1", initialAddr1)}
            initialValue={initialAddr1.value}
            rules={[{ required: true }]}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
        <Col sm={24} md={6}>
          <Form.Item
            name="address_line_2"
            label={genLabel("Address Line 2", initialAddr2)}
            initialValue={initialAddr2.value}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col sm={6}>
          <Form.Item
            label="Country"
            name={`country`}
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={initialCountry}
            disabled={alreadyActivated}
          >
            <Select options={Object.values(COUNTRIES)} />
          </Form.Item>
        </Col>
        <Col sm={6}>
          <Form.Item
            name="city"
            label={genLabel("City", initialCity)}
            initialValue={initialCity.value}
            rules={[{ required: true }]}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
        <Col sm={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues["country"] !== currentValues["country"]
            }
          >
            {({ getFieldValue }) => {
              const selectedCountry =
                getFieldValue("country") ?? initialCountry;

              const options = (PROVINCES_BY_COUNTRY[selectedCountry] ?? []).map(
                ({ abbreviation, name }) => ({
                  value: abbreviation,
                  label: name,
                })
              );
              return (
                <Form.Item
                  label={PROVINCE_LABELS_BY_COUNTRY[selectedCountry]}
                  name={`province`}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={initialProvince.value}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      option.props.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={PROVINCE_LABELS_BY_COUNTRY[selectedCountry]}
                    disabled={alreadyActivated}
                    options={options}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col sm={6}>
          <Form.Item
            name="postal_code"
            label={genLabel("Zipcode", initialZip)}
            initialValue={initialZip.value}
            rules={[{ required: true }]}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
