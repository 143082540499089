import { PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  message,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { useQueries } from "react-query";
import {
  orderServiceExport,
  useOrderServiceCount,
  useOrderServiceList,
} from "api/orderService";
import { useTableQueryParams } from "components/Table/helpers";
import fileDownload from "js-file-download";
import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useQueryParam } from "use-query-params";
import { useModelAttributes } from "./attributes";
import ColumnConfigWidget from "./ColumnConfigWidget";
import { ColumnDrag, ColumnDrop } from "./ColumnDragDrop";
import { columnsForAttributes, defaultColumnConfig } from "./columns";
import ExportCodeButton from "./ExportCode";
import { JsonArrayParam } from "./util";

const keyByKey = (data) => _.keyBy(data, "key");

const columnDragStyle = {
  fontWeight: "bold",
};

const columnDropStyle = {
  opacity: "0.75",
  // right leaning hatching
  backgroundImage:
    "linear-gradient(135deg, gray 5%, transparent 5%, transparent 50%, gray 50%, gray 55%, transparent 55%, transparent 100%)",
  backgroundSize: "8px 8px",
};

const useFilterData = (models) => {
  const uniqModels = _.uniq(models);
  const queries = useQueries(
    uniqModels.map((model) => useOrderServiceList.query({ model }))
  );
  return _.zipObject(uniqModels, queries);
};

/**
 * Builds the `filter` option for a column.
 *
 * If a column includes `filterFetch`, we need to build up a list of values
 * for a checkbox based on available options that were fetched in
 * `useFilterData`.
 */
const columnFilter = (col, filterQueries) => {
  if (col.filterFetch) {
    const { model, key } = col.filterFetch;
    const data = filterQueries[model]?.data;
    if (data) {
      return _.uniq(data.map((row) => row[key]))
        .filter((x) => x != null)
        .sort()
        .map((val) => ({ text: val, value: val }));
    }
  }
  return col.filters;
};

const CustomTable = ({ model, attributes, attrsByKey }) => {
  const { queryParams, pagination, handleTableChange } = useTableQueryParams({
    defaultPageSize: 50,
  });

  // Column calculation
  const [columnConfig, setColumnConfig] = useQueryParam(
    "column",
    JsonArrayParam
  );
  useEffect(() => {
    if (!columnConfig.length) {
      setColumnConfig(defaultColumnConfig(attributes));
    }
    // This runs only once on mount -- if columnConfig exists, it's coming from
    // the query string, so we don't want to clobber it. Otherwise we want the
    // default columns since we loaded this page with no query. If this ran any
    // time columnConfig changed, we'd clobber columnConfig any time the user
    // manually empties out all the columns.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = columnsForAttributes(attrsByKey, columnConfig);

  // Main queries
  const queryParamsWithFields = {
    _fields: columnConfig.map(({ key }) => key),
    ...queryParams,
  };
  const { data, isLoading } = useOrderServiceList({
    model,
    ...queryParamsWithFields,
  });
  const { data: count } = useOrderServiceCount({ model, ...queryParams });

  // If a column specifies `filterFetch` we need to fetch more data to build up
  // a checkbox filter
  const filterQueries = useFilterData(
    columns.flatMap((col) => col.filterFetch?.model ?? [])
  );

  // CSV
  const handleDownloadCsv = async () => {
    try {
      const data = await orderServiceExport({
        model,
        ...queryParams,
        _limit: -1,
        as_csv: {
          fields: columns.map(({ title, config: { key } }) => ({
            label: title,
            value: key,
          })),
        },
      });
      fileDownload(
        data,
        `${model}-${moment().format("YYYY-MM-DDTHH-mm-ss")}.csv`
      );
    } catch (e) {
      console.log(e);
      message.error("Unable to download CSV, please try again");
    }
  };
  const csvDownloadButton = count != null && (
    <Button type="primary" onClick={handleDownloadCsv}>
      Download {count} rows as CSV
    </Button>
  );
  const exportCodeButton = (
    <ExportCodeButton
      model={model}
      queryParams={queryParamsWithFields}
      columns={columns}
    />
  );
  const clearColumnsButton = (
    <Button type="primary" onClick={() => setColumnConfig([])}>
      Clear columns
    </Button>
  );

  // Config widget and column dragging
  const handleConfigFinish = (idx) => ({ key, title }) => {
    setColumnConfig((cols) => _.set(cols, idx, { key, title }));
  };
  const handleConfigDelete = (idx) => () => {
    setColumnConfig((cols) => _.filter(cols, (_, i) => i !== idx));
  };
  const handleConfigAddFinish = ({ key, title }) => {
    setColumnConfig((cols) => [...cols, { key, title }]);
  };
  const handleColumnDrop = ({ from, to }) => {
    setColumnConfig((cols) => {
      const newCols = [...cols];
      newCols.splice(to, 0, ...newCols.splice(from, 1));
      return newCols;
    });
  };
  const columnTitleWithConfigWidget = (col, idx) => (
    <ColumnDrop idx={idx} dropStyle={columnDropStyle} onDrop={handleColumnDrop}>
      <Row align="middle" style={{ height: "100%" }}>
        <Col>
          <ColumnDrag idx={idx} dragStyle={columnDragStyle}>
            {col.title}
          </ColumnDrag>
        </Col>
        <Col>
          <ColumnConfigWidget
            model={model}
            initialValues={{
              title: col.title,
              key: col.config.key,
            }}
            onFinish={handleConfigFinish(idx)}
            onDelete={handleConfigDelete(idx)}
          />
        </Col>
      </Row>
    </ColumnDrop>
  );
  const addColumnWidget = (
    <ColumnConfigWidget
      model={model}
      icon={
        <Tooltip title="Add a column">
          <PlusOutlined />
        </Tooltip>
      }
      onFinish={handleConfigAddFinish}
    />
  );

  return (
    <Table
      rowKey="id"
      scroll={{ x: "max-content" }}
      loading={isLoading}
      columns={[
        ...columns.map((col, idx) => ({
          ...col,
          filters: columnFilter(col, filterQueries),
          title: columnTitleWithConfigWidget(col, idx),
        })),
        {
          title: addColumnWidget,
          key: "add-column",
        },
      ]}
      pagination={{ ...pagination, total: count }}
      dataSource={data}
      onChange={handleTableChange}
      title={() => (
        <Space>
          {csvDownloadButton}
          {exportCodeButton}
          {clearColumnsButton}
        </Space>
      )}
      showSorterTooltip={false}
    />
  );
};

const CustomTableWrapper = ({ model }) => {
  const { data: attributes, error } = useModelAttributes({ model });
  if (attributes) {
    return (
      <CustomTable
        model={model}
        attributes={attributes}
        attrsByKey={keyByKey(attributes)}
      />
    );
  } else if (error) {
    return (
      <Space>
        <Alert
          message="Error"
          description={`Error loading "${model}" attributes from the API.`}
          type="error"
        />
      </Space>
    );
  } else {
    return (
      <Space>
        <Spin>
          <Alert
            message="Loading"
            description={`Gathering "${model}" attributes from the API.`}
            type="info"
          />
        </Spin>
      </Space>
    );
  }
};

export default CustomTableWrapper;
