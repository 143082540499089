import { Button, Input, Radio, Row, Form } from "antd";
import { DatePicker, Space } from "antd";
import { useState } from "react";
import _ from "lodash";

const { RangePicker } = DatePicker;

const buttonRowStyle = {
  padding: "8px",
  borderTop: "1px solid #f0f0f0",
};

export const METHOD_DAY_RANGE_EXCLUSIVE_END = "day_range_exclusive_end";
export const METHOD_TIME_RANGE_EXCLUSIVE_END = "time_range_exclusive_end";
export const METHOD_EMPTY = "empty";
export const METHOD_NOT_EMPTY = "not_empty";
export const METHOD_TEXT_CONTAINS = "text_contains";
export const METHOD_TEXT_NOT_CONTAINS = "text_not_contains";
export const METHOD_TEXT_NOT_EQ = "not_equal";
export const METHOD_PROGRAM_SELECTION_STATUSES = "program_selection_statuses";

export const METHOD_CUSTOM_FILTER = "custom_filter";

export const StrapiFilter = ({
  defaultSelectedKey,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  searchPlaceholder,
  enabledFilterMethods,
}) => {
  if (!enabledFilterMethods || enabledFilterMethods.length === 0) {
    throw new Error("enabledFilterMethods is required");
  }

  if (
    (defaultSelectedKey?.method === METHOD_DAY_RANGE_EXCLUSIVE_END ||
      defaultSelectedKey?.method === METHOD_TIME_RANGE_EXCLUSIVE_END) &&
    defaultSelectedKey?.value?.length !== 2
  ) {
    throw new Error(
      "Day/time range filter method must have a value of type: [moment, moment]"
    );
  }

  const initFilterMethod =
    selectedKeys[0]?.method ??
    defaultSelectedKey?.method ??
    enabledFilterMethods[0];
  const initValue =
    initFilterMethod === METHOD_DAY_RANGE_EXCLUSIVE_END ||
    initFilterMethod === METHOD_TIME_RANGE_EXCLUSIVE_END
      ? selectedKeys[0]?.value ?? defaultSelectedKey?.value
      : null;

  if (!enabledFilterMethods.includes(initFilterMethod)) {
    throw new Error(
      "defaultFilterMethod is not in the list of enabledFilterMethods"
    );
  }

  const [filterMethod, setFilterMethod] = useState(initFilterMethod);
  const [filterValue, setFilterValue] = useState(initValue);

  const handleFilterMethodChange = (e) => {
    setFilterMethod(e.target.value);
  };

  const handleDateRangeChanged = (dates) => {
    setFilterValue(dates);
  };

  const handleSearchChanged = (e) => {
    setFilterValue(e.target.value);
  };

  const handleSubmit = () => {
    const selectedKey = {
      method: filterMethod,
      value:
        filterMethod === METHOD_EMPTY || filterMethod === METHOD_NOT_EMPTY
          ? null
          : filterValue,
    };
    setSelectedKeys([selectedKey]);
    confirm();
  };

  const handleReset = () => {
    setFilterMethod(null);
    setFilterValue(null);
    setSelectedKeys([]);
    clearFilters();
    confirm();
  };

  const radios = [];

  if (enabledFilterMethods.includes(METHOD_DAY_RANGE_EXCLUSIVE_END)) {
    radios.push(
      <Radio
        value={METHOD_DAY_RANGE_EXCLUSIVE_END}
        key={METHOD_DAY_RANGE_EXCLUSIVE_END}
      >
        <RangePicker
          showToday
          disabled={filterMethod !== METHOD_DAY_RANGE_EXCLUSIVE_END}
          value={filterValue}
          onChange={handleDateRangeChanged}
        />
      </Radio>
    );
  }

  if (enabledFilterMethods.includes(METHOD_TIME_RANGE_EXCLUSIVE_END)) {
    radios.push(
      <Radio
        value={METHOD_TIME_RANGE_EXCLUSIVE_END}
        key={METHOD_TIME_RANGE_EXCLUSIVE_END}
      >
        <RangePicker
          showToday
          disabled={filterMethod !== METHOD_TIME_RANGE_EXCLUSIVE_END}
          value={filterValue}
          onChange={handleDateRangeChanged}
          showTime
          format="YYYY-MM-DD HH:mm"
        />
      </Radio>
    );
  }

  if (enabledFilterMethods.includes(METHOD_TEXT_CONTAINS)) {
    radios.push(
      <Radio value={METHOD_TEXT_CONTAINS} key={METHOD_TEXT_CONTAINS}>
        <Form.Item label="Contains" className="mb-0">
          <Input
            placeholder={searchPlaceholder || "Search"}
            onChange={handleSearchChanged}
            value={filterValue}
            style={{ minWidth: "15rem" }}
            disabled={filterMethod !== METHOD_TEXT_CONTAINS}
          />
        </Form.Item>
      </Radio>
    );
  }

  if (enabledFilterMethods.includes(METHOD_TEXT_NOT_CONTAINS)) {
    radios.push(
      <Radio value={METHOD_TEXT_NOT_CONTAINS} key={METHOD_TEXT_NOT_CONTAINS}>
        <Form.Item label="Does not contain" className="mb-0">
          <Input
            placeholder={searchPlaceholder || "Search"}
            onChange={handleSearchChanged}
            value={filterValue}
            style={{ minWidth: "15rem" }}
            disabled={filterMethod !== METHOD_TEXT_NOT_CONTAINS}
          />
        </Form.Item>
      </Radio>
    );
  }

  if (enabledFilterMethods.includes(METHOD_TEXT_NOT_EQ)) {
    radios.push(
      <Radio value={METHOD_TEXT_NOT_EQ} key={METHOD_TEXT_NOT_EQ}>
        <Form.Item label="Not" className="mb-0">
          <Input
            placeholder={searchPlaceholder || "Search"}
            onChange={handleSearchChanged}
            value={filterValue}
            style={{ minWidth: "15rem" }}
            disabled={filterMethod !== METHOD_TEXT_NOT_EQ}
          />
        </Form.Item>
      </Radio>
    );
  }

  if (enabledFilterMethods.includes(METHOD_EMPTY)) {
    radios.push(
      <Radio value={METHOD_EMPTY} key={METHOD_EMPTY}>
        Value is empty
      </Radio>
    );
  }

  if (enabledFilterMethods.includes(METHOD_NOT_EMPTY)) {
    radios.push(
      <Radio value={METHOD_NOT_EMPTY} key={METHOD_NOT_EMPTY}>
        Value is not empty
      </Radio>
    );
  }

  return (
    <>
      <Radio.Group
        value={filterMethod}
        className="p-1"
        onChange={handleFilterMethodChange}
      >
        <Space direction="vertical">{radios}</Space>
      </Radio.Group>
      <Row justify="space-between" style={buttonRowStyle}>
        <Button
          type="link"
          size="small"
          disabled={selectedKeys.length === 0}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button type="primary" size="small" onClick={handleSubmit}>
          OK
        </Button>
      </Row>
    </>
  );
};

export const strapiFilterProps = ({
  defaultFilterMethod,
  defaultFilterValue,
  enabledFilterMethods,
  searchPlaceholder,
} = {}) => {
  return {
    filterDropdown: (props) => (
      <StrapiFilter
        enabledFilterMethods={enabledFilterMethods}
        searchPlaceholder={searchPlaceholder}
        {...props}
      />
    ),
    defaultFilteredValue: defaultFilterValue
      ? [
          {
            method: defaultFilterMethod,
            value: defaultFilterValue,
          },
        ]
      : null,
  };
};

export function strapiFilterKeyToStrapiQueryParam(filters, fieldPath) {
  // The custom filter key currently only supports a single filter option per field path. We could modify this logic
  // to support more complex combination filters.
  const filterKey = _.get(filters, fieldPath)?.[0];

  let filter = {};

  if (filterKey) {
    if (filterKey.method === METHOD_DAY_RANGE_EXCLUSIVE_END) {
      // Filter value is a range of dates expressed as an array of format [startDate (inclusive), endDate (exclusive)]
      const start = filterKey.value?.[0]?.startOf("day")?.toISOString();
      const end = filterKey.value?.[1]?.endOf("day")?.toISOString();
      if (start) {
        filter[fieldPath + "_gte"] = start;
      }
      if (end) {
        filter[fieldPath + "_lte"] = end;
      }
    }
    if (filterKey.method === METHOD_TIME_RANGE_EXCLUSIVE_END) {
      // Filter value is a range of dates expressed as an array of format [startDate (inclusive), endDate (exclusive)]
      const start = filterKey.value?.[0]?.toISOString();
      const end = filterKey.value?.[1]?.toISOString();
      if (start) {
        filter[fieldPath + "_gte"] = start;
      }
      if (end) {
        filter[fieldPath + "_lte"] = end;
      }
    }
    if (filterKey.method === METHOD_TEXT_CONTAINS && filterKey.value) {
      filter[fieldPath + "_contains"] = filterKey?.value;
    } else if (
      filterKey.method === METHOD_TEXT_NOT_CONTAINS &&
      filterKey.value
    ) {
      filter[fieldPath + "_ncontains"] = filterKey?.value;
    } else if (filterKey.method === METHOD_TEXT_NOT_EQ && filterKey.value) {
      filter[fieldPath + "_ne"] = filterKey?.value;
    } else if (filterKey.method === METHOD_EMPTY) {
      filter[fieldPath + "_null"] = true;
    } else if (filterKey.method === METHOD_NOT_EMPTY) {
      filter[fieldPath + "_null"] = false;
    }

    if (filterKey.method === METHOD_CUSTOM_FILTER) {
      filter = { ...filter, ...filterKey.value };
    }

    if (filterKey.method === METHOD_PROGRAM_SELECTION_STATUSES) {
      filter._or = filterKey.value.map((_) => {
        const [program, selection_status] = _.split(":");

        return {
          [`${fieldPath}.program`]: program,
          [`${fieldPath}.selection_status`]: selection_status,
        };
      });
    }
  }

  return filter;
}
