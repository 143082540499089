import { Table, Tooltip } from "antd";

import React, { useState, useMemo } from "react";

import moment from "moment";

import {
  useSamplingLocationProgramEnrollmentList,
  useSamplingLocationProgramEnrollmentCount,
  useBulkUpdateSamplingLocationProgramEnrollments,
} from "api/samplingLocationProgramEnrollment";

import { useTableQueryParams } from "components/Table/helpers";
import { METHOD_NOT_EMPTY } from "components/Table/StrapiFilter";

import { ActionMenu } from "./components/ActionMenu";
import { useUpdateEnrollmentPeriodMenuItem } from "./components/useUpdateEnrollmentPeriodMenuItem";

import { useProgramEnrollmentsTableColumns } from "./useProgramEnrollmentsTableColumns";

const TODAY = moment().format("YYYY-MM-DD");

const ProgramEnrollmentsTable = ({ program }) => {
  // --- Table Query Parameters
  const {
    queryParams,
    pagination,
    handleTableChange: onTableChangeHandler,
  } = useTableQueryParams({
    permanentFilters: {
      "program.id": program?.id,
    },
    defaultFilters: {
      "sampling_location.sampling_location_name": [
        {
          method: METHOD_NOT_EMPTY,
        },
      ],
    },
    defaultPageSize: 25,
    defaultSort: "sampling_location.sampling_location_name:ASC",
  });

  // --- Selected Program Enrollment Ids
  const [
    selectedProgramEnrollmentIds,
    setSelectedProgramEnrollmentIds,
  ] = useState([]);

  // --- Program Enrollments State
  const {
    data: programEnrollmentsCount,
  } = useSamplingLocationProgramEnrollmentCount(queryParams);

  const {
    data: programEnrollments,
    isLoading: isProgramEnrollmentsLoading,
  } = useSamplingLocationProgramEnrollmentList(queryParams);

  const {
    mutateAsync: updateProgramEnrollments,
    isLoading: isProgramEnrollmentsUpdateLoading,
  } = useBulkUpdateSamplingLocationProgramEnrollments();

  const selectedProgramEnrollments = useMemo(() => {
    if (!programEnrollments) {
      return [];
    }

    return programEnrollments.filter((programEnrollment) => {
      return selectedProgramEnrollmentIds.includes(programEnrollment.id);
    });
  }, [programEnrollments, selectedProgramEnrollmentIds]);

  const {
    menuItemProps: updateEnrollmentPeriodMenuItemProps,
    Component: UpdateEnrollmentPeriodModal,
  } = useUpdateEnrollmentPeriodMenuItem({
    program,
    programEnrollments: selectedProgramEnrollments,
  });

  /**
   *
   *
   * @param   {object} attributes
   * @returns {Promise<void>}
   */
  const onUpdateEnrollmentPeriodHandler = async (attributes) => {
    const payload = selectedProgramEnrollmentIds.map((id) => ({
      id,
      ...attributes,
    }));

    await updateProgramEnrollments(payload);

    setSelectedProgramEnrollmentIds([]);
  };

  // --- Program Enrollments Table State
  const { programEnrollmentsTableColumns } = useProgramEnrollmentsTableColumns({
    programEnrollments,
  });

  const columns = [
    ...programEnrollmentsTableColumns(),

    {
      align: "center",
      fixed: "right",
      width: "75px",

      title: () => (
        <ActionMenu
          actions={[updateEnrollmentPeriodMenuItemProps]}
        ></ActionMenu>
      ),

      render: () => null,

      key: "actions",
    },
  ];

  /**
   * @param   {number[]} newSelectedProgramEnrollmentIds
   * @returns {void}
   */
  const onSelectedProgramEnrollmentIdsChange = (
    newSelectedProgramEnrollmentIds
  ) => {
    setSelectedProgramEnrollmentIds(newSelectedProgramEnrollmentIds);
  };

  /**
   * @param   {*} record
   * @returns {*}
   */
  const getSelectedProgramEnrollmentCheckboxProps = (record) => {
    return {
      disabled: record.start_date < TODAY && record.end_date < TODAY,
    };
  };

  const rowSelectionProps = {
    selectedRowKeys: selectedProgramEnrollmentIds,
    onChange: onSelectedProgramEnrollmentIdsChange,
    getCheckboxProps: getSelectedProgramEnrollmentCheckboxProps,
    renderCell(checked, record, index, node) {
      if (record.start_date < TODAY && record.end_date < TODAY) {
        return (
          <Tooltip
            title={
              "Please contact @eng-on-call in the #eng-support channel on Slack to modify enrollments which have expired"
            }
          >
            {node}
          </Tooltip>
        );
      }

      return node;
    },
  };

  return (
    <>
      <Table
        className="admin-program-enrollments-table"
        rowKey="id"
        rowSelection={rowSelectionProps}
        loading={
          isProgramEnrollmentsLoading || isProgramEnrollmentsUpdateLoading
        }
        columns={columns}
        footer={() => (
          <div style={{ textAlign: "right" }}>
            {programEnrollmentsCount?.toLocaleString()} results
          </div>
        )}
        pagination={{ ...pagination, total: programEnrollmentsCount }}
        dataSource={programEnrollments}
        onChange={onTableChangeHandler}
        scroll={{ x: "max-content" }}
      />

      <UpdateEnrollmentPeriodModal
        selectedItems={selectedProgramEnrollments}
        onConfirm={onUpdateEnrollmentPeriodHandler}
      ></UpdateEnrollmentPeriodModal>
    </>
  );
};

export default ProgramEnrollmentsTable;
