import { Button, Input, Form, Typography } from "antd";
import axios from "axios";
import { useState } from "react";
import { withRouter, Link } from "react-router-dom";

const PwdReset = () => {
  const [reset, setReset] = useState(false);
  const [noMatch, setNoMatch] = useState(false);

  if (reset) {
    return (
      <div>
        <p>Your password has been reset.</p>
        <p>
          <Link to="/account/login">Login</Link>
        </p>
      </div>
    );
  }

  return (
    <>
      <Form
        onFinish={({
          username,
          confirmationCode,
          newPassword,
          confirmNewPassword,
        }) => {
          if (newPassword === confirmNewPassword) {
            axios
              .put(
                `${process.env.REACT_APP_API}/identity/cognito/password/confirmForgot`,
                { username, confirmationCode, newPassword: newPassword.trim() }
              )
              .then(() => setReset(true));
          } else {
            setNoMatch(true);
          }
        }}
      >
        Confirmation code
        <Form.Item
          name="confirmationCode"
          rules={[
            {
              required: true,
              message: "Please enter the code we emailed you.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div style={{ textAlign: "right" }}>
          <Link to="/account/forgot">Resend code.</Link>
        </div>
        Email
        <Form.Item
          name="username"
          labelCol={{ span: 24, offset: 0 }}
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        New password
        <Form.Item
          name="newPassword"
          labelCol={{ span: 24, offset: 0 }}
          rules={[
            {
              required: true,
              message: "Please enter new password.",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        Confirm new password
        <Form.Item
          name="confirmNewPassword"
          labelCol={{ span: 24, offset: 0 }}
          rules={[
            {
              required: true,
              message: "Please enter same password as above.",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        {noMatch && (
          <Typography.Text type="danger">
            Your passwords do not match.
          </Typography.Text>
        )}
        <Button htmlType="submit">reset</Button>
      </Form>
    </>
  );
};

export default withRouter(PwdReset);
