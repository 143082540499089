import { withRouter } from "react-router-dom";
import { PageHeader, Table, Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import fileDownload from "js-file-download";

import { useReportCount, useReportList, fetchReportCsv } from "api/reports";
import { useTableQueryParams } from "components/Table/helpers";
import { createInvoicingTableColumns } from "./helpers";
import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_CONTAINS,
  METHOD_TEXT_NOT_EQ,
} from "components/Table/StrapiFilter";
import { useProgramList } from "api/program";
import { previousMonthStart, previousMonthEnd } from "utils/time";

function Invoicing() {
  const { queryParams, pagination, handleTableChange } = useTableQueryParams({
    defaultPageSize: 500,
    defaultSort: "report_generation_job_id:DESC",
    defaultFilters: {
      environment: [
        {
          method: METHOD_TEXT_CONTAINS,
          value: "prod",
        },
      ],
      date_report_emailed: [
        {
          method: METHOD_DAY_RANGE_EXCLUSIVE_END,
          value: [previousMonthStart, previousMonthEnd],
        },
      ],
      "product.product_sku": [
        {
          method: METHOD_TEXT_NOT_EQ,
          value: "SKU0019",
        },
      ],
    },
  });

  const { data: reportCount } = useReportCount(queryParams);

  const reports = useReportList({
    ...queryParams,
    _joins: [
      "product",
      "report_kits.kit.sample.sampling_location_id",
      "report_kits.kit.sample.organization",
      "report_kits.kit.selection_statuses.program",
    ],
  });

  const { data: programs } = useProgramList();

  const columns = createInvoicingTableColumns(programs);

  const downloadInvoicingCSV = () => {
    const date = moment().format("MM-DD-YYYY");
    if (reports.data.length) {
      fetchReportCsv(queryParams)
        .then((data) =>
          fileDownload(data, `${date}-monthly-reports-for-invoicing.csv`)
        )
        .catch((error) => {
          message.error(
            `Unable to update reports due to a ${error.response?.data?.error} error.`
          );
        });
    } else {
      message.error("No reports were found. Please update the table filters.");
    }
  };

  return (
    <>
      <PageHeader title="Show Me The Money $$$" className="px-0" />
      {/* Temp page title */}
      <Button
        className="mb-2"
        type="primary"
        size="small"
        shape="round"
        onClick={downloadInvoicingCSV}
      >
        <DownloadOutlined /> Monthly Reports
      </Button>
      <Table
        rowKey={"id"}
        loading={reports.isLoading}
        columns={columns}
        pagination={{
          ...pagination,
          total: reportCount,
          showSizeChanger: true,
          pageSizeOptions: ["50", "100", "250", "500"],
        }}
        dataSource={reports.data}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
      />
    </>
  );
}

export default withRouter(Invoicing);
