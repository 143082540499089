import { PageHeader, Table } from "antd";

import { createProgramTableColumns } from "./helpers";
import { useProgramList, useProgramCount } from "api/program";
import { useTableQueryParams } from "components/Table/helpers";

const ProgramsPage = () => {
  const { queryParams, pagination, handleTableChange } = useTableQueryParams({
    defaultPageSize: 10,
    defaultSort: "updated_at:DESC",
  });

  // fetch data for programs and associated product deliverables
  const { data: allProgramsCount } = useProgramCount(queryParams);
  const {
    data: programsData,
    isLoading: isProgramsDataLoading,
  } = useProgramList({
    ...queryParams,
    _joins: ["product_deliverables.product"],
  });

  const columns = createProgramTableColumns(programsData);

  return (
    <>
      <PageHeader title="Programs & Bundles" />
      <Table
        rowKey="id"
        loading={isProgramsDataLoading}
        columns={columns}
        pagination={{ ...pagination, total: allProgramsCount }}
        dataSource={programsData}
        onChange={handleTableChange}
        scroll={{ x: "min-content" }}
        className="px-3"
      />
    </>
  );
};

export default ProgramsPage;
