import { Button, Modal, Input, Typography } from "antd";
import _ from "lodash";
import { useState } from "react";
import qs from "qs";

import { orderServiceRoot } from "api/util";
import { titleCase } from "./util";

const hangingIndent = (str, n) => {
  const [first, ...rest] = str.split("\n");
  return first + rest.map((line) => `\n${" ".repeat(n)}${line}`).join("");
};

const pythonRequestsText = ({ model, params }) => {
  const headers = {
    Authorization: "Bearer: $YOUR_TOKEN_HERE",
  };
  return `requests.get(
    ${JSON.stringify(`${orderServiceRoot}/${model}`)},
    headers=${hangingIndent(JSON.stringify(headers, null, 4), 4)},
    params=${hangingIndent(JSON.stringify(params, null, 4), 4)}
)`;
};

const curlText = ({ model, params }) =>
  [
    "curl",
    '-H "Authorization: Bearer $YOUR_TOKEN_HERE"',
    `-g '${orderServiceRoot}/${model}?${qs.stringify(params)}'`
      .replaceAll("%5B", "[")
      .replaceAll("%5D", "]"),
  ].join(" ");

const frontendText = ({ model, columns }) => {
  const modelSingular = model.replace(/s$/, "");
  const title = titleCase(modelSingular);
  const Camel = title.replaceAll(" ", "");
  const lowerCamel = Camel.at(0).toLowerCase() + Camel.slice(1);
  const cols = columns.map((col) => _.omit(col, "render"));

  return `import { Table } from "antd";

import { useTableQueryParams } from "components/Table/helpers";
import { use${Camel}List, use${Camel}Count } from "api/${lowerCamel}";

const ${Camel}Table = () => {
  const { queryParams, pagination, handleTableChange } = useTableQueryParams();

  const ${lowerCamel}Query = use${Camel}List(queryParams);
  const { data: ${lowerCamel}Count } = use${Camel}Count(queryParams);

  const columns = ${hangingIndent(JSON.stringify(cols, null, 2), 2)};

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{ ...pagination, total: ${lowerCamel}Count }}
      onChange={handleTableChange}
      dataSource={${lowerCamel}Query.data}
      isLoading={${lowerCamel}Query.isLoading}
    />
  )
};

export default ${Camel}Table;`;
};

const copyableCodeStyle = {
  fontFamily: "monospace",
};

const CopyableCode = (props) => (
  <Input.TextArea rows={6} readOnly style={copyableCodeStyle} {...props} />
);

const ExportCodePanel = ({ model, queryParams, columns }) => {
  const { _where, ...restParams } = queryParams;
  const params = _.mapValues(
    {
      ..._.omit(restParams, ["_start", "_limit"]),
      ..._where,
    },
    (v) => (Array.isArray(v) && v.length === 1 ? v[0] : v)
  );
  return (
    <div>
      <Typography.Text>Python (requests)</Typography.Text>
      <CopyableCode value={pythonRequestsText({ model, params })} rows={10} />
      <Typography.Text>cURL</Typography.Text>
      <CopyableCode value={curlText({ model, params })} rows={4} />
      <Typography.Text>React component</Typography.Text>
      <CopyableCode value={frontendText({ model, columns })} rows={10} />
    </div>
  );
};

const ExportCodeButton = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button type="primary" onClick={() => setVisible(!visible)}>
        Show code
      </Button>
      <Modal
        visible={visible}
        footer={null}
        width="75%"
        onCancel={() => setVisible(false)}
      >
        <ExportCodePanel {...props} />
      </Modal>
    </>
  );
};

export default ExportCodeButton;
