export const getOrder = (props, orgId) => {
  const accountId = !props?.organization?.id ? orgId : props.organization.id;

  props
    .actionOrganizationGet({ id: accountId })
    .then((res) => {
      return props.actionOrderGet({ id: props.match.params.id });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getKitsPerCycle = (locations) => {
  let total = 0;
  locations.forEach((item) => {
    total = total + item.kits;
  });
  return total;
};
