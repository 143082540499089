import { useState } from "react";
import { Button, PageHeader, Table, Row, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import fileDownload from "js-file-download";

import { useFormReviewStatusOptions } from "api/formReviewStatus";
import { useCountyNameMap } from "api/counties";
import { useStateNameMap, useStateOptions } from "api/states";
import {
  fetchSamplingLocationDetailedCsv,
  useSamplingLocationListDetailed,
  useSamplingLocationCount,
} from "api/samplingLocation";
import { useTableQueryParams } from "components/Table/helpers";
import { getColumns } from "./helpers";
import { useSamplingLocationsProgramEnrollmentFilterProps } from "./useSamplingLocationsProgramEnrollmentFilterProps";

export default function SamplingLocations() {
  const [isCsvDownloading, setIsCsvDownloading] = useState(false);
  const { queryParams, handleTableChange, pagination } = useTableQueryParams();

  const { data: countyNameMap } = useCountyNameMap();
  const { data: stateNameMap } = useStateNameMap();
  const { data: stateOptions } = useStateOptions();
  const { data: reviewStatusOptions } = useFormReviewStatusOptions();
  const locationsQuery = useSamplingLocationListDetailed(queryParams);
  const { data: locationsCount } = useSamplingLocationCount(queryParams);

  const {
    samplingLocationsProgramEnrollmentFilterProps,
  } = useSamplingLocationsProgramEnrollmentFilterProps();

  const downloadSamplingLocationsCSV = () => {
    setIsCsvDownloading(true);
    const date = moment().format("MM-DD-YYYY");
    fetchSamplingLocationDetailedCsv(queryParams)
      .then((data) => fileDownload(data, `${date}-sampling-locations.csv`))
      .catch(message.error)
      .finally(() => setIsCsvDownloading(false));
  };

  const columns = getColumns({
    samplingLocationsProgramEnrollmentFilterProps,
    countyNameMap,
    stateNameMap,
    stateOptions,
    reviewStatusOptions,
  });

  return (
    <div>
      <PageHeader title="Sampling Locations" />
      <Row className="px-3 mb-1">
        <Button
          className="mr-1"
          type="primary"
          size="small"
          shape="round"
          loading={isCsvDownloading}
          onClick={downloadSamplingLocationsCSV}
        >
          <DownloadOutlined />
        </Button>
        Total Locations: {locationsCount}
      </Row>
      <Table
        scroll={{ x: "max-content" }}
        className="px-3"
        rowKey="id"
        loading={locationsQuery.isLoading}
        columns={columns}
        dataSource={locationsQuery.data}
        onChange={handleTableChange}
        pagination={{ ...pagination, total: locationsCount }}
      />
    </div>
  );
}
