import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import moment from "moment";
import {
  PageHeader,
  Typography,
  message,
  Table,
  Button,
  Modal,
  Row,
  Col,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import _ from "lodash";
import fileDownload from "js-file-download";

import TableSearch from "components/Table/TableSearch";
import EditableTable from "components/Table/EditableTable";
import Loader from "components/Loader/Loader";
import ActivationForm from "containers/Activation/ActivationForm";
import { useProgramOptions } from "api/program";
import ProgramName from "components/ProgramDisplayName";
import {
  getQueryStringsForPage,
  getFilterValueFromQueryString,
  updateQueryStringForFilters,
} from "./helpers";

export default function Leads() {
  const history = useHistory();
  const currentUrlParams = new URLSearchParams(window.location.search);
  const queryStringOptions = ["program", "search", "state", "status"];
  const filterOptions = ["program", "state", "status"];
  const currentQueryStrings = getQueryStringsForPage(
    queryStringOptions,
    currentUrlParams
  );

  const [paramsForFetch, setParamsForFetch] = useState({
    _page: 1,
    _limit: 30,
    searchInput: currentQueryStrings.search || null,
  });

  const [loading, setLoading] = useState(true);
  const [stateFilters, setStateFilters] = useState();
  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState();

  const {
    data: programs,
    isLoading: isProgramOptionsLoading,
  } = useProgramOptions();
  let programsForFilter = programs
    ?.map(({ code, label }) => ({
      text: label,
      value: code,
    }))
    .concat([{ text: "None", value: "none" }]);

  const tableRef = useRef();

  const axiosHeaders = () => {
    return {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
  };

  const axiosParams = (searchParams) => {
    let p = {
      _start: (searchParams._page - 1) * searchParams._limit,
      _limit: searchParams._limit,
      _sort: `created_at:DESC`,
      state: searchParams.state,
      status: searchParams.status,
      public_viz_opt_in_contains: searchParams.public_viz_opt_in,
    };

    if (searchParams.program === "none") {
      p.program_null = true;
    } else {
      p.program = searchParams.program;
    }

    p = _.omitBy(p, _.isNil);

    return {
      params: {
        ...p,
        _where: {
          _or: [
            { [`organization.display_id_contains`]: searchParams.searchInput },
            {
              [`organization.organization_name_contains`]: searchParams.searchInput,
            },
            { organization_name_contains: searchParams.searchInput },
          ],
        },
      },
      paramsSerializer: qs.stringify,
      headers: axiosHeaders(),
    };
  };

  const getLeads = (searchParams) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}leads`,
      axiosParams(searchParams)
    );
  };

  const getLeadsCount = (searchParams) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}leads/count`,
      axiosParams(searchParams)
    );
  };

  const downloadLeadsCSV = (isForAdditionalContacts) => {
    let params = axiosParams(paramsForFetch);
    params.params.as_csv = true;
    params.params._start = 0;
    params.params._limit = -1;
    params.params.additional_contacts_only = isForAdditionalContacts;

    const date = moment().format("MM-DD-YYYY");
    const fileName = isForAdditionalContacts
      ? `Additional contacts from leads ${date}.csv`
      : `Sampling locations from leads ${date}.csv`;

    return axios
      .get(`${process.env.REACT_APP_API_URL}leads`, params)
      .then((response) => {
        fileDownload(response.data, fileName);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          message.error(
            "Your download contained no results. Please update your search and try again."
          );
        } else message.error("Something went wrong!");
      });
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DATASETS_API_URL}/datasets/master_data/states`,
        {
          headers: axiosHeaders(),
        }
      )
      .then(({ data }) => {
        const filters = data
          .map((state) => {
            return {
              text: state.state_name,
              value: state.state_cd,
            };
          })
          .sort((a, b) => a.label - b.label);
        setStateFilters(filters);
      })
      .catch(() => message.error("Something went wrong!"))
      .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    handleSearchParamsUpdated({
      _page: 1,
      state: getFilterValueFromQueryString(currentQueryStrings.state),
      status: getFilterValueFromQueryString(currentQueryStrings.status),
      program: getFilterValueFromQueryString(currentQueryStrings.program),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchParamsUpdated = (updatedParams) => {
    //update url for all filters
    updateQueryStringForFilters(
      updatedParams,
      currentUrlParams,
      history,
      filterOptions
    );

    //update url for searched text
    if (updatedParams.searchInput === null) {
      currentUrlParams.delete("search");
      history.push(
        `${window.location.pathname.toString()}?${currentUrlParams.toString()}`
      );
    } else if (updatedParams.searchInput !== undefined) {
      currentUrlParams.set("search", updatedParams.searchInput);
      history.push(
        `${window.location.pathname.toString()}?${currentUrlParams.toString()}`
      );
    }

    setParamsForFetch({
      ...paramsForFetch,
      ...updatedParams,
    });
  };

  const handleTableChange = (pagination, filters, sorter, { action }) => {
    if (action === "filter") {
      handleSearchParamsUpdated({
        _page: 1,
        state: filters.state ? filters.state[0] : null,
        status: filters.status ? filters.status[0] : null,
        program: filters.program ? filters.program[0] : null,
        public_viz_opt_in: filters.public_viz_opt_in
          ? filters.public_viz_opt_in[0]
          : null,
      });
    }
  };

  const handleActivate = (lead) => {
    setModalContent(
      <ActivationForm
        lead={lead}
        onLeadActivated={handleLeadModalClosed}
        onLeadRejected={handleLeadModalClosed}
      />
    );
    setModalOpen(true);
  };

  const handleLeadModalClosed = () => {
    setModalOpen(false);
  };

  const activationStatusToText = (text) => {
    if (text === "accepted") {
      return (
        <Typography.Text type="success" data-cy="activation_status">
          Activated
        </Typography.Text>
      );
    } else if (text === "pending_review") {
      return (
        <Typography.Text type="warning" data-cy="activation_status">
          Pending Review
        </Typography.Text>
      );
    } else if (text === "rejected") {
      return (
        <Typography.Text type="danger" data-cy="activation_status">
          Rejected
        </Typography.Text>
      );
    } else {
      return (
        <Typography.Text data-cy="activation_status">{text}</Typography.Text>
      );
    }
  };

  const columns = [
    {
      title: "Lead Org Name",
      dataIndex: ["organization_name"],
      key: "organization_name",
      render: (text, record) => (
        <Button
          data-cy="organization_name"
          type="link"
          onClick={() => handleActivate(record)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Submitted on",
      dataIndex: ["created_at"],
      key: "created_at",
      render: (text) => moment(text).format("MM-DD-YYYY"),
    },
    {
      title: "Activation Status",
      dataIndex: ["status"],
      key: "status",
      render: activationStatusToText,
      filters: [
        {
          text: "Accepted",
          value: "accepted",
        },
        {
          text: "Pending Review",
          value: "pending_review",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
      ],
      defaultFilteredValue: getFilterValueFromQueryString(
        currentQueryStrings.status
      ), //needed to show the filter in the UI
      filterResetToDefaultFilteredValue: true,
      filterMultiple: false,
    },
    {
      title: "Linked Org",
      key: "organization",
      render: (text, record) => {
        if (_.isNil(record.organization)) {
          return <Typography.Text data-cy="organization">-</Typography.Text>;
        } else if (record.organization.display_id) {
          return (
            <Typography.Text data-cy="organization">
              {`${record.organization.organization_name} (${record.organization.display_id})`}
            </Typography.Text>
          );
        } else {
          return (
            <Typography.Text data-cy="organization">
              {record.organization.organization_name}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: "Program",
      dataIndex: ["program"],
      key: "program",
      filters: programsForFilter,
      filterSearch: true,
      filterMultiple: false,
      defaultFilteredValue: getFilterValueFromQueryString(
        currentQueryStrings.program
      ), //needed to show the filter in the UI
      render: (text) => (
        <Typography.Text data-cy="program">
          <ProgramName programCode={text} />
        </Typography.Text>
      ),
    },
    {
      title: "State",
      dataIndex: ["state"],
      key: "state",
      filters: stateFilters,
      filterMultiple: false,
      defaultFilteredValue: getFilterValueFromQueryString(
        currentQueryStrings.state
      ), //needed to show the filter in the UI
      filterSearch: true,
      render: (text) => (
        <Typography.Text data-cy="state">{text ?? "-"}</Typography.Text>
      ),
    },
    {
      title: "Est. Population",
      dataIndex: ["estimated_population_served"],
      key: "estimated_population_served",
      render: (text) => (
        <Typography.Text data-cy="estimated_population_served">
          {text ?? "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Locations",
      dataIndex: ["num_of_sampling_locations"],
      key: "num_of_sampling_locations",
      render: (text) => (
        <Typography.Text data-cy="num_of_sampling_locations">
          {text ?? "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Viz Data Sharing",
      dataIndex: ["public_viz_opt_in"],
      key: "public_viz_opt_in",
      render: (text) => (
        <Typography.Text>{text?.toString() ?? "-"}</Typography.Text>
      ),
      filters: [
        {
          text: "true",
          value: true,
        },
        {
          text: "false",
          value: false,
        },
      ],
      filterMultiple: false,
    },
  ];

  const expandableConfig = {
    rowExpandable: (record) =>
      record.lead_sampling_locations &&
      record.lead_sampling_locations.length > 0,
    expandedRowRender: (record) => {
      const nestedColumns = [
        {
          title: "Sampling Location Name",
          dataIndex: ["sampling_location_name"],
          key: "sampling_location_name",
        },
        {
          title: "Activation Status",
          dataIndex: ["status"],
          key: "status",
          render: activationStatusToText,
        },
        {
          title: "Est. Population",
          dataIndex: ["estimated_population_served"],
          key: "estimated_population_served",
        },
        {
          title: "Shipping Address",
          key: "lead_shipping_location",
          render: (text, record) => {
            const loc = record.lead_shipping_location;
            return (
              <Typography.Text>
                {`${[loc.street_address_1, loc.street_address_2]
                  .filter((x) => !_.isNil(x))
                  .join(" ")} - ${loc.city}, ${loc.state} ${loc.zip_code}`}
              </Typography.Text>
            );
          },
        },
      ];

      const data = record.lead_sampling_locations.sort(
        (l, r) => l.estimated_population_served - r.estimated_population_served
      );

      return (
        <Table
          columns={nestedColumns}
          dataSource={data}
          pagination={false}
          bordered
        />
      );
    },
  };

  if (loading || isProgramOptionsLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageHeader
        title="Leads"
        className="mb-3"
        footer={
          <Row gutter={8}>
            <Col>
              <TableSearch
                disableDatePicker={true}
                searchPlaceholderText="Search lead org..."
                onSearchParamsUpdated={handleSearchParamsUpdated}
                isDisplayInline
                defaultSearch={currentQueryStrings.search} //needed to show in UI
              />
            </Col>
            <Col>
              <Button
                onClick={() => {
                  downloadLeadsCSV();
                }}
                type="primary"
                shape="round"
              >
                <DownloadOutlined /> Leads
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  const isForAdditionalContacts = true;
                  downloadLeadsCSV(isForAdditionalContacts);
                }}
                type="primary"
                shape="round"
              >
                <DownloadOutlined /> Additional Contacts
              </Button>
            </Col>
          </Row>
        }
      />
      <Modal
        visible={modalOpen}
        width={"75%"}
        footer={[]}
        onCancel={() => setModalOpen(false)}
        destroyOnClose
      >
        <div className="lead-modal-content">{modalContent}</div>
      </Modal>
      <EditableTable
        ref={tableRef}
        rowKey="id"
        columns={columns}
        expandable={expandableConfig}
        paramsForFetch={paramsForFetch}
        fetchRows={getLeads}
        fetchRowCount={getLeadsCount}
        onSearchParamsUpdated={handleSearchParamsUpdated}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        className="px-3"
        id="leads-table"
        getPopupContainer={() => document.getElementById("leads-table")}
      />
    </>
  );
}
