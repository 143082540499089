import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import { orderServiceFetchList } from "../../../api/orderService";

const ProgramFilterModal = ({ visible, onOk, onCancel }) => {
  const [allPrograms, setAllPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  // Fetch the list of programs
  useEffect(() => {
    fetchPrograms();
  }, []);

  const fetchPrograms = async () => {
    await orderServiceFetchList({ model: "programs", _sort: "name" })
      .then((programs) => {
        setAllPrograms(programs);
      })
      .catch((e) => {
        console.log(`Error fetching programs`, e);
      });
  };

  const handleCheckboxChange = (programCode) => {
    setSelectedPrograms((prevSelectedPrograms) => {
      if (prevSelectedPrograms.includes(programCode)) {
        return prevSelectedPrograms.filter((code) => code !== programCode);
      } else {
        return [...prevSelectedPrograms, programCode];
      }
    });
  };

  const handleSelectData = () => {
    // Pass selection back to the form
    onOk(selectedPrograms);
  };

  const handleClear = () => {
    // Uncheck all boxes before sending back to form
    setSelectedPrograms([]);
    onOk([]);
  };

  return (
    <Modal
      key="program-filter-modal"
      title="Select Programs"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="select" type="primary" onClick={handleSelectData}>
          Select
        </Button>,
        <Button key="clear" onClick={handleClear}>
          Clear Filters
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      {allPrograms.map((program) => (
        <>
          <Checkbox
            key={program.code}
            onChange={() => handleCheckboxChange(program.code)}
            checked={selectedPrograms.includes(program.code)}
          >
            {program.name}
          </Checkbox>
          <br />
        </>
      ))}
    </Modal>
  );
};

export default ProgramFilterModal;
