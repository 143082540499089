import { Col, Row, Typography } from "antd";

const { Text, Title } = Typography;

export const DataVizInclusionSection = ({ lead }) => {
  let customerInclusionPreference = (
    <>
      <Row>
        <Text type="warning">
          Customer results preference not captured in onboarding form.
        </Text>
      </Row>
      <Row>
        <Text>
          Please reach out via Typeform to confirm their preference. If they’d
          like to include their results, enroll all CDC sampling locations in
          the Public Data Visualization Program.
        </Text>
      </Row>
    </>
  );
  if (lead.public_viz_opt_in === true) {
    customerInclusionPreference = (
      <Row>
        <Text type="success">Yes, we can include results in Data Viz 🎉</Text>
      </Row>
    );
  }
  if (lead.public_viz_opt_in === false) {
    customerInclusionPreference = (
      <Row>
        <Text type="danger">No, we cannot include results in Data Viz 😢</Text>
      </Row>
    );
  }

  return (
    <Row className="my-4">
      <Col>
        <Title level={5}>Public Data Visualization Opt-in Status</Title>
        {customerInclusionPreference}
      </Col>
    </Row>
  );
};
