import { createRef, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  Modal,
  Form,
  Button,
  message,
} from "antd";
import {
  actionUsersGet,
  actionAccountUserGet,
  actionUserAdd,
  actionUserEdit,
  actionRolesGet,
  actionUserRolesEdit,
  actionUserAccountsEdit,
} from "actions";
const { Option } = Select;
const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionUsersGet,
  actionAccountUserGet,
  actionUserAdd,
  actionUserEdit,
  actionRolesGet,
  actionUserRolesEdit,
  actionUserAccountsEdit,
};

class Users extends Component {
  state = {
    loading: true,
    visible: true,
    user: {},
  };
  formRef = createRef();

  componentDidMount() {
    this.props
      .actionRolesGet()
      .then((res) => {
        return this.props.actionAccountUserGet(this.props.match.params.id);
      })
      .then((res) => {
        const formData = res.payload.data;
        const roles = [];
        if (
          formData &&
          formData.accounts &&
          formData.accounts[0] &&
          formData.accounts[0].roles
        ) {
          formData.accounts[0].roles.forEach((item) => {
            roles.push(item.name);
          });
        }
        this.formRef.current.setFieldsValue({
          Name: formData.name,
          Email: formData.email,
          Permissions: roles,
          Account: this.getUserAccount(),
          Accounts: this.getUserAccounts(),
        });
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getUserAccount() {
    const accounts = [];
    if (this.props.accountUser && this.props.accountUser.accounts) {
      this.props.accountUser.accounts.forEach((item) => {
        accounts.push(item.id);
      });
    }
    return accounts;
  }

  getUserAccounts() {
    const accounts = [];
    if (
      this.props.accountUser &&
      this.props.accountUser.accounts &&
      this.props.accountUser.accounts[0].access_to
    ) {
      this.props.accountUser.accounts[0].access_to.inclusion.forEach((item) => {
        accounts.push(item);
      });
    }
    return accounts;
  }

  render() {
    return (
      <div>
        <Modal
          title=""
          width={"80%"}
          footer={null}
          visible={this.state.visible}
          onCancel={(e) => {
            this.props.history.push(`/users${this.props.location.search}`);
          }}
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            onFinish={(values) => {
              this.props
                .actionUserEdit({
                  id: this.props.match.params.id,
                  value: {
                    name: values["Name"],
                    email: values["Email"],
                  },
                })
                .then((res) => {
                  return this.props.actionUserRolesEdit({
                    userId: this.props.match.params.id,
                    accountId: this.props.accountUser.accounts[0].id,
                    roles: values["Permissions"],
                  });
                })
                .then((res) => {
                  message.success("User updated.");
                  this.props.history.push("/users");
                })
                .catch((err) => {
                  message.error("Error updating user.");
                });
            }}
            onFieldsChange={(values) => {
              if (values && values[0] && values[0].name[0] === "Accounts") {
                this.props
                  .actionUserAccountsEdit({
                    user_id: this.props.match.params.id,
                    account_id: this.props.accountUser.accounts[0].id,
                    inclusion: values[0].value,
                  })
                  .then((res) => {
                    console.log("saved user accounts...");
                  });
              }
            }}
            onFinishFailed={(err) => {
              console.log(err);
            }}
          >
            <h1>User</h1>
            <Row gutter={30}>
              <Col sm={12}>
                <Form.Item
                  name="Name"
                  label="Name"
                  value={this.state.user.name}
                  rules={[{ required: true }]}
                >
                  <Input disabled={this.state.loading} />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  disabled={this.state.loading}
                  name="Email"
                  label="Email"
                  rules={[{ required: true }]}
                >
                  <Input disabled={this.state.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <h2>Account</h2>
                <Form.Item name="Account" label="Account">
                  <Select mode="multiple" disabled>
                    {this.props.accounts.map((item) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <h2>Roaming</h2>
                <Form.Item
                  name="Accounts"
                  label="Accounts to which user has access"
                >
                  <Select mode="multiple" disabled={this.state.loading}>
                    <Option value="ALL">All</Option>
                    {this.props.accounts.map((item) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <h2>Permissions</h2>
                <Form.Item name="Permissions">
                  <Checkbox.Group disabled={this.state.loading}>
                    {this.props.roles.map((role, index) => (
                      <div className="user-permission">
                        <Checkbox key={index} value={role.name}>
                          {role.description}
                        </Checkbox>
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Button id="user-form-button" type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(Users);
export default withRouter(UsersContainer);
