import { Row, Col, Form, Input, Select } from "antd";

import {
  PROVINCES_BY_COUNTRY,
  PROVINCE_LABELS_BY_COUNTRY,
  COUNTRIES,
} from "configs/constants";

export default function AddressQuestions({
  required,
  index,
  namePath,
  initialValues,
  disabled = false,
  showNameField = true,
}) {
  const pathPrefix = namePath ? namePath : index ? [index] : [];

  return (
    <>
      <Row gutter={40}>
        {showNameField && (
          <Col sm={24} xs={24}>
            <Form.Item
              name={[...pathPrefix, "name"]}
              label="Shipping Location Name"
              initialValue={initialValues?.name}
              rules={[
                {
                  required,
                  message: "Enter a unique name",
                  whitespace: true,
                },
              ]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        )}
        <Col sm={12} xs={24}>
          <Form.Item
            label="Street Address 1"
            name={[...pathPrefix, "address_line_1"]}
            data-cy="address_1"
            initialValue={initialValues?.addressLine1}
            rules={[
              {
                required,
                message: "You must enter an address",
                whitespace: true,
              },
            ]}
          >
            <Input disabled={disabled} placeholder="Street Address 1" />
          </Form.Item>
        </Col>

        <Col sm={12} xs={24}>
          <Form.Item
            label="Street Address 2"
            name={[...pathPrefix, "address_line_2"]}
            initialValue={initialValues?.addressLine2}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input disabled={disabled} placeholder="Street Address 2" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={40}>
        <Col sm={6} xs={24}>
          <Form.Item
            label="City"
            name={[...pathPrefix, "city"]}
            data-cy="city"
            initialValue={initialValues?.city}
            rules={[
              {
                required,
                message: "You must enter a city",
                whitespace: true,
              },
            ]}
          >
            <Input disabled={disabled} placeholder="City" />
          </Form.Item>
        </Col>

        <Col sm={6} xs={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues["country"] !== currentValues["country"];
            }}
          >
            {({ getFieldValue }) => {
              const selectedCountry =
                getFieldValue("country") || COUNTRIES.US.value;

              const options = (PROVINCES_BY_COUNTRY[selectedCountry] ?? []).map(
                ({ abbreviation, name }) => ({
                  value: abbreviation,
                  label: name,
                })
              );
              return (
                <Form.Item
                  label={PROVINCE_LABELS_BY_COUNTRY[selectedCountry]}
                  name={[...pathPrefix, "state"]}
                  data-cy="state"
                  initialValue={initialValues?.state}
                  rules={[
                    {
                      required,
                      message: `You must select a ${PROVINCE_LABELS_BY_COUNTRY[
                        selectedCountry
                      ].toLowerCase()}`,
                    },
                  ]}
                >
                  <Select
                    id="input-select-province"
                    showSearch
                    placeholder={PROVINCE_LABELS_BY_COUNTRY[selectedCountry]}
                    options={options}
                  ></Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col sm={6} xs={24}>
          <Form.Item
            label="Postal Code"
            data-cy="zip_code"
            name={[...pathPrefix, "zip_code"]}
            initialValue={initialValues?.zipCode}
            rules={[
              {
                required: required,
                message: "Enter Postal Code",
                pattern: new RegExp(
                  /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] \d[A-Z]\d)$/
                ), // Supports US, Mexico, & Canada
              },
            ]}
          >
            <Input disabled={disabled} placeholder="Postal Code" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
