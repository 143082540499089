import Title from "antd/es/typography/Title";
import { Checkbox, Col, Form, Input, Radio, Row, Typography } from "antd";
import React from "react";
import { leadInitialValue, genLabel } from "../helpers";

export default function OtherInfoSection({ lead, alreadyActivated }) {
  const initialTaxId = leadInitialValue(lead, "tax_id", "tax_id");
  const initialTaxNumber = leadInitialValue(
    lead,
    "tax_id_number",
    "tax_id_number"
  );
  const initialTaxStatus = leadInitialValue(lead, "tax_status", "tax_status");
  const initialUSTerritory = leadInitialValue(
    lead,
    "us_territory",
    "us_territory"
  );
  const initialTribalTerritory = leadInitialValue(
    lead,
    "recognized_tribal_indian_territory",
    "recognized_tribal_indian_territory"
  );

  return (
    <>
      <Title level={5} className="mt-3">
        Other Details
      </Title>
      <Row gutter={16} className="mt-2">
        <Col sm={24} md={6}>
          <Form.Item
            name="tax_id"
            label={genLabel("Tax ID", initialTaxId)}
            initialValue={initialTaxId.value}
            rules={[{ required: false }]}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
        <Col sm={24} md={6}>
          <Form.Item
            name="tax_id_number"
            label={genLabel("Tax ID Number", initialTaxNumber)}
            initialValue={initialTaxNumber.value}
            rules={[{ required: false }]}
          >
            <Input disabled={alreadyActivated} />
          </Form.Item>
        </Col>
        <Col sm={24} md={6}>
          <Form.Item
            name="tax_status"
            label={genLabel("Tax Status", initialTaxStatus)}
            initialValue={initialTaxStatus.value ?? "Taxable"}
            rules={[{ required: false }]}
          >
            <Radio.Group disabled={alreadyActivated}>
              <Radio value="Taxable">Taxable</Radio>
              <Radio value="Exempt">Exempt</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" align="middle">
        <Col>
          <Form.Item
            name="recognized_tribal_indian_territory"
            valuePropName="checked"
            initialValue={initialTribalTerritory.value}
            className="mb-0"
          >
            <Checkbox disabled={alreadyActivated}>
              Recognized Tribal Indian Territory
            </Checkbox>
          </Form.Item>
        </Col>
        {!initialUSTerritory.fromLead && initialUSTerritory.value && (
          <Col>
            <Typography.Text className="pl-1" style={{ color: "blue" }}>
              (via linked Org)
            </Typography.Text>
          </Col>
        )}
      </Row>
      <Row justify="start" align="middle">
        <Col>
          <Form.Item
            name="us_territory"
            valuePropName="checked"
            initialValue={initialUSTerritory.value}
            className="mb-0"
          >
            <Checkbox
              disabled={alreadyActivated}
              onChange={(e) => console.log(e)}
            >
              US Territory
            </Checkbox>
          </Form.Item>
        </Col>
        {!initialTribalTerritory.fromLead && initialTribalTerritory.value && (
          <Col>
            <Typography.Text className="pl-1" style={{ color: "blue" }}>
              (via linked Org)
            </Typography.Text>
          </Col>
        )}
      </Row>
    </>
  );
}
