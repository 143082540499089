import produce from "immer";

const getDefaultState = () => {
  return {
    "shipping-locations": [1],
  };
};

const order = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "ORDER_ADD":
      console.log(action);
      return state;
    case "ORDER_EDIT":
      nextState = produce(state, (draftState) => {
        draftState[action.data.field] = action.data.value;
      });
      return nextState;
    case "SHIPPING_LOCATION_ADD":
      nextState = produce(state, (draftState) => {
        draftState["shipping-locations"].push(action.data.index);
      });
      return nextState;
    case "SHIPPING_LOCATION_DELETE":
      nextState = produce(state, (draftState) => {
        delete draftState[`location-${action.data.index + 1}-number-of-kits`];
        delete draftState[`location-${action.data.index + 1}-shipping-address`];
        draftState["shipping-locations"].splice(action.data.index, 1);
      });
      return nextState;
    case "ORDER_RESET":
      nextState = produce(state, (draftState) => getDefaultState());
      return nextState;
    default:
      return state;
  }
};

export default order;
