import "./view.css";

import { HomeOutlined } from "@ant-design/icons";

import { Table, Typography } from "antd";

import { withRouter } from "react-router-dom";

import { useFunctionList } from "api/functions";

import { ViewTemplate } from "../ViewTemplate";

function Content(props) {
  const columns = [
    {
      title: "Name",
      key: "name",
      render: (fn) => (
        <a href={`/admin/functions/${fn.name}`}>{fn.details.label}</a>
      ),
    },
    {
      title: "Description",
      key: "description",
      render: (fn) => (
        <Typography.Text style={{ whiteSpace: "pre-line" }}>
          {fn.details.description}
        </Typography.Text>
      ),
    },
  ];

  return (
    <>
      <div>
        <Table columns={columns} dataSource={props.fns} rowKey="name"></Table>
      </div>
    </>
  );
}

function ListView() {
  const fnListQuery = useFunctionList();

  return (
    <ViewTemplate
      {...{
        navigation: () => [
          {
            to: "/",
            label: <HomeOutlined />,
          },
          {
            to: "/admin/functions",
            label: "Functions",
          },
        ],

        heading: () => {
          return <>Functions</>;
        },

        content: ([fns]) => {
          return <Content fns={fns.data}></Content>;
        },

        queries: [fnListQuery],
      }}
    ></ViewTemplate>
  );
}

export default withRouter(ListView);
