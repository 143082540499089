import moment from "moment";

import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_TEXT_CONTAINS,
  strapiFilterProps,
} from "components/Table/StrapiFilter";
import OrderFulfillmentPriorityTag from "components/OrderFulfillmentPriorityTag";
import { Tooltip, Typography } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { Link, Text } = Typography;

export const getUpcomingOrdersColumns = () => {
  return [
    {
      title: "Priority",
      key: "fulfillment_priority",
      dataIndex: ["fulfillment_priority"],
      filters: [
        { value: "none", text: "None", label: "None" },
        { value: "low", text: "Low", label: "Low" },
        { value: "medium", text: "Medium", label: "Medium" },
        { value: "high", text: "High", label: "High" },
      ],
      onFilter: (value, record) => {
        return record.fulfillment_priority === value;
      },
      render: (priority) => {
        return <OrderFulfillmentPriorityTag priority={priority} />;
      },
    },
    {
      title: "Shipping Order #",
      key: "shipping_order_id",
      dataIndex: ["shipping_order_number"],

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_TEXT_CONTAINS],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
        searchPlaceholder: `Search Shipping Order #`,
      }),
    },
    {
      title: "Customer",
      key: "order-customer",
      render: (record) => (
        <>
          <Link
            href={`/organizations/${record.order_placement?.organization.id}`}
            target="_blank"
          >
            {record.order_placement?.organization.organization_name}
          </Link>
          <br />
          <Text>
            {`\u{21B3} `}
            {record.shipping_location_name}
          </Text>
        </>
      ),
    },
    {
      title: "Business Days Until Ship Date",
      key: "days_until_shipment",
      dataIndex: ["days_until_shipment"],
      width: "150px",
      render: (val) => (
        <Text type={Number(val) < 0 ? "danger" : ""}>
          {Number(val) < 0 && (
            <Tooltip title="Days since requested ship date!">
              <ExclamationCircleFilled />
            </Tooltip>
          )}
          &nbsp;
          {val}
        </Text>
      ),
    },
    {
      title: "Date Order Placed",
      key: "date_order_placed",
      dataIndex: ["date_order_placed"],
      render: (dateOrderPlaced) => {
        return moment(dateOrderPlaced).format("MM/DD/YYYY");
      },

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
      }),
    },
    {
      title: "Requested Shipping Date",
      key: "requested_shipping_date",
      dataIndex: ["requested_shipping_date"],
      render: (requestedShippingDate) => {
        return requestedShippingDate
          ? moment(requestedShippingDate).format("MM/DD/YYYY")
          : "-";
      },

      ...strapiFilterProps({
        enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
        defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
      }),
    },
  ];
};
