import { Input, Form, Row, Col, Typography } from "antd";
import { useEffect, useState } from "react";

import { useDatasetCounties } from "api/datasets";

import StatesSelector from "components/States";
import NpdesSelector from "components/NPDES/NpdesSelector";
import _ from "lodash";

import PopulationEstimate from "../form/PopulationEstimate";
import PopulationEstimateSource from "../form/PopulationEstimateSource";
import PrimaryCountySelector from "../form/PrimaryCountySelector";
import AdditionalCountiesRadio from "../form/AdditionalCountiesRadio";
import AdditionalCountiesSelector from "../form/AdditionalCountiesSelector";
import ZipCode from "../form/ZipCode";
import Notes from "../form/Notes";

const { Title, Text } = Typography;

const CatchmentOverview = ({
  organization,
  location,
  states,
  npdesIds,
  onError,
  form,
  isInternal,
}) => {
  const [showAdditionalCounties, setShowAdditionalCounties] = useState();
  const [stateId, setStateId] = useState();

  const countiesQuery = useDatasetCounties(
    {
      stateId: stateId,
    },
    {
      enabled: Boolean(stateId),
      onError,
    }
  );

  useEffect(() => {
    setStateId(location.state_id);
    setShowAdditionalCounties(
      !_.isNil(location.counties_served) && location.counties_served.length > 0
    );
  }, [location]);

  const handleStateSelected = (value) => {
    setStateId(value);
    form.setFieldsValue({ county_id: null });
    form.setFieldsValue({ counties_served: null });
  };

  return (
    <>
      <Row gutter={[48, 16]} className="mb-4">
        <Col span={24}>
          <Title level={4}>
            Catchment Overview for {organization.organization_name}
          </Title>
          <Text>
            In order to complete your report we need information about your new
            sampling location. Please answer the required questions below.
          </Text>
        </Col>
      </Row>
      <Row gutter={[48, 16]} className="mb-4">
        <Col md={8} sm={12} xs={24}>
          <StatesSelector
            label="State"
            name="state_id"
            initialValue={location.state_id}
            required={!isInternal}
            states={states}
            onSelect={handleStateSelected}
          />
        </Col>
        <Col md={8} sm={12} xs={24}>
          <Form.Item
            name="city"
            label="City"
            initialValue={location.city}
            className="mb-0"
          >
            <Input
              autoComplete="address-level2"
              placeholder="Specify city name"
            />
          </Form.Item>
        </Col>
        <Col md={8} sm={12} xs={24}>
          <ZipCode initialValue={location.zip_id} isInternal={isInternal} />
        </Col>
      </Row>
      <Row gutter={[48, 16]} className="mb-4">
        <Col md={8} sm={24} xs={24}>
          <PrimaryCountySelector
            counties={countiesQuery?.data}
            isInternal={isInternal}
            initialValue={location.county_id}
          />
        </Col>
        <Col md={8} sm={24} xs={24}>
          <AdditionalCountiesRadio
            initialValue={
              !_.isNil(location.counties_served) &&
              location.counties_served.length > 0
            }
            onChange={(val) => setShowAdditionalCounties(val)}
          />
        </Col>
        <Col md={8} sm={22} xs={22}>
          {showAdditionalCounties && (
            <AdditionalCountiesSelector
              counties={countiesQuery?.data}
              initialValues={location.counties_served}
              form={form}
            />
          )}
        </Col>
      </Row>

      <Row gutter={[48, 16]} className="mb-4">
        <Col md={16} sm={24} xs={24}>
          <Notes initialValue={location.notes} locationType="community" />
        </Col>
      </Row>
      <Row gutter={[48, 16]} className="mb-4">
        <Col md={8} sm={12} xs={24}>
          <PopulationEstimate
            initialValue={location.estimated_population_served}
            isInternal={isInternal}
          />
        </Col>
        <Col md={8} sm={12} xs={24}>
          <PopulationEstimateSource
            initialValue={location.population_served_source}
          />
        </Col>
        <Col md={8} sm={12} xs={24}>
          <NpdesSelector
            npdesIds={npdesIds}
            initialValue={location.npdes_id ?? location.npdes_id_missing_reason}
            isInternal={isInternal}
          />
        </Col>
      </Row>
    </>
  );
};

export default CatchmentOverview;
