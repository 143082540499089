import { Button, Table, Tag } from "antd";
import moment from "moment";
import { useState } from "react";

import { useProgramOptions } from "api/program";
import {
  useSamplingLocationProgramEnrollmentCount,
  useSamplingLocationProgramEnrollmentList,
} from "api/samplingLocationProgramEnrollment";
import { useTableQueryParams } from "components/Table/helpers";

import ProgramEnrollmentModal from "./ProgramEnrollmentModal";

const StatusTag = ({ start_date, end_date }) => {
  const today = moment().format("YYYY-MM-DD");

  if (end_date < today) {
    return <Tag>Past</Tag>;
  } else if (start_date > today) {
    return <Tag color="purple">Future</Tag>;
  } else {
    return <Tag color="blue">Active</Tag>;
  }
};

const isActiveFilter = (value) => {
  const today = moment().format("YYYY-MM-DD");

  if (value === "past") {
    return { end_date_lt: today };
  } else if (value === "future") {
    return { start_date_gt: today };
  } else if (value === "active") {
    return { start_date_lte: today, end_date_gte: today };
  } else {
    return {};
  }
};

const transformFilters = ({ is_active, ...filters }) => ({
  ...filters,
  ...isActiveFilter(is_active?.[0]),
});

const ProgramEnrollmentTable = ({ samplingLocationId }) => {
  const [editingData, setEditingData] = useState();

  const { data: programs } = useProgramOptions();

  const { queryParams, handleTableChange, pagination } = useTableQueryParams({
    permanentFilters: {
      sampling_location: samplingLocationId,
    },
    transformFilters,
    defaultSort: "start_date:ASC",
  });

  const { data, isLoading } = useSamplingLocationProgramEnrollmentList({
    ...queryParams,
    _joins: ["program"],
  });

  const {
    data: count,
    isLoading: countLoading,
  } = useSamplingLocationProgramEnrollmentCount(queryParams);

  const columns = [
    {
      title: "Program",
      dataIndex: ["program", "name"],
      key: "program",
      filters: programs,
      filterMultiple: true,
      filterSearch: true,
      sorter: true,
    },
    {
      title: "Enrollment Start",
      dataIndex: ["start_date"],
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: "Enrollment End",
      dataIndex: ["end_date"],
      sorter: true,
    },
    {
      title: "Active?",
      key: "is_active",
      filters: [
        { text: "Past", value: "past" },
        { text: "Active", value: "active" },
        { text: "Future", value: "future" },
      ],
      filterMultiple: false,
      render: StatusTag,
    },
    {
      render: (row) => (
        <Button type="link" onClick={() => setEditingData(row)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div>
      <ProgramEnrollmentModal
        onCancel={() => setEditingData(null)}
        onOk={() => setEditingData(null)}
        initialValues={{
          ...editingData,
          sampling_location: samplingLocationId,
        }}
        visible={!!editingData}
      />
      <Table
        size="small"
        rowKey="id"
        scroll={{ x: "max-content", y: 200 }}
        loading={isLoading || countLoading}
        columns={columns}
        pagination={{ ...pagination, total: count, hideOnSinglePage: true }}
        dataSource={data}
        onChange={handleTableChange}
      />
      <Button type="primary" onClick={() => setEditingData({})}>
        New enrollment
      </Button>
    </div>
  );
};

export default ProgramEnrollmentTable;
