import { useState } from "react";
import { useUserContext } from "context";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import {
  PageHeader,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Tag,
  Space,
  message,
} from "antd";

import { useCreateRework, REWORK_STATUS_OPTIONS } from "api/rework";
import KitSelector from "utils/components";
import ReworkTypeSelector from "./components/ReworkTypeSelector";
import DeliveryMethodSelector from "./components/DeliveryMethodSelector";
import ReworkUrgencySelector from "./components/ReworkUrgencySelector";
import AssaySelector from "./components/AssaySelector";
import { convertFormToReworkPayload, reworkCreateSuccessMsg } from "./helpers";
import "./ReworksRequestForm.css";
import moment from "moment";

const { TextArea } = Input;
const { Text } = Typography;

/**
 * Form for Both Reruns and Reissue Requests
 *
 * Rework: any process that needs to be done over, inclusive of both reruns and reissues
 * Rerun: the lab has to redo an assay
 * Reissue: the pcr pipeline/pdf needs to be triggered again to pull in some data correction
 * - Either "internal" or "external"
 * - See ReworkTypeSelector.js selectorText for more info
 */
function ReworkRequest() {
  const { userName } = useUserContext();
  const { mutate: createRework } = useCreateRework();
  const [selectedKits, setSelectedKits] = useState([]);
  const [reworkType, setReworkType] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState(null);

  const onFinish = (values) => {
    setLoading(true);
    setError(null);
    createRework(
      {
        ...convertFormToReworkPayload(values),
        kit_ids_to_rework: _.map(selectedKits, "value"),
        requester: userName,
      },
      {
        onSuccess: (kits) => {
          form.resetFields();
          setSelectedKits([]);
          message.success(reworkCreateSuccessMsg(kits), 8);
        },
        onError: (e) => {
          const messageText = e.response?.data?.message || e.message;

          setError(messageText);

          Sentry.captureException(e);
          message.error(
            "An error occurred while submitting the rework request!",
            6
          );
        },
        onSettled: () => setLoading(false),
      }
    );
  };

  const handleKitSelected = (kit) => {
    setSelectedKits([...selectedKits, kit]);
  };

  const handleKitUnselected = (kitId) => {
    let remainingKits = selectedKits.filter((k) => k.value !== kitId);
    setSelectedKits(remainingKits);
  };

  const checkReworkIneligibility = (kit) => {
    return (
      !kit.date_received ||
      moment.utc(kit.date_received) <=
        moment.utc(`${process.env.REACT_APP_EFFIGY_LATEST_AIRTABLE_DATE}`)
    );
  };

  const hasOnlyValidKits =
    selectedKits.length &&
    selectedKits.filter((k) => checkReworkIneligibility(k)).length === 0;

  const showAssaySelector = ["LAB_RERUN", "EXTERNAL_LAB_RERUN"].includes(
    reworkType
  );

  return (
    <>
      <PageHeader title="Reworks Request Form" />
      <Form
        form={form}
        layout="vertical"
        className="px-3"
        name="reworks-request"
        onValuesChange={(_c, formVal) => setReworkType(formVal?.type_of_rework)}
        onFinish={onFinish}
      >
        <KitSelector className="mb-3" onKitSelected={handleKitSelected} />

        <Row className="mb-3">
          <Col span={12}>{error && <Text type="danger">{error}</Text>}</Col>
        </Row>

        <Space direction="vertical" className="mb-3">
          <Text>Kits Selected: </Text>
          <div>
            {selectedKits.map((kit) => (
              <Tag
                key={kit.kit_id}
                closable
                onClose={() => handleKitUnselected(kit.value)}
                color={checkReworkIneligibility(kit) && "error"}
                title={
                  checkReworkIneligibility(kit)
                    ? "This kit is not currently eligible for rework due to project effigy."
                    : null
                }
              >
                {kit.label}
              </Tag>
            ))}
          </div>
        </Space>

        <Row className="mb-3">
          <Col span={12}>
            <ReworkTypeSelector />
          </Col>
          <Col span={12}>
            <DeliveryMethodSelector />
          </Col>
        </Row>

        <Form.Item
          label="Reason for Rework"
          name="reason_for_rework_and_requested_changes"
        >
          <TextArea
            rows={4}
            placeholder="Reason for Rework or database changes needed"
          />
        </Form.Item>

        <Form.Item label="Notes for Lab Team" name="notes_for_lab_team">
          <Input />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          className="w-40"
          initialValue="PENDING"
        >
          <Select options={REWORK_STATUS_OPTIONS()} />
        </Form.Item>

        <ReworkUrgencySelector className="mb-3" />
        <AssaySelector
          hidden={!showAssaySelector}
          required={showAssaySelector}
          className="mb-3"
        />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={!hasOnlyValidKits}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default ReworkRequest;
