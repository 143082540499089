import { Tag, Typography } from "antd";
import _ from "lodash";
import React from "react";

import {
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  METHOD_EMPTY,
  METHOD_NOT_EMPTY,
  METHOD_TEXT_CONTAINS,
  strapiFilterProps,
} from "components/Table/StrapiFilter";

import { columnSearchProps } from "components/Table/SearchFilter";
import { niceTitle } from "./util";

const lowCardinalityColumns = {
  programs: ["*"],
  "facility-types": ["*"],
  "form-review-statuses": ["*"],
  "report-review-statuses": ["*"],
  "sampling-site-types": ["*"],
};

const isLowCardinality = (attr) => {
  const names = lowCardinalityColumns[attr.parentModel] ?? [];
  if (names.includes("*")) {
    return true;
  } else {
    return names.includes(attr.name);
  }
};

const columnInfoByAttr_ = (attr) => {
  switch (attr.type) {
    case "integer":
    case "float":
      return {
        key: attr.key,
        sorter: true,
        ...columnSearchProps({ placeholder: `Search ${attr.title}` }),
      };
    case "email":
    case "string":
    case "text":
      if (isLowCardinality(attr)) {
        return {
          sorter: true,
          key: attr.key,
          filterFetch: { model: attr.parentModel, key: attr.name },
          filterMultiple: true,
        };
      } else {
        return {
          sorter: true,
          key: attr.key,
          ...strapiFilterProps({
            enabledFilterMethods: [METHOD_TEXT_CONTAINS, METHOD_EMPTY],
          }),
        };
      }
    case "richtext":
      return {};
    case "enumeration":
      return {
        sorter: true,
        key: attr.key,
        filters: attr.enum.map((val) => ({ text: val, value: val })),
        filterMultiple: true,
      };
    case "date":
    case "datetime":
    case "timestamp":
      return {
        key: attr.key,
        sorter: true,
        ...strapiFilterProps({
          enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END, METHOD_EMPTY],
          defaultFilterMethod: METHOD_DAY_RANGE_EXCLUSIVE_END,
        }),
      };
    case "json":
      return {
        render: (x) => (
          <Typography.Text style={{ maxWidth: "20rem" }} ellipsis={true}>
            {x ? JSON.stringify(x) : null}
          </Typography.Text>
        ),
      };
    case "boolean":
      return {
        key: attr.key,
        render: JSON.stringify,
        sorter: true,
        ...strapiFilterProps({
          enabledFilterMethods: [METHOD_EMPTY],
        }),
      };
    case "relation":
      return {
        render: (x) => `id: ${_.isObject(x) ? x.id : x} (relation)`,
      };
    default:
      return {
        render: () => `Not sure how to render this! (type=${attr.type})`,
      };
  }
};

const flattenManyRelation = (record, dataIndex) => {
  const [key, ...rest] = dataIndex || [];
  if (!key || !record) {
    return record || [];
  } else if (Array.isArray(record)) {
    return record.flatMap((row) => flattenManyRelation(row[key], rest));
  } else {
    return flattenManyRelation(record[key], rest);
  }
};

const columnInfoByAttr = (attr) => {
  const columnInfo = columnInfoByAttr_(attr);
  if (attr.isArray) {
    // one-to-many and many-to-many relationships are handled specially:
    // 1. Displayed as arrays
    // 2. Sorting is disabled
    // 3. Basic filtering
    const render = columnInfo.render ?? ((text) => <Tag>{text}</Tag>);
    return {
      ...columnInfo,
      sorter: false,
      dataIndex: [],
      render: (record) =>
        flattenManyRelation([record], attr.dataIndex).map((x, idx) => (
          <React.Fragment key={idx}>{render(x)}</React.Fragment>
        )),
      ...strapiFilterProps({
        enabledFilterMethods: [
          METHOD_EMPTY,
          METHOD_NOT_EMPTY,
          METHOD_TEXT_CONTAINS,
        ],
        defaultFilterMethod: METHOD_TEXT_CONTAINS,
      }),
    };
  } else if (attr.dataIndex.length > 2) {
    // Strapi does not allow sorting by relations more than 1 deep
    return _.omit(columnInfo, ["sorter"]);
  } else {
    return columnInfo;
  }
};

/** @typedef { import("./attributes").Attribute[] } Attribute */
/** @typedef { import("antd/es/table").ColumnType<object> } Column */

/** @typedef ColumnConfig
 * @property {string} key
 * @property {string?} title
 */

/** @typedef {Column} ColumnWithConfig
 * @property {ColumnConfig} config
 */

/** Returns antd Table column definitions, given a map of attributes (by key)
 * and an array of column selections.
 *
 * @param {Record<string,Attribute>?} attrsByKey
 * @param {ColumnConfig[]} columnConfig
 *
 * @returns {ColumnWithConfig[]}
 */
export const columnsForAttributes = (attrsByKey, columnConfig) => {
  if (!attrsByKey) {
    return [];
  }
  return columnConfig
    .map((cfg) => ({ ...cfg, attr: attrsByKey[cfg.key] }))
    .filter(({ attr }) => attr)
    .map(({ attr, ...cfg }) => ({
      title: cfg.title || niceTitle(attr),
      dataIndex: attr.dataIndex,
      key: cfg.key,
      config: cfg,
      ...columnInfoByAttr({ ...attr, title: cfg.title || niceTitle(attr) }),
    }));
};

/** Returns a reasonable default set of columns, given all possible attributes
 * for a model.
 *
 * @param {Attribute[]?} attributes
 * @param {number?} maxCols
 * @returns {ColumnConfig[]}
 */
export const defaultColumnConfig = (attributes, maxCols = 8) => {
  if (!attributes) {
    return [];
  }
  const ownAttrs = attributes.filter((attr) => attr.dataIndex.length === 1);
  const chosenAttrs =
    ownAttrs.length <= maxCols
      ? ownAttrs
      : ownAttrs.filter((attr) =>
          attr.dataIndex[0].match(/(^|_)(id|name|code)(_|$)/)
        );
  return chosenAttrs
    .slice(0, maxCols)
    .map(({ dataIndex }) => ({ key: dataIndex.join(".") }));
};
