import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Table,
  Input,
  PageHeader,
  Typography,
  Modal,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { formatDate } from "utils/time";
import SampleLanding from "containers/Sample/Landing";
import {
  actionKitsPageGet,
  actionSampleGetByOrg,
  actionKitsGet,
  actionKitsTotalGet,
} from "actions";
import { updateTableRecord } from "components/Table/helpers";
import _ from "lodash";
import { LogSampleButton } from "components/Buttons/TableCellButton";

const { Text } = Typography;

const mapStateToProps = ({ organization, kits }) => ({
  organization,
  kits,
});

const mapDispatchToProps = {
  actionKitsPageGet,
  actionSampleGetByOrg,
  actionKitsGet,
  actionKitsTotalGet,
};

const KitsTable = (props) => {
  const [kits, setKits] = useState(props.kitsPage);
  const [searchInput, setSearchInput] = useState();
  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState();

  useEffect(() => {
    setKits(props.kitsPage);
  }, [props.kitsPage]);

  const handleLogSampleClicked = (kitId) => {
    setModalContent(
      <SampleLanding
        kitId={kitId}
        onFormSubmissionSuccess={(updatedSample) =>
          handleSampleSubmitted(kitId, updatedSample)
        }
      />
    );
    setModalOpen(true);
  };

  // this chunk of code ensure the table record that a log is submitted for reflects the change once it
  // completes on the backend
  const handleSampleSubmitted = (recordId, updatedSample) => {
    updateTableRecord(
      recordId,
      kits,
      (rec) => {
        const recCopy = _.clone(rec);
        recCopy.sample = updatedSample;
        return recCopy;
      },
      setKits
    );
  };

  async function search() {
    if (searchInput) {
      const filteredSearch = searchInput.replace(/^\D+/g, "");
      const searchResult = await props
        .actionKitsTotalGet(props.organization, filteredSearch) // update # of table pages
        .then(() => props.actionKitsGet(props.organization, filteredSearch))
        .then(() => props.actionSampleGetByOrg(props.organization.id));
      setKits(searchResult);
    } else {
      props.actionKitsPageGet(props.organization, 0);
      props.actionSampleGetByOrg(props.organization.id);
      props.actionKitsTotalGet(props.organization);
    }
  }

  const skipNumber = (page) => (page - 1) * 10;

  const pagination = {
    defaultCurrent: 1,
    total: props.kits.kitsTotal,
    position: ["bottomLeft"],
    showSizeChanger: false,
    onChange: (page) => {
      props
        .actionKitsPageGet(props.organization, skipNumber(page))
        .then(() => props.actionSampleGetByOrg(props.organization.id));
    },
  };

  return (
    <>
      <PageHeader
        title="Kits"
        footer={
          <>
            <Row className="mb-2">
              <Col span={6}>
                <Input
                  data-cy="kits-table__search-kits-input"
                  placeholder="Search Kit ID"
                  value={searchInput}
                  onChange={({ currentTarget: { value } }) => {
                    setSearchInput(value);
                    if (value.length === 0) {
                      search();
                    }
                  }}
                  onPressEnter={() => {
                    search();
                  }}
                  prefix={<SearchOutlined className="input__prefix-icon" />}
                  allowClear
                />
              </Col>
              <Col>
                <Button
                  data-cy="kits-table__search-kits-btn"
                  className="ml-1"
                  type="primary"
                  onClick={() => {
                    search();
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      <Modal
        visible={modalOpen}
        width={"75%"}
        footer={[]}
        onCancel={() => setModalOpen(false)}
        destroyOnClose
      >
        <div className="sample-modal-content">{modalContent}</div>
      </Modal>
      <Table
        data-cy="kits-table"
        className="px-3"
        rowKey="kit_id"
        columns={[
          {
            title: "Kit ID",
            render: (record) => {
              const shippingKitId = record?.shipping_kit_id || "ID Pending";
              return <Link to={`/kit/${record.id}`}>{shippingKitId}</Link>;
            },
          },
          {
            title: "Sampling Date",
            sorter: (a, b) =>
              a.sample?.sample_collection_date_time -
              b.sample?.sample_collection_date_time,
            render: (record) => {
              return (
                <Text data-cy="kits-table__sampling-date">
                  {formatDate(
                    record.sample?.sample_collection_date_time,
                    record.sample?.time_zone
                  )}
                </Text>
              );
            },
          },
          {
            title: "Tracking No.",
            render: (record) => {
              const trackingNumber = record?.inbound_tracking_number;
              return trackingNumber ? (
                <a
                  data-cy="kits-table__tracking"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}
                >
                  FX{trackingNumber}
                </a>
              ) : null;
            },
          },
          {
            title: "Sampling Site",
            key: "action",
            render: (record) => {
              if (record.sample?.sampling_location_id) {
                return (
                  <Text data-cy="kits-table__sampling-site">
                    {record.sample.sampling_location_id.sampling_location_name}
                  </Text>
                );
              }
              return (
                <LogSampleButton
                  onClick={() => handleLogSampleClicked(record.id)}
                />
              );
            },
          },
        ]}
        dataSource={kits}
        pagination={pagination}
      />
    </>
  );
};

const KitsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KitsTable);
export default withRouter(KitsTableContainer);
