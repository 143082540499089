import {
  dateIsInFuture,
  dateIsMoreThan30DaysOld,
  formatToDateTime,
  timeZone,
} from "utils/time";
import axios from "axios";
import { getToken } from "api/util";

const _ = require("lodash");
const moment = require("moment-timezone");

const fieldsWithConfirmationNeeded = [
  "flow_rate_day_mgd",
  "sampling_start_date",
  "sampling_collection_date",
];

const requiresConfirmation = (fieldName) => {
  return fieldsWithConfirmationNeeded.some((str) => str === fieldName);
};

const checkConfirm = (obj, fieldName, fieldValue) => {
  if (fieldName === "flow_rate_day_mgd") {
    fieldValue
      ? obj.setConfirm(true)
      : obj.setShowInfluentFlowInputWarning(false);
    fieldValue
      ? obj.setShowInfluentFlowInputWarning(false)
      : obj.setShowInfluentFlowInputWarning(true);
  }

  // Only check for start date in the future or far past
  if (fieldName === "sampling_start_date") {
    if (obj.formRef.current?.getFieldError(fieldName).length) {
      obj.setShowSampleStartDatetimeWarning("error");
    } else {
      if (dateIsInFuture(fieldValue) || dateIsMoreThan30DaysOld(fieldValue)) {
        obj.setShowSampleStartDatetimeWarning("warning");
      } else {
        obj.setShowSampleStartDatetimeWarning(false);
      }
    }
  }

  // Changing start date will trigger check for collection date, handle all checks here
  if (fieldName === "sampling_collection_date") {
    if (obj.formRef.current?.getFieldError(fieldName).length) {
      obj.setShowSampleCollectionDatetimeWarning("error");
    } else {
      if (checkForDuplicateDateTimes(obj)) {
        obj.setShowSampleCollectionDatetimeWarning("duplicate");
      } else if (checkForLongCollectionPeriod(obj)) {
        obj.setShowSampleCollectionDatetimeWarning("duration");
      } else if (
        dateIsInFuture(fieldValue) ||
        dateIsMoreThan30DaysOld(fieldValue)
      ) {
        obj.setShowSampleCollectionDatetimeWarning("warning");
      } else {
        obj.setShowSampleCollectionDatetimeWarning(false);
      }
    }
  }
};

const areValidDates = (obj) => {
  return (
    !isNaN(obj.formRef.current?.getFieldValue("sampling_start_date")) &&
    !isNaN(obj.formRef.current?.getFieldValue("sampling_collection_date")) &&
    !_.isNil(obj.formRef.current?.getFieldValue("sampling_start_date")) &&
    !_.isNil(obj.formRef.current?.getFieldValue("sampling_collection_date"))
  );
};

const checkForDuplicateDateTimes = (obj) => {
  const startDate = new Date(
    obj.formRef.current?.getFieldValue("sampling_start_date")
  );
  const endDate = new Date(
    obj.formRef.current?.getFieldValue("sampling_collection_date")
  );

  const comparisonOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  if (
    areValidDates(obj) &&
    startDate.toLocaleString("en-US", comparisonOptions) ===
      endDate.toLocaleString("en-US", comparisonOptions)
  ) {
    return true;
  }
  return false;
};

const checkForLongCollectionPeriod = (obj) => {
  if (areValidDates(obj)) {
    const startDatetime = new Date(
      obj.formRef.current?.getFieldValue("sampling_start_date")
    );
    const endDatetime = new Date(
      obj.formRef.current?.getFieldValue("sampling_collection_date")
    );

    // Calculate the difference in milliseconds between the two dates
    const timeDifference = Math.abs(
      endDatetime.getTime() - startDatetime.getTime()
    );

    // Calculate the number of hours in the time difference
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference > 24.01;
  }
  return false;
};

export const handleFormFieldChanges = (obj) => {
  if (
    obj.changedFields &&
    obj.changedFields[0] &&
    obj.changedFields[0].name[0] === "sampling_type"
  ) {
    obj.setMethod(obj.changedFields[0].value);

    if (obj.changedFields[0].value.includes("time-proportional")) {
      obj.formRef.current?.setFieldsValue({
        sampling_volume: null,
      });
    } else if (obj.changedFields[0].value.includes("flow-proportional")) {
      obj.formRef.current?.setFieldsValue({
        sampling_interval: null,
      });
    } else if (obj.changedFields[0].value.includes("grab")) {
      obj.formRef.current?.setFieldsValue({
        sampling_interval: null,
        sampling_volume: null,
        num_subsamples: null,
        sampling_start_time: null,
        sampling_start_date: null,
      });
    } else if (obj.changedFields[0].value === "other") {
      obj.formRef.current?.setFieldsValue({
        sampling_interval: null,
        sampling_volume: null,
      });
    }
  }

  _.map(obj.changedFields, (changedField) => {
    if (requiresConfirmation(changedField.name)) {
      const fieldName = changedField.name;
      const fieldValue = changedField.value;
      checkConfirm(obj, fieldName, fieldValue);
    }
  });

  if (
    obj.changedFields[0]?.name[0] === "sampling_start_date" &&
    obj.changedFields[0]?.value
  ) {
    if (
      obj.formRef.current.getFieldValue(["sampling_type"]) &&
      obj.formRef.current.getFieldValue(["sampling_type"]).includes("24-hr")
    ) {
      let newCollectionDate = moment(obj.changedFields[0].value).add(1, "d");
      obj.formRef.current.setFieldsValue({
        sampling_collection_date: newCollectionDate,
      });
    }
  }
  if (
    obj.changedFields[0]?.name[0] === "time_zone" &&
    obj.changedFields[0]?.value
  ) {
    obj.formRef.current.setFieldsValue({
      time_zone: obj.changedFields[0].value,
    });
  }
};

export const handleFormErrors = ({
  err,
  setShowSampleCollectionDatetimeWarning,
  setShowSampleStartDatetimeWarning,
}) => {
  err.errorFields.forEach((field) => {
    if (field.name[0] === "sampling_start_date") {
      setShowSampleStartDatetimeWarning("error");
    }
    if (field.name[0] === "sampling_collection_date") {
      setShowSampleCollectionDatetimeWarning("error");
    }
  });
  return null;
};

export const handleFormFinish = (values, _self) => {
  return null;
};

export const initialValues = (sample) => {
  if (!sample) {
    return {};
  }

  const {
    author,
    sample_start_date_time,
    sample_collection_date_time,
    time_zone,
  } = sample;

  const momentStartDateTime = sample_start_date_time
    ? moment(sample_start_date_time).tz(time_zone)
    : null;

  const momentCollectionDateTime = sample_collection_date_time
    ? moment(sample_collection_date_time).tz(time_zone)
    : null;

  return {
    ...author,
    ...sample,
    sampling_start_date: momentStartDateTime,
    sampling_collection_date: momentCollectionDateTime,
    time_zone: time_zone,
  };
};

export const createSamplePayload = (values, kit, site, org) => {
  const {
    sampling_collection_date,
    sampling_start_date,
    sample_matrix,
  } = values;

  const sample_collection_date_time = formatToDateTime(
    sampling_collection_date
  );

  const sample_start_date_time = formatToDateTime(sampling_start_date);

  return {
    sample_notes: values.sample_notes,
    precipitation_in: values.precipitation_in,
    precipitation_bool: values.precipitation_bool,
    sampling_volume: values.sampling_volume,
    sampling_interval: values.sampling_interval,
    flow_rate_day_mgd: values.flow_rate_day_mgd,
    sample_collection_date_time: sample_collection_date_time
      ? sample_collection_date_time
      : null,
    sample_start_date_time: sample_start_date_time
      ? sample_start_date_time
      : null,
    sampling_type: values.sampling_type,
    author: {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
    },
    num_subsamples: values.num_subsamples,
    kit: kit.id,
    sampling_location_id: site?.id,
    organization: org.id,
    time_zone: kit.sample?.time_zone ? kit.sample.time_zone : timeZone,
    sample_matrix: sample_matrix ?? site?.sample_matrix,
  };
};

export const notifySupportOfUnexpectedKit = (payload) => {
  return axios.post(`${process.env.REACT_APP_API_URL}send-samples`, payload, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const updateSample = (sample) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}samples/${sample.id}`,
    sample,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const createSample = (sample) => {
  return axios.post(`${process.env.REACT_APP_API_URL}samples`, sample, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};
