import { Input, Form, Row, Col, Typography } from "antd";

export default function Signature({ isInternal }) {
  const { Title } = Typography;

  return (
    <Row gutter={[48, 16]} className="mt-5 mb-3">
      <Col span={24}>
        <Title level={4}>Submitted by</Title>
      </Col>

      <Col sm={8} xs={24}>
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: !isInternal,
              message: "Enter first name",
              min: 1,
              type: "string",
              whitespace: true,
            },
          ]}
        >
          <Input
            autoComplete="given-name"
            placeholder="Enter first name"
            data-cy="sampling-location-form_first_name"
          />
        </Form.Item>
      </Col>

      <Col sm={8} xs={24}>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: !isInternal,
              message: "Enter last name",
              min: 1,
              type: "string",
              whitespace: true,
            },
          ]}
        >
          <Input
            autoComplete="family-name"
            placeholder="Enter last name"
            data-cy="sampling-location-form_last_name"
          />
        </Form.Item>
      </Col>

      <Col sm={8} xs={24}>
        <Form.Item
          name="email"
          label="Email"
          normalize={(value) => value.trim()}
          rules={[
            {
              required: !isInternal,
              message: "Enter email",
              type: "email",
            },
          ]}
          className="mb-0"
        >
          <Input
            autoComplete="email"
            placeholder="Enter email"
            data-cy="sampling-location-form_email"
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
