import produce from "immer";

const getDefaultState = () => {
  return {};
};

const lead = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "LEAD_ADD":
      nextState = produce(state, (draftState) => action.data);
      return nextState;
    case "LEADS_GET":
      return action.payload.data;
    default:
      return state;
  }
};

export default lead;
