const kit = (state = null, action) => {
  switch (action.type) {
    case "KIT_GET":
      return action.payload.data;
    default:
      return state;
  }
};

export default kit;
