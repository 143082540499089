import React, { useRef, useEffect } from "react";
import { isFragment } from "react-is";

/**
 * Flattens any `React.Fragment` nodes out of children.
 *
 * @example
 * flattenFragments([<><div /><div /></>, <><span /><span /></>])
 * // => [<div />, <div />, <span />, <span />]
 */
export const flattenFragments = (children) =>
  React.Children.map(children, (child) =>
    isFragment(child) ? child.props.children : child
  );

/**
 * Renders an Element or Component (i.e. Element-returning function). That is,
 * {@link React.cloneElement} for elements, or {@link React.createElement} for
 * component functions.
 *
 * @example
 * renderClone(<MyElement id="my-id" />, { className="hello" })
 * // => <MyElement id="my-id" className="hello" />
 *
 * @example
 * renderClone(MyElement, { className="hello" })
 * // => <MyElement className="hello" />
 */
export const renderClone = (elementOrComponent, props) => {
  if (React.isValidElement(elementOrComponent)) {
    return React.cloneElement(elementOrComponent, props);
  } else {
    return React.createElement(elementOrComponent, props);
  }
};

/**
 * Like {@link React.Children.map} except when `children` only has a single
 * element, returns `f(children[0])` instead of children.map(f). That is,
 * returns the element itself instead of wrapping it in an array.
 */
export const mapChildren = (children, f) =>
  React.Children.count(children) === 1
    ? f(React.Children.only(children))
    : React.Children.map(children, f);

/**
 * Hook to check if a component is currently mounted. Used to avoid updating
 * state on an unmounted component when dealing with async requests. This is
 * not technically necessary, but is used to suppress very noisy warnings.
 * See https://mobile.twitter.com/dan_abramov/status/1421037772203233280
 */
export const useIsMounted = () => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => (isMounted.current = false);
  }, []);
  return isMounted.current;
};
