import { Row, Typography } from "antd";

const { Paragraph, Text, Link } = Typography;

const linkList = [
  {
    text: "GOVERNMENT",
    href: "https://biobot.io/government/",
  },
  {
    text: "ENTERPRISE",
    href: "https://biobot.io/enterprise/",
  },
  {
    text: "BLOG",
    href: "https://biobot.io/blog/",
  },
  {
    text: "NEWSROOM",
    href: "https://biobot.io/media/",
  },
];

const NotFound = () => {
  return (
    <Paragraph className="mt-4">
      <img
        src="/assets/graphic-404.png"
        alt="404 pipes"
        className="full-width"
      />
      <Row
        justify="center"
        style={{
          margin: "4vh",
        }}
      >
        <Text className="text-3xl">
          Uh oh, we can’t seem to find the page you are looking for.
        </Text>
      </Row>
      <Row
        justify="center"
        style={{
          margin: "2vh",
        }}
      >
        <Text className="text-xl">Here are some helpful links instead:</Text>
      </Row>
      <Row justify="center">
        {linkList.map((link) => (
          <Link
            href={link.href}
            target="_blank"
            className="text-xl mx-2"
            key={link.text}
          >
            {link.text}
          </Link>
        ))}
      </Row>
    </Paragraph>
  );
};

export default NotFound;
