import { Button, PageHeader, Select } from "antd";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useModelOptions } from "./attributes";
import CustomTable from "./CustomTable";
import { fuzzyMatch, titleCase } from "./util";

const ModelSelect = (props) => {
  const { data: modelOptions, isLoading } = useModelOptions();
  return (
    <Select
      style={{ minWidth: "20rem" }}
      placeholder="Select a model"
      showSearch
      autoFocus
      options={modelOptions}
      loading={isLoading}
      filterOption={(search, { label }) => !!fuzzyMatch(label, search)}
      {...props}
    />
  );
};

const MagicTablePage = () => {
  const {
    path,
    params: { model },
  } = useRouteMatch();
  const history = useHistory();
  const [showSelect, setShowSelect] = useState(false);

  const handleModelChange = (model) => {
    setShowSelect(false);
    const nextPath = path.includes(":model")
      ? path.replace(":model", model)
      : `${path}/${model}`;
    history.push(nextPath);
  };

  if (!model) {
    return (
      <PageHeader title="Select a model">
        <ModelSelect value={model} onChange={handleModelChange} />
      </PageHeader>
    );
  }

  return (
    <div>
      <PageHeader
        title={
          <>
            {showSelect ? (
              <ModelSelect value={model} onChange={handleModelChange} />
            ) : (
              titleCase(model)
            )}
            <Button type="link" onClick={() => setShowSelect(!showSelect)}>
              {showSelect ? "Cancel" : "Edit"}
            </Button>
          </>
        }
      />
      <CustomTable key={model} model={model} />
    </div>
  );
};

export default MagicTablePage;
