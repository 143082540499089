import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceCustomQuery,
  orderServiceUpdateMutation,
  orderServiceTable,
} from "./orderService";

const model = "order-placements";

export const useOrderPlacement = orderServiceFindOneQuery({ model });
export const useOrderPlacementList = orderServiceListQuery({ model });
export const useOrderPlacementCount = orderServiceCountQuery({ model });
export const useCreateOrderPlacement = orderServiceCreateMutation({ model });
export const useUpdateOrderPlacement = orderServiceUpdateMutation({ model });
export const useOrderPlacementTable = orderServiceCustomQuery({
  model,
  path: "table",
});

export const exportOrderPlacements = ({ _where, _sort, _limit }) => {
  return orderServiceTable({
    model,
    _where,
    _sort,
    _limit,
    as_csv: true,
  });
};
