import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import qs from "qs";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  message,
  Popconfirm,
  Select,
  Table,
  Typography,
  PageHeader,
} from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { UserAddModal } from "./components.js";
import {
  actionUsersGet,
  actionUserAdd,
  actionUserEdit,
  actionAccountsGet,
  actionWelcomeEmailSend,
} from "actions";
import { usePrevious } from "utils";
import Loader from "components/Loader/Loader";
import { useUserContext } from "context";

const { Option } = Select;
const { Text } = Typography;

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionUsersGet,
  actionUserAdd,
  actionUserEdit,
  actionAccountsGet,
  actionWelcomeEmailSend,
};

export function Users(props) {
  const { organizationId, organizationName } = useUserContext();

  const initialAccountId =
    qs.parse(props.location.search, { ignoreQueryPrefix: true }).id ||
    organizationId;
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [accountId, setAccountId] = useState(initialAccountId);
  const [accountName, setAccountName] = useState(organizationName);

  const getData = useCallback(() => {
    setLoading(true);
    props
      .actionUsersGet(accountId)
      .then(() => props.actionAccountsGet())
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
      });
  }, [accountId, props]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!props.users) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previousLocation = usePrevious(props.location.pathname);
  useEffect(() => {
    if (previousLocation !== props.location.pathname) {
      if (props.location.search) {
        setAccountId(
          qs.parse(props.location.search, {
            ignoreQueryPrefix: true,
          }).id
        );
      }
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname, previousLocation]);

  if (loading) {
    return <Loader />;
  }

  const getColumns = () => [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, result) => (
        <Link to={`/user/${result.id}${props.location.search}`}>{text}</Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created",
      dataIndex: "created_date",
      key: "created",
      render: (text) => moment(text).format("MM-DD-YYYY"),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (text, result) => {
        if (result.active === true) {
          return "True";
        }
        return "False";
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (text, result) => (
        <Popconfirm
          title="Are you sure to disable this user?"
          onConfirm={() => {
            props
              .actionUserEdit({
                id: result.id,
                value: {
                  active: false,
                },
              })
              .then((res) => {
                message.success("User account disabled.");
                getData();
              })
              .catch((err) => {
                message.error("Error disabling user account.");
              });
          }}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      {loading && <Loader />}

      <PageHeader
        title="Users"
        footer={
          <>
            {loading && (
              <Row>
                <LoadingOutlined />
              </Row>
            )}
            <Row className="pageheader-footer__row-btn-container">
              <Col>
                {props.accounts.length > 0 && (
                  <>
                    <Text>Filter users by account: </Text>
                    <Select
                      showSearch
                      disabled
                      optionFilterProp="children"
                      value={Number(accountId)}
                      onChange={(value, e) => {
                        setAccountId(value);
                        setAccountName(e.name);
                        props.history.push({
                          search: `?id=${value}`,
                        });
                        setTimeout(() => {
                          getData();
                        }, 1000);
                      }}
                    >
                      {props.accounts.map((item) => (
                        <Option value={item.id} name={item.name} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </>
                )}
              </Col>
              <Col>
                <Button
                  id="users-show-modal-button"
                  onClick={() => setShowAddModal(true)}
                  type="primary"
                >
                  Add User
                </Button>
              </Col>
            </Row>
          </>
        }
      />

      <Table
        rowKey={(obj) => obj.id}
        columns={getColumns()}
        dataSource={props.users}
        pagination={{
          defaultPageSize: 10,
          position: ["bottomLeft"],
        }}
        className="px-3"
      />

      <UserAddModal
        visible={showAddModal}
        accountId={accountId}
        accountName={accountName}
        onChange={() => getData()}
        handleCancel={() => setShowAddModal(false)}
        {...props}
      />
    </>
  );
}

const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(Users);
export default withRouter(UsersContainer);
