import { Form, Select, Typography } from "antd";
import React from "react";
import _ from "lodash";

const { Text } = Typography;

export default function PrimaryCountySelector({
  counties,
  initialValue,
  isInternal,
}) {
  const options = (counties ?? [])
    .map((c) => {
      return {
        label: c.county_name,
        value: c.county_id,
      };
    })
    .sort((a, b) => a.label - b.label);

  return (
    <>
      <Form.Item
        label="Primary County of Catchment Area"
        name="county_id"
        initialValue={initialValue}
        className="mb-0"
        rules={[
          {
            required: !isInternal,
            message: "Select your county",
          },
        ]}
      >
        <Select
          autoComplete="chrome-off"
          data-cy="select-primary-county"
          options={options}
          showSearch
          placeholder="Select primary county"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={_.isNil(counties) || counties.length === 0}
        />
      </Form.Item>
      <Text type="secondary">
        This should pertain to the catchment area served by the WWTP location,
        rather than the physical address. If no county is associated with the
        catchment area, select the closest option.
      </Text>
    </>
  );
}
