import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Row, Col, Form, DatePicker, Typography, Tooltip } from "antd";
import moment from "moment";
import { dateIsOneDayPast } from "utils/time";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return {
      order: state.order,
    };
  } else {
    return null;
  }
};

const { Title } = Typography;

const mapDispatchToProps = {};

const ShippingDate = (props) => {
  const [shippingDateWarning, setShippingDateWarning] = useState(false);

  useEffect(() => {
    const order = props.order;
    const requestedShippingDate = order["requested-shipping-date"];
    if (requestedShippingDate) {
      moment(requestedShippingDate).diff(moment(), "months") > 2
        ? setShippingDateWarning(true)
        : setShippingDateWarning(false);
    } else {
      setShippingDateWarning(false);
    }
  }, [props.order]);

  return (
    <Row className="mb-3">
      <Col span={24}>
        <Title level={3}>Shipping Date</Title>
      </Col>

      <Col data-cy="shipping-date">
        <Tooltip
          placement="right"
          title={
            "This date is more than 3 months from now. Are you sure it's correct?"
          }
          visible={shippingDateWarning}
        >
          <Form.Item
            name={`requested-shipping-date`}
            label={`Requested shipping date`}
            rules={[
              {
                required: true,
              },
            ]}
            hasFeedback
            validateStatus={shippingDateWarning ? "warning" : ""}
          >
            <DatePicker
              id="input-requested-order-date"
              disabledDate={(current) => {
                return dateIsOneDayPast(current);
              }}
            />
          </Form.Item>
        </Tooltip>
      </Col>
    </Row>
  );
};

const ShippingDateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingDate);
export default ShippingDateContainer;
