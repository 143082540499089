import _, { range } from "lodash";

import {
  AliquotId,
  AliquotStatus,
  KitName,
  TargetQuant,
} from "../../../../api/pipelinesAPI/types";
import {
  CartesianGrid,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceDot,
  ReferenceLine,
} from "recharts";
import moment from "moment/moment";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { KitMetaPartial } from "../types/orderService";
import ChartTooltip from "./ChartTooltip";

interface Props {
  kitNameUnderReview: string;
  targetQuants: TargetQuant[];
  statusMap: Record<AliquotId, AliquotStatus>;
  kitMap: Record<KitName, KitMetaPartial>;
  highlightedAliquotId?: number;
}

interface PCRResult {
  aliquotId: number;
  aliquotName: string;
  kitName: string;
  targetName: string;
  dateOfQPCR: string;
  collectionDate: string;
}

const colorMap: _.Dictionary<string> = {
  InfA: "blue",
  InfB: "purple",
  RSV: "green",
  "sars-cov-2": "blue",
  hMPXV: "blue",
  NoV_GI: "blue",
  NoV_GII: "purple",
  pmmv: "red",
  "brsv vaccine": "yellow",
  "EXO RNA": "orange",
};

const HistoricalQuantChart = ({
  kitNameUnderReview,
  targetQuants,
  statusMap,
  kitMap,
  highlightedAliquotId,
}: Props) => {
  const quantsByAliquot = _.groupBy(targetQuants, (q) => q.qpcr_aliquot_id);

  const pcrResults: PCRResult[] = Object.entries(quantsByAliquot).flatMap(
    ([aliquotIdStr, quants]) => {
      const aliquotId = Number(aliquotIdStr);
      const status = statusMap[aliquotId];
      const aliquotName = status?.qpcr_aliquot.aliquot_name;
      const kitName = status?.qpcr_aliquot?.tube?.kit?.kit_name;

      let collectionDate = kitMap[kitName]?.sample?.sample_collection_date_time;
      collectionDate = moment
        .utc(collectionDate)
        .startOf("day")
        .format("MM/DD/YYYY");

      const result: any = {
        aliquotId: aliquotId,
        aliquotName: aliquotName,
        kitName: kitName,
        dateOfQPCR: quants[0].created_at,
        collectionDate: collectionDate || "???",
      };

      quants.forEach((q) => {
        const targetName = q.primer_target_protocol.primer_target_name;
        const conc = q.copies_per_ml_sewage_adjusted || 0.0;
        const closeToZero = conc >= -0.001 && conc <= 0.001;
        const concentration = closeToZero ? 0.0 : Math.log10(conc);

        result[`conc_${targetName}`] = concentration;
      });

      return result;
    }
  );

  const pcrResultsInCollectionOrder = pcrResults.sort(
    (a: PCRResult, b: PCRResult) => {
      return a.collectionDate.localeCompare(b.collectionDate);
    }
  );

  const firstPCRResults = _.uniqBy(
    pcrResultsInCollectionOrder,
    (x) => x.kitName
  );
  const rerunResults = pcrResultsInCollectionOrder.filter(
    (r) => !firstPCRResults.some((fr) => r.aliquotId === fr.aliquotId)
  );

  const collectionDateUnderReview = firstPCRResults.filter(
    (r) => r.kitName === kitNameUnderReview
  )[0].collectionDate;
  const highlightedResult = pcrResultsInCollectionOrder.filter(
    (r) => r.aliquotId === highlightedAliquotId
  )[0];

  const targetNames = [
    ...new Set(
      targetQuants.map((q) => q.primer_target_protocol.primer_target_name)
    ),
  ];

  return (
    <ResponsiveContainer width="100%" height={550}>
      <LineChart data={firstPCRResults}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="collectionDate"
          height={128}
          label={{ value: "Sample Collection Date", position: "insideBottom" }}
          tick={<CustomizedAxisTick />}
        />
        <YAxis
          label={{
            value: "Log Adjusted Concentration /mL",
            angle: -90,
            position: "insideLeft",
            offset: 30,
          }}
          width={100}
          ticks={range(0, 7)}
        />
        <Tooltip
          content={(props) => {
            return (
              // @ts-ignore
              <ChartTooltip
                {...props}
                targetNames={targetNames}
                firstPcrResults={firstPCRResults}
                rerunResults={rerunResults}
              />
            );
          }}
        />
        <Legend verticalAlign="top" />
        {targetNames.map((name) => (
          <Line
            key={"line-" + name}
            type="linear"
            dataKey={`conc_${name}`}
            stroke={colorMap[name]}
            name={name}
          />
        ))}
        {rerunResults.flatMap((r) => {
          return targetNames.map((targetName) => (
            <ReferenceDot
              x={r.collectionDate}
              // @ts-ignore
              y={r[`conc_${targetName}`]}
              fill={colorMap[targetName]}
            />
          ));
        })}
        <ReferenceLine
          x={collectionDateUnderReview}
          label={kitNameUnderReview}
          stroke="black"
        />
        {highlightedResult && (
          <ReferenceLine
            x={highlightedResult.collectionDate}
            label={highlightedResult.kitName}
            stroke="black"
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HistoricalQuantChart;
