import { Row, Col, Form, Input, Typography } from "antd";

const { Title } = Typography;

export default function Author({ isRequired }) {
  return (
    <>
      <Row>
        <Title level={5}>
          Please share details on who is entering this log
        </Title>
      </Row>
      <Row gutter={48}>
        <Col sm={8} xs={24}>
          <Form.Item
            name="email"
            label={"Email"}
            rules={[
              {
                type: "email",
                required: isRequired,
                message: "Email",
                whitespace: true,
              },
            ]}
            normalize={(value) => value.trim()}
            className="block"
          >
            <Input
              data-cy="author__email-address-input"
              className="full-width"
              placeholder="Enter email address"
              autoComplete="email"
            />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name="first_name"
            label={"First Name"}
            rules={[
              {
                required: isRequired,
                message: "Enter first name",
                min: 1,
                type: "string",
                whitespace: true,
              },
            ]}
            className="block"
          >
            <Input
              data-cy="author__first-name-input"
              className="full-width"
              placeholder="Enter name"
              autoComplete="given-name"
            />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name="last_name"
            label={"Last Name"}
            rules={[
              {
                required: isRequired,
                message: "Enter last name",
                min: 1,
                type: "string",
                whitespace: true,
              },
            ]}
            className="block"
          >
            <Input
              data-cy="author__last-name-input"
              className="full-width"
              placeholder="Enter last name"
              autoComplete="family-name"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
