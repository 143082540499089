import Selector from "./Selector";

const selectorOptions = [
  { label: "Standard Report Email", value: "STANDARD_REPORT_EMAIL" },
  { label: "Reissue Email", value: "REISSUE_EMAIL" },
  { label: "Manually Sent By CX", value: "SEND_MANUALLY" },
];

const selectorText = [
  `"Standard report email" sends the report with the same text as new
  reports through the automated email.`,
  `"Reissue email" sends the report with a standard reissue template
  through the automated email pipeline.`,
  `"Manually sent by CX" provides approved reports to the CX team to
  be manually sent to the customer.`,
];

export default function DeliveryMethodSelector({ className }) {
  return (
    <Selector
      label="Delivery Method"
      options={selectorOptions}
      selectorText={selectorText}
      className={className}
      formItem={{
        placeholder: "Select Method",
        width: "66%",
        name: "delivery_method",
      }}
    />
  );
}
